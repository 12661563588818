/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAppSelector} from '../../redux/hooks/redux'

const REACT_APP_URL = process.env.REACT_APP_URL

const AuthLayout = () => {
  const intl = useIntl()
  const {authBackgroundImage} = useAppSelector((state) => state.serviceReduser)

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='h-100 d-flex flex-column-fluid px-10 justify-content-center'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/misc/background-auth.png')})`,
        backgroundRepeat: `round`,
      }}
    >
      <div className='d-flex flex-column flex-lg-row w-lg-80 justify-content-center'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 order-2 order-lg-1 flex-center'>
          {authBackgroundImage === 'login' ? (
            <img
              className='mx-auto w-md-20 w-xl-450px w-350px h-90'
              src={toAbsoluteUrl('/media/illustrations/sigma-new/2.svg')}
              alt=''
            />
          ) : authBackgroundImage === 'registr' ? (
            <div className='d-flex flex-column flex-center h-90'>
              <img
                className='mx-auto w-md-20 mb-10 w-xl-250px w-150px'
                src={toAbsoluteUrl('/media/illustrations/sigma-new/1.svg')}
                alt=''
              />
              <h1 className='text-dark fs-2sx fw-bolder text-center mb-7 w-lg-550px w-300px'>
                {intl.formatMessage({id: 'REGISTRATION.TEXT.TITLE'})}
              </h1>

              <div className='text-white fs-1x text-center w-lg-550px w-300px'>
                {intl.formatMessage({id: 'REGISTRATION.TEXT.CONTENT_PART1'})}
                <a className='opacity-75-hover text-info fw-bold me-1'>Sidbis</a>
                {intl.formatMessage({id: 'REGISTRATION.TEXT.CONTENT_PART2'})}
              </div>
            </div>
          ) : (
            <img
              className='mx-auto w-md-20 w-xl-450px w-350px h-90'
              src={toAbsoluteUrl('/media/illustrations/sigma-new/3.svg')}
              alt=''
            />
          )}

          <div className='d-flex flex-center flex-wrap px-5'>
            <div className='d-flex fw-semibold text-white fs-base align-items-center'>
              <a href={`${REACT_APP_URL}`}>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logo.svg')}
                  className='h-40px app-sidebar-logo-default me-15 px-5'
                />
              </a>
              <a className='px-5 text-white me-15' href={`${REACT_APP_URL}`}>
                sidbis.com
              </a>

              <a className='px-5 text-white' target='_blank'>
                {intl.formatMessage({id: 'AUTH.HELP'})}
              </a>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-20 order-1 order-lg-2 menu-bg-dark w-lg-400px'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='py-10 px-5'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
