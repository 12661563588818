import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import AsyncSelect from 'react-select/async'
import {scoreAPI} from '../../../../../../redux/services/ScoreService'
import {IObjectionSelect} from '../../../../models/controls'
import {IScoreObjection} from '../../../../../../models/score/Score'

interface SelectObjectionProps {
  setSelectObjection: (objection?: IScoreObjection) => void
  initialDefaultValue?: string
}

const SelectScoreObjection: FC<SelectObjectionProps> = ({
  setSelectObjection,
  initialDefaultValue,
}) => {
  const intl = useIntl()
  const [getScoreObjections] = scoreAPI.useLazyObjectionFetchQuery()
  const [scoreObjections, setScoreObjections] = useState<IScoreObjection[]>()
  const [defaultValue, setDefaultValue] = useState<IObjectionSelect>()

  const loadOptions = (inputValue: string, callback: (options: IObjectionSelect[]) => void) => {
    if (inputValue && inputValue.length >= 3) {
      getScoreObjections({status: 'PUBLISHED', search: inputValue})
        .unwrap()
        .then((response) => {
          callback(filter(response.objections))
        })
    } else if (inputValue === '') {
      getScoreObjections({status: 'PUBLISHED'})
        .unwrap()
        .then((response) => {
          setScoreObjections([...response.objections])
          callback(filter(response.objections))
        })
    } else {
      return callback([])
    }
  }

  const filter = (objections: IScoreObjection[]) => {
    const options: IObjectionSelect[] = []
    objections.forEach((el) => {
      options.push({value: el.id, label: el.name})
    })
    return options
  }

  const handleObjectionSelect = (id?: string) => {
    setDefaultValue(undefined)
    const objection = scoreObjections?.find((el) => el.id === id)
    setSelectObjection(objection)
  }

  useEffect(() => {
    if (initialDefaultValue) {
      const objection = scoreObjections?.find((el) => el.id === initialDefaultValue)
      setDefaultValue({value: objection?.id, label: objection?.name} as IObjectionSelect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreObjections])

  return (
    <>
      <h5 className='fs-6 ms-1 text-dark'>{intl.formatMessage({id: 'OBJECTION.SELECT'})}</h5>
      <div className='mb-2 mt-2'>
        <AsyncSelect
          cacheOptions={false}
          defaultOptions
          isClearable={true}
          loadOptions={loadOptions}
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          placeholder={intl.formatMessage({id: 'OBJECTION.SELECT'})}
          onChange={(e) => handleObjectionSelect(e?.value)}
          isDisabled={false}
          value={defaultValue}
        />
      </div>
    </>
  )
}
export {SelectScoreObjection}
