import {FC} from 'react'
import {useIntl} from 'react-intl'
import {OneObject} from './OneObject'
import {ObjectsTableHeader} from './ObjectsTableHeader'
import {IControlListResponse} from '../../models/controls'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'
import {TablePagination} from '../../../../../_metronic/helpers/pagination/TablePagination'

type Props = {
  controls?: IControlListResponse
  type: string | undefined
}

export const ObjectsTable: FC<Props> = ({controls, type}) => {
  const {state, updateState} = useQueryRequest()
  const intl = useIntl()

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ObjectsTableHeader type={type} />
        {controls?.total !== 0 && (
          <tbody>
            {controls &&
              controls.controls.map((c) => <OneObject control={c} key={c.id} type={type} />)}
          </tbody>
        )}
      </table>
      {controls?.total === 0 && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
      <div className='card-footer px-0'>
        <div className='d-flex flex-stack align-items-center'>
          <div className='justify-content-start'>
            <label>
              <select
                className='form-select form-select-sm form-select-solid'
                value={state.limit}
                onChange={(e) => {
                  updateState({skip: 0, limit: parseInt(e.target.value)})
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </label>
            <span className='small text-uppercase ms-4'>
              {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}: {controls && controls.total}
            </span>
          </div>
          <TablePagination total={controls ? controls.total : 0} />
        </div>
      </div>
    </>
  )
}
