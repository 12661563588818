import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type ObjectionsFilter = {
  objections: {label: string; value: string}[]
  manager: string
  period: {start: string; end: string}
  periods_number: number
}

type selectedObjectionsFilter = {
  usersObjFilter: ObjectionsFilter
  setUsersObjectionFilter: (newObjFilter: ObjectionsFilter) => void
}

const initialStateObjectionsFilter: ObjectionsFilter = {
  objections: [],
  manager: '',
  period: {start: '', end: ''},
  periods_number: 1,
}

const initialStatePeriodsGetControlsCallback: selectedObjectionsFilter = {
  usersObjFilter: initialStateObjectionsFilter,
  setUsersObjectionFilter: () => {},
}

function getObjFilter(key: string): ObjectionsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as ObjectionsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateObjectionsFilter
}

const usersObjectionsFiltersContext = createContext<selectedObjectionsFilter>(
  initialStatePeriodsGetControlsCallback
)

const ObjectionsUserProvider: FC<WithChildren> = ({children}) => {
  const [objFilter, setObjFilter] = useState<ObjectionsFilter>(
    getObjFilter(`${wl.hostname}_objectionsUserFilter`)
  )
  const setFilter = (objFilter: ObjectionsFilter) => {
    localStorage.setItem(`${wl.hostname}_objectionsUserFilter`, JSON.stringify(objFilter))
    setObjFilter(objFilter)
  }

  return (
    <usersObjectionsFiltersContext.Provider
      value={{usersObjFilter: objFilter, setUsersObjectionFilter: setFilter}}
    >
      {children}
    </usersObjectionsFiltersContext.Provider>
  )
}

const useUserObjectionsFilters = () => useContext(usersObjectionsFiltersContext)
export {ObjectionsUserProvider, useUserObjectionsFilters}
