/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ObjectionsTable} from './objections-table/ObjectionsTable'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {clearCheckedItemsAdd} from '../../../../../redux/slices/serviceSlice'
import {useAppDispatch, useAppSelector} from '../../../../../redux/hooks/redux'
import {QueryRequestProvider} from '../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  show: boolean
  groupId: string
  handleClose: () => void
}

const ObjectionList: FC<Props> = ({show, handleClose, groupId}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {idSelectedItemsAdd} = useAppSelector((state) => state.serviceReduser)
  const [addObjectionsToGroup, {isLoading}] = scoreAPI.useGroupObjectionAddMutation()

  const handleCloseModal = () => {
    if (idSelectedItemsAdd.size !== 0) {
      dispatch(clearCheckedItemsAdd())
    }
    handleClose()
  }

  const handleAdd = () => {
    const objectionIds = Array.from(idSelectedItemsAdd.keys())

    addObjectionsToGroup({
      id: groupId,
      data: {objection_ids: objectionIds},
    })
      .unwrap()
      .then(() => handleCloseModal())
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-750px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.ADD'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body className='py-1 mb-2'>
          <div className='card-body p-0'>
            <div className='table-responsive px-15'>
              <QueryRequestProvider>
                <ObjectionsTable skip={!show} groupId={groupId} />
              </QueryRequestProvider>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-sm btn-primary text-uppercase'
            onClick={handleAdd}
            disabled={idSelectedItemsAdd.size === 0 || isLoading}
          >
            {!isLoading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'ORGANIZATION.ADDUSERDEPARTMENT'})}
              </span>
            )}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ObjectionList}
