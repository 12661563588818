import {FC, useEffect, useState} from 'react'
import {OneRow} from './OneRowUsersObjection'
import {ObjectionsHeader} from './UsersObjectionHeader'
import {
  IAnalyticsObjectionUsersResponse,
  IObjectionUserPeriod,
} from '../../../../models/analytics/Analytics'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  objectionsAnalytics?: IAnalyticsObjectionUsersResponse
  selectedUsers?: {label: string; value: string}[]
}
export const UsersObjectionTable: FC<Props> = ({objectionsAnalytics, selectedUsers}) => {
  const [pageObjections, updatePageObjections] = useState<IObjectionUserPeriod[]>([])
  const [allObjections, updateAllObjections] = useState<IObjectionUserPeriod[]>([])
  const {state} = useQueryRequest()

  useEffect(() => {
    if (objectionsAnalytics && selectedUsers?.length !== 0) {
      let emptyUsers: IObjectionUserPeriod[] = []
      selectedUsers?.forEach((user) => {
        let mark = false
        for (let d of objectionsAnalytics?.data) {
          if (user.value === d.created_by.id) {
            mark = true
            break
          }
        }
        if (mark === false) {
          emptyUsers.push({
            created_by: {
              id: user.value,
              name: user.label,
            },
            score: {},
          })
        }
      })
      updateAllObjections(objectionsAnalytics.data.concat(emptyUsers))
    } else if (!objectionsAnalytics) {
      updateAllObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectionsAnalytics])

  useEffect(() => {
    if (allObjections.length !== 0) {
      if (allObjections.length >= state.skip! + state.limit!) {
        updatePageObjections(allObjections.slice(state.skip, state.skip! + state.limit!))
      } else {
        updatePageObjections(allObjections.slice(state.skip))
      }
    } else {
      updatePageObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, allObjections])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ObjectionsHeader
          periods={
            objectionsAnalytics && Object.keys(objectionsAnalytics.periods).length !== 0
              ? objectionsAnalytics?.periods
              : {}
          }
        />
        <tbody>
          {pageObjections.map((u) => (
            <OneRow
              object={u}
              key={u.created_by.id}
              periodsNumber={
                objectionsAnalytics ? Object.keys(objectionsAnalytics.periods).length : 0
              }
            />
          ))}
        </tbody>
      </table>
    </>
  )
}
