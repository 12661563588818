/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICriteria} from '../../../../../models/score/Score'
import {useIntl} from 'react-intl'

type Props = {
  criterion: ICriteria
  show: boolean
  handleClose: () => void
}

const InfoCriterionModal: FC<Props> = ({criterion, show, handleClose}) => {
  const intl = useIntl()

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogclass='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className='small text-uppercase'>{criterion.name}</h4>
          </Modal.Title>
          <div className='w-10 text-end'>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <i className='bi bi-info-circle fs-2hx text-success me-4' />

            <div className='d-flex flex-column'>
              <h4 className='mb-1 fs-7 text-gray-800 text-uppercase'>
                {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.EXCELLENT'})}
              </h4>

              <span className='fs-7'>{criterion.score_zone.MAX_DESCRIPTION}</span>
            </div>
          </div>
          {criterion.rating_scale !== 'BINARY_TEXT_SCALE' &&
            criterion.rating_scale !== 'BINARY_SCALE' && (
              <div className='alert alert-warning d-flex align-items-center p-5'>
                <i className='bi bi-info-circle fs-2hx text-warning me-4' />

                <div className='d-flex flex-column'>
                  <h4 className='mb-1 fs-7 text-gray-800 text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.NORMAL'})}
                  </h4>
                  <span className='fs-7'>{criterion.score_zone.AVERAGE_DESCRIPTION}</span>
                </div>
              </div>
            )}
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <i className='bi bi-info-circle fs-2hx text-danger me-4' />

            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-gray-800'>
                <span className='mb-1 fs-7 text-gray-800 text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.CRITICAL'})}
                </span>
              </h4>

              <span className='fs-7'>{criterion.score_zone.MIN_DESCRIPTION}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {InfoCriterionModal}
