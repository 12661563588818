import clsx from 'clsx'
import type {FC} from 'react'
import {useIntl} from 'react-intl'
import {useDrag, useDrop} from 'react-dnd'
import type {Identifier, XYCoord} from 'dnd-core'
import {useEffect, useRef, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {RoleAccess} from '../../../../models/users/Users'
import {usersAPI} from '../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../redux/services/ScoreService'
import {ICriteria, IStatus} from '../../../../models/score/Score'
import {buttonsBYRatingScale} from '../../../controls/helpers/control-helpers'
import {getAccesses} from '../../../../../_metronic/helpers/components/RoleAccesses'

const ItemTypes = {
  CARD: 'card',
}

interface DragItem {
  id: string
  type: string
  index: number
}

interface Props {
  id: string
  index: number
  formId: string
  status: IStatus
  criterion: ICriteria
  handleShowEditCriterion: (criterion: ICriteria) => void
  handleShowInfoCriterion: (criterion: ICriteria) => void
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

export const CriterionCard: FC<Props> = ({
  id,
  index,
  status,
  formId,
  criterion,
  handleShowEditCriterion,
  handleShowInfoCriterion,
  moveCard,
}) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [excludeCriteriaForm, {isLoading}] = scoreAPI.useFormsActionMutation()
  const [updateCriterionPosition] = scoreAPI.useFormsUpdateCriterionPositionMutation()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const [{handlerId}, drop] = useDrop<DragItem, void, {handlerId: Identifier | null}>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {id, index}
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        updateCriterionPosition({id: formId, criterionId: item.id, position: item.index})
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const hanleRemoveSingleCriterion = (id: string, formId: string) => {
    excludeCriteriaForm({
      id: formId,
      action: 'remove',
      data: {criterion_ids: [id]},
    })
  }

  const opacity = isDragging ? 0.3 : 1
  drag(drop(ref))
  return (
    <div
      ref={ref}
      style={{cursor: isDragging ? 'grabbing' : 'grab', opacity: opacity}}
      className='flex-column-fluid p-0 mb-2'
      data-handler-id={handlerId}
    >
      <div className='rounded bg-gray-200 py-2 px-4 z-index-0'>
        <div className='d-flex fw-bold small align-items-center'>
          <div className='bd-highlight w-100'>
            <div className='d-flex align-items-center menu-title text-gray-900 text-uppercase'>
              {criterion.name}
              {roleAccesses.includes('CRITERIAMODIFY') && (
                <i
                  className='bi bi-pencil text-hover-primary ms-3 cursor-pointer'
                  onClick={() => {
                    handleShowEditCriterion(criterion)
                  }}
                />
              )}
            </div>
            <span className='text-gray-700 small'>
              {intl.formatMessage({id: criterion.rating_scale})}
            </span>
            <div className='d-flex mb-1'>
              {buttonsBYRatingScale(criterion.rating_scale).map((el, i) => {
                if (
                  criterion.rating_scale === 'BINARY_SCALE' ||
                  criterion.rating_scale === 'BINARY_TEXT_SCALE'
                ) {
                  return (
                    <div
                      key={i}
                      className={clsx(
                        'col-1 bg-primary rounded me-2 mt-1 h-2px',
                        {'bg-success': el},
                        {'bg-danger': !el}
                      )}
                    />
                  )
                }
                return (
                  <div
                    key={i}
                    className={clsx(
                      'bg-primary rounded me-2 mt-1 h-2px',
                      {
                        'bg-success':
                          el + 1 <= criterion.score_zone.MAX_RANGE_MAX! &&
                          el + 1 >= criterion.score_zone.MAX_RANGE_MIN!,
                      },
                      {
                        'bg-warning':
                          el + 1 <= criterion.score_zone.AVERAGE_RANGE_MAX! &&
                          el + 1 >= criterion.score_zone.AVERAGE_RANGE_MIN!,
                      },
                      {
                        'bg-danger':
                          el + 1 <= criterion.score_zone.MIN_RANGE_MAX! &&
                          el + 1 >= criterion.score_zone.MIN_RANGE_MIN!,
                      },
                      {col: criterion.rating_scale === 'DECINARY_SCALE'},
                      {'col-1': criterion.rating_scale !== 'DECINARY_SCALE'}
                    )}
                  />
                )
              })}
            </div>
            <div className='text-gray-800 fw-bold fs-7 small'>{criterion.created_by.name}</div>
          </div>
          {status === 'DRAFT' ? (
            <>
              <i
                className='bi bi-info-circle fs-2 text-hover-primary cursor-pointer ms-2 me-2'
                onClick={() => handleShowInfoCriterion(criterion)}
              />
              <OverlayTrigger
                delay={{show: 50, hide: 50}}
                overlay={
                  <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                    {intl.formatMessage({id: 'CRITERION.DELETE'})}
                  </Tooltip>
                }
              >
                {isLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border align-middle  w-20px h-20px' />
                  </span>
                ) : (
                  <i
                    className={clsx(
                      'bi bi-dash-circle fs-2 text-hover-primary cursor-pointer disable'
                    )}
                    onClick={() => hanleRemoveSingleCriterion(criterion.id, formId)}
                  />
                )}
              </OverlayTrigger>
            </>
          ) : (
            <i
              className='bi bi-info-circle fs-2 text-hover-primary ms-2 cursor-pointer'
              onClick={() => handleShowInfoCriterion(criterion)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
