/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IScoreForms} from '../../../../../models/score/Score'
import {scoreAPI} from '../../../../../redux/services/ScoreService'

type Props = {
  form: IScoreForms
  show: boolean
  handleClose: () => void
}

const DeleteFormModal: FC<Props> = ({form, show, handleClose}) => {
  const intl = useIntl()
  const [deleteForm, {isLoading}] = scoreAPI.useFormsDeleteMutation()

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <div className='w-100 text-end'>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='px-7'>
          <div className='w-100 text-center'>
            <label className='fs-6 mb-2'>{intl.formatMessage({id: 'FORM.DELETE.TEXT'})}</label>{' '}
            <label className='fw-bolder text-uppercase'>{form.name}</label>?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className='card-footer d-flex justify-content-end py-2 px-1'
            style={{border: 'none'}}
          >
            <button
              type='button'
              className='btn btn-sm btn-primary text-uppercase'
              onClick={() => {
                deleteForm({id: form.id})
                  .unwrap()
                  .then(() => {
                    handleClose()
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }}
              disabled={isLoading}
            >
              {!isLoading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'COMMON.BUTTON.DELETE'})}
                </span>
              )}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {DeleteFormModal}
