import {FC} from 'react'
import {usersAPI} from '../../../../../../redux/services/UsersService'
import {formatDateByZone} from '../../../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  createdOn: string
}

const ObjectionCreatedOnCell: FC<Props> = ({createdOn}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  return <>{formatDateByZone(createdOn!, currentUser?.language, currentUser?.time_zone)}</>
}

export {ObjectionCreatedOnCell}
