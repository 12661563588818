import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  type: string | undefined
}

const ObjectsTableHeader: FC<Props> = ({type}) => {
  const intl = useIntl()
  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='w-150px'>
          {intl.formatMessage({id: 'OBJECTS.TABLE.CALLDATE'})}
        </th>
        <th role='columnheader' className='w-150px'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
        </th>
        <th role='columnheader' className='w-100px'>
          {intl.formatMessage({id: 'CONTROLS.TYPE'})}
        </th>
        {type === 'CALL' && (
          <>
            <th role='columnheader' className='w-100px'>
              {intl.formatMessage({id: 'FILTERS.DIRECTION'})}
            </th>
            <th role='columnheader' className='w-100px'>
              {intl.formatMessage({id: 'FILTERS.DURATION'})}
            </th>
            <th role='columnheader' className='w-100px'>
              {intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
            </th>
          </>
        )}
        <th role='columnheader' className='w-150px'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.STATUS'})}
        </th>
        <th role='columnheader' className='w-70px'></th>
      </tr>
    </thead>
  )
}

export {ObjectsTableHeader}
