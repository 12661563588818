import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {
  getDepartmentsList,
  localeSettingsDepartmentsSelect,
} from '../../../../../../_metronic/helpers/components/GetDepartments'
import {departmentsAPI} from '../../../../../redux/services/DepartmentsService'
import {
  IAnyRatingScale,
  ICreateScoreForms,
  IRatingScale,
  IScoreForms,
  IScoreType,
} from '../../../../../models/score/Score'

const scoreFormsEditShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  form: IScoreForms
  show: boolean
  handleClose: () => void
}

const UpdateScoreForm: FC<Props> = ({form, show, handleClose}) => {
  const intl = useIntl()
  const [updateScoreForms, {isLoading, isError}] = scoreAPI.useFormsUpdateMutation()
  const [criterionRatingScale, setcriterionRatingScale] = useState<
    IAnyRatingScale | IRatingScale | ''
  >(form.rating_scale)
  const [scoreType, setScoreType] = useState<IScoreType[]>(form.types)

  const [departmentsOptions, setDepOptions] = useState<{label: string; value: string}[]>([])
  const [getDepartments, {data: departments, isLoading: getDepLoading}] =
    departmentsAPI.useLazyDepartmentsFetchQuery()

  if (departments) {
    departments.departments.all.map((d) => departmentsOptions.push({label: d.name, value: d.id}))
  }

  let formDepartments: {label: string; value: string}[] = []
  if (form.departments) {
    for (let i = 0; i < form.departments?.length; i++) {
      formDepartments.push({label: form?.departments[i].name, value: form?.departments[i].id})
    }
  }

  const [selected, setSelected] = useState(formDepartments)

  const [maxRangeMin, setMaxRangeMin] = useState(
    form.score_zone && form.score_zone.MAX_RANGE_MIN
      ? form.score_zone.MAX_RANGE_MIN?.toString()
      : ''
  )
  const [averageRangeMax, setAverageRangeMax] = useState(
    form.score_zone && form?.score_zone.AVERAGE_RANGE_MAX
      ? form.score_zone.AVERAGE_RANGE_MAX?.toString()
      : ''
  )
  const [averageRangeMin, setAverageRangeMin] = useState(
    form.score_zone && form.score_zone.AVERAGE_RANGE_MIN
      ? form.score_zone.AVERAGE_RANGE_MIN?.toString()
      : ''
  )
  const [minRangeMax, setMinRangeMax] = useState(
    form.score_zone && form.score_zone.MIN_RANGE_MAX
      ? form.score_zone.MIN_RANGE_MAX?.toString()
      : ''
  )

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
    setAverageRangeMax(
      form.score_zone && form.score_zone.AVERAGE_RANGE_MAX
        ? form.score_zone.AVERAGE_RANGE_MAX?.toString()
        : ''
    )
    setAverageRangeMin(
      form.score_zone && form.score_zone.AVERAGE_RANGE_MIN
        ? form.score_zone.AVERAGE_RANGE_MIN?.toString()
        : ''
    )
    setMaxRangeMin(
      form.score_zone && form.score_zone.MAX_RANGE_MIN
        ? form.score_zone.MAX_RANGE_MIN?.toString()
        : ''
    )
    setMinRangeMax(
      form.score_zone && form.score_zone.MIN_RANGE_MAX
        ? form.score_zone.MIN_RANGE_MAX?.toString()
        : ''
    )
  }

  const formik = useFormik({
    initialValues: {name: form.name, description: form.description},
    validationSchema: scoreFormsEditShema(intl.formatMessage),
    onSubmit: async (values) => {
      // const initScoreZone = {
      //   MAX_RANGE_MIN: form.score_zone.MAX_RANGE_MIN ? form.score_zone.MAX_RANGE_MIN : 80,
      //   AVERAGE_RANGE_MAX: form.score_zone.AVERAGE_RANGE_MAX
      //     ? form.score_zone.AVERAGE_RANGE_MAX
      //     : 79,
      //   AVERAGE_RANGE_MIN: form.score_zone.AVERAGE_RANGE_MIN
      //     ? form.score_zone.AVERAGE_RANGE_MIN
      //     : 30,
      //   MIN_RANGE_MAX: form.score_zone.MIN_RANGE_MAX ? form.score_zone.MIN_RANGE_MAX : 29,
      // }
      // const scoreZone = {
      //   MAX_RANGE_MIN: parseInt(maxRangeMin),
      //   AVERAGE_RANGE_MAX: parseInt(averageRangeMax),
      //   AVERAGE_RANGE_MIN: parseInt(averageRangeMin),
      //   MIN_RANGE_MAX: parseInt(minRangeMax),
      // }
      const data = {
        name: values.name,
        description: values.description,
        rating_scale: criterionRatingScale,
        score_types: scoreType,
        department_ids: getDepartmentsList(selected),
        // score_zone: scoreView === 'PERCENT' ? scoreZone : initScoreZone,
      }
      await updateScoreForms({id: form.id, data: data as ICreateScoreForms})
        .unwrap()
        .then((response) => {
          handleClose()
        })
        .catch((error) => {
          console.log(error)
          formik.resetForm()
        })
    },
  })

  useEffect(() => {
    if (maxRangeMin !== '') {
      setAverageRangeMax((parseInt(maxRangeMin) - 1).toString())
    }
  }, [maxRangeMin])

  useEffect(() => {
    if (averageRangeMax !== '') {
      setMaxRangeMin((parseInt(averageRangeMax) + 1).toString())
    }
  }, [averageRangeMax])

  useEffect(() => {
    if (averageRangeMin !== '') {
      setMinRangeMax((parseInt(averageRangeMin) - 1).toString())
    }
  }, [averageRangeMin])

  useEffect(() => {
    if (minRangeMax !== '') {
      setAverageRangeMin((parseInt(minRangeMax) + 1).toString())
    }
  }, [minRangeMax])

  useEffect(() => {
    if (departments) {
      let depsOptions: {label: string; value: string}[] = []
      departments.departments.all.map((d) => depsOptions.push({label: d.name, value: d.id}))
      setDepOptions(depsOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments])

  return (
    <>
      <Modal
        key={form.id}
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'SCOREFORMS.UPDATE.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='px-7'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.FORMNAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'SCOREFORMS.FORMNAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.DESCRIPTION'})}
                </span>
              </label>
              <textarea
                placeholder={intl.formatMessage({id: 'SCOREFORMS.DESCRIPTION'})}
                {...formik.getFieldProps('description')}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
              />
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.DEPARTMENTS'})}
                </span>
              </label>
              <div
                onClick={() => {
                  if (!departments) {
                    getDepartments()
                  }
                }}
              >
                <MultiSelect
                  className='form-control-select form-control-select-lg text-gray-900'
                  options={departmentsOptions}
                  hasSelectAll={true}
                  value={selected}
                  onChange={setSelected}
                  overrideStrings={localeSettingsDepartmentsSelect(
                    intl.formatMessage,
                    getDepLoading
                  )}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.DEPARTMENTS.SELECT'})}
                />
              </div>
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.RATINGSCALE'})}
                </span>
              </label>
              {form.status === 'DRAFT' ? (
                <select
                  className='form-select form-select-solid form-select-lg text-gray-900'
                  defaultValue={form.rating_scale}
                  onChange={(e: any) => {
                    setcriterionRatingScale(e.target.value)
                  }}
                >
                  <option value='' disabled></option>
                  <option value='ANY'>{intl.formatMessage({id: 'ANY'})}</option>
                  <option value='TERNARY_SCALE'>{intl.formatMessage({id: 'TERNARY_SCALE'})}</option>
                  <option value='BINARY_SCALE'>{intl.formatMessage({id: 'BINARY_SCALE'})}</option>
                  <option value='BINARY_TEXT_SCALE'>
                    {intl.formatMessage({id: 'BINARY_TEXT_SCALE'})}
                  </option>
                  <option value='QUATERNARY_SCALE'>
                    {intl.formatMessage({id: 'QUATERNARY_SCALE'})}
                  </option>
                  <option value='QUINKARNARY_SCALE'>
                    {intl.formatMessage({id: 'QUINKARNARY_SCALE'})}
                  </option>
                  <option value='DECINARY_SCALE'>
                    {intl.formatMessage({id: 'DECINARY_SCALE'})}
                  </option>
                </select>
              ) : (
                <input
                  disabled
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
                  type='text'
                  value={intl.formatMessage({id: form.rating_scale})}
                />
              )}
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 mb-5 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.SCORETYPE'})}
                </span>
              </label>
              <div className='d-flex flex-wrap'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-5'>
                  <input
                    disabled={form.status !== 'DRAFT'}
                    className='form-check-input'
                    type='checkbox'
                    value='PERCENT_SIMPLE'
                    defaultChecked={form.types.indexOf('PERCENT_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('PERCENT_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'PERCENT_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                  />
                  <label className='label ms-2'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.SIMPLE'})} (Per)
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-5'>
                  <input
                    disabled={form.status !== 'DRAFT'}
                    className='form-check-input'
                    type='checkbox'
                    value='NUMBER_AVERAGE_SIMPLE'
                    defaultChecked={form.types.indexOf('NUMBER_AVERAGE_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('NUMBER_AVERAGE_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'NUMBER_AVERAGE_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                  />
                  <label className='label ms-2'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.AVERAGE'})} (Avr)
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                  <input
                    disabled={form.status !== 'DRAFT'}
                    className='form-check-input'
                    type='checkbox'
                    value='NUMBER_SUM_SIMPLE'
                    defaultChecked={form.types.indexOf('NUMBER_SUM_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('NUMBER_SUM_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'NUMBER_SUM_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                  />
                  <label className='label ms-2'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.SUM'})} (Sum)
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {UpdateScoreForm}
