import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {loginAction, logoutAction} from '../slices/authSlice'
import {RootState} from '../Store'
import {Mutex} from 'async-mutex'

interface IAuthModel {
  access_token: string
}

const API_URL = process.env.REACT_APP_API_URL
const REACT_APP_PROFILE_NAME = process.env.REACT_APP_PROFILE_NAME
const wl = window.location

function api_url() {
  if (wl.hostname.split('.')[0] === REACT_APP_PROFILE_NAME) {
    return API_URL
  } else {
    return API_URL?.replace('api', wl.hostname.split('.')[0])
  }
}

const mutex = new Mutex()

const baseQueryStaticUrl = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, {getState}) => {
    headers.set('Content-Type', 'application/json')
    const accessToken = (getState() as RootState).authReduser.accessToken
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  credentials: 'include',
})

const baseQueryDynamicUrl = fetchBaseQuery({
  baseUrl: api_url(),
  prepareHeaders: (headers, {getState}) => {
    headers.set('Content-Type', 'application/json')
    const accessToken = (getState() as RootState).authReduser.accessToken
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  credentials: 'include',
})

export const baseQueryWithReauthStaticUrl: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQueryStaticUrl(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQueryStaticUrl(
          {
            url: '/auth/refresh-token',
            method: 'POST',
            body: {grant_type: 'refresh_token', refresh_token: 'cookie'},
          },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          api.dispatch(loginAction(refreshResult.data as IAuthModel))
          result = await baseQueryStaticUrl(args, api, extraOptions)
        } else {
          api.dispatch(logoutAction())
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQueryStaticUrl(args, api, extraOptions)
    }
  }
  return result
}

export const baseQueryWithReauthDynamicUrl: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQueryDynamicUrl(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQueryDynamicUrl(
          {
            url: '/auth/refresh-token',
            method: 'POST',
            body: {grant_type: 'refresh_token', refresh_token: 'cookie'},
          },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          api.dispatch(loginAction(refreshResult.data as IAuthModel))
          result = await baseQueryDynamicUrl(args, api, extraOptions)
        } else {
          api.dispatch(logoutAction())
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQueryDynamicUrl(args, api, extraOptions)
    }
  }
  return result
}
