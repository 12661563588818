import {FC, useEffect, useRef, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import {useAppDispatch} from '../../redux/hooks/redux'
import {authAPI} from '../../redux/services/AuthService'
import {profileAPI} from '../../redux/services/ProfileService'
import {usersAPI} from '../../redux/services/UsersService'
import {logoutAction} from '../../redux/slices/authSlice'

const Logout: FC = () => {
  const dispatch = useAppDispatch()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [logoutUser] = authAPI.useLazyLogoutQuery()

  useEffect(() => {
    const logout = async () => {
      if (!didRequest.current) {
        await logoutUser()
        dispatch(logoutAction())
        dispatch(authAPI.util.resetApiState())
        dispatch(profileAPI.util.resetApiState())
        dispatch(usersAPI.util.resetApiState())
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, logoutUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <Navigate to={'/auth/'} replace />
}

export {Logout}
