import {FC, useEffect, useState} from 'react'
import {
  selectReduserAddRow,
  selectReduserRemoveRow,
} from '../../../../../../redux/slices/serviceSlice'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {ICriteria, IGroup, IScoreForms} from '../../../../../../models/score/Score'
import {CriterionNameCell} from '../../../criteria/criteria-table/cells/CriterionNameCell'

type Props = {
  object: {type: string; object: IGroup | IScoreForms}
  criteria: ICriteria
  page: number
}

const OneCriteriaExclude: FC<Props> = ({object, criteria, page}) => {
  const dispatch = useAppDispatch()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  const [checked, setChecked] = useState<boolean>(false)

  const handleCheck = (isSelected: boolean) => {
    setChecked(isSelected)
    if (isSelected) {
      if (!selected.rows.get(criteria.id)) {
        dispatch(selectReduserAddRow({page: page, id: criteria.id}))
      }
    } else {
      if (selected.rows.get(criteria.id)) {
        dispatch(selectReduserRemoveRow({page: page, id: criteria.id}))
      }
    }
  }

  useEffect(() => {
    if (selected.rows.get(criteria.id)) {
      handleCheck(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria])

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else if (selected.all.get(page) === 'unChecked') {
      handleCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.all])

  return (
    <>
      <tr role='row' className='fs-7 text-gray-800 small fw-bold'>
        <td role='cell' className='col-2 px-0'>
          <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={checked}
              onChange={(e) => handleCheck(e.target.checked)}
            />
          </div>
        </td>
        <td role='cell' className='col-5 px-0'>
          <CriterionNameCell criterionName={criteria.name} ratingScale={criteria.rating_scale} />
        </td>
        <td role='cell' className='col-5 ps-8'>
          {object.object.name}
        </td>
      </tr>
    </>
  )
}

export {OneCriteriaExclude}
