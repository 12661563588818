/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {useIntl} from 'react-intl'
import WaveSurfer from 'wavesurfer.js'
import Filter from './components/Filter'
import {FC, useEffect, useState} from 'react'
import {ControlForm} from '../form/ControlForm'
import {KTSVG} from '../../../../_metronic/helpers'
import Regions from 'wavesurfer.js/dist/plugins/regions'
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {usersAPI} from '../../../redux/services/UsersService'
import AddObjection from './components/objection/AddObjection'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'
import AddBreakpoint from './components/breakpoint/AddBreakpoint'
import {objectionAPI} from '../../../redux/services/ObjectionService'
import {RGBAToString, StringToRGBA} from '../helpers/control-helpers'
import {
  IBreakPointType,
  IBreakPoints,
  IControlData,
  IControlRecord,
  ICreator,
  ICurrentRegion,
  IObjection,
  IStringColorType,
} from '../models/controls'
import {breakpointAPI} from '../../../redux/services/BreakpointsService'
import {ControlObjection} from './components/objection/ControlObjection'
import {ControlBreakpoint} from './components/breakpoint/ControlBreakpoint'

const DefaultRangeSec = 8
const initialCurrentRegion: ICurrentRegion = {start: 0, end: 0}

type Props = {
  control: IControlData
  controlRecord: IControlRecord
  loadingRecord: boolean
  isErrorGetRecord: boolean
  errorMessage?: string
}

const ControlDetail: FC<Props> = ({
  control,
  controlRecord,
  loadingRecord,
  isErrorGetRecord,
  errorMessage,
}) => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>()
  const [waveSurferRegions, setWaveSurferRegions] = useState<Regions>()
  const [isPlayPause, setIsPlayPause] = useState<boolean>(false)
  const [comicalVoice, setComicalVoice] = useState<boolean>(false)
  const [speed, setSpeed] = useState(1)
  const [showZoom, setShowZoom] = useState<boolean>(false)
  const [zoomValue, setZoomValue] = useState<number>(0)
  const [addRangeComment, setAddRangeComment] = useState<boolean>(false)
  const [addRangeObjection, setAddRangeObjection] = useState<boolean>(false)
  const [addSingleComment, setAddSingleComment] = useState<boolean>(false)
  const [commentBreakpoint, setCommentBreakpoint] = useState<string>('')
  const [commentObjection, setCommentObjection] = useState<string>('')
  const [currentRegion, setCurrentRegion] = useState<ICurrentRegion>(initialCurrentRegion)
  const [currentRegionIsPlay, setCurrentRegionIsPlay] = useState<boolean>(false)
  const [breakPoints, setBreakPoints] = useState<IBreakPoints[]>([])
  const [objections, setObjections] = useState<IObjection[]>([])
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [mute, setMute] = useState<boolean>(false)
  const [newCommentColor, setNewCommentColor] = useState<IStringColorType>('DEFAULT')
  const [creatingRegions, setCreatingRegions] = useState<boolean>(true)
  const [breakpointCreate] = breakpointAPI.useBreakpointCreateMutation()
  const [breakpointDelete] = breakpointAPI.useBreakpointDeleteMutation()
  const [objectionCreate] = objectionAPI.useObjectionCreateMutation()
  const [objectionDelete] = objectionAPI.useObjectionDeleteMutation()
  const [isError, setIsError] = useState<boolean>(isErrorGetRecord)
  const [downloadRecordBlob, setDownloadRecordBlob] = useState<string | undefined>()
  const [showBreakpoints, setShowBreakpoints] = useState<boolean>(true)
  const [showObjections, setShowObjections] = useState<boolean>(true)

  const PlayPause = () => {
    waveSurfer?.playPause()
  }

  useEffect(() => {
    if (control.breakpoints && control.breakpoints.length > 0) {
      const initBreakPoints = [...control.breakpoints!]
      const sortInitBreakPoints = initBreakPoints.sort(
        (a, b) =>
          new Date(b.created_on as string).getTime() - new Date(a.created_on as string).getTime()
      )
      setBreakPoints(sortInitBreakPoints)
    } else {
      setBreakPoints(control.breakpoints!)
    }

    setObjections(control.objections!)
  }, [control])

  async function convert_base64_to_blob(params: string) {
    const response = await fetch(params)
    return response.blob().then((blob) => setDownloadRecordBlob(URL.createObjectURL(blob)))
  }

  useEffect(() => {
    if (controlRecord?.record_src !== undefined) {
      convert_base64_to_blob(controlRecord.record_src)
      const wavesurfer = WaveSurfer.create({
        container: `#waveform_${control!.id}`,
        waveColor: '#5f19c2',
        progressColor: '#B5B5C3',
        audioRate: speed,
      })
      wavesurfer.load(controlRecord?.record_src as string).catch((e) => {
        wavesurfer.destroy()
        setIsError(true)
      })
      wavesurfer.on('timeupdate', (currentTime) => {
        setCurrentTime(parseFloat(currentTime.toFixed(2)))
      })
      wavesurfer.on('finish', function () {
        setIsPlayPause(false)
        setMute(false)
      })
      wavesurfer.on('play', function () {
        setIsPlayPause(true)
      })
      wavesurfer.on('pause', function () {
        setIsPlayPause(false)
        setCurrentRegionIsPlay(false)
      })

      wavesurfer.registerPlugin(TimelinePlugin.create())
      const wavesurferRegion = wavesurfer.registerPlugin(Regions.create())

      wavesurferRegion.on('region-updated', (region) => {
        setCurrentRegion({
          id: region.id,
          start: parseFloat(region.start.toFixed(2)),
          end: parseFloat(region.end.toFixed(2)),
        })
      })

      setWaveSurfer(wavesurfer)
      setWaveSurferRegions(wavesurferRegion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlRecord?.record_src])

  useEffect(() => {
    if (waveSurferRegions) {
      breakPoints.map((bp) =>
        waveSurferRegions.addRegion({
          id: bp.id,
          start: bp.detail.start,
          end: bp.type === 'SINGLE' ? bp.detail.start : bp.detail.end,
          color: StringToRGBA(bp.comment_color ? bp.comment_color : newCommentColor),
          drag: false,
          resize: false,
          minLength: bp.type === 'SINGLE' ? 0 : 1,
        })
      )
      objections.map((obj) =>
        waveSurferRegions.addRegion({
          id: obj.id,
          start: obj.detail.start,
          end: obj.detail.end,
          color: StringToRGBA('BLUE'),
          drag: false,
          resize: false,
          minLength: 1,
        })
      )
      setTimeout(() => {
        setCreatingRegions(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveSurferRegions])

  useEffect(() => {
    if (isErrorGetRecord) {
      setCreatingRegions(false)
      setIsError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorGetRecord])

  const createSingleComment = () => {
    if (control.type === 'CALL' && waveSurfer?.isPlaying()) {
      waveSurfer?.pause()
    }
    if (control.type === 'CALL') {
      const uuid = uuidv4()
      let startFixed = parseFloat(waveSurfer!?.getCurrentTime().toFixed(2))

      breakpointSingleDefault(uuid, startFixed)
      setCurrentRegion({
        id: uuid,
        start: startFixed,
      })
    }

    setAddSingleComment(true)
  }

  const breakpointSingleDefault = (uuid: string, start: number) => {
    waveSurferRegions!.addRegion({
      id: uuid,
      start: start,
    })
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === uuid)
    cr![0].setOptions({color: StringToRGBA('DEFAULT')})
  }

  const createRangeComment = () => {
    if (waveSurfer?.isPlaying()) {
      waveSurfer?.pause()
    }

    const uuid = uuidv4()
    let startFixed = parseFloat(waveSurfer!?.getCurrentTime().toFixed(2)) - DefaultRangeSec
    let endFixed = parseFloat(waveSurfer!?.getCurrentTime().toFixed(2))
    if (startFixed <= 0) {
      endFixed += Math.abs(startFixed)
      startFixed = 0
    }

    breakpointRangeDefault(uuid, startFixed, endFixed)
    setCurrentRegion({
      id: uuid,
      start: startFixed,
      end: endFixed,
    })
    setAddRangeComment(true)
  }

  const breakpointRangeDefault = (uuid: string, start: number, end: number) => {
    waveSurferRegions!.addRegion({
      id: uuid,
      start: start,
      end: end,
      color: StringToRGBA('DEFAULT'),
      minLength: 1,
    })
  }

  const currentRegionPlayStop = () => {
    if (currentRegionIsPlay) {
      waveSurfer?.pause()
    } else {
      waveSurfer?.pause()
      setTimeout(() => {
        const regions = waveSurferRegions?.getRegions()
        const cr = regions?.filter((region) => region.id === currentRegion.id)
        setCurrentRegionIsPlay(true)
        cr![0].play()
      }, 100)
    }
  }

  const updateRegion = (color: string) => {
    if (control.type === 'CALL') {
      const regions = waveSurferRegions?.getRegions()
      const cr = regions?.filter((region) => region.id === currentRegion.id)
      cr![0].setOptions({color: color})
    }
    setNewCommentColor(RGBAToString(color)!)
  }

  const handleChangeStart = (start: number) => {
    setCurrentRegion({id: currentRegion.id, start: start, end: currentRegion.end})
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === currentRegion.id)
    cr![0].setOptions({start: start})
  }

  const handleChangeEnd = (end: number) => {
    setCurrentRegion({id: currentRegion.id, start: currentRegion.start, end: end})
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === currentRegion.id)
    cr![0].setOptions({end: end})
  }

  const handleCancelBreakpoint = () => {
    setAddRangeComment(false)
    setAddSingleComment(false)
    if (control.type === 'CALL') {
      const regions = waveSurferRegions?.getRegions()
      const cr = regions?.filter((region) => region.id === currentRegion.id)
      cr![0].remove()
    }

    setCommentBreakpoint('')
    setNewCommentColor('DEFAULT')
    if (currentRegionIsPlay) {
      waveSurfer?.pause()
    }
  }

  const handleCancelObjection = () => {
    setAddRangeObjection(false)
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === currentRegion.id)
    if (cr) {
      cr![0].remove()
    }
    setCommentObjection('')
    if (currentRegionIsPlay) {
      waveSurfer?.pause()
    }
  }

  // ==== HANDLE SAVE BREAKPOINT ====
  const handleSaveBreakpoint = (type: IBreakPointType) => {
    const created_by: ICreator = {id: currentUser!?.id, name: currentUser?.name}
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === currentRegion.id)
    if (cr) {
      cr![0].setOptions({drag: false, resize: false})
    }

    const brDetail = (type: IBreakPointType) => {
      if (control.type === 'CALL') {
        if (type === 'RANGE') {
          return {
            start: parseFloat(cr![0].start.toFixed(2)),
            end: parseFloat(cr![0].end.toFixed(2)),
          }
        } else {
          return {
            start: parseFloat(cr![0].start.toFixed(2)),
          }
        }
      } else {
        return {start: 0, end: 0}
      }
    }

    const newBreakPoint: IBreakPoints = {
      id: cr ? cr![0].id : undefined,
      comment: commentBreakpoint,
      comment_color: cr ? RGBAToString(cr![0].color)! : newCommentColor,
      created_by: created_by as ICreator,
      created_on: moment().toISOString().slice(0, -1),
      type: type,
      detail: brDetail(type),
    }

    if (breakPoints.length > 0) {
      setBreakPoints([newBreakPoint, ...breakPoints])
    } else {
      setBreakPoints([...breakPoints, newBreakPoint])
    }
    setAddRangeComment(false)
    setAddSingleComment(false)
    setCurrentRegion(initialCurrentRegion)
    setCommentBreakpoint('')
    setNewCommentColor('DEFAULT')

    breakpointCreate({
      id: cr ? cr![0].id : undefined,
      control_id: control.id,
      comment: commentBreakpoint,
      comment_color: cr ? RGBAToString(cr![0].color)! : newCommentColor,
      user_id: currentUser!?.id,
      type: type,
      detail: brDetail(type),
    })
  }

  const handleRemoveBreakPoint = (id: string) => {
    const newBreakPoints = [...breakPoints]
    newBreakPoints.findIndex((br, index, arr) => br.id === id && arr.splice(index, 1))
    setBreakPoints([...newBreakPoints])
    breakpointDelete({id: id})
  }

  const handleRemoveObjection = (id: string) => {
    const newObjections = [...objections]
    const filtered = newObjections.filter((br) => br.id !== id)
    setObjections([...filtered])

    objectionDelete({id: id})
  }

  // ==== HANDLE SAVE OBJECTION ====
  const handleSaveObjection = (objection_value: number, score_objection_id: string) => {
    const regions = waveSurferRegions?.getRegions()
    const cr = regions?.filter((region) => region.id === currentRegion.id)
    if (cr !== undefined) {
      cr![0].setOptions({drag: false, resize: false})
    }

    const pointDetail = () => {
      return {
        start: parseFloat(cr![0].start.toFixed(2)),
        end: parseFloat(cr![0].end.toFixed(2)),
      }
    }
    setAddRangeComment(false)
    setAddSingleComment(false)
    setAddRangeObjection(false)
    setCurrentRegion(initialCurrentRegion)
    setCommentObjection('')

    objectionCreate({
      id: control.type === 'CALL' ? cr![0].id : undefined,
      score_objection_id: score_objection_id,
      comment: commentObjection,
      objection_value: objection_value,

      detail: control.type === 'CALL' ? pointDetail() : {start: 0, end: 0},
      control_id: control.id,
      user_id: currentUser!.id,
    })
      .unwrap()
      .then((response) => {
        setObjections([...objections, response])
      })
  }

  const objectionRangeDefault = (uuid: string, start: number, end: number) => {
    waveSurferRegions!.addRegion({
      id: uuid,
      start: start,
      end: end,
      color: StringToRGBA('BLUE'),
      minLength: 1,
    })
  }

  const createRangeObjection = () => {
    if (control.type === 'CALL') {
      if (waveSurfer?.isPlaying()) {
        waveSurfer?.pause()
      }

      const uuid = uuidv4()
      let startFixed = parseFloat(waveSurfer!?.getCurrentTime().toFixed(2)) - DefaultRangeSec
      let endFixed = parseFloat(waveSurfer!?.getCurrentTime().toFixed(2))
      if (startFixed <= 0) {
        endFixed += Math.abs(startFixed)
        startFixed = 0
      }

      objectionRangeDefault(uuid, startFixed, endFixed)

      setCurrentRegion({
        id: uuid,
        start: startFixed,
        end: endFixed,
      })
    }
    setAddRangeObjection(true)
  }

  const handlerShowBreakpoints = () => {
    setShowBreakpoints(!showBreakpoints)
  }

  const handlerShowObjections = () => {
    setShowObjections(!showObjections)
  }

  return (
    <>
      <div className='card card-custom card-border mt-4'>
        <span className='small text-gray-500 mt-1 ms-2'>{control.id}</span>
        <div className='card-body p-3'>
          {control.type === 'MEETING' && (
            <div className='d-flex flex-center mb-5'>
              <a
                className='btn btn-sm btn-light-primary text-uppercase'
                href={control.meeting.link}
                target='_blank'
              >
                <span className='svg-icon svg-icon-muted svg-icon-1x'>
                  <KTSVG path='/media/icons/duotune/arrows/arr095.svg' />
                </span>
                {intl.formatMessage({id: 'CONTROL.GOTOLINK'})}
              </a>
            </div>
          )}
          {loadingRecord ? (
            <div className='overlay overlay-block p-5'>
              <div className='overlay-layer card-rounded bg-opacity-5'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {isError ? (
                <span className='text-danger fs-5 d-flex justify-content-center'>
                  {errorMessage ? (
                    <span className='text-uppercase'>Bitrix24 {errorMessage}</span>
                  ) : (
                    intl.formatMessage({id: 'CONTROL.RECORDNOTFOUND'})
                  )}
                  {/* {intl.formatMessage({id: 'CONTROL.RECORDNOTFOUND'})} */}
                </span>
              ) : (
                <>
                  {controlRecord && (
                    <>
                      <div id={`waveform_${control!.id}`} className='wavesurfer waveform'></div>
                      <div className='d-flex flex-center mt-4'>
                        <div className='d-flex align-items-center me-2'>
                          <input
                            type='text'
                            min={0}
                            step={0.1}
                            className='form-control form-control-sm w-80px border-0 bg-body'
                            value={`${currentTime} sec`}
                            disabled
                          />
                        </div>
                        <label>
                          <select
                            className='form-select form-select-sm form-select-solid bg-body border-0'
                            value={speed}
                            onChange={(e) => {
                              setSpeed(parseFloat(e.target.value))
                              waveSurfer?.setPlaybackRate(parseFloat(e.target.value), !comicalVoice)
                            }}
                          >
                            <option value='0.25'>0.25x</option>
                            <option value='0.5'>0.50x</option>
                            <option value='0.75'>0.75x</option>
                            <option value='1'>1.0x</option>
                            <option value='1.25'>1.25x</option>
                            <option value='1.5'>1.50x</option>
                            <option value='1.75'>1.75x</option>
                            <option value='2'>2.0x</option>
                          </select>
                        </label>

                        <button className='btn btn-icon mx-1' onClick={() => waveSurfer?.skip(-15)}>
                          <OverlayTrigger
                            delay={{show: 50, hide: 50}}
                            overlay={
                              <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                                - 15 {intl.formatMessage({id: 'CONTROLS.SECONDS'})}
                              </Tooltip>
                            }
                          >
                            <i className='bi bi-arrow-counterclockwise fs-2 text-hover-primary' />
                          </OverlayTrigger>
                        </button>

                        <button className='btn btn-icon mx-6 play-pause' onClick={PlayPause}>
                          <i
                            className={clsx('fs-4x bi', {
                              'bi-play-fill text-gray-700 text-hover-primary': !isPlayPause,
                              'bi-pause-fill text-primary': isPlayPause,
                            })}
                          />
                        </button>

                        <button className='btn btn-icon mx-1' onClick={() => waveSurfer?.skip(15)}>
                          <OverlayTrigger
                            delay={{show: 50, hide: 50}}
                            overlay={
                              <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                                + 15 {intl.formatMessage({id: 'CONTROLS.SECONDS'})}
                              </Tooltip>
                            }
                          >
                            <i className='bi bi-arrow-clockwise fs-2 text-hover-primary' />
                          </OverlayTrigger>
                        </button>

                        <button
                          className='btn btn-icon mx-1'
                          onClick={() => {
                            setComicalVoice(!comicalVoice)
                            waveSurfer?.setPlaybackRate(waveSurfer.getPlaybackRate(), comicalVoice)
                          }}
                        >
                          <OverlayTrigger
                            delay={{show: 50, hide: 50}}
                            overlay={
                              <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                                {intl.formatMessage({id: 'CONTROLS.COMICALVOICE'})}
                              </Tooltip>
                            }
                          >
                            <i
                              className={clsx('bi bi-emoji-smile fs-2', {
                                'text-primary': comicalVoice,
                              })}
                            />
                          </OverlayTrigger>
                        </button>
                        {control.type === 'CALL' && control!.call.duration > 60 && (
                          <>
                            <button
                              className='btn btn-icon mx-1'
                              onClick={() => setShowZoom(!showZoom)}
                            >
                              <OverlayTrigger
                                delay={{show: 50, hide: 50}}
                                overlay={
                                  <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                                    {intl.formatMessage({id: 'CONTROLS.ZOOM'})}
                                  </Tooltip>
                                }
                              >
                                <i
                                  className={clsx('bi bi-search text-hover-primary fs-2', {
                                    'text-primary': showZoom,
                                  })}
                                />
                              </OverlayTrigger>
                            </button>
                            {showZoom && (
                              <span>
                                <Form.Range
                                  defaultValue={zoomValue}
                                  onChange={(e) => {
                                    waveSurfer?.zoom(parseInt(e.target.value))
                                    setZoomValue(parseInt(e.target.value))
                                  }}
                                />
                              </span>
                            )}
                          </>
                        )}
                        <OverlayTrigger
                          delay={{show: 50, hide: 50}}
                          overlay={
                            <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                              {intl.formatMessage({id: 'CONTROLS.DOWNLOAD'})}
                            </Tooltip>
                          }
                        >
                          <a
                            href={downloadRecordBlob}
                            download={control.id + '.wav'}
                            className='btn btn-icon mx-1'
                          >
                            <i className='bi bi-cloud-download fs-2' />
                          </a>
                        </OverlayTrigger>
                        <button
                          className='btn btn-icon'
                          onClick={() => {
                            waveSurfer?.setMuted(!mute)
                            setMute(!mute)
                          }}
                        >
                          <OverlayTrigger
                            delay={{show: 50, hide: 50}}
                            overlay={
                              <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                                {intl.formatMessage({id: 'CONTROLS.MUTE'})}
                              </Tooltip>
                            }
                          >
                            <i
                              className={clsx('fa fa-volume-xmark fs-2', {'text-primary': mute})}
                            />
                          </OverlayTrigger>
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className='row gx-5 gx-xl-10'>
        <div className='col-xxl-6 mb-xl-10 mt-5 mb-10'>
          {!isError && !loadingRecord && (
            <>
              {control.type === 'CALL' && (
                <>
                  <div className='d-flex flex-center mb-5'>
                    <button
                      className='btn btn-sm btn-primary me-2'
                      onClick={createRangeComment}
                      disabled={addRangeComment || addSingleComment || addRangeObjection}
                    >
                      {intl.formatMessage({id: 'CONTROLS.ADDRANGECOMMENT'})}
                    </button>
                    <button
                      className='btn btn-sm btn-light-primary'
                      onClick={createSingleComment}
                      disabled={addSingleComment || addRangeComment || addRangeObjection}
                    >
                      {intl.formatMessage({id: 'CONTROLS.ADDSINGLECOMMENT'})}
                    </button>
                  </div>
                  <div className='d-flex flex-center mb-5'>
                    <button
                      className='btn btn-sm btn-info'
                      onClick={createRangeObjection}
                      disabled={addSingleComment || addRangeComment || addRangeObjection}
                    >
                      {/* {intl.formatMessage({id: 'CONTROLS.ADDSINGLECOMMENT'})} */}
                      Добавить возражение
                    </button>
                  </div>
                </>
              )}

              {control.type === 'MEETING' && (
                <div className='d-flex flex-center mb-5'>
                  <button
                    className='btn btn-sm btn-light-primary me-2'
                    onClick={createSingleComment}
                    disabled={addSingleComment || addRangeComment || addRangeObjection}
                  >
                    {intl.formatMessage({id: 'CONTROLS.ADDCOMMENT'})}
                  </button>
                  <button
                    className='btn btn-sm btn-info'
                    onClick={createRangeObjection}
                    disabled={addSingleComment || addRangeComment || addRangeObjection}
                  >
                    {intl.formatMessage({id: 'CONTROLS.ADDOBJECTION'})}
                  </button>
                </div>
              )}
            </>
          )}
          {addRangeObjection && (
            <AddObjection
              c_type={control.type}
              comment={commentObjection}
              currentRegion={currentRegion}
              currentRegionIsPlay={currentRegionIsPlay}
              currentRegionPlayStop={currentRegionPlayStop}
              handleChangeEnd={handleChangeEnd}
              handleChangeStart={handleChangeStart}
              setComment={setCommentObjection}
              handleSaveObjection={handleSaveObjection}
              handleCancelObjection={handleCancelObjection}
            />
          )}
          {(addRangeComment || addSingleComment) && (
            <AddBreakpoint
              comment={commentBreakpoint}
              type={control.type}
              newCommentColor={newCommentColor}
              addRangeComment={addRangeComment}
              handleCancel={handleCancelBreakpoint}
              currentRegionIsPlay={currentRegionIsPlay}
              currentRegion={currentRegion}
              currentRegionPlayStop={currentRegionPlayStop}
              updateRegion={updateRegion}
              handleChangeEnd={handleChangeEnd}
              handleChangeStart={handleChangeStart}
              handleSave={handleSaveBreakpoint}
              setComment={setCommentBreakpoint}
            />
          )}
          {control.type === 'CALL' && creatingRegions ? (
            <>
              <div className='overlay overlay-block p-5 mt-5'>
                <div className='overlay-layer bg-opacity-5'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>
                      {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Filter
                showBreakpoints={handlerShowBreakpoints}
                showObjections={handlerShowObjections}
              />

              {breakPoints.length === 0 && objections.length === 0 && !isError && (
                <>
                  <div className='d-flex justify-content-center mt-10'>
                    <i className='bi bi-inbox fs-4x' />
                  </div>
                </>
              )}
              {objections.length > 0 && (
                <>
                  {showObjections &&
                    objections.map((obj) => (
                      <ControlObjection
                        c_type={control.type}
                        key={obj.id}
                        objectionData={obj}
                        waveSurferRegions={waveSurferRegions!}
                        waveSurfer={waveSurfer!}
                        removeObjection={handleRemoveObjection}
                      />
                    ))}
                </>
              )}
              {breakPoints.length > 0 && (
                <>
                  {showBreakpoints &&
                    breakPoints.map((br, i) => (
                      <ControlBreakpoint
                        type={control.type}
                        key={i}
                        breakPointData={br}
                        waveSurferRegions={waveSurferRegions!}
                        waveSurfer={waveSurfer!}
                        removeBreakPoint={handleRemoveBreakPoint}
                      />
                    ))}
                </>
              )}
            </>
          )}
        </div>
        <div
          className={clsx('mb-xl-10 mt-4 mb-4', {'col-xxl-6': !isError}, {'col-xxl-12': isError})}
        >
          <ControlForm controlSingle={control} />
        </div>
      </div>
    </>
  )
}

export {ControlDetail}
