import {createRoot} from 'react-dom/client'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-datetime/css/react-datetime.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthInit} from './app/modules/auth'
import {Provider} from 'react-redux'
import store from './app/redux/Store'

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <MetronicI18nProvider>
        <AuthInit>
          <AppRoutes />
        </AuthInit>
      </MetronicI18nProvider>
    </Provider>
  )
}
