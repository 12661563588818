import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {RoleAccess} from '../../../../../models/users/Users'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'

const CriteriaHeader = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 text-uppercase small'>
        <th role='columnheader' className='col-2'>
          {intl.formatMessage({id: 'CRITERIATABLE.NAME'})}
        </th>
        <th role='columnheader' className='col-2'>
          {intl.formatMessage({id: 'CRITERIATABLE.AUTHOR'})}
        </th>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'CRITERIATABLE.CREATEDON'})}
        </th>
        <th role='columnheader' className='col-2'>
          {intl.formatMessage({id: 'CRITERIATABLE.GROUP'})}
        </th>
        <th role='columnheader' className='col-2'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.STATUS'})}
        </th>
        {roleAccesses.includes('CRITERIAMODIFY')}
        <th role='columnheader' className='col-2'></th>
      </tr>
    </thead>
  )
}

export {CriteriaHeader}
