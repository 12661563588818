import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {IAccountModel, IAccountModelResponse} from '../../models/account/Account'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'

export const accountAPI = createApi({
  reducerPath: 'accountAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['account'],
  endpoints: (build) => ({
    accountFetch: build.query<IAccountModel, void>({
      query: () => ({
        url: '/account',
      }),
      providesTags: ['account'],
    }),
    accountUpdate: build.mutation<IAccountModelResponse, IAccountModel>({
      query: (data) => ({
        url: `/account`,
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(accountUpdate, {dispatch, queryFulfilled}) {
        const patchResultAccount = dispatch(
          accountAPI.util.updateQueryData('accountFetch', undefined, (account) => {
            if (account.subdomain !== accountUpdate.subdomain) {
              const originalSubdomain = account.subdomain
              Object.assign(account, accountUpdate)
              account.subdomain = originalSubdomain
            } else {
              Object.assign(account, accountUpdate)
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResultAccount.undo()
        }
      },
    }),
  }),
})
