import {OneRowCommunications} from './OneRowCommunications'
import {FC, useEffect, useState} from 'react'
import {CommunicationsHeader} from './CommunicationsHeader'
import {
  IAnalyticsCommunicationsResponse,
  ICommunicationAnalytics,
} from '../../../../models/analytics/Analytics'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  communicationsAnalytics?: IAnalyticsCommunicationsResponse | undefined
  selectedUsers?: {label: string; value: string}[]
}

export const CommunicationsTable: FC<Props> = ({communicationsAnalytics, selectedUsers}) => {
  const [pageCommunications, updatePageCommunications] = useState<ICommunicationAnalytics[]>([])
  const [allCommunications, updateAllCommunications] = useState<ICommunicationAnalytics[]>([])
  const {state} = useQueryRequest()

  useEffect(() => {
    if (communicationsAnalytics && selectedUsers?.length !== 0) {
      let emptyUsers: ICommunicationAnalytics[] = []
      selectedUsers?.forEach((user) => {
        let mark = false
        for (let d of communicationsAnalytics?.data) {
          if (user.value === d.created_by.id) {
            mark = true
            break
          }
        }
        if (mark === false) {
          emptyUsers.push({
            created_by: {
              id: user.value,
              name: user.label,
            },
            department: {
              name: '',
            },
            communications_count: 0,
            communications_count_incoming: 0,
            communications_count_outgoing: 0,
            communications_count_score: 0,
            communications_count_score_incoming: 0,
            communications_count_score_outgoing: 0,
          })
        }
      })

      updateAllCommunications(communicationsAnalytics.data.concat(emptyUsers))
    } else if (!communicationsAnalytics) {
      updateAllCommunications([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationsAnalytics])

  useEffect(() => {
    if (allCommunications.length !== 0) {
      if (allCommunications.length > state.skip! + state.limit!) {
        updatePageCommunications(allCommunications.slice(state.skip, state.skip! + state.limit!))
      } else {
        updatePageCommunications(allCommunications.slice(state.skip))
      }
    } else {
      updatePageCommunications([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, allCommunications])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <CommunicationsHeader />
        <tbody>
          {pageCommunications.map((u) => (
            <OneRowCommunications object={u} key={u.created_by.id} />
          ))}
        </tbody>
      </table>
    </>
  )
}
