import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type ObjectionsFilter = {
  users: {label: string; value: string}[]
  objections: {label: string; value: string}[]
  date: {start: string; end: string}
}

type selectedObjectionsFilter = {
  objFilter: ObjectionsFilter
  setObjectionsFilter: (newObjFilter: ObjectionsFilter) => void
}

const initialStateObjectionsFilter: ObjectionsFilter = {
  users: [],
  objections: [],
  date: {start: '', end: ''},
}

const initialStatePeriodsGetControlsCallback: selectedObjectionsFilter = {
  objFilter: initialStateObjectionsFilter,
  setObjectionsFilter: () => {},
}

function getObjFilter(key: string): ObjectionsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as ObjectionsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateObjectionsFilter
}

const objectionsFiltersContext = createContext<selectedObjectionsFilter>(
  initialStatePeriodsGetControlsCallback
)

const ObjectionsFiltersProvider: FC<WithChildren> = ({children}) => {
  const [objFilter, setObjFilter] = useState<ObjectionsFilter>(
    getObjFilter(`${wl.hostname}_objectionsFilter`)
  )
  const setFilter = (objFilter: ObjectionsFilter) => {
    localStorage.setItem(`${wl.hostname}_objectionsFilter`, JSON.stringify(objFilter))
    setObjFilter(objFilter)
  }

  return (
    <objectionsFiltersContext.Provider
      value={{objFilter: objFilter, setObjectionsFilter: setFilter}}
    >
      {children}
    </objectionsFiltersContext.Provider>
  )
}

const useObjectionsFilters = () => useContext(objectionsFiltersContext)
export {ObjectionsFiltersProvider, useObjectionsFilters}
