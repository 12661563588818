/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {CriteriaTable} from './criteria/criteria-table/CriteriaTable'
import {QueryRequestProvider} from '../../../../_metronic/helpers/pagination/QueryRequestProvider'

const Criteria: FC = () => {
  return (
    <>
      <div className='card card-custom card-stretch shadow mb-5'>
        <div className='card-body px-1'>
          <div className='table-responsive px-15 overflow-visible'>
            <QueryRequestProvider>
              <CriteriaTable />
            </QueryRequestProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default Criteria
