import Criteria from './components/Criteria'
import ScoreForms from './components/ScoreForms'
import CriteriaGroups from './components/CriteriaGroups'
import {Navigate, Route, Routes} from 'react-router-dom'
import ToolsHeaderCard from './components/ToolsHeaderCard'
import Objection from './components/Objection'
import {ObjectionGroupsWrapper} from './components/objection-groups/ObjectionGroupsWrapper'

const ToolsPage = () => {
  return (
    <Routes>
      <Route element={<ToolsHeaderCard />}>
        <Route path='/' element={<ScoreForms />} />
        <Route path='/score_forms/' element={<ScoreForms />} />
        <Route path='/criteria/' element={<Criteria />} />
        <Route path='/criteria_groups/' element={<CriteriaGroups />} />
        <Route path='/objections/' element={<Objection />} />
        <Route path='/objection_groups/' element={<ObjectionGroupsWrapper />} />
        <Route path='/objection_groups/:id/' element={<ObjectionGroupsWrapper />} />
        <Route index element={<ScoreForms />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export default ToolsPage
