import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {selectReduserUpdateAll} from '../../../../../../redux/slices/serviceSlice'

type Props = {
  page: number
}

const AddCriteriaHeader: FC<Props> = ({page}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  const [checked, setChecked] = useState(false)

  const handleCheck = (isSelected: boolean) => {
    setChecked(isSelected)
    if (isSelected) {
      dispatch(selectReduserUpdateAll({page: page, selectAllMode: 'checked'}))
    } else if (selected.all.get(page) === 'checked') {
      dispatch(selectReduserUpdateAll({page: page, selectAllMode: undefined}))
    }
  }

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else {
      handleCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else if (
      selected.all.get(page) === 'indeterminate' ||
      selected.all.get(page) === 'unChecked'
    ) {
      handleCheck(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.all])
  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 text-uppercase small'>
        <th role='columnheader' className='col-2'>
          <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={checked}
              onChange={(e) => handleCheck(e.target.checked)}
            />
          </div>
        </th>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'CRITERIATABLE.NAME'})}
        </th>
        <th role='columnheader' className='col-4'>
          {intl.formatMessage({id: 'CRITERIATABLE.GROUP'})}
        </th>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.STATUS'})}
        </th>
      </tr>
    </thead>
  )
}

export {AddCriteriaHeader}
