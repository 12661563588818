/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {useUsersObjectionFilters} from '../helpers/UsersObjectionProvider'
import {ObjectionsFooter} from '../tables_widgets/objection_table/ObjectionsFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {UsersObjectionTable} from '../tables_widgets/users_objection_table/UsersObjectionTable'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'

const UsersObjectionAnalytics: FC = () => {
  const intl = useIntl()
  const [periodState, setPeriodState] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {usersObjFilter, setUsersObjectionFilter} = useUsersObjectionFilters()
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const [getUsers, {data: users, isLoading: isLoadingGetUsers}] = usersAPI.useLazyUsersFetchQuery()
  const [getAnalytics, {data: analytics, isLoading: isLoadingAnalytic}] =
    analyticsAPI.useLazyAnalyticsObjectionUsersFetchQuery()
  const [selectedObjection, setSelectedObjection] = useState<string>('')
  const [getObjectionsList, {data: objections}] = scoreAPI.useLazyObjectionFetchQuery()
  const [objectionsOptions, setObjectionsOptions] = useState<{label: string; value: string}[]>([])
  const [num, setNum] = useState<number>(
    usersObjFilter.periods_number ? usersObjFilter.periods_number : 1
  )

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: isLoadingGetUsers
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  useEffect(() => {
    if (periodState) {
      setUsersObjectionFilter({
        period: periodState,
        users: usersObjFilter.users,
        objection: usersObjFilter.objection,
        periods_number: usersObjFilter.periods_number,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    if (users && users?.users.length !== 0) {
      let usersList: {label: string; value: string}[] = []
      users.users.map((user) => usersList.push({label: user.name, value: user.id}))
      setUsersOptions(usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (objections && objections?.objections.length !== 0) {
      let objectionsList: {label: string; value: string}[] = []
      objections.objections.forEach((objection) => {
        if (objection.status === 'PUBLISHED') {
          objectionsList.push({label: objection.name, value: objection.id})
        }
      })
      setObjectionsOptions(objectionsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objections])

  useEffect(() => {
    if (usersObjFilter.objection !== '') {
      setSelectedObjection(usersObjFilter.objection)
    }
    if (usersObjFilter.users.length !== 0) {
      setSelectedUsers(usersObjFilter.users)
    }
    if (usersObjFilter.period.start !== '' && usersObjFilter.period.end !== '') {
      setPeriodState(usersObjFilter.period)
    }

    setNum(usersObjFilter.periods_number)

    if (
      usersObjFilter.objection !== '' &&
      usersObjFilter.users.length !== 0 &&
      usersObjFilter.period.start !== '' &&
      usersObjFilter.period.end !== ''
    ) {
      getAnalytics({
        period: usersObjFilter.period.start + ',' + usersObjFilter.period.end,
        manager_ids: getSelected(usersObjFilter.users),
        score_objection_id: usersObjFilter.objection,
        number_of_periods: usersObjFilter.periods_number,
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersObjFilter])

  return (
    <>
      <div className='card card-custom card-stretch shadow mt-20'>
        <div className='card-header'>
          <div className='card-title d-flex mt-5 mb-3 col-lg-12'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'OBJECTION.PERIOD.TITLE'})}</h3>
          </div>
          <span className='small text-uppercase text-muted mb-5 col-lg-12'>
            {intl.formatMessage({id: 'OBJECTION.USERS.PERIOD.INFO'})}
          </span>

          <div className='col-lg-12 d-flex mb-4'>
            <div className='pe-3 col-lg-4'>
              <MultiSelect
                onMenuToggle={() => {
                  if (!users) {
                    getUsers({is_active: true})
                  }
                  if (selectedUsers.length !== 0) {
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      users: selectedUsers,
                      objection: usersObjFilter.objection,
                      periods_number: usersObjFilter.periods_number,
                    })
                  }
                }}
                className='text-gray-900'
                options={usersOptions}
                hasSelectAll={true}
                value={selectedUsers}
                onChange={(e: any) => {
                  setSelectedUsers(e)
                  if (e.length === 0) {
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      users: e,
                      objection: usersObjFilter.objection,
                      periods_number: usersObjFilter.periods_number,
                    })
                  }
                }}
                overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                closeOnChangedValue={false}
                labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
              />
            </div>
            <div className='pe-3 col-lg-4 popup'>
              <select
                onClick={() => {
                  if (!objections) {
                    getObjectionsList({})
                  }
                }}
                disabled={isLoadingAnalytic}
                value={selectedObjection}
                className='form-select form-select-solid form-select-lg text-gray-900'
                placeholder={intl.formatMessage({id: 'OBJECTION.SELECT'})}
                onChange={(e: any) => {
                  setSelectedObjection(e.target.value)
                  setUsersObjectionFilter({
                    period: usersObjFilter.period,
                    users: usersObjFilter.users,
                    objection: e.target.value,
                    periods_number: usersObjFilter.periods_number,
                  })
                }}
              >
                <option value='' disabled style={{display: 'none'}}>
                  {intl.formatMessage({id: 'ANALYTICS.SELECTOBJECTION'})}
                </option>
                {objectionsOptions &&
                  objectionsOptions.map((objection) => (
                    <option value={objection.value} key={objection.value}>
                      {objection.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className='pe-3 col-lg-3'>
              <DateRangePickerInput
                language={currentUser?.language}
                timeZone={currentUser?.time_zone}
                rangeId='2'
                periodState={periodState}
                updateState={setPeriodState}
                onlyIcon={false}
                cancel={true}
              />
            </div>
            <div className='col-lg-1'>
              <OverlayTrigger
                placement='top-start'
                trigger={['hover', 'focus']}
                overlay={
                  <Popover>
                    <div className='p-3 small text-uppercase text-gray-700'>
                      {intl.formatMessage({id: 'ANALYTICS.SELECTPERIODCOUNT'})}
                    </div>
                  </Popover>
                }
              >
                <select
                  defaultValue={num}
                  onChange={(e: any) => {
                    setNum(e.target.value)
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      users: usersObjFilter.users,
                      objection: usersObjFilter.objection,
                      periods_number: e.target.value,
                    })
                  }}
                  className='form-select form-select-solid form-select-lg text-gray-900'
                >
                  {Array.from({length: 12}).map((_, index) => (
                    <option value={index + 1} key={index}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <UsersObjectionTable
              objectionsAnalytics={
                usersObjFilter.objection !== '' &&
                usersObjFilter.users.length !== 0 &&
                usersObjFilter.period.start !== '' &&
                usersObjFilter.period.end !== ''
                  ? analytics
                  : undefined
              }
              selectedUsers={selectedUsers}
            />
          </div>
          {isLoadingAnalytic ? (
            <div className='d-flex flex-center'>
              <span
                className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
                style={{display: 'block'}}
              >
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            (usersObjFilter.objection === '' ||
              usersObjFilter.users.length === 0 ||
              usersObjFilter.period.start === '' ||
              usersObjFilter.period.end === '') && (
              <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
                {intl.formatMessage({id: 'OBJECTION.USERS.PERIOD.TABLE.INFO'})}
              </span>
            )
          )}
        </div>
        <ObjectionsFooter selectedUsers={selectedUsers} />
      </div>
    </>
  )
}

export {UsersObjectionAnalytics}
