import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {
  selectReduserAddRow,
  selectReduserRemoveRow,
} from '../../../../../../redux/slices/serviceSlice'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {ICriteria, IGroup, IScoreForms} from '../../../../../../models/score/Score'
import {CriterionNameCell} from '../../../criteria/criteria-table/cells/CriterionNameCell'
import {CriterionGroupCell} from '../../../criteria/criteria-table/cells/CriterionGroupCell'
import {CriterionStatusCell} from '../../../criteria/criteria-table/cells/CriterionStatusCell'

type Props = {
  object: {type: string; object: IGroup | IScoreForms}
  criteria: ICriteria
  page: number
}

const OneCriteriaAdd: FC<Props> = ({object, criteria, page}) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  let checkCriteria: boolean = false
  if (object.type === 'form' && object.object.criterions) {
    if (object.object.criterions) {
      checkCriteria = object.object?.criterions.some(
        (criterion: ICriteria) => criterion.id === criteria.id
      )
    }
  } else if (object.type === 'group' && criteria.groups?.id === object.object.id) {
    checkCriteria = true
  }
  const [checked, setChecked] = useState<boolean>(checkCriteria)

  const handleCheck = (isSelected: boolean) => {
    if (criteria.status === 'PUBLISHED') {
      setChecked(isSelected)
      if (isSelected) {
        if (!selected.rows.get(criteria.id)) {
          dispatch(selectReduserAddRow({page: page, id: criteria.id}))
        }
      } else {
        if (selected.rows.get(criteria.id)) {
          dispatch(selectReduserRemoveRow({page: page, id: criteria.id}))
        }
      }
    }
  }

  useEffect(() => {
    if (selected.rows.get(criteria.id)) {
      handleCheck(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria])

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else if (selected.all.get(page) === 'unChecked') {
      handleCheck(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.all])

  return (
    <>
      <tr role='row' className='fs-7 text-gray-800 small fw-bold'>
        <td role='cell' className='col-2 px-0'>
          {criteria.status === 'PUBLISHED' ? (
            !checkCriteria ? (
              <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={checked}
                  disabled={checkCriteria}
                  readOnly={checkCriteria}
                  onChange={(e) => handleCheck(e.target.checked)}
                />
              </div>
            ) : (
              <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={true}
                  disabled={true}
                />
              </div>
            )
          ) : (
            <div
              className='d-flex flex-center'
              title={intl.formatMessage({id: 'CRITERIA.NOTPUBLISHED'})}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen047.svg'
                className='svg-icon svg-icon-1 svg-icon-muted'
              />
            </div>
          )}
        </td>
        <td role='cell' className='col-3'>
          <CriterionNameCell criterionName={criteria.name} ratingScale={criteria.rating_scale} />
        </td>
        <td role='cell' className='col-4'>
          <CriterionGroupCell criterionGroup={criteria.groups ? criteria.groups.name : '--'} />
        </td>
        <td role='cell' className='col-3'>
          <CriterionStatusCell status={criteria.status} />
        </td>
      </tr>
    </>
  )
}

export {OneCriteriaAdd}
