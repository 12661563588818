import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {
  getDepartmentsList,
  localeSettingsDepartmentsSelect,
} from '../../../../../../_metronic/helpers/components/GetDepartments'
import {departmentsAPI} from '../../../../../redux/services/DepartmentsService'
import {
  IAnyRatingScale,
  ICreateScoreForms,
  IRatingScale,
  IScoreType,
  createScoreFormsInitialValues,
} from '../../../../../models/score/Score'

const scoreFormsAddShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateScoreForms: FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const [createScoreForms, {isLoading, isError}] = scoreAPI.useFormsCreateMutation()
  const [criterionRatingScale, setcriterionRatingScale] = useState<
    IAnyRatingScale | IRatingScale | ''
  >('')
  const [scoreType, setScoreType] = useState<IScoreType[]>([])
  const [selected, setSelected] = useState([])
  const [departmentsOptions, setDepOptions] = useState<{label: string; value: string}[]>([])
  const [getDepartments, {data: departments, isLoading: getDepLoading}] =
    departmentsAPI.useLazyDepartmentsFetchQuery()

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
    setScoreType([])
    setcriterionRatingScale('')
  }

  const formik = useFormik({
    initialValues: createScoreFormsInitialValues,
    validationSchema: scoreFormsAddShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        description: values.description,
        rating_scale: criterionRatingScale,
        score_types: scoreType,
        department_ids: getDepartmentsList(selected),
      }
      await createScoreForms(data as ICreateScoreForms)
        .unwrap()
        .then((r) => {
          console.log(r)
          handleCloseModal()
        })
        .catch(() => {
          formik.resetForm()
        })
    },
  })

  useEffect(() => {
    if (departments) {
      let depsOptions: {label: string; value: string}[] = []
      departments.departments.all.map((d) => depsOptions.push({label: d.name, value: d.id}))
      setDepOptions(depsOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'SCOREFORMS.ADDMODAL.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='px-7'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.FORMNAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'SCOREFORMS.FORMNAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.DESCRIPTION'})}
                </span>
              </label>
              <textarea
                placeholder={intl.formatMessage({id: 'SCOREFORMS.DESCRIPTION'})}
                {...formik.getFieldProps('description')}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900'
              />
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.DEPARTMENTS'})}
                </span>
              </label>
              <div
                onClick={() => {
                  if (!departments) {
                    getDepartments()
                  }
                }}
              >
                <MultiSelect
                  className='form-control-select form-control-select-lg text-gray-900'
                  options={departmentsOptions}
                  hasSelectAll={true}
                  value={selected}
                  onChange={setSelected}
                  overrideStrings={localeSettingsDepartmentsSelect(
                    intl.formatMessage,
                    getDepLoading
                  )}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.DEPARTMENTS.SELECT'})}
                />
              </div>
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.RATINGSCALE'})}
                </span>
              </label>
              <select
                className='form-select form-select-solid form-select-lg text-gray-900'
                defaultValue=''
                onChange={(e: any) => {
                  setcriterionRatingScale(e.target.value)
                }}
              >
                <option value='' disabled></option>
                <option value='ANY'>{intl.formatMessage({id: 'ANY'})}</option>
                <option value='TERNARY_SCALE'>{intl.formatMessage({id: 'TERNARY_SCALE'})}</option>
                <option value='BINARY_SCALE'>{intl.formatMessage({id: 'BINARY_SCALE'})}</option>
                <option value='BINARY_TEXT_SCALE'>
                  {intl.formatMessage({id: 'BINARY_TEXT_SCALE'})}
                </option>
                <option value='QUATERNARY_SCALE'>
                  {intl.formatMessage({id: 'QUATERNARY_SCALE'})}
                </option>
                <option value='QUINKARNARY_SCALE'>
                  {intl.formatMessage({id: 'QUINKARNARY_SCALE'})}
                </option>
                <option value='DECINARY_SCALE'>{intl.formatMessage({id: 'DECINARY_SCALE'})}</option>
              </select>
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 mb-5 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.SCORETYPE'})}
                </span>
              </label>
              <div className='d-flex flex-wrap'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='PERCENT_SIMPLE'
                    defaultChecked={scoreType.indexOf('PERCENT_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('PERCENT_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'PERCENT_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                    id='PercentSimpleType'
                  />
                  <label className='form-check-label' htmlFor='PercentSimpleType'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.SIMPLE'})}(Per)
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='NUMBER_AVERAGE_SIMPLE'
                    defaultChecked={scoreType.indexOf('NUMBER_AVERAGE_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('NUMBER_AVERAGE_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'NUMBER_AVERAGE_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                    id='NumberAverageSimpleType'
                  />
                  <label className='form-check-label' htmlFor='NumberAverageSimpleType'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.AVERAGE'})}(Avr)
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='NUMBER_SUM_SIMPLE'
                    defaultChecked={scoreType.indexOf('NUMBER_SUM_SIMPLE') !== -1}
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setScoreType(scoreType.concat('NUMBER_SUM_SIMPLE'))
                      } else {
                        let newScoreType = scoreType.filter((el) => el !== 'NUMBER_SUM_SIMPLE')
                        setScoreType(newScoreType)
                      }
                    }}
                    id='NumberSumSimpleType'
                  />
                  <label className='form-check-label' htmlFor='NumberSumSimpleType'>
                    {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.SUM'})}(Sum)
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  scoreType.length === 0 ||
                  criterionRatingScale === ''
                }
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'SCOREFORMS.ADDFORM.BUTTON'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {CreateScoreForms}
