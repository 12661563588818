import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type ObjectionsFilter = {
  users: {label: string; value: string}[]
  objection: string
  period: {start: string; end: string}
  periods_number: number
}

type selectedObjectionsFilter = {
  usersObjFilter: ObjectionsFilter
  setUsersObjectionFilter: (newObjFilter: ObjectionsFilter) => void
}

const initialStateObjectionsFilter: ObjectionsFilter = {
  users: [],
  objection: '',
  period: {start: '', end: ''},
  periods_number: 1,
}

const initialStatePeriodsGetControlsCallback: selectedObjectionsFilter = {
  usersObjFilter: initialStateObjectionsFilter,
  setUsersObjectionFilter: () => {},
}

function getObjFilter(key: string): ObjectionsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as ObjectionsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateObjectionsFilter
}

const usersObjectionFiltersContext = createContext<selectedObjectionsFilter>(
  initialStatePeriodsGetControlsCallback
)

const UsersObjectionProvider: FC<WithChildren> = ({children}) => {
  const [objFilter, setObjFilter] = useState<ObjectionsFilter>(
    getObjFilter(`${wl.hostname}_usersObjectionFilter`)
  )
  const setFilter = (objFilter: ObjectionsFilter) => {
    localStorage.setItem(`${wl.hostname}_usersObjectionFilter`, JSON.stringify(objFilter))
    setObjFilter(objFilter)
  }

  return (
    <usersObjectionFiltersContext.Provider
      value={{usersObjFilter: objFilter, setUsersObjectionFilter: setFilter}}
    >
      {children}
    </usersObjectionFiltersContext.Provider>
  )
}

const useUsersObjectionFilters = () => useContext(usersObjectionFiltersContext)
export {UsersObjectionProvider, useUsersObjectionFilters}
