import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type CommunicationsFilter = {
  users: {label: string; value: string}[]
  period: {start: string; end: string}
}

type selectedCommunicationsFilter = {
  communicationsFilter: CommunicationsFilter
  setCommunicationsFilter: (newCommFilter: CommunicationsFilter) => void
}

const initialStateCommunicationsFilter: CommunicationsFilter = {
  users: [],
  period: {start: '', end: ''},
}

const initialStatePeriodsGetControlsCallback: selectedCommunicationsFilter = {
  communicationsFilter: initialStateCommunicationsFilter,
  setCommunicationsFilter: () => {},
}

function getCommFilter(key: string): CommunicationsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as CommunicationsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateCommunicationsFilter
}

const communicationsFiltersContext = createContext<selectedCommunicationsFilter>(
  initialStatePeriodsGetControlsCallback
)

const CommunicationsFiltersProvider: FC<WithChildren> = ({children}) => {
  const [communicationsFilter, setCommunicationsFilter] = useState<CommunicationsFilter>(
    getCommFilter(`${wl.hostname}_communicationsFilter`)
  )
  const setFilter = (communicationsFilter: CommunicationsFilter) => {
    localStorage.setItem(
      `${wl.hostname}_communicationsFilter`,
      JSON.stringify(communicationsFilter)
    )
    setCommunicationsFilter(communicationsFilter)
  }

  return (
    <communicationsFiltersContext.Provider
      value={{communicationsFilter: communicationsFilter, setCommunicationsFilter: setFilter}}
    >
      {children}
    </communicationsFiltersContext.Provider>
  )
}

const useCommunicationsFilters = () => useContext(communicationsFiltersContext)
export {CommunicationsFiltersProvider, useCommunicationsFilters}
