import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  company_name?: string
}

const ProfileCompany: FC<Props> = ({company_name}) => {
  const intl = useIntl()

  return (
    <div className='fv-row mb-8'>
      <label className='ms-2 mb-2 label text-muted text-uppercase'>
        {intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATION'})}
      </label>
      <span className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white text-black'>
        {company_name === ''
          ? intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATIONNAME'})
          : company_name}
      </span>
    </div>
  )
}

export {ProfileCompany}
