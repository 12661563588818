import {FC} from 'react'
import {UserName} from '../cells/UserName'
import {IObjectionsUser} from '../../../../models/analytics/Analytics'

type Props = {
  object: IObjectionsUser
  objectionsNumber: number
}

const OneRow: FC<Props> = ({object, objectionsNumber}) => {
  return (
    <>
      <tr role='row' className='fs-7'>
        <th role='cell' className='w-200px table-fixed-th'>
          <UserName user={object.created_by} />
        </th>
        {object.average_all !== '-'
          ? object.objections_average.map((objection) => (
              <td
                role='cell'
                className='min-w-200px px-0 text-center'
                key={object.objections_average.indexOf(objection)}
              >
                {objection.average}
              </td>
            ))
          : Array.from({length: objectionsNumber}).map((_, index) => (
              <td role='cell' className='min-w-200px px-0 text-center' key={index}>
                -
              </td>
            ))}
        <td role='cell' className='min-w-150px px-0 text-center'>
          {object.average_all}
        </td>
      </tr>
    </>
  )
}

export {OneRow}
