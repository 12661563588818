import {FC} from 'react'

type Props = {
  name: string
}

const ObjectionNameCell: FC<Props> = ({name}) => {
  return <span className='small text-uppercase fw-bolder'>{name}</span>
}

export {ObjectionNameCell}
