import {useIntl} from 'react-intl'
import {
  ICriteria,
  IGroup,
  IScoreForms,
  ListResponseCriterions,
} from '../../../../../../models/score/Score'
import {FC, useEffect, useState} from 'react'
import {OneCriteriaAdd} from './OneCriteriaAdd'
import {
  selectReduserClear,
  selectReduserRemoveRow,
  selectReduserUpdateAll,
} from '../../../../../../redux/slices/serviceSlice'
import {AddCriteriaHeader} from './AddCriteriaHeader'
import {scoreAPI} from '../../../../../../redux/services/ScoreService'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {useQueryRequest} from '../../../../../../../_metronic/helpers/pagination/QueryRequestProvider'
import {TablePagination} from '../../../../../../../_metronic/helpers/pagination/TablePagination'

type Props = {
  object: {type: string; object: IGroup | IScoreForms}
}

export const AddCriteriaTable: FC<Props> = ({object}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  const {state, updateState} = useQueryRequest()
  const [criteria, setCriteriaList] = useState<ListResponseCriterions<ICriteria>>()
  const [getCriteria, {isLoading: isLoadingGetCriterions}] = scoreAPI.useLazyCriteriaFetchQuery({})

  useEffect(() => {
    if (state) {
      let ratingScale =
        object.type === 'form' ? (object.object as IScoreForms).rating_scale : undefined
      getCriteria({
        skip: state.skip,
        limit: state.limit,
        search: state.search!,
        rating_scale: ratingScale !== 'ANY' ? ratingScale : undefined,
      })
        .unwrap()
        .then((response) => {
          setCriteriaList(response)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (criteria) {
      if (criteria.criterions.length === 0) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
        return
      }
      let publeshedCriteria: ICriteria[] = []
      if (criteria.criterions.length !== 0) {
        for (let i = 0; i < criteria.criterions.length; i++) {
          if (criteria.criterions[i].status === 'PUBLISHED') {
            publeshedCriteria.push(criteria.criterions[i])
          }
        }
      }
      const usersEvery = publeshedCriteria.every((criteria: ICriteria) =>
        selected.rows.has(criteria.id)
      )
      const usersSome = publeshedCriteria.some((criteria: ICriteria) =>
        selected.rows.has(criteria.id)
      )
      if (usersEvery) {
        if (selected.all.get(state.skip as number) !== undefined)
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'checked'}))
        if (selected.all.get(state.skip as number) === undefined) {
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
          criteria.criterions.map((criteria: ICriteria) =>
            dispatch(selectReduserRemoveRow({id: criteria.id}))
          )
        }
      } else if (usersSome) {
        if (selected.all.get(state.skip as number) !== 'indeterminate')
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      } else if (!usersSome) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, selected])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <AddCriteriaHeader page={state.skip as number} />
        <tbody>
          {criteria &&
            criteria?.criterions.length !== 0 &&
            criteria.criterions.map((c) => (
              <OneCriteriaAdd object={object} criteria={c} key={c.id} page={state.skip as number} />
            ))}
        </tbody>
      </table>
      {isLoadingGetCriterions && (
        <div className='d-flex flex-center'>
          <span
            className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
            style={{display: 'block'}}
          >
            {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
      {criteria && criteria?.criterions.length === 0 && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
      <div className='card-footer px-0'>
        <div className='d-flex flex-stack  align-items-center'>
          <div className='justify-content-start'>
            <label>
              <select
                className='form-select form-select-sm form-select-solid'
                value={state.limit}
                onChange={(e) => {
                  updateState({skip: 0, limit: parseInt(e.target.value)})
                  selectReduserClear()
                }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </label>
            <span className='small text-uppercase ms-4'>
              {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}: {criteria ? criteria?.total : 0}
            </span>
          </div>
          <TablePagination total={criteria ? criteria?.total : 0} />
        </div>
      </div>
    </>
  )
}
