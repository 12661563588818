import {FC} from 'react'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {IGroupObjectionDelete, IObjectionGroup} from '../../../../../models/score/Score'

type Props = {
  group: IObjectionGroup
  show: boolean
  handleClose: () => void
}

const DeleteGroup: FC<Props> = ({group, show, handleClose}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {data: groups} = scoreAPI.useGroupsObjectionFetchQuery()
  const [deleteGroupMutation, {isLoading: isLoadingDelete}] =
    scoreAPI.useGroupObjectionDeleteMutation()

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const formik = useFormik({
    initialValues: {objections_move_to: ''},
    onSubmit: async (values) => {
      if (values.objections_move_to !== '') {
        const data: IGroupObjectionDelete = {
          objections_move_to: values.objections_move_to,
        }
        await deleteGroupMutation({id: group.id, data: data})
          .unwrap()
          .then(() => {
            handleCloseModal()
          })
          .catch(() => {
            formik.resetForm()
          })
      } else {
        await deleteGroupMutation({id: group.id})
          .unwrap()
          .then(() => {
            handleCloseModal()
          })
          .catch(() => {
            formik.resetForm()
          })
      }
      navigate(`/tools/objection_groups/`, {replace: true})
    },
  })

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <div className='w-100 text-end'>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleCloseModal}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='w-100 text-center'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.DELETE'})}
              </label>
              <label className='mb-10'>
                {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.DELETE.REMOVE'})}
              </label>
            </div>
            <div className='fv-row mb-8'>
              <select
                className='form-select form-select-solid form-select-lg text-gray-900'
                {...formik.getFieldProps('objections_move_to')}
              >
                <option value='none' key='none'>
                  Без группы
                </option>
                {groups &&
                  groups.map((gr: IObjectionGroup) => {
                    if (gr.id !== group.id) {
                      return (
                        <option value={gr.id} key={gr.id}>
                          {gr.name}
                        </option>
                      )
                    }
                    return null
                  })}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoadingDelete && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.DELETE'})}
                  </span>
                )}
                {isLoadingDelete && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {DeleteGroup}
