/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import Popover from 'react-bootstrap/Popover'
import {OverlayTrigger} from 'react-bootstrap'
import {RoleAccess} from '../../../models/users/Users'
import {getDates, getPercent} from '../DashboardWrapper'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {usePeriodsControls} from '../helpers/PeriodGetControlsProvider'
import {convertTime} from '../../../../_metronic/helpers/components/ConvertTime'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'

const AllControlsPeriod: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [pulseState, setPulseState] = useState<boolean>(true)
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const {periods, setPeriodsGetControls} = usePeriodsControls()

  const [periodState, setPeriodState] = useState<{start: string; end: string}>({start: '', end: ''})

  const {data: controlsAll} = analyticsAPI.useAnalyticsFetchQuery({})
  const {data: controlsAllDuration} = analyticsAPI.useAnalyticsFetchQuery({
    is_durations: true,
  })

  const [getControlsAllPeriod, {data: controlsAllPeriod, isLoading: isLoadingControlsAllPeriod}] =
    analyticsAPI.useLazyAnalyticsFetchQuery()
  const [
    getControlsAllPeriodDuration,
    {data: controlsAllPeriodDuration, isLoading: isLoadingControlsAllPeriodDuration},
  ] = analyticsAPI.useLazyAnalyticsFetchQuery()

  useEffect(() => {
    if (currentUser && periods) {
      setTimeout(() => {
        setPulseState(false)
      }, 10000)

      let weekPeriod = getDates()
      setRoleAccesses(getAccesses(currentUser.role))
      if (weekPeriod !== '') {
        if (periods.periodAllControls.start === '' && periods.periodAllControls.end === '') {
          setPeriodState({start: weekPeriod.split(',')[0], end: weekPeriod.split(',')[1]})
        } else {
          setPeriodState({
            start: periods.periodAllControls.start,
            end: periods.periodAllControls.end,
          })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    if (periodState.start !== '' && periodState.end !== '') {
      getControlsAllPeriod({
        period: periodState.start + ',' + periodState.end,
      })

      getControlsAllPeriodDuration({
        period: periodState.start + ',' + periodState.end,
        is_durations: true,
      })

      setPeriodsGetControls({
        periodAllControls: {start: periodState.start, end: periodState.end},
        periodCheckedControls: {
          start: periods.periodCheckedControls.start,
          end: periods.periodCheckedControls.end,
        },
        periodOwnCheckedControls: {
          start: periods.periodOwnCheckedControls.start,
          end: periods.periodOwnCheckedControls.end,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  return (
    <>
      <div className='card h-100'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='btn btn-icon pulse pulse-info pe-3'>
              <i className='fa-solid fa-phone fs-2 text-info'></i>
              {pulseState && <span className='pulse-ring'></span>}
            </div>
            <div>
              <span className='small fw-bold text-hover-info text-gray-800 m-0 text-uppercase'>
                {intl.formatMessage({id: 'ANALYTICS.CONTROLS.PERIOD'})}
              </span>
              <div className='small text-gray-700 d-flex flex-row3'>
                {periodState.start.replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1').split('T')[0]}-
                {periodState.end.replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1').split('T')[0]}
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <div className='small text-gray-500'>
                  {roleAccesses.includes('ANALYTICSALL')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.ALLUSERS'})
                    : roleAccesses.includes('ANALYTICSDEP')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.DEPARTMENT'})
                    : roleAccesses.includes('ANALYTICSOWN')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.OWN'})
                    : ''}
                </div>
              </div>
            </div>
          </div>
          <div className='card-toolbar m-0 d-flex flex-column'>
            <div className='d-flex flex-row align-self-end'>
              <div>
                {periodState.start !== '' && periodState.end !== '' && (
                  <DateRangePickerInput
                    language={currentUser?.language}
                    timeZone={currentUser?.time_zone}
                    rangeId='1'
                    periodState={periodState}
                    updateState={setPeriodState}
                    onlyIcon={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-row px-9 pt-6 pb-8'>
          {isLoadingControlsAllPeriod || isLoadingControlsAllPeriodDuration ? (
            <div className='d-flex flex-column col-12'>
              <span className='indicator-progress align-self-center' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm text-primary align-middle ms-2 mt-10'></span>
              </span>
            </div>
          ) : (
            <>
              <div className='d-flex flex-column col-6'>
                <div className='d-flex align-items-center fw-bold fs-4 flex-wrap mb-3 text-gray-700'>
                  <div className='fs-3qx fw-bolder text-gray-900'>{controlsAllPeriod?.total}</div>
                </div>

                <div className='d-flex align-items-center fw-bold flex-wrap fs-4 text-gray-700'>
                  <OverlayTrigger
                    placement='bottom-start'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover>
                        <div className='p-3 small text-uppercase text-gray-700'>
                          {intl.formatMessage({id: 'ANALYTICS.CONTROLS.DURATION'})}
                        </div>
                      </Popover>
                    }
                  >
                    <i className='fa-regular fa-clock pe-3 fs-4 btn p-0'></i>
                  </OverlayTrigger>
                  <span className='me-3'>
                    {controlsAllPeriodDuration && convertTime(controlsAllPeriodDuration?.total)}
                  </span>
                </div>
              </div>
              <div className='d-flex col-6'>
                <span className='bullet bullet-line h-auto bg-muted w-1px me-2'></span>
                <div className='d-flex flex-column flex-stack col-12'>
                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {controlsAll &&
                          controlsAllPeriod &&
                          getPercent(controlsAll.total, controlsAllPeriod.total)}
                        %
                      </span>
                    </OverlayTrigger>
                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{
                          width:
                            controlsAll && controlsAllPeriod
                              ? getPercent(controlsAll.total, controlsAllPeriod.total) + '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT.DURATION'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {controlsAllDuration &&
                          controlsAllPeriodDuration &&
                          getPercent(controlsAllDuration.total, controlsAllPeriodDuration.total)}
                        %
                      </span>
                    </OverlayTrigger>

                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{
                          width:
                            controlsAllDuration && controlsAllPeriodDuration
                              ? getPercent(
                                  controlsAllDuration.total,
                                  controlsAllPeriodDuration.total
                                ) + '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {AllControlsPeriod}
