import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {OneObjectionAdd} from './OneObjectionAdd'
import {ObjectionsAddHeader} from './ObjectionsAddHeader'
import {scoreAPI} from '../../../../../../redux/services/ScoreService'
import {IScoreObjection, ListResponseObjection} from '../../../../../../models/score/Score'
import {TablePagination} from '../../../../../../../_metronic/helpers/pagination/TablePagination'
import {useQueryRequest} from '../../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  skip: boolean
  groupId: string
}

const ObjectionsTable: FC<Props> = ({skip, groupId}) => {
  const intl = useIntl()
  const {state, updateState} = useQueryRequest()

  const [objections, setObjections] = useState<ListResponseObjection<IScoreObjection>>()

  const [fetchScoreObjections] = scoreAPI.useLazyObjectionFetchQuery()

  const [itemsSelectAll, setItemsSelectAll] = useState<boolean>(false)

  const handleEnableSelectAll = () => setItemsSelectAll(true)
  const handleDisableSelectAll = () => setItemsSelectAll(false)

  useEffect(() => {
    handleDisableSelectAll()
    if (!skip) {
      fetchScoreObjections({skip: state.skip, limit: state.limit})
        .unwrap()
        .then((response) => {
          setObjections(response)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, state])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ObjectionsAddHeader
          isChecked={itemsSelectAll}
          selectAllEnable={handleEnableSelectAll}
          selectAllDisable={handleDisableSelectAll}
        />
        <tbody>
          {objections &&
            objections.objections.map((obj) => (
              <OneObjectionAdd
                objection={obj}
                key={obj.id}
                groupId={groupId}
                selectAll={itemsSelectAll}
              />
            ))}
        </tbody>
      </table>
      <div className='separator mb-8'></div>
      <div className='card-footer px-0 mb-3'>
        <div className='d-flex flex-stack align-items-center'>
          <div className='justify-content-start'>
            <label>
              <select
                className='form-select form-select-sm form-select-solid'
                value={state.limit}
                onChange={(e) => {
                  updateState({skip: 0, limit: parseInt(e.target.value)})
                }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </label>
            <span className='small text-uppercase ms-4'>
              {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}: {objections && objections.total}
            </span>
          </div>
          <TablePagination total={objections ? objections.total : 0} />
        </div>
      </div>
    </>
  )
}

export {ObjectionsTable}
