/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {FC, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {ICriteria, ICriteriaCreate} from '../../../../../models/score/Score'
import {
  checkMaxValueAverageMax,
  checkMaxValueAverageMin,
  checkMaxValueMaxRangeMin,
  checkMaxValueMinRangeMax,
} from './helpers/check-value-score-zone'

const criteriaAddShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  criterion: ICriteria
  show: boolean
  handleClose: () => void
}

const EditCriterionModal: FC<Props> = ({criterion, show, handleClose}) => {
  const intl = useIntl()
  const [editCriterion, {isLoading, isError}] = scoreAPI.useCriteriaUpdateMutation()
  const [maxRangeMin, setMaxRangeMin] = useState<number>(criterion.score_zone.MAX_RANGE_MIN!)
  const [averageRangeMax, setAverageRangeMax] = useState<number>(
    criterion.score_zone.AVERAGE_RANGE_MAX!
  )
  const [averageRangeMin, setAverageRangeMin] = useState<number>(
    criterion.score_zone.AVERAGE_RANGE_MIN!
  )
  const [minRangeMax, setMinRangeMax] = useState<number>(criterion.score_zone.MIN_RANGE_MAX!)
  const [maxDescription, setMaxDescription] = useState<string>(
    criterion.score_zone.MAX_DESCRIPTION!
  )
  const [averageDescription, setAverageDescription] = useState<string>(
    criterion.score_zone.AVERAGE_DESCRIPTION!
  )
  const [minDescription, setMinDescription] = useState<string>(
    criterion.score_zone.MIN_DESCRIPTION!
  )

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
    setMaxRangeMin(criterion.score_zone.MAX_RANGE_MIN!)
    setAverageRangeMax(criterion.score_zone.AVERAGE_RANGE_MAX!)
    setAverageRangeMin(criterion.score_zone.AVERAGE_RANGE_MIN!)
    setMinRangeMax(criterion.score_zone.MIN_RANGE_MAX!)
    setMaxDescription(criterion.score_zone.MAX_DESCRIPTION!)
    setAverageDescription(criterion.score_zone.AVERAGE_DESCRIPTION!)
    setMinDescription(criterion.score_zone.MIN_DESCRIPTION!)
  }

  const formik = useFormik({
    initialValues: {
      name: criterion.name,
      score_zone: {
        rating_scale: criterion.rating_scale,
      },
    },
    validationSchema: criteriaAddShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        score_zone:
          criterion.rating_scale !== 'BINARY_SCALE' &&
          criterion.rating_scale !== 'BINARY_TEXT_SCALE' &&
          criterion.rating_scale !== 'TERNARY_SCALE'
            ? {
                rating_scale: criterion.rating_scale,
                MAX_RANGE_MIN: maxRangeMin,
                MAX_DESCRIPTION: maxDescription,
                AVERAGE_RANGE_MAX: averageRangeMax,
                AVERAGE_RANGE_MIN: averageRangeMin,
                AVERAGE_DESCRIPTION: averageDescription,
                MIN_RANGE_MAX: minRangeMax,
                MIN_DESCRIPTION: minDescription,
              }
            : criterion.rating_scale === 'TERNARY_SCALE'
            ? {
                rating_scale: criterion.rating_scale,
                MAX_DESCRIPTION: maxDescription,
                AVERAGE_DESCRIPTION: averageDescription,
                MIN_DESCRIPTION: minDescription,
              }
            : {
                rating_scale: criterion.rating_scale,
                MAX_DESCRIPTION: maxDescription,
                MIN_DESCRIPTION: minDescription,
              },
      }

      await editCriterion({id: criterion.id, data: data as ICriteriaCreate})
        .unwrap()
        .then(() => {
          handleClose()
        })
        .catch(() => {
          formik.resetForm()
        })
    },
  })

  const handleChengeMaxRangeMin = (v: number) => {
    setMaxRangeMin(v)
    setAverageRangeMax(v - 1)
    if (averageRangeMin - 1 === v - 1) {
      setAverageRangeMin(v - 1)
    }
    if (v - 1 === minRangeMax) {
      setMinRangeMax(minRangeMax - 1)
    }
  }
  const handleChengeAverageMax = (v: number) => {
    setAverageRangeMax(v)
    setMaxRangeMin(v + 1)
    if (v <= averageRangeMin) {
      setAverageRangeMin(v)
      setMinRangeMax(v - 1)
    }
  }
  const handleChengeAverageMin = (v: number) => {
    setAverageRangeMin(v)
    setMinRangeMax(v - 1)
    if (v > averageRangeMax) {
      setAverageRangeMax(v + 1)
      setMaxRangeMin(v + 2)
    }
  }
  const handleChengeMinRangeMax = (v: number) => {
    setMinRangeMax(v)
    setAverageRangeMin(v + 1)
    if (v + 1 > averageRangeMax) {
      setAverageRangeMax(v + 1)
      setMaxRangeMin(v + 2)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'CRITERIA.UPDATE.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='py-2'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.NAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'CRITERIA.ADD.NAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col mb-5'>
              <label className='row  col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.RATINGSCALE'})}
                </span>
              </label>
              <span className='small text-uppercase fw-bolder text-gray-900'>
                {intl.formatMessage({id: criterion.rating_scale})}
              </span>
            </div>
            <div className='col mb-5'>
              <label className='col-form-label text-gray-600'>
                <span className='small text-uppercase  fw-bolder'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE'})}
                </span>
              </label>
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.EXCELLENT'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MAXRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={maxRangeMin}
                    onChange={(e) => handleChengeMaxRangeMin(parseInt(e.target.value))}
                    min={3}
                    max={checkMaxValueMaxRangeMin(criterion.rating_scale)}
                    disabled={
                      criterion.status !== 'DRAFT' ||
                      criterion.rating_scale === 'BINARY_SCALE' ||
                      criterion.rating_scale === 'BINARY_TEXT_SCALE' ||
                      criterion.rating_scale === 'TERNARY_SCALE'
                        ? true
                        : false
                    }
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MAXRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={criterion.score_zone.MAX_RANGE_MAX}
                    disabled
                  />
                  <div className='h-30px w-30px bg-success rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm mb-5'>
                <textarea
                  className='form-control border-success'
                  placeholder='Description'
                  onChange={(e) => setMaxDescription(e.target.value)}
                  value={maxDescription}
                />
              </div>
              {criterion.rating_scale !== 'BINARY_SCALE' &&
                criterion.rating_scale !== 'BINARY_TEXT_SCALE' && (
                  <>
                    <div className='d-flex flex-stack mb-2'>
                      <label className='col-form-label fw-bolder text-gray-600'>
                        <span className='small text-uppercase'>
                          {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.NORMAL'})}
                        </span>
                      </label>
                      <div className='d-flex'>
                        {/* AVERAGERANGEMIN */}
                        <input
                          type='number'
                          className='w-100px form-control form-control-lg text-gray-900'
                          value={averageRangeMin}
                          onChange={(e) => handleChengeAverageMin(parseInt(e.target.value))}
                          min={2}
                          max={checkMaxValueAverageMin(criterion.rating_scale)}
                          disabled={
                            criterion.status !== 'DRAFT' ||
                            criterion.rating_scale === 'TERNARY_SCALE'
                              ? true
                              : false
                          }
                        />
                        <label className='align-self-center mx-2'>—</label>
                        {/* AVERAGERANGEMAX */}
                        <input
                          type='number'
                          className='w-100px form-control form-control-lg text-gray-900 me-5'
                          value={averageRangeMax}
                          onChange={(e) => handleChengeAverageMax(parseInt(e.target.value))}
                          min={2}
                          max={checkMaxValueAverageMax(criterion.rating_scale)}
                          disabled={
                            criterion.status !== 'DRAFT' ||
                            criterion.rating_scale === 'TERNARY_SCALE'
                              ? true
                              : false
                          }
                        />
                        <div className='h-30px w-30px bg-warning rounded-1 align-self-center'></div>
                      </div>
                    </div>
                    <div className='input-group input-group-sm mb-5'>
                      <textarea
                        className='form-control border-warning'
                        placeholder='Description'
                        onChange={(e) => setAverageDescription(e.target.value)}
                        value={averageDescription}
                      ></textarea>
                    </div>
                  </>
                )}
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.CRITICAL'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MINRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={criterion.score_zone.MIN_RANGE_MIN!}
                    disabled
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MINRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={minRangeMax}
                    onChange={(e) => handleChengeMinRangeMax(parseInt(e.target.value))}
                    min={
                      criterion.rating_scale === 'BINARY_SCALE' ||
                      criterion.rating_scale === 'BINARY_TEXT_SCALE'
                        ? 0
                        : 1
                    }
                    max={checkMaxValueMinRangeMax(criterion.rating_scale)}
                    disabled={
                      criterion.status !== 'DRAFT' ||
                      criterion.rating_scale === 'BINARY_SCALE' ||
                      criterion.rating_scale === 'BINARY_TEXT_SCALE' ||
                      criterion.rating_scale === 'TERNARY_SCALE'
                        ? true
                        : false
                    }
                  />
                  <div className='h-30px w-30px bg-danger rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm '>
                <textarea
                  className='form-control border-danger'
                  placeholder='Description'
                  onChange={(e) => setMinDescription(e.target.value)}
                  value={minDescription}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {EditCriterionModal}
