import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'

type Props = {
  periods?: {[key: string]: {start: string; end: string}}
}

const ObjectionsUserHeader: FC<Props> = ({periods}) => {
  const intl = useIntl()
  const [objState, setObjState] = useState<{value: string; label?: string}[]>([])

  useEffect(() => {
    if (periods) {
      let objList: {value: string; label?: string}[] = []
      for (let key in periods) {
        objList.push({
          value: key,
          label:
            periods[key].start.replaceAll('-', '/').split('T')[0] +
            '-' +
            periods[key].end.replaceAll('-', '/').split('T')[0],
        })
      }
      setObjState(objList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods])

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='table-fixed-th'>
          {intl.formatMessage({id: 'TOOLS.OBJECTION.TITLE'})}
        </th>
        {objState && objState.length !== 0 ? (
          objState.map((objection) => (
            <td role='columnheader' className='min-w-200px px-0 text-center' key={objection.value}>
              {objection.label}
            </td>
          ))
        ) : (
          <td role='columnheader' className='text-center'>
            {intl.formatMessage({id: 'FILTERS.PERIOD'})}
          </td>
        )}
      </tr>
    </thead>
  )
}

export {ObjectionsUserHeader}
