import {FC, useEffect, useState} from 'react'
import {IScoreObjection} from '../../../../../../models/score/Score'
import {usersAPI} from '../../../../../../redux/services/UsersService'
import {checkedItemAdd} from '../../../../../../redux/slices/serviceSlice'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {ObjectionName} from '../../components/score-objections-table/cell/ObjectionNameCell'
import {formatDateByZone} from '../../../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  objection: IScoreObjection
  groupId: string
  selectAll: boolean
}

const OneObjectionAdd: FC<Props> = ({objection, groupId, selectAll}) => {
  const dispatch = useAppDispatch()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {idSelectedItemsAdd} = useAppSelector((state) => state.serviceReduser)
  const [checked, setChecked] = useState<boolean>(
    objection.group !== null
      ? groupId === objection.group.id
        ? true
        : (idSelectedItemsAdd.get(objection.id) as boolean)
      : (idSelectedItemsAdd.get(objection.id) as boolean)
  )

  const handleCheck = (isSelected: boolean) => {
    if (isSelected) {
      dispatch(checkedItemAdd(objection.id))
    }
    setChecked(isSelected)
  }

  useEffect(() => {
    handleCheck(selectAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll])

  return (
    <>
      <tr role='row' className='fs-7'>
        <td role='cell' className='min-w-50px px-0'>
          <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
            <input
              className='form-check-input'
              type='checkbox'
              disabled={
                objection.group !== null ? (groupId === objection.group.id ? true : false) : false
              }
              defaultChecked={checked}
              onChange={(e) => {
                handleCheck(e.target.checked)
              }}
            />
          </div>
        </td>
        <td role='cell' className='min-w-200px p-3'>
          <ObjectionName objection={objection} />
        </td>
        <td role='cell' className='min-w-150px py-3 px-0'>
          <label className='fw-bold text-dark'>{objection.created_by.name}</label>
        </td>
        <td role='cell' className='min-w-100px py-3 px-0'>
          <span className='fw-bold text-uppercase small'>
            {formatDateByZone(objection.created_on!, currentUser?.language, currentUser?.time_zone)}
          </span>
        </td>
      </tr>
    </>
  )
}

export {OneObjectionAdd}
