import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {IScoreObjection} from '../../../../../../models/score/Score'
import {InfoObjectionModal} from '../../../../../tools/components/objection/modals/InfoObjectionModal'

interface SelectedScoreObjectionProps {
  comment: string
  objectionValue: number
  selectedScoreObjection: IScoreObjection
  setIsSaveDisable: (v: boolean) => void
  setObjectionValue: (v: number) => void
  setComment: (value: React.SetStateAction<string>) => void
}

const SelectedScoreObjection: FC<SelectedScoreObjectionProps> = ({
  comment,
  setComment,
  objectionValue,
  setIsSaveDisable,
  setObjectionValue,
  selectedScoreObjection,
}) => {
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)

  useEffect(() => {
    if (selectedScoreObjection !== undefined) {
      setIsLoad(true)
      // handlerIsLoad()
      setTimeout(() => {
        setIsLoad(false)
      }, 1000)
    }
  }, [selectedScoreObjection])

  useEffect(() => {
    if (selectedScoreObjection && objectionValue !== 0) {
      if (comment !== undefined && comment !== '') {
        setIsSaveDisable(false)
      } else {
        setIsSaveDisable(true)
      }
    } else {
      setIsSaveDisable(true)
    }
  }, [comment, objectionValue, selectedScoreObjection, setIsSaveDisable])

  const handleCloseInfoModal = () => {
    setShowInfoModal(false)
  }

  return (
    <>
      <div className='overflow-hidden flex-column-fluid p-0 mb-5 data-loader'>
        <div className='rounded bg-gray-200 py-2 px-4'>
          <div
            className={clsx('d-flex fw-bold small align-items-center px-4 mt-1', {
              'w-250px skeleton': isLoad,
            })}
          >
            <div className='bd-highlight w-100 '>
              <div className='menu-title text-gray-900 text-uppercase'>
                {!isLoad ? selectedScoreObjection.name : ''}
              </div>
            </div>
            {!isLoad ? (
              <i
                className='bi bi-info-circle fs-2 text-hover-primary ms-2 cursor-pointer'
                onClick={() => setShowInfoModal(true)}
              />
            ) : (
              <div className='h-20px'></div>
            )}
          </div>
          <div className='separator my-3'></div>
          <div
            className={clsx('d-flex align-items-center mb-1 ', {
              'w-350px skeleton': isLoad,
            })}
          >
            {!isLoad ? (
              <>
                <OverlayTrigger
                  delay={{show: 1000, hide: 50}}
                  overlay={
                    <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                      Reset
                    </Tooltip>
                  }
                >
                  <button className='btn btn-icon me-2' onClick={() => setObjectionValue(0)}>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2 text-gray-800 text-hover-primary m-0 p-0'
                    />
                  </button>
                </OverlayTrigger>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-danger',
                      {
                        active: objectionValue === 1,
                      }
                    )}
                    onClick={() => setObjectionValue(1)}
                  >
                    <span className='small'>1</span>
                  </button>
                  <div className='bg-danger rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-warning',
                      {
                        active: objectionValue === 2,
                      }
                    )}
                    onClick={() => setObjectionValue(2)}
                  >
                    <span className='small'>2</span>
                  </button>
                  <div className='bg-warning rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-warning',
                      {
                        active: objectionValue === 3,
                      }
                    )}
                    onClick={() => setObjectionValue(3)}
                  >
                    <span className='small'>3</span>
                  </button>
                  <div className='bg-warning rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-success',
                      {
                        active: objectionValue === 4,
                      }
                    )}
                    onClick={() => setObjectionValue(4)}
                  >
                    <span className='small'>4</span>
                  </button>
                  <div className='bg-success rounded me-2 mt-1 h-5px'></div>
                </div>
              </>
            ) : (
              <div style={{height: '44px'}}></div>
            )}
          </div>
        </div>
      </div>
      {/* ==== comment ==== */}
      <div className='input-group input-group-sm'>
        <textarea
          className='form-control'
          placeholder='Comment'
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </div>
      <InfoObjectionModal
        objection={selectedScoreObjection}
        show={showInfoModal}
        handleClose={handleCloseInfoModal}
      />
    </>
  )
}

export {SelectedScoreObjection}
