import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from 'immer'

enableMapSet()
type selectMode = 'checked' | 'unChecked' | 'indeterminate'
const selectedAll = new Map<number, selectMode>()
const selectedRows = new Map<string, boolean>()

interface ISelected {
  all: typeof selectedAll
  rows: typeof selectedRows
}

interface IUnSelected {
  rows: typeof selectedRows
}

interface IService {
  idSelectedItemsAdd: typeof selectedRows
  selected: ISelected
  unSelected: IUnSelected
  authBackgroundImage: string
}

const initialServiceState: IService = {
  idSelectedItemsAdd: selectedRows,
  selected: {all: selectedAll, rows: selectedRows},
  unSelected: {rows: selectedRows},
  authBackgroundImage: 'login',
}

interface IPASelect {
  id?: string
  page?: number
  selectAllMode?: selectMode
  isChecked?: boolean
}

const serviceSlice = createSlice({
  name: 'service',
  initialState: initialServiceState,
  reducers: {
    setLoginBackground: (state) => {
      state.authBackgroundImage = 'login'
    },
    setRegistrBackground: (state) => {
      state.authBackgroundImage = 'registr'
    },
    setFrogotPasswordBackground: (state) => {
      state.authBackgroundImage = 'forgotPassword'
    },
    checkedItemAdd: (state, action: PayloadAction<string>) => {
      state.idSelectedItemsAdd.set(action.payload, true)
    },
    uncheckedItemAdd: (state, action: PayloadAction<string>) => {
      state.idSelectedItemsAdd.delete(action.payload)
    },
    clearCheckedItemsAdd: (state) => {
      state.idSelectedItemsAdd.clear()
    },
    selectReduserAddRow: (state, action: PayloadAction<IPASelect>) => {
      state.selected.rows.set(action.payload.id as string, true)
    },
    selectReduserRemoveRow: (state, action: PayloadAction<IPASelect>) => {
      state.selected.rows.delete(action.payload.id as string)
    },
    selectReduserUpdateAll: (state, action: PayloadAction<IPASelect>) => {
      state.selected.all.set(
        action.payload.page as number,
        action.payload.selectAllMode as selectMode
      )
    },
    selectReduserClear: (state) => {
      state.selected = {all: selectedAll, rows: selectedRows}
    },
    unSelectReduserAddRow: (state, action: PayloadAction<IPASelect>) => {
      state.unSelected.rows.set(action.payload.id as string, true)
    },
    unSelectReduserRemoveRow: (state, action: PayloadAction<IPASelect>) => {
      state.unSelected.rows.delete(action.payload.id as string)
    },
    unSelectReduserClear: (state) => {
      state.unSelected = {rows: selectedRows}
    },
  },
})

export const {
  setLoginBackground,
  setRegistrBackground,
  setFrogotPasswordBackground,
  checkedItemAdd,
  uncheckedItemAdd,
  clearCheckedItemsAdd,
  selectReduserAddRow,
  selectReduserRemoveRow,
  selectReduserUpdateAll,
  selectReduserClear,
  unSelectReduserAddRow,
  unSelectReduserRemoveRow,
  unSelectReduserClear,
} = serviceSlice.actions
export default serviceSlice.reducer
