import moment from 'moment'
import {useIntl} from 'react-intl'
import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {DateRangePicker} from 'react-bootstrap-daterangepicker'
import clsx from 'clsx'

type Props = {
  updateState: Dispatch<SetStateAction<{start: string; end: string}>>
  periodState: {start: string; end: string}
  language?: string
  timeZone?: string
  rangeId: string
  onlyIcon: boolean
  cancel?: boolean
}

export const DateRangePickerInput: FC<Props> = ({
  periodState,
  updateState,
  language,
  timeZone,
  rangeId,
  onlyIcon,
  cancel,
}) => {
  const [languageState, updateLanguageState] = useState('en')
  const [state, setState] = useState<{
    start: moment.Moment | undefined
    end: moment.Moment | undefined
  }>({
    start: undefined,
    end: undefined,
  })

  const intl = useIntl()

  const localeSettings = {
    format: 'DD/MM/YYYY',
    separator: ' - ',
    applyLabel: intl.formatMessage({id: 'FILTERS.BUTTON.APPLY'}),
    cancelLabel: intl.formatMessage({id: 'COMMON.BUTTON.CLOSE'}),
    timeZone: timeZone,
    customRangeLabel: intl.formatMessage({id: 'DATERANGEPICKER.CUSTOM'}),
    daysOfWeek: [
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.SU'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.MO'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.TU'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.WE'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.TH'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.FR'}),
      intl.formatMessage({id: 'DATERANGEPICKER.DAYS.SA'}),
    ],
    monthNames: [
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.JENUARY'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.FEBRUARY'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.MARCH'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.APRIL'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.MAY'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.JUNE'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.JULY'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.AUGUST'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.SEPTEMBER'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.OCTOBER'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.NOVEMBER'}),
      intl.formatMessage({id: 'DATERANGEPICKER.MONTH.DECEMBER'}),
    ],
    firstDay: 1,
  }

  const rangeEngSettings = {
    Today: [moment().toDate(), moment().toDate()],
    Yesterday: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
    'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
    'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate(),
    ],
  }

  const rangeRusSettings = {
    Сегодня: [moment().toDate(), moment().toDate()],
    Вчера: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    'Последние 7 дней': [moment().subtract(6, 'days').toDate(), moment().toDate()],
    'Псоледние 30 дней': [moment().subtract(29, 'days').toDate(), moment().toDate()],
    'Этот месяц': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    'Прошлый месяц': [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate(),
    ],
  }

  const handleCallback = (start: any, end: any) => {
    updateState({
      start: start.format('YYYY-MM-DDT00:00:00'),
      end: end.format('YYYY-MM-DDT23:59:00'),
    })
  }

  useEffect(() => {
    if (language) {
      updateLanguageState(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  useEffect(() => {
    if (periodState.start !== '') {
      let startDate = moment(
        periodState.start.replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1').split('T')[0],
        'DD/MM/YYYY'
      )
      let endDate = moment(
        periodState.end.replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1').split('T')[0],
        'DD/MM/YYYY'
      )
      setState({start: startDate, end: endDate})
    } else {
      setState({start: undefined, end: undefined})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  return (
    <>
      {languageState === 'ru' && state && (
        <DateRangePicker
          initialSettings={{
            startDate: state.start,
            endDate: state.end,
            timeZone: timeZone,
            maxDate: moment().toDate(),
            ranges: rangeRusSettings,
            locale: localeSettings,
            alwaysShowCalendars: true,
          }}
          onCallback={handleCallback}
        >
          <div className='input-group input-group-solid'>
            <span
              className={clsx('input-group-text', {
                'btn btn-small btn-light py-2 px-4': onlyIcon === true,
              })}
            >
              <i className='fa fa-calendar p-0'></i>
            </span>
            {onlyIcon === false && (
              <input
                id={'ReportRange' + rangeId}
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900 pe-0'
                placeholder='01/01/2023 - 02/01/2023'
                defaultValue={
                  periodState.start !== '' && state.start && state.end
                    ? state.start.format('DD/MM/YYYY') + ' - ' + state.end.format('DD/MM/YYYY')
                    : ''
                }
              />
            )}
            {onlyIcon === false && !cancel && state.start !== undefined && state.end !== undefined && (
              <button
                className='input-group-text p-3'
                onClick={() => {
                  setState({start: undefined, end: undefined})
                  updateState({start: '', end: ''})
                }}
              >
                <i className='fa fa-xmark'></i>
              </button>
            )}
          </div>
        </DateRangePicker>
      )}
      {languageState === 'en' && (
        <DateRangePicker
          initialSettings={{
            startDate: state.start,
            endDate: state.end,
            timeZone: timeZone,
            maxDate: moment().toDate(),
            ranges: rangeEngSettings,
            locale: localeSettings,
            alwaysShowCalendars: true,
          }}
          onCallback={handleCallback}
        >
          <div className='input-group input-group-solid cursor-pointer'>
            <span className='input-group-text'>
              <i className='fa fa-calendar'></i>
            </span>
            {onlyIcon === false && (
              <input
                id={'ReportRange' + rangeId}
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900 pe-0'
                placeholder='01/01/2023 - 02/01/2023'
                defaultValue={
                  periodState.start !== '' && state.start && state.end
                    ? state.start.format('DD/MM/YYYY') + ' - ' + state.end.format('DD/MM/YYYY')
                    : ''
                }
              />
            )}
            {onlyIcon === false && !cancel && state.start !== undefined && state.end !== undefined && (
              <button
                className='input-group-text p-3'
                onClick={() => {
                  setState({start: undefined, end: undefined})
                  updateState({start: '', end: ''})
                }}
              >
                <i className='fa fa-xmark'></i>
              </button>
            )}
          </div>
        </DateRangePicker>
      )}
    </>
  )
}
