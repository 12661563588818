import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IObjectionGroup} from '../../../../../models/score/Score'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {selectReduserClear} from '../../../../../redux/slices/serviceSlice'
import {useAppDispatch, useAppSelector} from '../../../../../redux/hooks/redux'

type Props = {
  show: boolean
  handleClose: () => void
  currentGroupId: string
}

const MoveObjectionsModal: FC<Props> = ({show, handleClose, currentGroupId}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {data} = scoreAPI.useGroupsObjectionFetchQuery()
  const {selected} = useAppSelector((state) => state.serviceReduser)

  const [moveToGroup, {isLoading: loading}] = scoreAPI.useGroupObjectionAddMutation()

  const handleCloseClearModal = () => {
    if (selected.rows.size !== 0) {
      dispatch(selectReduserClear())
    }
    dispatch(selectReduserClear())
    handleClose()
  }

  const [groups, setGroups] = useState<IObjectionGroup[]>(
    data!.filter((gr) => gr.id !== currentGroupId)
  )

  useEffect(() => {
    setGroups(data!.filter((gr) => gr.id !== currentGroupId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupId])

  const formik = useFormik({
    initialValues: {
      group_id: groups[0].id,
    },
    onSubmit: async (values) => {
      const ids = Array.from(selected.rows.keys())
      await moveToGroup({id: values.group_id!, data: {objection_ids: ids}})
        .unwrap()
        .then(() => {
          handleCloseClearModal()
          formik.resetForm()
        })
    },
  })

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>{intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.REMOVE'})}</Modal.Title>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <label className='fw-bold fs-5 mb-5'>
              {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.SELECT'})}
            </label>
            <select
              className='form-select form-select-solid form-select-lg text-gray-900 text-uppercase'
              {...formik.getFieldProps('group_id')}
            >
              {groups.map((group: IObjectionGroup) => (
                <option value={group.id} key={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end' style={{border: 'none'}}>
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.MOVE'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {MoveObjectionsModal}
