/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {useObjectionsFilters} from '../helpers/ObjectionsFilters'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {ObjectionsTable} from '../tables_widgets/objection_table/ObjectionsTable'
import {ObjectionsFooter} from '../tables_widgets/objection_table/ObjectionsFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'

const ObjectionsUsersAnalytics: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {objFilter, setObjectionsFilter} = useObjectionsFilters()
  const [periodState, setPeriodState] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const [objectionsOptions, setObjectionsOptions] = useState<{label: string; value: string}[]>([])
  const [selectedObjections, setSelectedObjections] = useState<{label: string; value: string}[]>([])
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const [getUsers, {data: users, isLoading: isLoadingGetUsers}] = usersAPI.useLazyUsersFetchQuery()
  const [getObjectionsList, {data: objections, isLoading: isLoadingGetObjections}] =
    scoreAPI.useLazyObjectionFetchQuery()
  const [getObjections, {data: objectionsList, isLoading: isLoadingObjections}] =
    analyticsAPI.useLazyAnalyticsObjectionsFetchQuery()

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: isLoadingGetUsers
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  const localeSettingsObjectionsSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.OBJECTIONS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.OBJECTIONS.CLEARSELECTED'}),
      noOptions: isLoadingGetObjections
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.OBJECTIONS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.OBJECTIONS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.OBJECTIONS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.OBJECTIONS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  useEffect(() => {
    if (periodState) {
      setObjectionsFilter({
        date: periodState,
        users: objFilter.users,
        objections: objFilter.objections,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    if (users && users?.users.length !== 0) {
      let usersList: {label: string; value: string}[] = []
      users.users.map((user) => usersList.push({label: user.name, value: user.id}))
      setUsersOptions(usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (objections && objections?.objections.length !== 0) {
      let objectionsList: {label: string; value: string}[] = []
      objections.objections.forEach((objection) => {
        if (objection.status === 'PUBLISHED') {
          objectionsList.push({label: objection.name, value: objection.id})
        }
      })
      setObjectionsOptions(objectionsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objections])

  useEffect(() => {
    if (objFilter.objections.length !== 0) {
      setSelectedObjections(objFilter.objections)
    }
    if (objFilter.users.length !== 0) {
      setSelectedUsers(objFilter.users)
    }
    if (objFilter.date.start !== '' && objFilter.date.end !== '') {
      setPeriodState(objFilter.date)
    }
    if (
      objFilter.objections.length !== 0 &&
      objFilter.users.length !== 0 &&
      objFilter.date.start !== '' &&
      objFilter.date.end !== ''
    ) {
      getObjections({
        period: objFilter.date.start + ',' + objFilter.date.end,
        user_ids: getSelected(objFilter.users),
        score_objection_ids: getSelected(objFilter.objections),
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objFilter])

  return (
    <>
      <div className='card card-custom card-stretch shadow mt-20'>
        <div className='card-header'>
          <div className='d-flex flex-stack col-12 me-10'>
            <div className='card-title m-0 d-flex flex-wraps col-3'>
              <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'OBJECTIONS.TITLE'})}</h3>
            </div>

            <div className='col-lg-9 d-flex flex-end'>
              <div className='pe-3 col-lg-4'>
                <MultiSelect
                  onMenuToggle={() => {
                    if (!users) {
                      getUsers({is_active: true})
                    }
                    if (selectedUsers.length !== 0) {
                      setObjectionsFilter({
                        date: objFilter.date,
                        users: selectedUsers,
                        objections: objFilter.objections,
                      })
                    }
                  }}
                  className='text-gray-900'
                  options={usersOptions}
                  hasSelectAll={true}
                  value={selectedUsers}
                  onChange={(e: any) => {
                    setSelectedUsers(e)
                    if (e.length === 0) {
                      setObjectionsFilter({
                        date: objFilter.date,
                        users: e,
                        objections: objFilter.objections,
                      })
                    }
                  }}
                  overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
                />
              </div>
              <div className='pe-3 col-lg-4 popup'>
                <MultiSelect
                  onMenuToggle={() => {
                    if (!objections) {
                      getObjectionsList({})
                    }

                    if (selectedObjections.length !== 0) {
                      setObjectionsFilter({
                        date: objFilter.date,
                        users: objFilter.users,
                        objections: selectedObjections,
                      })
                    }
                  }}
                  className='text-gray-900'
                  options={objectionsOptions}
                  hasSelectAll={true}
                  value={selectedObjections}
                  onChange={(e: any) => {
                    setSelectedObjections(e)
                    if (e.length === 0) {
                      setObjectionsFilter({
                        date: objFilter.date,
                        users: objFilter.users,
                        objections: e,
                      })
                    }
                  }}
                  overrideStrings={localeSettingsObjectionsSelect(intl.formatMessage)}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.OBJECTIONS.SELECT'})}
                />
              </div>
              <div className='col-lg-4'>
                <DateRangePickerInput
                  language={currentUser?.language}
                  timeZone={currentUser?.time_zone}
                  rangeId='2'
                  periodState={periodState}
                  updateState={setPeriodState}
                  onlyIcon={false}
                  cancel={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <ObjectionsTable
              objectionsAnalytics={
                objFilter.objections.length !== 0 &&
                objFilter.users.length !== 0 &&
                objFilter.date.start !== '' &&
                objFilter.date.end !== ''
                  ? objectionsList
                  : undefined
              }
              selectedUsers={objFilter.users}
            />
          </div>
          {isLoadingObjections ? (
            <div className='d-flex flex-center'>
              <span
                className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
                style={{display: 'block'}}
              >
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            (objFilter.objections.length === 0 ||
              objFilter.users.length === 0 ||
              objFilter.date.start === '' ||
              objFilter.date.end === '') && (
              <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
                {intl.formatMessage({id: 'OBJECTIONS.TABLE.INFO'})}
              </span>
            )
          )}
        </div>
        <ObjectionsFooter selectedUsers={selectedUsers} />
      </div>
    </>
  )
}

export {ObjectionsUsersAnalytics}
