import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ISequenceGroup} from '../../../../models/analytics/Analytics'

type Props = {
  type: string | undefined
  groups?: {[key: string]: ISequenceGroup}
}

const ControlsCriteriaTableHeader: FC<Props> = ({type, groups}) => {
  const intl = useIntl()
  return (
    <thead>
      {groups && Object.values(groups).length !== 0 && (
        <>
          <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
            <th role='columnheader' className='w-200px'></th>
            <th role='columnheader' className='w-200px'></th>
            <th role='columnheader' className='w-150px'></th>
            {type === 'CALL' && (
              <>
                <th role='columnheader' className='w-150px'></th>
                <th role='columnheader' className='w-150px'></th>
              </>
            )}
            {groups &&
              Object.values(groups).map(
                (group) =>
                  group.criterions.length !== 0 && (
                    <th
                      role='columnheader'
                      className='min-w-150px fw-bold text-gray-800 border-end text-center'
                      colSpan={group.criterions.length}
                      key={group.id}
                    >
                      {group.name}
                    </th>
                  )
              )}
            {groups &&
              !(Object.values(groups).length === 1 && Object.values(groups)[0].id === 'n/g') && (
                <th
                  role='columnheader'
                  className='min-w-250px text-center text-gray-800 border-end'
                  colSpan={Object.values(groups).length - 1}
                >
                  {intl.formatMessage({id: 'ANALYTICS.CONTROLS.CRITERIA.SCORE'})}
                </th>
              )}
          </tr>
          <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
            <th role='columnheader' className='w-200px table-fixed-th' style={{border: 'none'}}>
              {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
            </th>
            <th role='columnheader' className='min-w-200px'>
              {intl.formatMessage({id: 'OBJECTS.TABLE.CALLDATE'})}
            </th>
            <th role='columnheader' className='min-w-150px'>
              {intl.formatMessage({id: 'CONTROLS.TYPE'})}
            </th>
            {type === 'CALL' && (
              <>
                <th role='columnheader' className='min-w-150px'>
                  {intl.formatMessage({id: 'FILTERS.DIRECTION'})}
                </th>
                <th role='columnheader' className='min-w-150px'>
                  {intl.formatMessage({id: 'FILTERS.DURATION'})}
                </th>
              </>
            )}
            {groups &&
              Object.values(groups).map((group) =>
                group.criterions.map((criterion) =>
                  criterion.id === group.criterions[group.criterions.length - 1].id ? (
                    <th
                      role='columnheader'
                      className='min-w-150px border-end text-center'
                      key={criterion.id}
                      id={criterion.id}
                    >
                      {criterion.name}
                    </th>
                  ) : (
                    <th role='columnheader' className='min-w-150px text-center' key={criterion.id}>
                      {criterion.name}
                    </th>
                  )
                )
              )}
            {groups &&
              Object.values(groups).map(
                (group) =>
                  group.id !== 'n/g' && (
                    <th
                      role='columnheader'
                      className='min-w-100px text-center border-end'
                      key={group.id}
                    >
                      {group.name}
                    </th>
                  )
              )}
            <th role='columnheader' className='min-w-250px text-center'>
              {intl.formatMessage({id: 'ANALYTICS.CONTROLS.CRITERIA.COMMENT'})}
            </th>
          </tr>
        </>
      )}
    </thead>
  )
}

export {ControlsCriteriaTableHeader}
