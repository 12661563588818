import {useIntl} from 'react-intl'
import AsyncSelect from 'react-select/async'
import {FC, useEffect, useState} from 'react'
import {scoreAPI} from '../../../../../../redux/services/ScoreService'
import {IObjectionGroup, IObjectionGroupsSelect} from '../../../../../../models/score/Score'

interface Props {
  setSelectGroupObjection: (group?: IObjectionGroup) => void
  initialDefaultValue?: string
}

const SelectGroupsObjection: FC<Props> = ({setSelectGroupObjection, initialDefaultValue}) => {
  const intl = useIntl()

  const [getGroupsObjection] = scoreAPI.useLazyGroupsObjectionFetchQuery()

  const [groups, setGroups] = useState<IObjectionGroup[]>()
  const [defaultValue, setDefaultValue] = useState<IObjectionGroupsSelect>()

  const loadOptions = (
    inputValue: string,
    callback: (options: IObjectionGroupsSelect[]) => void
  ) => {
    getGroupsObjection()
      .unwrap()
      .then((response) => {
        setGroups(response)
        callback(filter(response))
      })
  }

  const filter = (groups: IObjectionGroup[]) => {
    const options: IObjectionGroupsSelect[] = []
    groups.forEach((gr) => {
      options.push({value: gr.id, label: gr.name})
    })
    return options
  }

  const handleGroupsObjectionSelect = (id?: string) => {
    setDefaultValue(undefined)
    const group = groups?.find((gr) => gr.id === id)
    setSelectGroupObjection(group)
  }

  useEffect(() => {
    if (initialDefaultValue) {
      const group = groups?.find((gr) => gr.id === initialDefaultValue)
      setDefaultValue({value: group?.id, label: group?.name} as IObjectionGroupsSelect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  return (
    <>
      <label className='col-form-label fw-bolder text-gray-600'>
        <span className='small text-uppercase'>
          {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.TITLE'})}
        </span>
      </label>
      <div className='mb-5'>
        <AsyncSelect
          cacheOptions={false}
          defaultOptions
          isClearable={true}
          isSearchable={false}
          loadOptions={loadOptions}
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          placeholder={intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.TITLE'})}
          onChange={(e) => handleGroupsObjectionSelect(e?.value)}
          isDisabled={false}
          value={defaultValue}
        />
      </div>
    </>
  )
}
export {SelectGroupsObjection}
