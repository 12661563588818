import clsx from 'clsx'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'
import {authAPI} from '../../../redux/services/AuthService'

const updatePasswordSchema = (f: any) =>
  Yup.object().shape({
    password: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    confirm_password: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

const REACT_APP_URL = process.env.REACT_APP_URL

export function UpdatePassword() {
  const [updatePassword, {isLoading: loading, isError}] = authAPI.useUpdatePasswordMutation()
  const intl = useIntl()
  const navigate = useNavigate()
  const {config} = useLayout()

  const formik = useFormik({
    initialValues: {password: '', confirm_password: ''},
    validationSchema: updatePasswordSchema(intl.formatMessage),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const data = {
        token: document.location.href.split('update-password/')[1],
        password: values.password,
        confirm_password: values.confirm_password,
      }
      await updatePassword(data)
        .unwrap()
        .then(() => {
          setStatus(intl.formatMessage({id: 'AUTH.GENERAL.UPDATEPASSWORD.INFO'}))
          setTimeout(() => {
            formik.resetForm()
            navigate('/login', {replace: true})
          }, 3000)
        })
        .catch((error) => {
          if (error.status === 422) {
            setStatus(intl.formatMessage({id: 'DETAIL.UPDATEPASSWORD.ERROR'}))
          } else {
            setStatus(intl.formatMessage({id: error.data.detail}))
          }
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='text-center mb-11'>
        {config.layoutType === 'dark-sidebar' ? (
          <a href={`${REACT_APP_URL}`}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-white.svg')}
              className='h-50px app-sidebar-logo-default mb-5'
            />
          </a>
        ) : (
          <>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo.svg')}
                className='h-50px app-sidebar-logo-default theme-light-show mb-5'
              />
            </a>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-white.svg')}
                className='h-50px app-sidebar-logo-default theme-dark-show mb-5'
              />
            </a>
          </>
        )}
        <h1 className='text-light fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.UPDATEPASSWORD.TITLE'})}
        </h1>
        <div className='separator separator-content my-14'>
          <span className='text-muted fw-semibold fs-7 min-w-150px'>
            {intl.formatMessage({id: 'AUTH.UPDATEPASSWORD.SUBTITLE'})}
          </span>
        </div>
      </div>

      {formik.status && (
        <>
          {isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {!isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-info'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      )}

      <form className='form w-lg-400px' noValidate onSubmit={formik.handleSubmit}>
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx('form-control bg-white text-black', {
                  'is-invalid': (formik.touched.password && formik.errors.password) || isError,
                })}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='fv-row mb-5'>
          <input
            type='password'
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
            autoComplete='off'
            {...formik.getFieldProps('confirm_password')}
            className={clsx('form-control bg-white text-black', {
              'is-invalid':
                (formik.touched.confirm_password && formik.errors.confirm_password) || isError,
            })}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirm_password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8 d-flex flex-center'>
          <button
            type='submit'
            className='btn btn-sm btn-info w-50 mb-5 me-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}
