import {FC, useEffect, useState} from 'react'
import {OneRowObjectionsUser} from './OneRowObjectionsUser'
import {
  IAnalyticsUserObjectionsResponse,
  IUserObjectionsPeriod,
} from '../../../../models/analytics/Analytics'
import {ObjectionsUserHeader} from './ObjectionsUserHeader'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  objectionsAnalytics?: IAnalyticsUserObjectionsResponse
  selectedObjections?: {label: string; value: string}[]
}

export const ObjectionsUserTable: FC<Props> = ({objectionsAnalytics, selectedObjections}) => {
  const [pageObjections, updatePageObjections] = useState<IUserObjectionsPeriod[]>([])
  const [allObjections, updateAllObjections] = useState<IUserObjectionsPeriod[]>([])
  const {state} = useQueryRequest()

  useEffect(() => {
    if (objectionsAnalytics && selectedObjections?.length !== 0) {
      let emptyObjections: IUserObjectionsPeriod[] = []
      selectedObjections?.forEach((objection) => {
        let mark = false
        for (let d of objectionsAnalytics?.data) {
          if (objection.value === d.score_objection.id) {
            mark = true
            break
          }
        }
        if (mark === false) {
          emptyObjections.push({
            score_objection: {
              id: objection.value,
              name: objection.label,
            },
            score: {},
          })
        }
      })

      updateAllObjections(objectionsAnalytics.data.concat(emptyObjections))
    } else if (!objectionsAnalytics) {
      updateAllObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectionsAnalytics])

  useEffect(() => {
    if (allObjections.length !== 0) {
      if (allObjections.length >= state.skip! + state.limit!) {
        updatePageObjections(allObjections.slice(state.skip, state.skip! + state.limit!))
      } else {
        updatePageObjections(allObjections.slice(state.skip))
      }
    } else {
      updatePageObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, allObjections])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ObjectionsUserHeader
          periods={
            objectionsAnalytics && Object.keys(objectionsAnalytics.periods).length !== 0
              ? objectionsAnalytics?.periods
              : {}
          }
        />
        <tbody>
          {pageObjections.map((u) => (
            <OneRowObjectionsUser
              object={u}
              key={u.score_objection.id}
              periodsNumber={
                objectionsAnalytics ? Object.keys(objectionsAnalytics.periods).length : 0
              }
            />
          ))}
        </tbody>
      </table>
    </>
  )
}
