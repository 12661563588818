import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {fixObjEmptyField} from '../../../../../_metronic/helpers/components/CheckEmptyFields'
import {TimeZoneOptions} from '../../../../../_metronic/helpers/components/TimeZoneOptions'
import {useLang, SelectedLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useLayout} from '../../../../../_metronic/layout/core'
import {ProfileUpdateInitialValues, ProfileUpdateModel} from '../../../../models/profile/Profile'
import {profileAPI} from '../../../../redux/services/ProfileService'
import {ProfileCompany} from './profile/Company'
import {ProfileEmail} from './profile/Email'
import {ProfileFullname} from './profile/Fullname'
import {Language} from './profile/Language'
import {ProfilePhone} from './profile/Phone'

const editProfileSchema = (f: any) =>
  Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email(f({id: 'FORMIK.ERROR.WRONGEMAILFORMAT'}))
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    phone: Yup.number(),
    company_name: Yup.string(),
    language: Yup.string(),
    time_zone: Yup.string(),
  })

const REACT_APP_URL = process.env.REACT_APP_URL

export function Profile() {
  const intl = useIntl()
  const {config} = useLayout()
  const {setLang, lang} = useLang()
  const [companySwith, setCompanySwith] = useState(false)
  const [editProfileState, updateEditProfileState] = useState(false)
  const {data: profile_info} = profileAPI.useProfileInfoFetchQuery()
  const [updateProfile, {isLoading: loading, isError}] = profileAPI.useProfileUpdateMutation()

  useEffect(() => {
    if (profile_info?.company_name) {
      setCompanySwith(true)
    }
    if (profile_info?.language && profile_info?.language !== lang) {
      setLang(profile_info?.language as SelectedLang)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_info])

  const formik = useFormik({
    initialValues: Object.assign(
      ProfileUpdateInitialValues,
      fixObjEmptyField({
        id: profile_info?.id,
        name: profile_info?.name,
        phone: profile_info?.phone,
        email: profile_info?.email,
        company_name: profile_info?.company_name,
        language: profile_info?.language,
        time_zone: profile_info?.time_zone,
      })
    ),
    validationSchema: editProfileSchema(intl.formatMessage),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        phone: values.phone,
        company_name: !companySwith ? '' : values.company_name,
        language: values.language,
        time_zone: values.time_zone,
      }

      await updateProfile(data as ProfileUpdateModel)
        .unwrap()
        .then(() => {
          if (profile_info?.language !== values?.language) {
            setLang(values.language)
          }
          formik.resetForm()
          setCompanySwith(false)
          updateEditProfileState(false)
        })
    },
  })

  return (
    <>
      <div className='text-center mb-11'>
        {config.layoutType === 'dark-sidebar' ? (
          <a href={`${REACT_APP_URL}`}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-white.svg')}
              className='h-50px app-sidebar-logo-default mb-5'
            />
          </a>
        ) : (
          <>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo.svg')}
                className='h-50px app-sidebar-logo-default theme-light-show mb-5'
              />
            </a>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-white.svg')}
                className='h-50px app-sidebar-logo-default theme-dark-show mb-5'
              />
            </a>
          </>
        )}
        <h1 className='text-light fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.PROFILEINFO.TITLE'})} {profile_info?.name}
        </h1>
        <div className='text-center fw-semibold fs-6'>
          <Link to='/accounts/' className='link-info'>
            <KTSVG className='svg-icon-info' path='/media/icons/duotune/arrows/arr025.svg' />{' '}
            {intl.formatMessage({id: 'AUTH.PROFILEINFO.GOTOACCOUNT'})}
          </Link>
        </div>
        <div className='separator separator-content my-14'>
          <span className='text-muted fw-semibold fs-7 min-w-125px'>
            {intl.formatMessage({id: 'AUTH.PROFILE.SUBTITLE'})}
          </span>
        </div>
      </div>

      {editProfileState === false ? (
        <div className='form w-lg-400px'>
          <ProfileFullname name={profile_info?.name} />
          <ProfileEmail email={profile_info?.email} />
          {companySwith && <ProfileCompany company_name={profile_info?.company_name} />}
          <ProfilePhone phone={profile_info?.phone} />
          <Language language={profile_info?.language} time_zone={profile_info?.time_zone} />
          <div className='text-center'>
            <button
              type='button'
              className='btn btn-sm btn-info w-200px mb-5'
              onClick={() => {
                // getProfileInfo()
                updateEditProfileState(!editProfileState)
              }}
            >
              <span className='indicator-label me-2'>
                {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
              </span>
              <KTSVG path='/media/icons/duotune/art/art005.svg' />
            </button>
          </div>
        </div>
      ) : (
        <form className='form w-lg-400px' noValidate onSubmit={formik.handleSubmit}>
          <div className='fv-row mb-8'>
            <label className='ms-2 mb-2 label text-muted text-uppercase'>
              {intl.formatMessage({id: 'AUTH.INPUT.FULLNAME'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.FULLNAME'})}
              type='text'
              {...formik.getFieldProps('name')}
              className={clsx('form-control bg-white text-black', {
                'is-invalid': (formik.touched.name && formik.errors.name) || isError,
              })}
            />
          </div>
          <ProfileEmail email={profile_info?.email} />
          <div className='d-flex flex-center fv-row mb-8'>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid me-10'>
              <label
                className='form-check-label text-muted me-5 text-uppercase'
                htmlFor='OrganizationSwitchProfile'
              >
                {intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATION'})}
              </label>
              <input
                className='form-control form-check-input bg-active-info'
                type='checkbox'
                checked={companySwith}
                onChange={(e) => setCompanySwith(e.target.checked)}
              />
            </div>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATIONNAME'})}
              type='text'
              {...formik.getFieldProps('company_name')}
              className={clsx('form-control bg-white text-black', {
                'visible-hidden': !companySwith,
              })}
            />
          </div>

          <div className='fv-row mb-8'>
            <label className='ms-2 mb-2 label text-muted text-uppercase'>
              {intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
              type='phone'
              {...formik.getFieldProps('phone')}
              className='form-control bg-white text-black'
            />
          </div>
          <div className='fv-row mb-8 d-flex flex-stack'>
            <div className='fv-row w-50 me-2'>
              <label className='ms-2 me-5 mb-2 label text-muted text-uppercase'>
                {intl.formatMessage({id: 'MENU.LANGUAGE'})}:
              </label>
              <select
                {...formik.getFieldProps('language')}
                className='form-control form-select bg-white text-black'
              >
                <option value='en'>English</option>
                <option value='ru'>Russian</option>
              </select>
            </div>

            <div className='fv-row w-50 ms-2'>
              <label className='me-5 mb-2 label text-muted text-uppercase'>
                {intl.formatMessage({id: 'AUTH.PROFILEINFO.TIMEZONE'})}:
              </label>
              <select
                placeholder={intl.formatMessage({id: 'AUTH.PROFILEINFO.TIMEZONE'})}
                {...formik.getFieldProps('time_zone')}
                className='form-control bg-white text-black'
              >
                <TimeZoneOptions />
              </select>
            </div>
          </div>
          <div className='fv-row mb-8 text-end'>
            <Link to='/update-password/' className='link-info text-uppercase fw-bold'>
              {intl.formatMessage({id: 'AUTH.PROFILEINFO.UPDATEPASSWORD'})}
            </Link>
          </div>

          <div className='fv-row mb-8 d-flex flex-center'>
            <button
              type='submit'
              className='btn btn-sm btn-info w-50 mb-5 me-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              className='btn btn-sm btn-light-info w-50 mb-5'
              onClick={() => {
                updateEditProfileState(!editProfileState)
                formik.resetForm()
              }}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'COMMON.BUTTON.BACK'})}
              </span>
            </button>
          </div>
        </form>
      )}
    </>
  )
}
