/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {usersAPI} from '../../../../app/redux/services/UsersService'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'

const REACT_APP_URL = process.env.REACT_APP_URL

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='ms-4 d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
              <a
                href={`${REACT_APP_URL}/accounts/`}
                target='_blank'
                rel='noreferrer'
                className='ms-2 text-muted text-hover-primary fs-8 text-small text-lowercase'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr095.svg'
                  className='svg-icon svg-icon-gray-900 me-2'
                />
              </a>
            </div>
            <a className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.profile.email}
            </a>
            {/* <a
              href={`${REACT_APP_URL}/info/`}
              target='_blank'
              rel='noreferrer'
              className='text-muted text-hover-primary fs-8 text-small text-lowercase'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr095.svg'
                className='svg-icon svg-icon-gray-900 me-2'
              />
              Profile info
            </a> */}
          </div>
        </div>
      </div>

      <div className='separator separator-dashed my-2'></div>

      <div className='menu-item fw-bold fs-7 text-uppercase px-5'>
        <Link to={`/settings/users/${currentUser?.id}/`} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.SETTINGS'})}
        </Link>
      </div>
      <Languages />

      <div className='separator separator-dashed my-2'></div>

      <div className='menu-item fw-bold fs-7 text-uppercase px-5'>
        <Link to='/logout' className='menu-link px-5'>
          {intl.formatMessage({id: 'AUTH.SIGNOUT.TITLE'})}
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
