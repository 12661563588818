import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type PeriodsGetControls = {
  periodAllControls: {start: string; end: string}
  periodCheckedControls: {start: string; end: string}
  periodOwnCheckedControls: {start: string; end: string}
}

type selectedPeriodsGetControls = {
  periods: PeriodsGetControls
  setPeriodsGetControls: (newPeriods: PeriodsGetControls) => void
}

const initialStatePeriodsGetControls: PeriodsGetControls = {
  periodAllControls: {start: '', end: ''},
  periodCheckedControls: {start: '', end: ''},
  periodOwnCheckedControls: {start: '', end: ''},
}

const initialStatePeriodsGetControlsCallback: selectedPeriodsGetControls = {
  periods: initialStatePeriodsGetControls,
  setPeriodsGetControls: () => {},
}

function getPeriods(key: string): PeriodsGetControls {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as PeriodsGetControls
    } catch (er) {
      console.error(er)
    }
  }
  return initialStatePeriodsGetControls
}

const getPeriodsControlsContext = createContext<selectedPeriodsGetControls>(
  initialStatePeriodsGetControlsCallback
)

const PeriodGetControlsProvider: FC<WithChildren> = ({children}) => {
  const [periodsControls, setPeriodsControls] = useState<PeriodsGetControls>(
    getPeriods(`${wl.hostname}_periodsControls`)
  )
  const setPeriods = (periods: PeriodsGetControls) => {
    localStorage.setItem(`${wl.hostname}_periodsControls`, JSON.stringify(periods))
    setPeriodsControls(periods)
  }

  return (
    <getPeriodsControlsContext.Provider
      value={{periods: periodsControls, setPeriodsGetControls: setPeriods}}
    >
      {children}
    </getPeriodsControlsContext.Provider>
  )
}

const usePeriodsControls = () => useContext(getPeriodsControlsContext)
export {PeriodGetControlsProvider, usePeriodsControls}
