/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {ObjectionGroupList} from './components/ObjectionGroupItem'
import {ObjectionsWithoutGroups} from './components/ObjectionsWithoutGroups'
import {GroupScoreObjectionsList} from './components/GroupScoreObjectionsList'

const ObjectionGroupsWrapper: FC = () => {
  const {id} = useParams()

  return (
    <div className='row'>
      <div className='col-lg-3'>
        <div className='card card-body p-1 shadow'>
          <ObjectionGroupList />
        </div>
      </div>
      <div className='col-lg-9'>
        <div className='card card-custom card-stretch shadow'>
          {!id ? <ObjectionsWithoutGroups /> : <GroupScoreObjectionsList />}
        </div>
      </div>
    </div>
  )
}

export {ObjectionGroupsWrapper}
