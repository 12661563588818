/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {ObjectionsFooter} from '../tables_widgets/objection_table/ObjectionsFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {useCommunicationsFilters} from '../helpers/CommunicationsProvider'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'
import {CommunicationsTable} from '../tables_widgets/communications_table/CommunicationsTable'

const CommunicationsAnalytics: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {communicationsFilter, setCommunicationsFilter} = useCommunicationsFilters()
  const [periodState, setPeriodState] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const [getUsers, {data: users, isLoading: isLoadingGetUsers}] = usersAPI.useLazyUsersFetchQuery()
  const [getCommunications, {data: communicationsList, isLoading: isLoadingCommunications}] =
    analyticsAPI.useLazyAnalyticsCommunicationsFetchQuery()

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: isLoadingGetUsers
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  useEffect(() => {
    if (periodState) {
      setCommunicationsFilter({
        period: periodState,
        users: communicationsFilter.users,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    if (users && users?.users.length !== 0) {
      let usersList: {label: string; value: string}[] = []
      users.users.map((user) => usersList.push({label: user.name, value: user.id}))
      setUsersOptions(usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (communicationsFilter.users.length !== 0) {
      setSelectedUsers(communicationsFilter.users)
    }
    if (communicationsFilter.period.start !== '' && communicationsFilter.period.end !== '') {
      setPeriodState(communicationsFilter.period)
    }
    if (
      communicationsFilter.users.length !== 0 &&
      communicationsFilter.period.start !== '' &&
      communicationsFilter.period.end !== ''
    ) {
      getCommunications({
        period: communicationsFilter.period.start + ',' + communicationsFilter.period.end,
        user_ids: getSelected(communicationsFilter.users),
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationsFilter])

  return (
    <>
      <div className='card card-custom card-stretch shadow mt-20'>
        <div className='card-header'>
          <div className='d-flex flex-stack col-12 me-10'>
            <div className='card-title m-0 d-flex flex-wraps col-3'>
              <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'CALLS.TITLE'})}</h3>
            </div>

            <div className='col-lg-9 d-flex flex-end'>
              <div className='pe-3 col-lg-4'>
                <MultiSelect
                  onMenuToggle={() => {
                    if (!users) {
                      getUsers({is_active: true})
                    }
                    if (selectedUsers.length !== 0) {
                      setCommunicationsFilter({
                        period: communicationsFilter.period,
                        users: selectedUsers,
                      })
                    }
                  }}
                  className='text-gray-900'
                  options={usersOptions}
                  hasSelectAll={true}
                  value={selectedUsers}
                  onChange={(e: any) => {
                    setSelectedUsers(e)
                    if (e.length === 0) {
                      setCommunicationsFilter({
                        period: communicationsFilter.period,
                        users: e,
                      })
                    }
                  }}
                  overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
                />
              </div>
              <div className='col-lg-4'>
                <DateRangePickerInput
                  language={currentUser?.language}
                  timeZone={currentUser?.time_zone}
                  rangeId='2'
                  periodState={periodState}
                  updateState={setPeriodState}
                  onlyIcon={false}
                  cancel={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <CommunicationsTable
              selectedUsers={selectedUsers}
              communicationsAnalytics={
                communicationsFilter.users.length !== 0 &&
                communicationsFilter.period.start !== '' &&
                communicationsFilter.period.end !== ''
                  ? communicationsList
                  : undefined
              }
            />
          </div>
          {isLoadingCommunications ? (
            <div className='d-flex flex-center'>
              <span
                className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
                style={{display: 'block'}}
              >
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            (communicationsFilter.users.length === 0 ||
              communicationsFilter.period.start === '' ||
              communicationsFilter.period.end === '') && (
              <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
                {intl.formatMessage({id: 'COMMUNICATIONS.TABLE.INFO'})}
              </span>
            )
          )}
        </div>
        <ObjectionsFooter selectedUsers={selectedUsers} />
      </div>
    </>
  )
}

export {CommunicationsAnalytics}
