/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {RoleAccess} from '../../../../../app/models/users/Users'
import {getAccesses} from '../../../../helpers/components/RoleAccesses'
import {usersAPI} from '../../../../../app/redux/services/UsersService'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard/'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.ANALYTICS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/analytics/criteria/'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.ANALYTICS.CRITERIA'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-9 ls-1'>
            {intl.formatMessage({id: 'MENU.TOOLS'})}
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/objects/'
        icon='/media/icons/duotune/abstract/abs027.svg'
        title={intl.formatMessage({id: 'MENU.OBJECTS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/tools/score_forms/'
        icon='/media/icons/duotune/abstract/abs026.svg'
        title={intl.formatMessage({id: 'TOOLS.SCOREFORMS.TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/tools/criteria/'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={intl.formatMessage({id: 'TOOLS.CRITERIA.TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/tools/criteria_groups/'
        icon='/media/icons/duotune/abstract/abs031.svg'
        title={intl.formatMessage({id: 'TOOLS.CRITERIAGROUPS.TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/tools/objections/'
        icon='/media/icons/duotune/general/gen044.svg'
        title={intl.formatMessage({id: 'TOOLS.OBJECTIONS.TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/tools/objection_groups/'
        icon='/media/icons/duotune/abstract/abs031.svg'
        title={intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.TITLE'})}
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-9 ls-1'>
            {intl.formatMessage({id: 'MENU.SETTINGS'})}
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/settings/'
        icon='/media/icons/duotune/communication/com006.svg'
        title={intl.formatMessage({id: 'MENU.ACCOUNT'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/settings/users/'
        icon='/media/icons/duotune/communication/com014.svg'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/settings/organization/'
        icon='/media/icons/duotune/communication/com001.svg'
        title={intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATION'})}
        fontIcon='bi-layers'
      />
      {roleAccesses.includes('INTEGRATIONREAD') && (
        <SidebarMenuItem
          to='/settings/integrations/'
          icon='/media/icons/duotune/abstract/abs034.svg'
          title={intl.formatMessage({id: 'MENU.INTEGRATIONS'})}
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}

export {SidebarMenuMain}
