import {IRatingScale} from '../../../models/score/Score'
import {IStringColorType} from '../models/controls'

export function RGBAToString(color: string) {
  if (color === 'rgba(197, 167, 244, 0.5)') {
    return 'DEFAULT'
  } else if (color === 'rgba(255, 0, 0, 0.5)') {
    return 'RED'
  } else if (color === 'rgba(255, 255, 0, 0.6)') {
    return 'YELLOW'
  } else if (color === 'rgba(0, 255, 0, 0.5)') {
    return 'GREEN'
  }
}

export function StringToRGBA(color: IStringColorType) {
  if (color === 'DEFAULT') {
    return 'rgba(197, 167, 244, 0.5)'
  } else if (color === 'RED') {
    return 'rgba(255, 0, 0, 0.5)'
  } else if (color === 'YELLOW') {
    return 'rgba(255, 255, 0, 0.6)'
  } else if (color === 'GREEN') {
    return 'rgba(0, 255, 0, 0.5)'
  } else if (color === 'BLUE') {
    return 'rgba(35, 132, 212, 0.5)'
  }
}

export function buttonsBYRatingScale(raitingScale: IRatingScale): number[] {
  let count = [0]
  if (raitingScale === 'BINARY_SCALE' || raitingScale === 'BINARY_TEXT_SCALE') {
    count = [0, 1]
  } else if (raitingScale === 'TERNARY_SCALE') {
    count = [1, 2, 3]
  } else if (raitingScale === 'QUATERNARY_SCALE') {
    count = [1, 2, 3, 4]
  } else if (raitingScale === 'QUINKARNARY_SCALE') {
    count = [1, 2, 3, 4, 5]
  } else if (raitingScale === 'DECINARY_SCALE') {
    count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  }
  return count
}
