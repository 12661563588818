import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {authAPI} from '../../../redux/services/AuthService'

export function Activate() {
  const {token} = useParams()
  const navigate = useNavigate()
  const [lazyActivateProfile] = authAPI.useLazyActivateQuery()

  useEffect(() => {
    const activate = async () => {
      await lazyActivateProfile({token: token as string})
    }

    activate()
    navigate('/login', {replace: true})
  }, [token, lazyActivateProfile, navigate])

  return <></>
}
