/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {AddCriteriaTable} from './add-criteria-table/AddCriteriaTable'
import {IGroup, IScoreForms} from '../../../../../models/score/Score'
import {selectReduserClear} from '../../../../../redux/slices/serviceSlice'
import {useAppDispatch, useAppSelector} from '../../../../../redux/hooks/redux'
import {QueryRequestProvider} from '../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  object: {type: string; object: IGroup | IScoreForms}
  show: boolean
  handleClose: () => void
}

const AddCriteriaModal: FC<Props> = ({object, show, handleClose}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [addCriteriaGroup, {isLoading: isLoadingGroup}] = scoreAPI.useGroupsActionMutation()
  const [addCriteriaFrom, {isLoading: isLoadingForm}] = scoreAPI.useFormsActionMutation()
  const {selected} = useAppSelector((state) => state.serviceReduser)

  const getAddCriteria = () => {
    let criteriaList: string[] = []
    if (selected.rows.size !== 0) {
      Array.from(selected.rows.keys()).map((criteriaId) => criteriaList.push(criteriaId))
    }
    return criteriaList
  }

  const handleCloseModal = () => {
    dispatch(selectReduserClear())
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-700px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'ADD.CRITERIA'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body className='px-7'>
          <QueryRequestProvider>
            <AddCriteriaTable object={object} />
          </QueryRequestProvider>
        </Modal.Body>
        <Modal.Footer>
          <div
            className='card-footer d-flex justify-content-end py-2 px-1'
            style={{border: 'none'}}
          >
            <button
              type='button'
              className='btn btn-sm btn-primary text-uppercase'
              onClick={() => {
                if (object.type === 'group') {
                  addCriteriaGroup({
                    id: object.object.id,
                    action: 'add',
                    data: {criterion_ids: getAddCriteria()},
                  })
                    .unwrap()
                    .then(() => {
                      handleCloseModal()
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                } else if (object.type === 'form') {
                  addCriteriaFrom({
                    id: object.object.id,
                    action: 'add',
                    data: {criterion_ids: getAddCriteria()},
                  })
                    .unwrap()
                    .then(() => {
                      handleCloseModal()
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }
              }}
            >
              {!isLoadingGroup && !isLoadingForm && (
                <span className='indicator-label'>{intl.formatMessage({id: 'ADD.CRITERIA'})}</span>
              )}
              {(isLoadingGroup || isLoadingForm) && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {AddCriteriaModal}
