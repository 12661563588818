import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'

type Props = {
  objections?: {[key: string]: string | undefined}
}

const ObjectionsHeader: FC<Props> = ({objections}) => {
  const intl = useIntl()
  const [objState, setObjState] = useState<{value: string; label?: string}[]>([])

  useEffect(() => {
    if (objections) {
      let objList: {value: string; label?: string}[] = []
      for (let key in objections) {
        objList.push({
          value: key,
          label: objections[key],
        })
      }
      setObjState(objList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objections])

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='w-200px table-fixed-th'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
        </th>
        {objState &&
          objState.length !== 0 &&
          objState.map((objection) => (
            <td role='columnheader' className='min-w-70px px-0 text-center' key={objection.value}>
              {objection.label}
            </td>
          ))}
        <td role='columnheader' className='min-w-70px px-0 text-center'>
          <OverlayTrigger
            placement='bottom-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'OBJECTIONS.AVERAGE.INFO'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'OBJECTIONS.AVERAGE'})}</span>
          </OverlayTrigger>
        </td>
      </tr>
    </thead>
  )
}

export {ObjectionsHeader}
