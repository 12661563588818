import clsx from 'clsx'
import {useIntl} from 'react-intl'
import update from 'immutability-helper'
import {Dropdown} from 'react-bootstrap'
import {CriterionCard} from './CriterionCard'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UpdateScoreForm} from './modals/UpdateScoreForm'
import {DeleteFormModal} from './modals/DeleteFormModal'
import {RoleAccess} from '../../../../models/users/Users'
import {usersAPI} from '../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../redux/services/ScoreService'
import {AddCriteriaModal} from '../criteria/modals/AddCriteriaModal'
import {EditCriterionModal} from '../criteria/modals/EditCriterionModal'
import {InfoCriterionModal} from '../criteria/modals/InfoCriterionModal'
import {ExcludeCriteriaModal} from '../criteria/modals/ExcludeCriteriaModal'
import {ICriteria, IScoreForms, IStatus} from '../../../../models/score/Score'
import {FC, ReactNode, forwardRef, useCallback, useEffect, useState} from 'react'
import {getAccesses} from '../../../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../../../_metronic/helpers/FormatDateZoneHelpers'

interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

interface FormProps {
  status: IStatus
  form: IScoreForms
  isFirst?: boolean
}

const FormSingle: FC<FormProps> = ({status, form, isFirst}) => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [updateForm, {isLoading: isLoadingUpdateForm}] = scoreAPI.useFormsUpdateMutation()
  const [getFormInfo, {data: formInfo, isLoading: isLoadingGetCriterions}] =
    scoreAPI.useLazyFormsByIdQuery({})

  const [createCriterionsPosition] = scoreAPI.useFormsCreateCriterionsPositionMutation()

  const [criterionCards, setCriterionCards] = useState<ICriteria[]>([])
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  const [infoCriterion, setInfoCriterion] = useState<ICriteria>()
  const [showModalEditFrom, setShowModalEditForm] = useState<boolean>(false)
  const [showModalDeleteForm, setShowModalDeleteForm] = useState<boolean>(false)
  const [showModalAddCriteria, setShowModalAddCriteria] = useState<boolean>(false)
  const [showModalInfoCriterion, setShowModalInfoCriterion] = useState<boolean>(false)
  const [showModalEditCriterion, setShowModalEditCriterion] = useState<boolean>(false)
  const [showModalDeleteCriteria, setShowModalDeleteCriteria] = useState<boolean>(false)

  const handleCloseDeleteCriteria = () => setShowModalDeleteCriteria(false)
  const handleShowDeleteCriteria = () => setShowModalDeleteCriteria(true)

  const handleCloseAddCriteria = () => setShowModalAddCriteria(false)
  const handleShowAddCriteria = () => setShowModalAddCriteria(true)

  const handleCloseDeleteForm = () => setShowModalDeleteForm(false)
  const handleShowDeleteForm = () => setShowModalDeleteForm(true)

  const handleCloseEditForm = () => setShowModalEditForm(false)
  const handleShowEditForm = () => setShowModalEditForm(true)

  useEffect(() => {
    if (formInfo !== undefined) {
      if (formInfo.criterions_position!.length > 0) {
        const sortedCriterionCards: ICriteria[] = []
        const criterions_position = [...formInfo.criterions_position!].sort(
          (a, b) => a.position - b.position
        )
        criterions_position.forEach((crPos) => {
          const criterion = formInfo.criterions!.find((cr) => cr.id === crPos.criterion_id)
          sortedCriterionCards.push(criterion!)
        })
        setCriterionCards(sortedCriterionCards)
      } else {
        createCriterionsPosition({id: formInfo.id})
        setCriterionCards(formInfo.criterions!)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo])

  const handleCloseInfoCriterion = () => {
    setInfoCriterion(undefined)
    setShowModalInfoCriterion(false)
  }
  const handleShowInfoCriterion = (cr: ICriteria) => {
    setInfoCriterion(cr)
    setShowModalInfoCriterion(true)
  }

  const handleCloseEditCriterion = () => {
    setInfoCriterion(undefined)
    setShowModalEditCriterion(false)
  }
  const handleShowEditCriterion = (cr: ICriteria) => {
    setInfoCriterion(cr)
    setShowModalEditCriterion(true)
  }

  function handleGetFormInfo() {
    if (formInfo === undefined) {
      getFormInfo({id: form.id})
    }
  }

  useEffect(() => {
    if (isFirst) {
      getFormInfo({id: form.id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirst])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCriterionCards((prevCards: ICriteria[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as ICriteria],
        ],
      })
    )
  }, [])

  const renderCard = useCallback((crCard: ICriteria, index: number) => {
    return (
      <CriterionCard
        key={crCard.id}
        index={index}
        id={crCard.id}
        criterion={crCard}
        formId={form.id}
        status={status}
        handleShowEditCriterion={handleShowEditCriterion}
        handleShowInfoCriterion={handleShowInfoCriterion}
        moveCard={moveCard}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='card mb-6 mb-xl-9'>
      <div className='card-body p-5' onMouseEnter={() => handleGetFormInfo()}>
        <div className='d-flex flex-stack mb-3 fs-7'>
          <div
            className={clsx(
              'badge text-uppercase',
              {'badge-light-success': status === 'PUBLISHED'},
              {'badge-light-primary': status === 'DRAFT'},
              {'badge-light-warning': status === 'ARCHIVED'}
            )}
          >
            {status === 'PUBLISHED' && intl.formatMessage({id: 'PUBLISHED'})}
            {status === 'DRAFT' && intl.formatMessage({id: 'DRAFT'})}
            {status === 'ARCHIVED' && intl.formatMessage({id: 'ARCHIVED'})}
          </div>

          {roleAccesses.includes('FORMSMODIFY') && (
            <div className='d-flex align-items-center'>
              {status !== 'PUBLISHED' && status !== 'ARCHIVED' && (
                <>
                  <button
                    className='btn btn-icon btn-sm btn-secondary w-20px h-20px me-2'
                    onClick={handleShowAddCriteria}
                  >
                    <span className='svg-icon svg-icon-2x'>
                      <KTSVG path='/media/icons/duotune/general/gen035.svg' />
                    </span>
                  </button>
                  <AddCriteriaModal
                    object={{type: 'form', object: formInfo ? formInfo : form}}
                    show={showModalAddCriteria}
                    handleClose={handleCloseAddCriteria}
                  />
                </>
              )}
              <Dropdown drop='down' align='end'>
                <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                  <span className='svg-icon svg-icon-3'>
                    <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                  {status !== 'ARCHIVED' && (
                    <Dropdown.Item
                      bsPrefix='menu-item'
                      as='div'
                      className='px-3'
                      onClick={handleShowEditForm}
                    >
                      <div className='menu-link p-3 text-uppercase small'>
                        {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                      </div>
                    </Dropdown.Item>
                  )}

                  {status === 'PUBLISHED' && (
                    <>
                      <Dropdown.Item
                        bsPrefix='menu-item'
                        as='div'
                        className='px-3 mt-1'
                        onClick={() => {
                          updateForm({id: form.id, data: {status: 'ARCHIVED'}})
                        }}
                        disabled={isLoadingUpdateForm}
                      >
                        <div className='menu-link p-3 text-uppercase small'>
                          {intl.formatMessage({id: 'CRITERION.ARCHIVE'})}
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                  {status === 'DRAFT' && (
                    <>
                      {formInfo?.criterions!?.length > 0 && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={() => {
                            updateForm({id: form.id, data: {status: 'PUBLISHED'}})
                          }}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                          </div>
                        </Dropdown.Item>
                      )}
                      {formInfo?.criterions!?.length > 0 && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={handleShowDeleteCriteria}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'GROUPS.DELETE.CRITERIA'})}
                          </div>
                        </Dropdown.Item>
                      )}
                      {roleAccesses.includes('FORMSDELETE') && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={handleShowDeleteForm}
                        >
                          <div className='menu-link text-danger p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'CRITERION.DELETEDRAFT'})}
                          </div>
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                  {status === 'ARCHIVED' && (
                    <>
                      <Dropdown.Item
                        bsPrefix='menu-item'
                        as='div'
                        className='px-3 mt-1'
                        onClick={() => {
                          updateForm({id: form.id, data: {status: 'PUBLISHED'}})
                        }}
                      >
                        <div className='menu-link p-3 text-uppercase small'>
                          {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <div className='mb-2'>
          <span className='fs-5 fw-bold mb-1 text-gray-900'>{form.name}</span>
        </div>
        <div className='fs-6 fw-semibold text-gray-700 mb-3'>{form.description}</div>
        <div className='fw-bold label text-gray-800 small'>
          {formatDateByZone(form.created_on!, currentUser?.language, currentUser?.time_zone)}
        </div>
        <span className='fw-bold label text-gray-600 small'>
          {form.departments.map((d, i, arr) => {
            if (arr.length - 1 === i) {
              return d.name
            } else {
              return d.name + ', '
            }
          })}
        </span>
        <div className='separator my-4'></div>
        <span className='d-flex fw-bolder small label my-2'>
          {intl.formatMessage({
            id: form.rating_scale,
          })}
        </span>
        <div className='text-gray-700 small mt-1 d-flex flex-column'>
          {form.types.map((d) => {
            return (
              <span
                className='label badge badge-secondary mb-2 me-3'
                style={{width: 'fit-content'}}
                key={d}
              >
                {intl.formatMessage({
                  id:
                    d === 'NUMBER_AVERAGE_SIMPLE'
                      ? 'SCOREFORMS.METHOD.NUMBER.AVERAGE'
                      : d === 'NUMBER_SUM_SIMPLE'
                      ? 'SCOREFORMS.METHOD.NUMBER.SUM'
                      : 'SCOREFORMS.METHOD.SIMPLE',
                })}
              </span>
            )
          })}
        </div>
        <div className='separator my-4' />
        {criterionCards.length > 0 && criterionCards.map((crCard, i) => renderCard(crCard, i))}
        <div className='d-flex flex-stack flex-wrapr mt-4'>
          <div className='text-gray-800 fw-bold fs-7 small'>{form.created_by.name}</div>
          <div className='border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3'>
            <span className='ms-1 fs-7 fw-bold text-gray-600'>
              Criterion(s) {form.criterions?.length}
            </span>
            {formInfo ? (
              <span className='fs-7 fw-bold text-gray-700 ms-2'>{formInfo.criterions?.length}</span>
            ) : (
              <>
                {isLoadingGetCriterions ? (
                  <span className='indicator-progress ms-2' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                ) : (
                  <span className='svg-icon svg-icon-1 ms-2 cursor-pointer'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr081.svg'
                      className='svg-icon svg-icon-2 text-hover-primary text-active-primary active'
                    />
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <UpdateScoreForm form={form} show={showModalEditFrom} handleClose={handleCloseEditForm} />
        {infoCriterion && (
          <>
            <InfoCriterionModal
              criterion={infoCriterion}
              show={showModalInfoCriterion}
              handleClose={handleCloseInfoCriterion}
            />
            <EditCriterionModal
              criterion={infoCriterion}
              show={showModalEditCriterion}
              handleClose={handleCloseEditCriterion}
            />
          </>
        )}
        <ExcludeCriteriaModal
          object={{type: 'form', object: formInfo ? formInfo : form}}
          criterions={formInfo && formInfo?.criterions ? formInfo.criterions : []}
          show={showModalDeleteCriteria}
          handleClose={handleCloseDeleteCriteria}
        />
        <DeleteFormModal
          form={form}
          show={showModalDeleteForm}
          handleClose={handleCloseDeleteForm}
        />
      </div>
    </div>
  )
}

export default FormSingle
