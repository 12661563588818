/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {FC, useState} from 'react'
import {ObjectionList} from '../modals/ObjectionList'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {RoleAccess} from '../../../../../models/users/Users'
import {useAppSelector} from '../../../../../redux/hooks/redux'
import {MoveObjectionsModal} from '../modals/MoveObjectionsModal'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {GroupObjectionsTable} from './score-objections-table/GroupObjectionsTable'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'
import {QueryRequestProvider} from '../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

const GroupScoreObjectionsList: FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  const {data} = scoreAPI.useGroupsObjectionFetchQuery()

  const {selected} = useAppSelector((state) => state.serviceReduser)
  const [showObjectionsGroup, setShowObjectionsGroup] = useState<boolean>(false)
  const [showMoveObjectionsModal, setShowMoveObjectionsModal] = useState<boolean>(false)
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  const handleCloseObjections = () => setShowObjectionsGroup(false)
  const handleShowObjections = () => setShowObjectionsGroup(true)

  const handleCloseMoveObjections = () => setShowMoveObjectionsModal(false)
  const handleShowMoveObjections = () => setShowMoveObjectionsModal(true)

  return (
    <>
      <div className='card-header'>
        <div className='d-flex flex-stack col-12 me-10'>
          <h3 className='fw-bolder m-0 card-title align-items-start flex-column'>
            {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.IN'})}
            <span className='fw-bold fs-8 text-uppercase small mt-1'>
              {selected.rows.size} {intl.formatMessage({id: 'USERS.SELECTED'})}
            </span>
          </h3>
          {roleAccesses.includes('ORGANIZATIONMODIFY') && (
            <div className='text-align-end align-self-center d-flex'>
              <button
                className='btn btn-primary btn-sm me-2'
                onClick={handleShowMoveObjections}
                disabled={
                  data && data !== undefined && data.length > 1
                    ? selected.rows.size === 0 || (data?.length as number) === 0
                    : true
                }
              >
                <span className='svg-icon svg-icon-muted svg-icon-1x'>
                  <KTSVG path='/media/icons/duotune/arrows/arr013.svg' />
                </span>
                {intl.formatMessage({id: 'ORGANIZATION.MOVEUSERSTODEPARTMENT'})}
              </button>
              {data && data !== undefined && data.length > 1 && (
                <MoveObjectionsModal
                  show={showMoveObjectionsModal}
                  handleClose={handleCloseMoveObjections}
                  currentGroupId={id!}
                />
              )}

              <button
                className='btn btn-primary btn-sm'
                onClick={handleShowObjections}
                disabled={(data?.length as number) === 0}
              >
                <span className='svg-icon svg-icon-muted svg-icon-1x'>
                  <KTSVG path='/media/icons/duotune/arrows/arr013.svg' />
                </span>
                {intl.formatMessage({id: 'ORGANIZATION.ADDUSERDEPARTMENT'})}
              </button>
              <ObjectionList
                show={showObjectionsGroup}
                handleClose={handleCloseObjections}
                groupId={id!}
              />
            </div>
          )}
        </div>
      </div>
      <div className='card-body p-1'>
        <div className='table-responsive px-15'>
          <QueryRequestProvider>
            <GroupObjectionsTable />
          </QueryRequestProvider>
        </div>
      </div>
    </>
  )
}

export {GroupScoreObjectionsList}
