import {FC} from 'react'
import {useIntl} from 'react-intl'
import {OneControlCriteria} from './OneControlCriteria'
import {ControlsCriteriaTableHeader} from './ControlsCriteriaTableHeader'
import {IAnalyticsControlsCriteriaResponse} from '../../../../models/analytics/Analytics'

type Props = {
  controls?: IAnalyticsControlsCriteriaResponse
  type: string | undefined
}

export const ControlsCriteriaTable: FC<Props> = ({controls, type}) => {
  const intl = useIntl()

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ControlsCriteriaTableHeader type={type} groups={controls?.sequence_groups} />
        {controls?.controls.total !== 0 && (
          <tbody>
            {controls &&
              controls.controls.data.map((c) => (
                <OneControlCriteria
                  groups={Object.values(controls.sequence_groups)}
                  control={c}
                  key={c.id}
                  type={type}
                />
              ))}
          </tbody>
        )}
      </table>
      {controls?.controls.total === 0 && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5 me-250px'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
      {!controls && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5 me-250px'>
          {intl.formatMessage({id: 'TABLE.NODATA.ANALYTICS.CONTROLSCRITERIA'})}
        </span>
      )}
    </>
  )
}
