import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import jwt from 'jwt-decode'

export interface IAuthModel {
  access_token: string
  profile_id?: string
}

export interface IProfile {
  sub: string
}

const initialAuthState: IAuthState = {
  accessToken: undefined,
  profileId: undefined,
  authLoading: {
    login: false,
  },
  error: {
    login: null,
  },
}

export interface IAuthState {
  accessToken?: string
  profileId?: string
  authLoading: {
    login: boolean
  }
  error: {
    login: string | null
  }
}

const getProfileId = (token: string) => {
  const profile: IProfile = jwt(token)
  return profile.sub
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    loginAction: (state, action: PayloadAction<IAuthModel>) => {
      state.accessToken = action.payload?.access_token
      state.profileId = getProfileId(state.accessToken)
    },
    logoutAction: (state) => {
      state.accessToken = undefined
      state.profileId = undefined
    },
  },
})

export const {loginAction, logoutAction} = authSlice.actions
export default authSlice.reducer
