import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  ICreateScoreForms,
  ICriteria,
  ICriteriaCreate,
  ICriterionsAction,
  IGroup,
  IGroupCreate,
  IScoreObjection,
  IScoreObjectionCreate,
  IScoreObjectionUpdate,
  IScoreForms,
  IStatus,
  ListResponseCriterions,
  ListResponseObjection,
  IObjectionGroup,
  IGroupObjectionCreate,
  IGroupObjectionDelete,
  IGroupObjectionList,
  IScoreObjectionShort,
  IScoreObjectionAddGroup,
  IRatingScale,
} from '../../models/score/Score'

export const scoreAPI = createApi({
  reducerPath: 'scoreAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['groups', 'criteria', 'forms', 'objections', 'groupsObjection'],
  endpoints: (build) => ({
    groupsFetch: build.query<IGroup[], void>({
      query: () => ({
        url: '/scoring/groups',
      }),
      providesTags: ['groups'],
    }),
    groupsById: build.query<IGroup, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/groups/${id}`,
      }),

      providesTags: (result) =>
        result ? [{type: 'groups', id: result?.id}] : [{type: 'groups', id: 'LIST'}],
    }),
    groupsCreate: build.mutation<IGroup, IGroupCreate>({
      query: (data) => ({
        url: '/scoring/groups',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['groups'],
    }),
    groupsUpdate: build.mutation<IGroup, {id: string; data: IGroupCreate}>({
      query: ({id, data}) => ({
        url: `/scoring/groups/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['groups'],
    }),
    groupsAction: build.mutation<
      IGroup,
      {id: string; action: 'add' | 'remove'; data: ICriterionsAction}
    >({
      query: ({id, action, data}) => ({
        url: `/scoring/groups/${id}/${action}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['groups'],
    }),
    groupsDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['groups'],
    }),
    criteriaFetch: build.query<
      ListResponseCriterions<ICriteria>,
      {skip?: number; limit?: number; search?: any; rating_scale?: IRatingScale}
    >({
      query: ({skip, limit, search, rating_scale}) => ({
        url: '/scoring/criterions',
        params: {skip: skip, limit: limit, search: search, rating_scale: rating_scale},
      }),
      providesTags: ['criteria'],
    }),
    criteriaCreate: build.mutation<ICriteria, ICriteriaCreate>({
      query: (data) => ({
        url: '/scoring/criterions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['criteria'],
    }),
    criteriaUpdate: build.mutation<void, {id: string; data: ICriteriaCreate}>({
      query: ({id, data}) => ({
        url: `/scoring/criterions/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['criteria', 'groups', 'forms'],
    }),
    criteriaDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/criterions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['criteria'],
    }),
    formsFetch: build.query<IScoreForms[], string | void>({
      query: (user_id) => {
        const params = user_id !== undefined ? `?user_id=${user_id}` : ''
        return {
          url: `/scoring/forms${params}`,
        }
      },
      providesTags: ['forms'],
    }),
    formsById: build.query<IScoreForms, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/forms/${id}`,
      }),
      providesTags: (result) =>
        result ? [{type: 'forms', id: result?.id}] : [{type: 'forms', id: 'LIST'}],
    }),
    formsCreate: build.mutation<IScoreForms, ICreateScoreForms>({
      query: (data) => ({
        url: '/scoring/forms',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['forms'],
    }),
    formsCreateCriterionsPosition: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/forms/${id}/criterions/position`,
        method: 'POST',
      }),
      invalidatesTags: ['forms'],
    }),
    formsUpdateCriterionPosition: build.mutation<
      void,
      {id: string; criterionId: string; position: number}
    >({
      query: ({id, criterionId, position}) => ({
        url: `/scoring/forms/${id}/criterion/${criterionId}`,
        method: 'PUT',
        body: {position: position},
      }),
      invalidatesTags: ['forms'],
    }),
    formsUpdate: build.mutation<IScoreForms, {id: string; data: ICreateScoreForms}>({
      query: ({id, data}) => ({
        url: `/scoring/forms/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['forms'],
    }),
    formsAction: build.mutation<
      IScoreForms,
      {id: string; action: 'add' | 'remove'; data: ICriterionsAction}
    >({
      query: ({id, action, data}) => ({
        url: `/scoring/forms/${id}/${action}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['forms'],
    }),
    formsDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/forms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['forms'],
    }),
    objectionFetch: build.query<
      ListResponseObjection<IScoreObjection>,
      {search?: string; status?: IStatus; skip?: number; limit?: number}
    >({
      query: ({search, status, skip, limit}) => ({
        url: '/scoring/objections',
        params: {search: search, status: status, skip: skip, limit: limit},
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.objections.map((el) => ({type: 'objections' as const, id: el.id})),
              {type: 'objections', id: 'LIST'},
            ]
          : [{type: 'objections', id: 'LIST'}],
    }),
    objectionCreate: build.mutation<IScoreObjection, IScoreObjectionCreate>({
      query: (data) => ({
        url: '/scoring/objections',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['objections'],
    }),
    objectionUpdate: build.mutation<IScoreObjection, {id: string; data: IScoreObjectionUpdate}>({
      query: ({id, data}) => ({
        url: `/scoring/objections/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (response) =>
        response ? [{type: 'objections', id: response?.id}] : [{type: 'objections', id: 'LIST'}],
    }),
    objectionDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/scoring/objections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['objections'],
    }),
    groupsObjectionFetch: build.query<IObjectionGroup[], void>({
      query: () => ({
        url: '/scoring/objection/groups',
      }),
      providesTags: ['groupsObjection'],
    }),
    groupObjectionsById: build.query<
      IGroupObjectionList,
      {id: string; skip?: number; limit?: number}
    >({
      query: ({id, skip, limit}) => ({
        url: `/scoring/objection/groups/${id}`,
        params: {skip: skip, limit: limit},
      }),

      providesTags: (response) =>
        response
          ? [
              ...response.objections.map((el: IScoreObjectionShort) => ({
                type: 'objections' as const,
                id: el.id,
              })),
              {type: 'objections', id: 'LIST'},
            ]
          : [{type: 'objections', id: 'LIST'}],
    }),
    groupObjectionCreate: build.mutation<IObjectionGroup, IGroupObjectionCreate>({
      query: (data) => ({
        url: '/scoring/objection/groups',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['groupsObjection'],
    }),
    groupObjectionUpdate: build.mutation<
      IObjectionGroup,
      {id: string; data: IGroupObjectionCreate}
    >({
      query: ({id, data}) => ({
        url: `/scoring/objection/groups/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['groupsObjection'],
    }),
    groupObjectionAdd: build.mutation<void, {id: string; data: IScoreObjectionAddGroup}>({
      query: ({id, data}) => ({
        url: `/scoring/objection/groups/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['groupsObjection', 'objections'],
    }),
    groupObjectionDelete: build.mutation<void, {id: string; data?: IGroupObjectionDelete}>({
      query: ({id, data}) => ({
        url: `/scoring/objection/groups/${id}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['groupsObjection', 'objections'],
    }),
  }),
})
