import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  selectReduserClear,
  selectReduserRemoveRow,
  selectReduserUpdateAll,
} from '../../../../../../redux/slices/serviceSlice'
import {OneGroupObjection} from './OneGroupObjection'
import {scoreAPI} from '../../../../../../redux/services/ScoreService'
import {ScoreObjectionsHeaderGroup} from './ScoreObjectionsHeaderGroup'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {
  IGroupObjectionList,
  IScoreObjection,
  IScoreObjectionShort,
  ListResponseObjection,
} from '../../../../../../models/score/Score'
import {TablePagination} from '../../../../../../../_metronic/helpers/pagination/TablePagination'
import {useQueryRequest} from '../../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

export const GroupObjectionsTable = () => {
  const intl = useIntl()
  const {id} = useParams()

  const dispatch = useAppDispatch()
  const {state, updateState} = useQueryRequest()

  const [skipGetGroups, setSkipGetGroups] = useState<boolean>(true)
  const [skipGetObjections, setSkipGetObjections] = useState<boolean>(true)
  const [data, setData] = useState<ListResponseObjection<IScoreObjection> | IGroupObjectionList>()
  const {selected} = useAppSelector((state) => state.serviceReduser)

  const {data: objections} = scoreAPI.useObjectionFetchQuery(
    {
      skip: state.skip,
      limit: state.limit,
    },
    {skip: skipGetObjections}
  )
  const {data: groupObjections} = scoreAPI.useGroupObjectionsByIdQuery(
    {
      id: id!,
      skip: state.skip,
      limit: state.limit,
    },
    {skip: skipGetGroups}
  )

  useEffect(() => {
    if (id) {
      setSkipGetGroups(false)
      dispatch(selectReduserClear())
    } else {
      setSkipGetObjections(false)
      dispatch(selectReduserClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setData(groupObjections)
  }, [groupObjections])

  useEffect(() => {
    setData(objections)
  }, [objections])

  useEffect(() => {
    if (data) {
      if (data.objections.length === 0) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
        return
      }
      const usersEvery = data.objections.every((obj: IScoreObjection | IScoreObjectionShort) =>
        selected.rows.has(obj.id)
      )
      const usersSome = data.objections.some((obj: IScoreObjection | IScoreObjectionShort) =>
        selected.rows.has(obj.id)
      )
      if (usersEvery) {
        if (selected.all.get(state.skip as number) !== undefined)
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'checked'}))
        if (selected.all.get(state.skip as number) === undefined) {
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
          data.objections.map((obj: IScoreObjection | IScoreObjectionShort) =>
            dispatch(selectReduserRemoveRow({id: obj.id}))
          )
        }
      } else if (usersSome) {
        if (selected.all.get(state.skip as number) !== 'indeterminate')
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      } else if (!usersSome) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObjections, selected])

  return (
    <>
      {data && data.total > 0 ? (
        <>
          <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
            <ScoreObjectionsHeaderGroup page={state.skip as number} />
            <tbody>
              {data &&
                data.objections.map((obj) => (
                  <OneGroupObjection
                    key={obj.id}
                    objection={obj as IScoreObjection}
                    page={state.skip as number}
                  />
                ))}
            </tbody>
          </table>
          <div className='card-footer px-0'>
            {data && data.total > 0 && (
              <>
                <div className='d-flex flex-stack  align-items-center'>
                  <div className='justify-content-start'>
                    <label>
                      <select
                        className='form-select form-select-sm form-select-solid'
                        value={state.limit}
                        onChange={(e) => {
                          updateState({skip: 0, limit: parseInt(e.target.value)})
                          selectReduserClear()
                        }}
                      >
                        <option value='10'>10</option>
                        <option value='25'>25</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                        <option value='500'>500</option>
                      </select>
                    </label>
                    <span className='small text-uppercase ms-4'>
                      {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}: {data && data.total}
                    </span>
                  </div>
                  <TablePagination total={data ? data.total : 0} />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {data && (
            <div className='d-flex justify-content-center'>
              <i className='bi bi-inbox fs-4x' />
            </div>
          )}
        </>
      )}
    </>
  )
}
