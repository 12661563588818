import {configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/es/storage'
import {persistReducer, persistStore} from 'redux-persist'
import AuthSlice from './slices/authSlice'
import {usersAPI} from './services/UsersService'
import {authAPI} from './services/AuthService'
import {profileAPI} from './services/ProfileService'
import {accountAPI} from './services/AccountService'
import {departmentsAPI} from './services/DepartmentsService'
import serviceSlice from './slices/serviceSlice'
import {integrationsAPI} from './services/IntegrationsService'
import {crmUsersAPI} from './services/CRMUsersService'
import {scoreAPI} from './services/ScoreService'
import {controlsAPI} from './services/ControlsService'
import {breakpointAPI} from './services/BreakpointsService'
import {analyticsAPI} from './services/AnalyticsService'
import {objectionAPI} from './services/ObjectionService'

const authPersistConfig = {
  key: 'sidbis:auth',
  storage: storage,
  whitelist: ['accessToken', 'profileId'],
}

export const authPersistor = persistReducer(authPersistConfig, AuthSlice)

const store = configureStore({
  reducer: {
    serviceReduser: serviceSlice,
    authReduser: authPersistor,
    [authAPI.reducerPath]: authAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [accountAPI.reducerPath]: accountAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [departmentsAPI.reducerPath]: departmentsAPI.reducer,
    [integrationsAPI.reducerPath]: integrationsAPI.reducer,
    [crmUsersAPI.reducerPath]: crmUsersAPI.reducer,
    [scoreAPI.reducerPath]: scoreAPI.reducer,
    [controlsAPI.reducerPath]: controlsAPI.reducer,
    [breakpointAPI.reducerPath]: breakpointAPI.reducer,
    [analyticsAPI.reducerPath]: analyticsAPI.reducer,
    [objectionAPI.reducerPath]: objectionAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authAPI.middleware)
      .concat(profileAPI.middleware)
      .concat(accountAPI.middleware)
      .concat(usersAPI.middleware)
      .concat(departmentsAPI.middleware)
      .concat(integrationsAPI.middleware)
      .concat(crmUsersAPI.middleware)
      .concat(scoreAPI.middleware)
      .concat(controlsAPI.middleware)
      .concat(breakpointAPI.middleware)
      .concat(analyticsAPI.middleware)
      .concat(objectionAPI.middleware),
  devTools: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)

export default store
