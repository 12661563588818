/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICriteriaCreate, IRatingScale} from '../../../../../models/score/Score'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {
  checkMaxValueAverageMax,
  checkMaxValueAverageMin,
  checkMaxValueMaxRangeMin,
  checkMaxValueMinRangeMax,
} from './helpers/check-value-score-zone'

const criteriaAddShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateCriterionModal: FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const [createCriteria, {isLoading, isError}] = scoreAPI.useCriteriaCreateMutation()
  const {data: groups} = scoreAPI.useGroupsFetchQuery()
  const [ratingScale, updateRatingScale] = useState<IRatingScale>('TERNARY_SCALE')
  const [maxRangeMax, setMaxRangeMax] = useState<number>(3)
  const [maxRangeMin, setMaxRangeMin] = useState<number>(3)
  const [averageRangeMax, setAverageRangeMax] = useState<number>(2)
  const [averageRangeMin, setAverageRangeMin] = useState<number>(2)
  const [minRangeMax, setMinRangeMax] = useState<number>(1)
  const [minRangeMin, setMinRangeMin] = useState<number>(1)
  const [maxDescription, setMaxDescription] = useState<string>('')
  const [minDescription, setMinDescription] = useState<string>('')
  const [averageDescription, setAverageDescription] = useState<string>('')

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
    updateRatingScale('TERNARY_SCALE')
    setMaxRangeMin(3)
    setAverageRangeMax(2)
    setAverageRangeMin(2)
    setMinRangeMax(1)
    setMaxDescription('')
    setMinDescription('')
    setAverageDescription('')
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      group_id: '',
      score_zone: {
        rating_scale: 'TERNARY_SCALE',
      },
    },
    validationSchema: criteriaAddShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data = {
        name: values.name,

        group_id: values.group_id !== '' ? values.group_id : null,
        score_zone:
          ratingScale !== 'BINARY_SCALE' &&
          ratingScale !== 'BINARY_TEXT_SCALE' &&
          ratingScale !== 'TERNARY_SCALE'
            ? {
                rating_scale: ratingScale,
                MAX_RANGE_MIN: maxRangeMin,
                MAX_DESCRIPTION: maxDescription,
                AVERAGE_RANGE_MAX: averageRangeMax,
                AVERAGE_RANGE_MIN: averageRangeMin,
                AVERAGE_DESCRIPTION: averageDescription,
                MIN_RANGE_MAX: minRangeMax,
                MIN_DESCRIPTION: minDescription,
              }
            : ratingScale === 'TERNARY_SCALE'
            ? {
                rating_scale: ratingScale,
                MAX_DESCRIPTION: maxDescription,
                AVERAGE_DESCRIPTION: averageDescription,
                MIN_DESCRIPTION: minDescription,
              }
            : {
                rating_scale: ratingScale,
                MAX_DESCRIPTION: maxDescription,
                MIN_DESCRIPTION: minDescription,
              },
      }
      await createCriteria(data as ICriteriaCreate)
        .unwrap()
        .then(() => {
          handleCloseModal()
        })
        .catch(() => {
          formik.resetForm()
        })
    },
  })

  useEffect(() => {
    if (ratingScale === 'BINARY_SCALE' || ratingScale === 'BINARY_TEXT_SCALE') {
      setMaxRangeMax(1)
      setMaxRangeMin(1)
      setMinRangeMax(0)
      setMinRangeMin(0)
    } else if (ratingScale === 'TERNARY_SCALE') {
      setMaxRangeMax(3)
      setMaxRangeMin(3)
      setAverageRangeMax(2)
      setAverageRangeMin(2)
      setMinRangeMax(1)
      setMinRangeMin(1)
    } else if (ratingScale === 'QUATERNARY_SCALE') {
      setMaxRangeMax(4)
      setMaxRangeMin(4)
      setAverageRangeMax(3)
      setAverageRangeMin(2)
      setMinRangeMax(1)
      setMinRangeMin(1)
    } else if (ratingScale === 'QUINKARNARY_SCALE') {
      setMaxRangeMax(5)
      setMaxRangeMin(5)
      setAverageRangeMax(4)
      setAverageRangeMin(2)
      setMinRangeMax(1)
      setMinRangeMin(1)
    } else if (ratingScale === 'DECINARY_SCALE') {
      setMaxRangeMax(10)
      setMaxRangeMin(8)
      setAverageRangeMax(7)
      setAverageRangeMin(4)
      setMinRangeMax(3)
      setMinRangeMin(1)
    }
  }, [ratingScale])

  const handleChengeMaxRangeMin = (v: number) => {
    setMaxRangeMin(v)
    setAverageRangeMax(v - 1)
    if (averageRangeMin - 1 === v - 1) {
      setAverageRangeMin(v - 1)
    }
    if (v - 1 === minRangeMax) {
      setMinRangeMax(minRangeMax - 1)
    }
  }
  const handleChengeAverageMax = (v: number) => {
    setAverageRangeMax(v)
    setMaxRangeMin(v + 1)
    if (v <= averageRangeMin) {
      setAverageRangeMin(v)
      setMinRangeMax(v - 1)
    }
  }
  const handleChengeAverageMin = (v: number) => {
    setAverageRangeMin(v)
    setMinRangeMax(v - 1)
    if (v > averageRangeMax) {
      setAverageRangeMax(v + 1)
      setMaxRangeMin(v + 2)
    }
  }
  const handleChengeMinRangeMax = (v: number) => {
    setMinRangeMax(v)
    setAverageRangeMin(v + 1)
    if (v + 1 > averageRangeMax) {
      setAverageRangeMax(v + 1)
      setMaxRangeMin(v + 2)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'CRITERIA.ADD.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='py-2'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.NAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'CRITERIA.ADD.NAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.RATINGSCALE'})}
                </span>
              </label>
              <select
                className='form-select form-select-solid form-select-lg text-gray-900'
                onChange={(e: any) => {
                  updateRatingScale(e.target.value)
                }}
              >
                <option value='TERNARY_SCALE'>{intl.formatMessage({id: 'TERNARY_SCALE'})}</option>
                <option value='BINARY_SCALE'>{intl.formatMessage({id: 'BINARY_SCALE'})}</option>
                <option value='BINARY_TEXT_SCALE'>
                  {intl.formatMessage({id: 'BINARY_TEXT_SCALE'})}
                </option>
                <option value='QUATERNARY_SCALE'>
                  {intl.formatMessage({id: 'QUATERNARY_SCALE'})}
                </option>
                <option value='QUINKARNARY_SCALE'>
                  {intl.formatMessage({id: 'QUINKARNARY_SCALE'})}
                </option>
                <option value='DECINARY_SCALE'>{intl.formatMessage({id: 'DECINARY_SCALE'})}</option>
              </select>
            </div>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIATABLE.GROUP'})}
                </span>
              </label>
              <select
                className='form-select form-select-solid form-select-lg text-gray-900'
                {...formik.getFieldProps('group_id')}
              >
                <option value=''>--</option>
                {groups &&
                  groups?.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className='col mb-2'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE'})}
                </span>
              </label>
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.EXCELLENT'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MAXRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={maxRangeMin}
                    onChange={(e) => handleChengeMaxRangeMin(parseInt(e.target.value))}
                    min={3}
                    max={checkMaxValueMaxRangeMin(ratingScale)}
                    disabled={
                      ratingScale === 'BINARY_SCALE' ||
                      ratingScale === 'BINARY_TEXT_SCALE' ||
                      ratingScale === 'TERNARY_SCALE'
                        ? true
                        : false
                    }
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MAXRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={maxRangeMax}
                    disabled
                  />
                  <div className='h-30px w-30px bg-success rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm mb-5'>
                <textarea
                  className='form-control border-success'
                  placeholder='Description'
                  onChange={(e) => setMaxDescription(e.target.value)}
                  value={maxDescription}
                />
              </div>
              {ratingScale !== 'BINARY_SCALE' && ratingScale !== 'BINARY_TEXT_SCALE' && (
                <>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='col-form-label fw-bolder text-gray-600'>
                      <span className='small text-uppercase'>
                        {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.NORMAL'})}
                      </span>
                    </label>
                    <div className='d-flex'>
                      {/* AVERAGERANGEMIN */}
                      <input
                        type='number'
                        className='w-100px form-control form-control-lg text-gray-900'
                        value={averageRangeMin}
                        onChange={(e) => handleChengeAverageMin(parseInt(e.target.value))}
                        min={2}
                        max={checkMaxValueAverageMin(ratingScale)}
                        disabled={ratingScale === 'TERNARY_SCALE' ? true : false}
                      />
                      <label className='align-self-center mx-2'>—</label>
                      {/* AVERAGERANGEMAX */}
                      <input
                        type='number'
                        className='w-100px form-control form-control-lg text-gray-900 me-5'
                        value={averageRangeMax}
                        onChange={(e) => handleChengeAverageMax(parseInt(e.target.value))}
                        min={2}
                        max={checkMaxValueAverageMax(ratingScale)}
                        disabled={ratingScale === 'TERNARY_SCALE' ? true : false}
                      />
                      <div className='h-30px w-30px bg-warning rounded-1 align-self-center'></div>
                    </div>
                  </div>
                  <div className='input-group input-group-sm mb-5'>
                    <textarea
                      className='form-control border-warning'
                      placeholder='Description'
                      onChange={(e) => setAverageDescription(e.target.value)}
                      value={averageDescription}
                    ></textarea>
                  </div>
                </>
              )}
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.CRITICAL'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MINRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={minRangeMin}
                    disabled
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MINRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={minRangeMax}
                    onChange={(e) => handleChengeMinRangeMax(parseInt(e.target.value))}
                    min={
                      ratingScale === 'BINARY_SCALE' || ratingScale === 'BINARY_TEXT_SCALE' ? 0 : 1
                    }
                    max={checkMaxValueMinRangeMax(ratingScale)}
                    disabled={
                      ratingScale === 'BINARY_SCALE' ||
                      ratingScale === 'BINARY_TEXT_SCALE' ||
                      ratingScale === 'TERNARY_SCALE'
                        ? true
                        : false
                    }
                  />
                  <div className='h-30px w-30px bg-danger rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm '>
                <textarea
                  className='form-control border-danger'
                  placeholder='Description'
                  onChange={(e) => setMinDescription(e.target.value)}
                  value={minDescription}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.BUTTON'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {CreateCriterionModal}
