import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {FC, useState} from 'react'
import {ICurrentRegion} from '../../../models/controls'
import {IScoreObjection} from '../../../../../models/score/Score'
import {SelectScoreObjection} from './components/SelectScoreObjection'
import {SelectedScoreObjection} from './components/SelectedScoreObjection'

interface Props {
  c_type: 'CALL' | 'MEETING'
  comment: string
  currentRegion: ICurrentRegion
  currentRegionIsPlay: boolean
  currentRegionPlayStop: () => void
  handleCancelObjection: () => void
  handleChangeEnd: (end: number) => void
  handleChangeStart: (start: number) => void
  setComment: (value: React.SetStateAction<string>) => void
  handleSaveObjection: (objection_value: number, score_objection_id: string) => void
}

const AddObjection: FC<Props> = ({
  c_type,
  comment,
  currentRegion,
  currentRegionIsPlay,
  setComment,
  handleChangeEnd,
  handleChangeStart,
  handleSaveObjection,
  handleCancelObjection,
  currentRegionPlayStop,
}) => {
  const intl = useIntl()
  const [selectedScoreObjection, setSelectedScoreObjection] = useState<IScoreObjection>()
  const [isSaveDisable, setIsSaveDisable] = useState<boolean>(true)
  const [objectionValue, setObjectionValue] = useState<number>(0)

  const handleSetObjection = (objection?: IScoreObjection) => {
    setSelectedScoreObjection(objection)
  }

  const handleSave = () => {
    handleSaveObjection(objectionValue, selectedScoreObjection?.id!)
  }

  return (
    <div className='card shadow-sm card-bordered mt-4 mb-4 border-info'>
      <div className='card-body pt-1 pb-1'>
        <div className='py-3'>
          <SelectScoreObjection setSelectObjection={handleSetObjection} />
        </div>
        {selectedScoreObjection && (
          <SelectedScoreObjection
            selectedScoreObjection={selectedScoreObjection}
            setIsSaveDisable={setIsSaveDisable}
            comment={comment}
            setComment={setComment}
            objectionValue={objectionValue}
            setObjectionValue={setObjectionValue}
          />
        )}

        <div className='d-flex justify-content-between flex-stack flex-wrap mt-3 mb-2'>
          {c_type === 'CALL' && (
            <span className='d-flex align-items-center'>
              <button className='btn btn-icon play-pause me-2' onClick={currentRegionPlayStop}>
                <i
                  className={clsx('fs-3x bi', {
                    'bi-play-fill text-gray-700 text-hover-primary': !currentRegionIsPlay,
                    'bi-stop-fill text-primary': currentRegionIsPlay,
                  })}
                />
              </button>
              <label className='me-3 fw-bold test-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.START'})}
              </label>
              <input
                type='number'
                min={0}
                step={0.1}
                className='form-control form-control-sm w-80px me-4'
                onChange={(e) => handleChangeStart(parseFloat(e.target.value))}
                value={currentRegion.start}
              />
              <label className='me-3 fw-bold'>{intl.formatMessage({id: 'CONTROLS.END'})}</label>
              <input
                type='number'
                min={0}
                step={0.1}
                className='form-control form-control-sm w-80px'
                onChange={(e) => handleChangeEnd(parseFloat(e.target.value))}
                value={currentRegion.end}
              />
            </span>
          )}
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-sm btn-primary me-2'
              onClick={handleSave}
              disabled={isSaveDisable}
            >
              {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
            </button>
            <button className='btn btn-sm btn-light-primary' onClick={handleCancelObjection}>
              {intl.formatMessage({id: 'COMMON.BUTTON.CLOSE'})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddObjection
