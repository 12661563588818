/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {DeleteGroup} from '../modals/DeleteGroup'
import {RenameGroup} from '../modals/RenameGroup'
import {Link, useLocation} from 'react-router-dom'
import {ObjectionList} from '../modals/ObjectionList'
import {RoleAccess} from '../../../../../models/users/Users'
import {IObjectionGroup} from '../../../../../models/score/Score'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {FC, ReactNode, forwardRef, useState} from 'react'
import {checkIsActive, KTSVG} from '../../../../../../_metronic/helpers'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'

type Props = {
  objectionGroup: IObjectionGroup
}
interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const ObjectionGropsItem: FC<Props> = ({objectionGroup}) => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const to = `/tools/objection_groups/${objectionGroup.id}/`
  const isActive = checkIsActive(pathname, to)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  const [showObjectionsGroup, setShowObjectionsGroup] = useState<boolean>(false)
  const [showModalDelete, setShowModalDeleteDepartment] = useState<boolean>(false)
  const [showModalRename, setShowModalRenameDepartment] = useState<boolean>(false)

  const handleCloseObjections = () => setShowObjectionsGroup(false)
  const handleShowObjections = () => setShowObjectionsGroup(true)

  const handleCloseDelete = () => setShowModalDeleteDepartment(false)
  const handleShowDelete = () => setShowModalDeleteDepartment(true)

  const handleCloseRename = () => setShowModalRenameDepartment(false)
  const handleShowRename = () => setShowModalRenameDepartment(true)

  return (
    <>
      <div
        className={clsx('d-flex bd-highlight fw-bold small py-2 px-4 rounded', {
          'bg-light-primary': isActive,
        })}
      >
        <div className='bd-highlight w-100'>
          <Link to={to}>
            <div className='menu-item text-gray-800'>
              <div className={clsx('', {'text-primary': isActive})}>
                <div className='d-flex align-items-center'>
                  <span className='bullet text-gray-900 me-4'></span>
                  <div className='flex-grow-1'>
                    <div className='menu-title text-gray-900 text-uppercase'>
                      {objectionGroup.name}
                    </div>
                    <span className='fw-bold label text-gray-600 small d-block'>
                      {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.IN'})}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        {roleAccesses.includes('ORGANIZATIONMODIFY') && (
          <div className='align-self-center'>
            <Dropdown drop='down' align='end'>
              <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowObjections}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'ORGANIZATION.ADDUSERDEPARTMENT'})}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowRename}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'ORGANIZATION.RENAME'})}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowDelete}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'ORGANIZATION.DELETE'})}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <ObjectionList
              show={showObjectionsGroup}
              handleClose={handleCloseObjections}
              groupId={objectionGroup.id}
            />
            <RenameGroup
              group={objectionGroup}
              show={showModalRename}
              handleClose={handleCloseRename}
            />
            <DeleteGroup
              group={objectionGroup}
              show={showModalDelete}
              handleClose={handleCloseDelete}
            />
          </div>
        )}
      </div>
    </>
  )
}

export {ObjectionGropsItem}
