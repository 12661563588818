import clsx from 'clsx'
import {FC} from 'react'
import {StringToRGBA} from '../../../helpers/control-helpers'
import {useIntl} from 'react-intl'
import {IBreakPointType, ICurrentRegion} from '../../../models/controls'

interface Props {
  comment: string
  type: 'CALL' | 'MEETING'
  newCommentColor: string
  addRangeComment: boolean
  handleCancel: () => void
  currentRegionIsPlay: boolean
  currentRegion: ICurrentRegion
  currentRegionPlayStop: () => void
  updateRegion: (color: string) => void
  handleChangeEnd: (start: number) => void
  handleChangeStart: (start: number) => void
  handleSave: (type: IBreakPointType) => void
  setComment: (value: React.SetStateAction<string>) => void
}

const AddBreakpoint: FC<Props> = ({
  comment,
  type,
  setComment,
  handleSave,
  handleCancel,
  updateRegion,
  currentRegion,
  newCommentColor,
  addRangeComment,
  handleChangeEnd,
  handleChangeStart,
  currentRegionIsPlay,
  currentRegionPlayStop,
}) => {
  const intl = useIntl()

  return (
    <div
      className={clsx('card shadow-sm card-bordered mt-4 mb-4', {
        'border-primary': newCommentColor === 'DEFAULT',
        'border-danger': newCommentColor === 'RED',
        'border-warning': newCommentColor === 'YELLOW',
        'border-success': newCommentColor === 'GREEN',
      })}
    >
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex justify-content-end flex-stack flex-wrap'>
          <button className='btn btn-icon mx-1' onClick={() => updateRegion(StringToRGBA('RED')!)}>
            <i className='bi bi-plus-circle-fill text-danger fs-1' />
          </button>
          <button
            className='btn btn-icon mx-1'
            onClick={() => updateRegion(StringToRGBA('YELLOW')!)}
          >
            <i className='bi bi-plus-circle-fill text-warning fs-1' />
          </button>
          <button
            className='btn btn-icon mx-1'
            onClick={() => updateRegion(StringToRGBA('GREEN')!)}
          >
            <i className='bi bi-plus-circle-fill text-success fs-1' />
          </button>
        </div>
        <div className='input-group input-group-sm'>
          <textarea
            className='form-control'
            placeholder='Comment'
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          ></textarea>
        </div>
        <div
          className={clsx('d-flex mt-3 mb-2', {
            'justify-content-between flex-stack flex-wrap': type === 'CALL',
            'flex-end': type === 'MEETING',
          })}
        >
          {type === 'CALL' && (
            <span className='d-flex align-items-center'>
              <button className='btn btn-icon play-pause me-2' onClick={currentRegionPlayStop}>
                <i
                  className={clsx('fs-3x bi', {
                    'bi-play-fill text-gray-700 text-hover-primary': !currentRegionIsPlay,
                    'bi-stop-fill text-primary': currentRegionIsPlay,
                  })}
                />
              </button>
              <label className='me-3 fw-bold test-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.START'})}
              </label>
              <input
                type='number'
                min={0}
                step={0.1}
                className='form-control form-control-sm w-80px me-4'
                onChange={(e) => handleChangeStart(parseFloat(e.target.value))}
                value={currentRegion.start}
              />
              {addRangeComment && (
                <>
                  <label className='me-3 fw-bold'>{intl.formatMessage({id: 'CONTROLS.END'})}</label>
                  <input
                    type='number'
                    min={0}
                    step={0.1}
                    className='form-control form-control-sm w-80px'
                    onChange={(e) => handleChangeEnd(parseFloat(e.target.value))}
                    value={currentRegion.end}
                  />
                </>
              )}
            </span>
          )}

          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-sm btn-primary me-2'
              onClick={() => (addRangeComment ? handleSave('RANGE') : handleSave('SINGLE'))}
            >
              {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
            </button>
            <button className='btn btn-sm btn-light-primary' onClick={handleCancel}>
              {intl.formatMessage({id: 'COMMON.BUTTON.CLOSE'})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBreakpoint
