/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {enableSplashScreen, useLayout} from '../../../../../_metronic/layout/core'
import {useAppDispatch} from '../../../../redux/hooks/redux'
import {authAPI} from '../../../../redux/services/AuthService'
import {profileAPI} from '../../../../redux/services/ProfileService'
import {logoutAction} from '../../../../redux/slices/authSlice'

const {REACT_APP_URL, REACT_APP_DOMAIN} = process.env

type Props = {
  subdomain: string
}

const Account: FC<Props> = ({subdomain}) => {
  const wl = window.location

  return (
    <div className='col-md-12'>
      <a
        href={`${wl.protocol}//${subdomain}.${wl.host.split('.').slice(-2).join('.')}`}
        className='btn btn-flex btn-outline btn-info border-none flex-center text-nowrap w-100 text-uppercase'
      >
        {subdomain}
        {'  '}
        <KTSVG path='/media/icons/duotune/arrows/arr023.svg' />
      </a>
    </div>
  )
}

export function SelectAccount() {
  const {data: profile} = profileAPI.useProfileMeFetchQuery()
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const {config} = useLayout()

  const refetchAccessToken = async () => {
    enableSplashScreen()
    dispatch(logoutAction())
    dispatch(authAPI.util.resetApiState())
  }

  window.addEventListener('message', function (e) {
    if (!e.origin.endsWith(`.${REACT_APP_DOMAIN}`) || e.origin === REACT_APP_URL) return
    refetchAccessToken()
  })

  return (
    <>
      <div className='text-center mb-11'>
        {config.layoutType === 'dark-sidebar' ? (
          <a href={`${REACT_APP_URL}`}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-white.svg')}
              className='h-50px app-sidebar-logo-default mb-5'
            />
          </a>
        ) : (
          <>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo.svg')}
                className='h-50px app-sidebar-logo-default theme-light-show mb-5'
              />
            </a>
            <a href={`${REACT_APP_URL}`}>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-white.svg')}
                className='h-50px app-sidebar-logo-default theme-dark-show mb-5'
              />
            </a>
          </>
        )}
        <h1 className='text-light fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.SELECTACCOUNT.TITLE'})} {profile?.name}
        </h1>
        <div className='text-center fw-semibold fs-6'>
          <Link to='/info/' className='link-info'>
            {intl.formatMessage({id: 'AUTH.SELECTACCOUNT.PROFILEINFO'})}{' '}
            <KTSVG className='svg-icon-info' path='/media/icons/duotune/arrows/arr028.svg' />
          </Link>
        </div>
        <div className='separator separator-content my-14'>
          <span className='text-muted fw-semibold fs-7 min-w-125px'>
            {intl.formatMessage({id: 'AUTH.SELECTACCOUNT.SUBTITLE'})}
          </span>
        </div>
      </div>

      <div className='row g-3 mb-9'>
        {profile?.accounts !== undefined &&
          profile?.accounts.map((subdomain: any, i: any) => {
            return <Account key={i} subdomain={subdomain} />
          })}
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <Link to='/logout/' className='link-info'>
          {intl.formatMessage({id: 'AUTH.SIGNOUT.TITLE'})}
        </Link>
      </div>
    </>
  )
}
