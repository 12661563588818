import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {RoleAccess} from '../../../models/users/Users'
import {checkIsActive} from '../../../../_metronic/helpers'
import {usersAPI} from '../../../redux/services/UsersService'
import {CreateScoreForms} from './forms/modals/CreateScoreForms'
import {CreateGroupModal} from './groups/modals/CreateGroupModal'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {CreateCriterionModal} from './criteria/modals/CreateCriterionModal'
import {CreateObjectionModal} from './objection/modals/CreateObjectionModal'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {CreateGroupObjectionModal} from './objection-groups/modals/CreateGroupObjectionModal'

const ToolsHeaderCard = () => {
  const intl = useIntl()
  const {id} = useParams()
  const {pathname} = useLocation()
  const [statePath, updateStatePath] = useState('scoreForms')
  const [showModalCreateScoreForm, setShowModalCreateScoreForm] = useState<boolean>(false)
  const [showModalCreateCriterion, setShowModalCreateCriterion] = useState<boolean>(false)
  const [showModalCreateGroup, setShowModalCreateGroup] = useState<boolean>(false)
  const [showModalCreateObjection, setShowModalCreateObjection] = useState<boolean>(false)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  const handleCloseCreateScoreForm = () => setShowModalCreateScoreForm(false)
  const handleShowCreateScoreForm = () => setShowModalCreateScoreForm(true)

  const handleCloseCreateCriterion = () => setShowModalCreateCriterion(false)
  const handleShowCreateCriterion = () => setShowModalCreateCriterion(true)

  const handleCloseCreateGroup = () => setShowModalCreateGroup(false)
  const handleShowCreateGroup = () => setShowModalCreateGroup(true)

  const handleCloseCreateObjection = () => setShowModalCreateObjection(false)
  const handleShowCreateObjection = () => setShowModalCreateObjection(true)

  useEffect(() => {
    if (pathname) {
      if (checkIsActive(pathname, '/tools/score_forms/')) {
        updateStatePath('scoreForms')
      } else if (checkIsActive(pathname, '/tools/criteria/')) {
        updateStatePath('criteria')
      } else if (checkIsActive(pathname, '/tools/criteria_groups/')) {
        updateStatePath('criteriaGroups')
      } else if (checkIsActive(pathname, '/tools/objections/')) {
        updateStatePath('objections')
      } else if (checkIsActive(pathname, '/tools/objection_groups/')) {
        updateStatePath('objection_groups')
      } else if (checkIsActive(pathname, `/tools/objection_groups/${id}/`)) {
        updateStatePath('objection_groups')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-0 d-flex'>
          <div className='d-flex overflow-auto h-55px col-8'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mb-5'>
              <li className='nav-item'>
                <Link
                  className={clsx(
                    'nav-link text-active-primary me-6 false fw-bold fs-7 text-uppercase small',
                    {
                      active: statePath === 'scoreForms',
                    }
                  )}
                  to='/tools/score_forms/'
                >
                  {intl.formatMessage({id: 'TOOLS.SCOREFORMS.TITLE'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={clsx(
                    'nav-link text-active-primary me-6 false fw-bold fs-7 text-uppercase small',
                    {
                      active: statePath === 'criteria',
                    }
                  )}
                  to='/tools/criteria/'
                >
                  {intl.formatMessage({id: 'TOOLS.CRITERIA.TITLE'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={clsx(
                    'nav-link text-active-primary me-6 false fw-bold fs-7 text-uppercase small',
                    {
                      active: statePath === 'criteriaGroups',
                    }
                  )}
                  to='/tools/criteria_groups/'
                >
                  {intl.formatMessage({id: 'TOOLS.CRITERIAGROUPS.TITLE'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={clsx(
                    'nav-link text-active-primary me-6 false fw-bold fs-7 text-uppercase small',
                    {
                      active: statePath === 'objections',
                    }
                  )}
                  to='/tools/objections/'
                >
                  {intl.formatMessage({id: 'TOOLS.OBJECTIONS.TITLE'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={clsx(
                    'nav-link text-active-primary me-6 false fw-bold fs-7 text-uppercase small',
                    {
                      active: statePath === 'objection_groups',
                    }
                  )}
                  to='/tools/objection_groups/'
                >
                  {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.TITLE'})}
                </Link>
              </li>
            </ul>
          </div>
          <div className='card-toolbar col-4 text-end'>
            {roleAccesses.includes('FORMSMODIFY') && statePath === 'scoreForms' && (
              <>
                <button className='btn btn-primary btn-sm' onClick={handleShowCreateScoreForm}>
                  {intl.formatMessage({id: 'TOOLS.SCOREFORMS.ADD.BUTTON'})}
                </button>
                <CreateScoreForms
                  show={showModalCreateScoreForm}
                  handleClose={handleCloseCreateScoreForm}
                />
              </>
            )}
            {roleAccesses.includes('CRITERIAMODIFY') && statePath === 'criteria' && (
              <>
                <button className='btn btn-primary btn-sm' onClick={handleShowCreateCriterion}>
                  {intl.formatMessage({id: 'TOOLS.CRITERIA.ADD.BUTTON'})}
                </button>
                <CreateCriterionModal
                  show={showModalCreateCriterion}
                  handleClose={handleCloseCreateCriterion}
                />
              </>
            )}
            {roleAccesses.includes('GROUPSMODIFY') && statePath === 'criteriaGroups' && (
              <>
                <button className='btn btn-primary btn-sm' onClick={handleShowCreateGroup}>
                  {intl.formatMessage({id: 'TOOLS.CRITERIAGROUPS.ADD.BUTTON'})}
                </button>
                <CreateGroupModal
                  show={showModalCreateGroup}
                  handleClose={handleCloseCreateGroup}
                />
              </>
            )}
            {roleAccesses.includes('CRITERIAMODIFY') && statePath === 'objections' && (
              <>
                <button className='btn btn-primary btn-sm' onClick={handleShowCreateObjection}>
                  {intl.formatMessage({id: 'TOOLS.OBJECTION.ADD.BUTTON'})}
                </button>
                <CreateObjectionModal
                  show={showModalCreateObjection}
                  handleClose={handleCloseCreateObjection}
                />
              </>
            )}
            {roleAccesses.includes('CRITERIAMODIFY') && statePath === 'objection_groups' && (
              <>
                <button className='btn btn-primary btn-sm' onClick={handleShowCreateObjection}>
                  {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.ADD.BUTTON'})}
                </button>
                <CreateGroupObjectionModal
                  show={showModalCreateObjection}
                  handleClose={handleCloseCreateObjection}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default ToolsHeaderCard
