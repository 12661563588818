import {Link} from 'react-router-dom'
import {useLayout} from '../../core'

const Footer = () => {
  const {classes} = useLayout()

  return (
    <>
      <div className='app-footer py-4 d-flex flex-center flex-lg-column' id='kt_footer'>
        <div
          className={`${classes.footerContainer} d-flex flex-column flex-md-row text-center align-items-center justify-content-center`}
        >
          <div className='text-dark order-2 order-md-1'>
            <span className='text-muted fw-bold me-1'>{new Date().getFullYear()} &copy;</span>
            <Link to='/' className='text-gray-800 text-hover-primary'>
              sidbis.com
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export {Footer}
