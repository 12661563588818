import React, {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../helpers'

const wl = window.location

export type SelectedLang = 'en' | 'ru'
type SelectedLangCallback = {
  lang: SelectedLang
  setLang: (newLang: SelectedLang) => void
}

const initialStateLang: SelectedLangCallback = {
  lang: 'en',
  setLang: () => {},
}

function getConfig(key: string): SelectedLangCallback {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as SelectedLangCallback
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateLang
}

const I18nContext = createContext<SelectedLangCallback>(initialStateLang)
const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const [lang, setLanguage] = useState<SelectedLang>(getConfig(`${wl.hostname}_i18nConfig`).lang)
  const setLang = (lang: SelectedLang) => {
    localStorage.setItem(`${wl.hostname}_i18nConfig`, JSON.stringify({lang: lang}))
    setLanguage(lang)
  }

  return <I18nContext.Provider value={{lang, setLang}}>{children}</I18nContext.Provider>
}

const useLang = () => useContext(I18nContext)
export {MetronicI18nProvider, useLang}
