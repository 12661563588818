/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import moment from 'moment'
import {AllControls} from './blocks/AllControls'
import {usersAPI} from '../../redux/services/UsersService'
import {AllControlsPeriod} from './blocks/AllControlsPeriod'
import {AllCheckedControls} from './blocks/AllCheckedControls'
import {ObjectionsUsersAnalytics} from './components/Objections'
import {ObjectionsUserAnalytics} from './components/ObjectionsUser'
import {UsersObjectionAnalytics} from './components/UsersObjection'
import {CheckedControlsPeriod} from './blocks/CheckedControlsPeriod'
import {ObjectionsFiltersProvider} from './helpers/ObjectionsFilters'
import {UsersObjectionProvider} from './helpers/UsersObjectionProvider'
import {ObjectionsUserProvider} from './helpers/ObjectionsUserProvider'
import {PeriodGetControlsProvider} from './helpers/PeriodGetControlsProvider'
import {SupervisorOwnCheckedControls} from './blocks/SupervisorOwnCheckedControls'
import {QueryRequestProvider} from '../../../_metronic/helpers/pagination/QueryRequestProvider'
import {CommunicationsFiltersProvider} from './helpers/CommunicationsProvider'
import {CommunicationsAnalytics} from './components/Communications'
import {FormsProvider} from './helpers/FormsProvider'
import {FormsAnalytics} from './components/Forms'

export const getDates = () => {
  const dayMilliseconds = 24 * 60 * 60 * 1000
  let todayDate = new Date()
  let todayFormatDate = moment(todayDate.getTime()).format().split('+')[0]

  let todayDay = todayDate.getDay()
  if (todayDay !== 1) {
    let mondayDateMlsec = todayDate.getTime() - dayMilliseconds * (todayDay - 1)
    let mondayDate = moment(mondayDateMlsec).format().split('T')[0] + 'T00:00:00'

    return mondayDate + ',' + todayFormatDate
  } else {
    // eslint-disable-next-line
    return todayFormatDate.split('T')[0] + 'T00:00:00' + ',' + todayFormatDate
  }
}

export const getPercent = (all: number, part: number) => {
  let percent = (part / (all / 100)).toFixed(2)
  if (percent === '0.00' || percent === 'NaN' || percent === 'Infinity') {
    return 0
  } else return percent
}

const DashboardPage: FC = () => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()

  return (
    <>
      <div className='d-flex mb-10'>
        <div className='row g-6 g-xl-9'>
          <div className='col-sm-6 col-xl-6'>
            <AllControls />
          </div>
          <div className='col-sm-6 col-xl-6'>
            <AllCheckedControls />
          </div>
          <div className='col-sm-6 col-xl-6'>
            <AllControlsPeriod />
          </div>
          <div className='col-sm-6 col-xl-6'>
            <CheckedControlsPeriod />
          </div>
          {currentUser?.role === 'SUPERVISOR' && (
            <div className='col-sm-6 col-xl-6 align-self-center'>
              <SupervisorOwnCheckedControls />
            </div>
          )}
        </div>
      </div>
      <div>
        <QueryRequestProvider>
          <ObjectionsFiltersProvider>
            <ObjectionsUsersAnalytics />
          </ObjectionsFiltersProvider>
        </QueryRequestProvider>
      </div>

      <div>
        <QueryRequestProvider>
          <UsersObjectionProvider>
            <UsersObjectionAnalytics />
          </UsersObjectionProvider>
        </QueryRequestProvider>
      </div>

      <div>
        <QueryRequestProvider>
          <ObjectionsUserProvider>
            <ObjectionsUserAnalytics />
          </ObjectionsUserProvider>
        </QueryRequestProvider>
      </div>

      <div>
        <QueryRequestProvider>
          <CommunicationsFiltersProvider>
            <CommunicationsAnalytics />
          </CommunicationsFiltersProvider>
        </QueryRequestProvider>
      </div>

      <div>
        <QueryRequestProvider>
          <FormsProvider>
            <FormsAnalytics />
          </FormsProvider>
        </QueryRequestProvider>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <PeriodGetControlsProvider>
      <DashboardPage />
    </PeriodGetControlsProvider>
  )
}

export {DashboardWrapper}
