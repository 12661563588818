import {FC} from 'react'
import {IScoreObjection} from '../../../../../../../models/score/Score'

type Props = {
  objection: IScoreObjection
}

const ObjectionName: FC<Props> = ({objection}) => {
  return (
    <>
      <div className='d-flex'>
        <div className='d-grid'>
          <label className='fw-bold text-dark'>{objection.name}</label>
          <label className='text-gray-500'>{objection.status}</label>
        </div>
      </div>
    </>
  )
}

export {ObjectionName}
