import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {} from '../../modules/controls/models/controls'
import {
  IAnalyticsCommunicationsQuery,
  IAnalyticsCommunicationsResponse,
  IAnalyticsControlsCriteriaQuery,
  IAnalyticsControlsCriteriaResponse,
  IAnalyticsFormsQuery,
  IAnalyticsFormsResponse,
  IAnalyticsObjectionUsersQuery,
  IAnalyticsObjectionUsersResponse,
  IAnalyticsObjectionsQuery,
  IAnalyticsObjectionsResponse,
  IAnalyticsQuery,
  IAnalyticsResponse,
  IAnalyticsUserObjectionsQuery,
  IAnalyticsUserObjectionsResponse,
} from '../../models/analytics/Analytics'

export const analyticsAPI = createApi({
  reducerPath: 'analyticsAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: [
    'analytics',
    'objections',
    'objectionUsers',
    'userObjections',
    'communications',
    'forms',
  ],
  endpoints: (build) => ({
    analyticsFetch: build.query<IAnalyticsResponse, IAnalyticsQuery>({
      query: ({period, status, is_durations, is_only_my_scores}) => ({
        url: '/analytics/controls',
        params: {
          period: period!,
          status: status!,
          is_durations: is_durations!,
          is_only_my_scores: is_only_my_scores!,
        },
      }),
      providesTags: ['analytics'],
    }),
    analyticsObjectionsFetch: build.query<IAnalyticsObjectionsResponse, IAnalyticsObjectionsQuery>({
      query: ({period, user_ids, score_objection_ids, skip, limit}) => ({
        url: '/analytics/objections',
        params: {
          period: period,
          user_ids: user_ids,
          score_objection_ids: score_objection_ids,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['objections'],
    }),
    analyticsObjectionUsersFetch: build.query<
      IAnalyticsObjectionUsersResponse,
      IAnalyticsObjectionUsersQuery
    >({
      query: ({period, number_of_periods, manager_ids, score_objection_id, skip, limit}) => ({
        url: '/analytics/objection/managers',
        params: {
          period: period,
          number_of_periods: number_of_periods,
          manager_ids: manager_ids,
          score_objection_id: score_objection_id,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['objectionUsers'],
    }),
    analyticsUserObjectionsFetch: build.query<
      IAnalyticsUserObjectionsResponse,
      IAnalyticsUserObjectionsQuery
    >({
      query: ({period, number_of_periods, manager_id, score_objection_ids, skip, limit}) => ({
        url: '/analytics/objections/manager',
        params: {
          period: period,
          number_of_periods: number_of_periods,
          manager_id: manager_id,
          score_objection_ids: score_objection_ids,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['userObjections'],
    }),
    analyticsCommunicationsFetch: build.query<
      IAnalyticsCommunicationsResponse,
      IAnalyticsCommunicationsQuery
    >({
      query: ({period, user_ids, skip, limit}) => ({
        url: '/analytics/communications',
        params: {
          period: period,
          user_ids: user_ids,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['communications'],
    }),
    analyticsFormsFetch: build.query<IAnalyticsFormsResponse, IAnalyticsFormsQuery>({
      query: ({period, user_ids, form_ids, score_types, skip, limit}) => ({
        url: '/analytics/forms',
        params: {
          period: period,
          user_ids: user_ids,
          form_ids: form_ids,
          score_types: score_types,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['forms'],
    }),
    analyticsControlscriteriaFetch: build.query<
      IAnalyticsControlsCriteriaResponse,
      IAnalyticsControlsCriteriaQuery
    >({
      query: ({period, user_ids, department_id, type, call_type, skip, limit}) => ({
        url: '/analytics/controls/criterions',
        params: {
          period: period,
          user_ids: user_ids,
          department_id: department_id,
          type: type,
          call_type: call_type,
          skip: skip!,
          limit: limit!,
        },
      }),
      providesTags: ['forms'],
    }),
  }),
})
