import {FC} from 'react'
import {Link} from 'react-router-dom'
import {ICreator} from '../../../../../../models/score/Score'

type Props = {
  createdBy: ICreator
}

const ObjectionAuthorCell: FC<Props> = ({createdBy}) => {
  return (
    <div className='d-flex col-lg-8'>
      <Link to={`/settings/users/${createdBy.id}/`} className='align-self-center'>
        <label className='fw-bold text-dark cursor-pointer'>{createdBy.name}</label>
      </Link>
    </div>
  )
}

export {ObjectionAuthorCell}
