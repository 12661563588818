import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {usersAPI} from '../../../../redux/services/UsersService'
import {convertTime} from '../../../../../_metronic/helpers/components/ConvertTime'
import {formatDateByZone} from '../../../../../_metronic/helpers/FormatDateZoneHelpers'
import {IControlCriteria, ISequenceGroup} from '../../../../models/analytics/Analytics'
import {scoreAPI} from '../../../../redux/services/ScoreService'

type Props = {
  groups: ISequenceGroup[]
  control: IControlCriteria
  type: string | undefined
}

const OneControlCriteria: FC<Props> = ({groups, control, type}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const intl = useIntl()
  const [getGroupCriteria] = scoreAPI.useLazyGroupsByIdQuery({})
  const [scoreState, setScoreState] = useState<{id: string; value: string}[]>([])

  const getScore = async () => {
    let scoreList: {id: string; value: string}[] = []
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id !== 'n/g') {
        let xMax = 0
        let xMin = 0
        let x = 0
        let r = 0
        await getGroupCriteria({id: groups[i].id})
          .unwrap()
          .then((response) => {
            if (response && response.criterions) {
              for (let j = 0; j < response.criterions.length; j++) {
                const cr = response.criterions[j]
                if (
                  control.criterions.find((c) => c.id === cr.id) &&
                  control.criterions.find((c) => c.id === cr.id)?.value !== '--' &&
                  control.criterions.find((c) => c.id === cr.id)?.value !== -2
                ) {
                  xMax = xMax + cr.score_zone.MAX_RANGE_MAX!
                  xMin = xMin + cr.score_zone.MIN_RANGE_MIN!
                  x = Number(control.criterions.find((c) => c.id === cr.id)?.value) + x
                }
              }

              r = x !== 0 ? ((x - xMin) / (xMax - xMin)) * 100 : 0
              scoreList.push({id: groups[i].id, value: r === 0 ? '0' : r.toFixed(1)})
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    setScoreState(scoreList)
  }

  useEffect(() => {
    if (groups) {
      getScore()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  return (
    <>
      <tr role='row' className='fs-7'>
        <td role='cell' className='w-200px table-fixed-th'>
          <div className='d-flex'>
            <Link to={`/settings/users/${control.created_by.id}/`}>
              <span className='fw-bold text-dark cursor-pointer'>{control.created_by.name}</span>
            </Link>
          </div>
        </td>
        <td role='cell' className='min-w-200px'>
          <div className='d-flex'>
            <span className='fw-bold text-gray-800'>
              {formatDateByZone(control.created_on!, currentUser?.language, currentUser?.time_zone)}
            </span>
          </div>
        </td>
        <td role='cell' className='min-w-150px py-0'>
          <div className='d-flex'>
            {control.type === 'CALL' && (
              <span className='badge badge-secondary small text-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.TYPE.CALL'})}
              </span>
            )}
            {control.type === 'MEETING' && (
              <span className='badge badge-light-info small text-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.MEETING'})}
              </span>
            )}
          </div>
        </td>
        {type === 'CALL' && control.type === 'CALL' && (
          <>
            <td role='cell' className='min-w-150px py-0'>
              {control.call_type === 'INCOMING' && (
                <span className='badge badge-light-success small text-uppercase'>
                  {intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}
                </span>
              )}
              {control.type === 'CALL' && control.call_type === 'OUTGOING' && (
                <span className='badge badge-light-warning small text-uppercase'>
                  {intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}
                </span>
              )}
            </td>
            <td role='cell' className='min-w-150px py-0'>
              {control.type === 'CALL' && (
                <span className=''>{convertTime(control.call_duration)}</span>
              )}
            </td>
          </>
        )}
        {control.criterions.map((criterionScore) =>
          document.getElementById(criterionScore.id) ? (
            <td role='cell' className='min-w-100px border-end text-center' key={criterionScore.id}>
              <span>
                {criterionScore.value === -2
                  ? '--'
                  : criterionScore.value === '--'
                  ? criterionScore.value
                  : criterionScore.value}
              </span>
            </td>
          ) : (
            <td role='cell' className='min-w-100px text-center' key={criterionScore.id}>
              <span>
                {criterionScore.value === -2
                  ? '--'
                  : criterionScore.value === '--'
                  ? criterionScore.value
                  : criterionScore.value}
              </span>
            </td>
          )
        )}
        {groups &&
          groups.map(
            (group) =>
              group.id !== 'n/g' &&
              (scoreState.find((g) => g.id === group.id) ? (
                <td role='cell' className='min-w-100px text-center border-end' key={group.id}>
                  {scoreState.find((g) => g.id === group.id)?.value}%
                </td>
              ) : (
                <td role='cell' className='min-w-100px text-center border-end' key={group.id}>
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </td>
              ))
          )}
        <td role='cell' className='min-w-250px'>
          {control.score_comment}
        </td>
      </tr>
    </>
  )
}

export {OneControlCriteria}
