import {FC} from 'react'

type Props = {
  user: {id: string; name: string}
}

const UserName: FC<Props> = ({user}) => {
  return (
    <>
      <div className='d-flex'>
        <div className='d-grid'>
          <label className='fw-bold text-dark'>{user.name}</label>
        </div>
      </div>
    </>
  )
}

export {UserName}
