/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {useUserObjectionsFilters} from '../helpers/ObjectionsUserProvider'
import {ObjectionsFooter} from '../tables_widgets/objection_table/ObjectionsFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {ObjectionsUserTable} from '../tables_widgets/objections_user_table/ObjectionsUserTable'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'

const ObjectionsUserAnalytics: FC = () => {
  const intl = useIntl()
  const [periodState, setPeriodState] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {usersObjFilter, setUsersObjectionFilter} = useUserObjectionsFilters()
  const [selectedObjections, setSelectedObjections] = useState<{label: string; value: string}[]>([])
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const [getUsers, {data: users}] = usersAPI.useLazyUsersFetchQuery()
  const [getAnalytics, {data: analytics, isLoading: isLoadingAnalytic}] =
    analyticsAPI.useLazyAnalyticsUserObjectionsFetchQuery()
  const [selectedManager, setSelectedManager] = useState<string>('')
  const [getObjectionsList, {data: objections, isLoading: isLoadingGetObjections}] =
    scoreAPI.useLazyObjectionFetchQuery()
  const [objectionsOptions, setObjectionsOptions] = useState<{label: string; value: string}[]>([])
  const [num, setNum] = useState<number>(
    usersObjFilter.periods_number ? usersObjFilter.periods_number : 1
  )

  const localeSettingsObjectionsSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.OBJECTIONS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.OBJECTIONS.CLEARSELECTED'}),
      noOptions: isLoadingGetObjections
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.OBJECTIONS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.OBJECTIONS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.OBJECTIONS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.OBJECTIONS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  useEffect(() => {
    if (periodState) {
      setUsersObjectionFilter({
        period: periodState,
        manager: usersObjFilter.manager,
        objections: usersObjFilter.objections,
        periods_number: usersObjFilter.periods_number,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    if (users && users?.users.length !== 0) {
      let usersList: {label: string; value: string}[] = []
      users.users.map((user) => usersList.push({label: user.name, value: user.id}))
      setUsersOptions(usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (objections && objections?.objections.length !== 0) {
      let objectionsList: {label: string; value: string}[] = []
      objections.objections.forEach((objection) => {
        if (objection.status === 'PUBLISHED') {
          objectionsList.push({label: objection.name, value: objection.id})
        }
      })
      setObjectionsOptions(objectionsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objections])

  useEffect(() => {
    if (usersObjFilter.manager !== '') {
      setSelectedManager(usersObjFilter.manager)
    }
    if (usersObjFilter.objections.length !== 0) {
      setSelectedObjections(usersObjFilter.objections)
    }
    if (usersObjFilter.period.start !== '' && usersObjFilter.period.end !== '') {
      setPeriodState(usersObjFilter.period)
    }

    setNum(usersObjFilter.periods_number)

    if (
      usersObjFilter.manager !== '' &&
      usersObjFilter.objections.length !== 0 &&
      usersObjFilter.period.start !== '' &&
      usersObjFilter.period.end !== ''
    ) {
      getAnalytics({
        period: usersObjFilter.period.start + ',' + usersObjFilter.period.end,
        manager_id: usersObjFilter.manager,
        score_objection_ids: getSelected(usersObjFilter.objections),
        number_of_periods: usersObjFilter.periods_number,
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersObjFilter])

  return (
    <>
      <div className='card card-custom card-stretch shadow mt-20'>
        <div className='card-header'>
          <div className='card-title d-flex mt-5 mb-3 col-lg-12'>
            <h3 className='fw-bolder m-0'>
              {intl.formatMessage({id: 'OBJECTIONS.USER.PERIOD.TITLE'})}
            </h3>
          </div>
          <span className='small text-uppercase text-muted mb-5 col-lg-12'>
            {intl.formatMessage({id: 'OBJECTION.USERS.PERIOD.INFO'})}
          </span>

          <div className='col-lg-12 d-flex mb-4'>
            <div className='pe-3 col-lg-4'>
              <MultiSelect
                onMenuToggle={() => {
                  if (!objections) {
                    getObjectionsList({})
                  }

                  if (selectedObjections.length !== 0) {
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      manager: usersObjFilter.manager,
                      objections: selectedObjections,
                      periods_number: usersObjFilter.periods_number,
                    })
                  }
                }}
                className='text-gray-900'
                options={objectionsOptions}
                hasSelectAll={true}
                value={selectedObjections}
                onChange={(e: any) => {
                  setSelectedObjections(e)
                  if (e.length === 0) {
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      manager: usersObjFilter.manager,
                      objections: e,
                      periods_number: usersObjFilter.periods_number,
                    })
                  }
                }}
                overrideStrings={localeSettingsObjectionsSelect(intl.formatMessage)}
                closeOnChangedValue={false}
                labelledBy={intl.formatMessage({id: 'MULTISELECT.OBJECTIONS.SELECT'})}
              />
            </div>
            <div className='pe-3 col-lg-4 popup'>
              <select
                onClick={() => {
                  if (!users) {
                    getUsers({is_active: true})
                  }
                }}
                disabled={isLoadingAnalytic}
                value={selectedManager}
                className='form-select form-select-solid form-select-lg text-gray-900'
                placeholder={intl.formatMessage({id: 'ANALYTICS.SELECTUSER'})}
                onChange={(e: any) => {
                  setSelectedManager(e.target.value)
                  setUsersObjectionFilter({
                    period: usersObjFilter.period,
                    manager: e.target.value,
                    objections: usersObjFilter.objections,
                    periods_number: usersObjFilter.periods_number,
                  })
                }}
              >
                <option value='' disabled style={{display: 'none'}}>
                  {intl.formatMessage({id: 'ANALYTICS.SELECTUSER'})}
                </option>
                {usersOptions &&
                  usersOptions.map((user) => (
                    <option value={user.value} key={user.value}>
                      {user.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className='pe-3 col-lg-3'>
              <DateRangePickerInput
                language={currentUser?.language}
                timeZone={currentUser?.time_zone}
                rangeId='2'
                periodState={periodState}
                updateState={setPeriodState}
                onlyIcon={false}
                cancel={true}
              />
            </div>
            <div className='col-lg-1'>
              <OverlayTrigger
                placement='top-start'
                trigger={['hover', 'focus']}
                overlay={
                  <Popover>
                    <div className='p-3 small text-uppercase text-gray-700'>
                      {intl.formatMessage({id: 'ANALYTICS.SELECTPERIODCOUNT'})}
                    </div>
                  </Popover>
                }
              >
                <select
                  defaultValue={num}
                  onChange={(e: any) => {
                    setNum(e.target.value)
                    setUsersObjectionFilter({
                      period: usersObjFilter.period,
                      manager: usersObjFilter.manager,
                      objections: usersObjFilter.objections,
                      periods_number: e.target.value,
                    })
                  }}
                  className='form-select form-select-solid form-select-lg text-gray-900'
                >
                  {Array.from({length: 12}).map((_, index) => (
                    <option value={index + 1} key={index}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <ObjectionsUserTable
              objectionsAnalytics={
                usersObjFilter.manager !== '' &&
                usersObjFilter.objections.length !== 0 &&
                usersObjFilter.period.start !== '' &&
                usersObjFilter.period.end !== ''
                  ? analytics
                  : undefined
              }
              selectedObjections={selectedObjections}
            />
          </div>
          {isLoadingAnalytic ? (
            <div className='d-flex flex-center'>
              <span
                className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
                style={{display: 'block'}}
              >
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            (usersObjFilter.manager === '' ||
              usersObjFilter.objections.length === 0 ||
              usersObjFilter.period.start === '' ||
              usersObjFilter.period.end === '') && (
              <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
                {intl.formatMessage({id: 'OBJECTIONS.USER.PERIOD.TABLE.INFO'})}
              </span>
            )
          )}
        </div>
        <ObjectionsFooter selectedUsers={selectedObjections} />
      </div>
    </>
  )
}

export {ObjectionsUserAnalytics}
