/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {FC, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IObjectionGroup, IScoreObjectionCreate} from '../../../../../models/score/Score'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {SelectGroupsObjection} from './components/SelectGroupsObjection'

const objectionAddShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateObjectionModal: FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const [createObjection, {isLoading, isError}] = scoreAPI.useObjectionCreateMutation()
  const [maxDescription, setMaxDescription] = useState<string>('')
  const [minDescription, setMinDescription] = useState<string>('')
  const [averageDescription, setAverageDescription] = useState<string>('')
  const [selectedGroupObjection, setSelectedGroupObjection] = useState<IObjectionGroup>()

  const handleSetObjection = (group?: IObjectionGroup) => {
    setSelectedGroupObjection(group)
  }

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
    setMaxDescription('')
    setMinDescription('')
    setAverageDescription('')
  }

  const initialValues: IScoreObjectionCreate = {
    name: '',
    score_zone: {
      MAX_DESCRIPTION: '',
      AVERAGE_DESCRIPTION: '',
      MIN_DESCRIPTION: '',
    },
  }

  const formik = useFormik({
    initialValues,
    validationSchema: objectionAddShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data: IScoreObjectionCreate = {
        name: values.name,
        score_zone: {
          MAX_DESCRIPTION: maxDescription,
          MIN_DESCRIPTION: minDescription,
          AVERAGE_DESCRIPTION: averageDescription,
        },
      }
      if (selectedGroupObjection) {
        data.group_id = selectedGroupObjection.id
      }
      await createObjection(data)
        .unwrap()
        .then(() => {
          handleCloseModal()
        })
        .catch(() => {
          formik.resetForm()
        })
    },
  })

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'OBJECTION.ADD.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='py-2'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'OBJECTION.ADD.NAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'OBJECTION.ADD.NAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <SelectGroupsObjection setSelectGroupObjection={handleSetObjection} />
            <div className='separator mb-4' />
            <div className='col mb-2'>
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.EXCELLENT'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MAXRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={4}
                    disabled
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MAXRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={4}
                    disabled
                  />
                  <div className='h-30px w-30px bg-success rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm mb-5'>
                <textarea
                  className='form-control border-success'
                  placeholder='Description'
                  onChange={(e) => setMaxDescription(e.target.value)}
                  value={maxDescription}
                />
              </div>
              <>
                <div className='d-flex flex-stack mb-2'>
                  <label className='col-form-label fw-bolder text-gray-600'>
                    <span className='small text-uppercase'>
                      {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.NORMAL'})}
                    </span>
                  </label>
                  <div className='d-flex'>
                    {/* AVERAGERANGEMIN */}
                    <input
                      type='number'
                      className='w-100px form-control form-control-lg text-gray-900'
                      value={2}
                      disabled
                    />
                    <label className='align-self-center mx-2'>—</label>
                    {/* AVERAGERANGEMAX */}
                    <input
                      type='number'
                      className='w-100px form-control form-control-lg text-gray-900 me-5'
                      value={3}
                      disabled
                    />
                    <div className='h-30px w-30px bg-warning rounded-1 align-self-center'></div>
                  </div>
                </div>
                <div className='input-group input-group-sm mb-5'>
                  <textarea
                    className='form-control border-warning'
                    placeholder='Description'
                    onChange={(e) => setAverageDescription(e.target.value)}
                    value={averageDescription}
                  ></textarea>
                </div>
              </>
              <div className='d-flex flex-stack mb-2'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'CRITERIA.ADD.SCOREZONE.CRITICAL'})}
                  </span>
                </label>
                <div className='d-flex'>
                  {/* MINRANGEMIN */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900'
                    value={1}
                    disabled
                  />
                  <label className='align-self-center mx-2'>—</label>
                  {/* MINRANGEMAX */}
                  <input
                    type='number'
                    className='w-100px form-control form-control-lg text-gray-900 me-5'
                    value={1}
                    disabled
                  />
                  <div className='h-30px w-30px bg-danger rounded-1 align-self-center'></div>
                </div>
              </div>
              <div className='input-group input-group-sm '>
                <textarea
                  className='form-control border-danger'
                  placeholder='Description'
                  onChange={(e) => setMinDescription(e.target.value)}
                  value={minDescription}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'OBJECTION.ADD.TITLE'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {CreateObjectionModal}
