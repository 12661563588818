import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  phone?: string
}

const ProfilePhone: FC<Props> = ({phone}) => {
  const intl = useIntl()

  return (
    <div className='fv-row mb-8'>
      <label className='ms-2 mb-2 label text-muted text-uppercase'>
        {intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
      </label>
      {phone === '' || phone === null || phone === undefined ? (
        <span className='form-control form-control-lg text-muted form-control-solid mb-3 mb-lg-0 bg-white text-black'>
          {intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
        </span>
      ) : (
        <span className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white text-black'>
          {phone}
        </span>
      )}
    </div>
  )
}

export {ProfilePhone}
