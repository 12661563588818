const TimeZoneOptions = () => {
  return (
    <>
      <option value='UTC'>UTC GMT+0:00</option>
      <option value='Africa/Cairo'>Africa/Cairo GMT+2:00</option>
      <option value='Africa/Casablanca'>Africa/Casablanca GMT+1:00</option>
      <option value='Africa/Harare'>Africa/Harare GMT+2:00</option>
      <option value='Africa/Monrovia'>Africa/Monrovia GMT+0:00</option>
      <option value='Africa/Nairobi'>Africa/Nairobi GMT+3:00</option>
      <option value='America/Bogota'>America/Bogota GMT-5:00</option>
      <option value='America/Caracas'>America/Caracas GMT-4:00</option>
      <option value='America/Chicago'>America/Chicago GMT-6:00</option>
      <option value='America/Chihuahua'>America/Chihuahua GMT-7:00</option>
      <option value='America/La_Paz'>America/La Paz GMT-4:00</option>
      <option value='America/Lima'>America/Lima GMT-5:00</option>
      <option value='America/Mazatlan'>America/Mazatlan GMT-7:00</option>
      <option value='America/Monterrey'>America/Monterrey GMT-6:00</option>
      <option value='America/Santiago'>America/Santiago GMT-4:00</option>
      <option value='America/Tijuana'>America/Tijuana GMT-8:00</option>
      <option value='Asia/Almaty'>Asia/Almaty GMT+6:00</option>
      <option value='Asia/Baghdad'>Asia/Baghdad GMT+3:00</option>
      <option value='Asia/Baku'>Asia/Baku GMT+4:00</option>
      <option value='Asia/Bangkok'>Asia/Bangkok GMT+7:00</option>
      <option value='Asia/Chongqing'>Asia/Chongqing GMT+8:00</option>
      <option value='Asia/Dhaka'>Asia/Dhaka GMT+6:00</option>
      <option value='Asia/Jakarta'>Asia/Jakarta GMT+7:00</option>
      <option value='Asia/Jerusalem'>Asia/Jerusalem GMT+3:00</option>
      <option value='Asia/Hong Kong'>Asia/Hong_Kong GMT+8:00</option>
      <option value='Asia/Irkutsk'>Asia/Irkutsk GMT+8:00</option>
      <option value='Asia/Kabul'>Asia/Kabul GMT+4:30</option>
      <option value='Asia/Kamchatka'>Asia/Kamchatka GMT+12:00</option>
      <option value='Asia/Karachi'>Asia/Karachi GMT+5:00</option>
      <option value='Asia/Kathmandu'>Asia/Kathmandu GMT+5:45</option>
      <option value='Asia/Kolkata'>Asia/Kolkata GMT+5:30</option>
      <option value='Asia/Krasnoyarsk'>Asia/Krasnoyarsk GMT+7:00</option>
      <option value='Asia/Kuala Lumpur'>Asia/Kuala_Lumpur GMT+8:00</option>
      <option value='Asia/Kuwait'>Asia/Kuwait GMT+3:00</option>
      <option value='Asia/Magadan'>Asia/Magadan GMT+11:00</option>
      <option value='Asia/Muscat'>Asia/Muscat GMT+4:00</option>
      <option value='Asia/Novosibirsk'>Asia/Novosibirsk GMT+7:00</option>
      <option value='Asia/Rangoon'>Asia/Rangoon GMT+6:30</option>
      <option value='Asia/Riyadh'>Asia/Riyadh GMT+3:00</option>
      <option value='Asia/Seoul'>Asia/Seoul GMT+9:00</option>
      <option value='Asia/Singapore'>Asia/Singapore GMT+8:00</option>
      <option value='Asia/Taipei'>Asia/Taipei GMT+8:00</option>
      <option value='Asia/Tashkent'>Asia/Tashkent GMT+5:00</option>
      <option value='Asia/Tbilisi'>Asia/Tbilisi GMT+4:00</option>
      <option value='Asia/Tehran'>Asia/Tehran GMT+4:30</option>
      <option value='Asia/Tokyo'>Asia/Tokyo GMT+9:00</option>
      <option value='Asia/Urumqi'>Asia/Urumqi GMT+6:00</option>
      <option value='Asia/Vladivostok'>Asia/Vladivostok GMT+10:00</option>
      <option value='Asia/Yakutsk'>Asia/Yakutsk GMT+9:00</option>
      <option value='Asia/Ekaterinburg'>Asia/Yekaterinburg GMT+5:00</option>
      <option value='Asia/Yerevan'>Asia/Yerevan GMT+4:00</option>
      <option value='Atlantic/Azores'>Atlantic/Azores GMT-1:00</option>
      <option value='Atlantic/Cape_Verde'>Atlantic/Cape_Verde GMT-1:00</option>
      <option value='Atlantic/South_Georgia'>Atlantic/South_Georgia GMT-2:00</option>
      <option value='Australia/Adelaide'>Australia/Adelaide GMT+9:30</option>
      <option value='Australia/Brisbane'>Australia/Brisbane GMT+10:00</option>
      <option value='Australia/Darwin'>Australia/Darwin GMT+9:30</option>
      <option value='Australia/Hobart'>Australia/Hobart GMT+10:00</option>
      <option value='Australia/Melbourne'>Australia/Melbourne GMT+10:00</option>
      <option value='Australia/Perth'>Australia/Perth GMT+8:00</option>
      <option value='Australia/Sydney'>Australia/Sydney GMT+10:00</option>
      <option value='Brazil/East'>Brazil/East GMT-3:00</option>
      <option value='Canada/Atlantic'>Canada/Atlantic GMT-4:00</option>
      <option value='Canada/Central'>Canada/Central GMT-6:00</option>
      <option value='Canada/Eastern'>Canada/Eastern GMT-5:00</option>
      <option value='Canada/Mountain'>Canada/Mountain GMT-7:00</option>
      <option value='Canada/Newfoundland'>Canada/Newfoundland GMT-4:30</option>
      <option value='Canada/Pacific'>Canada/Pacific GMT-8:00</option>
      <option value='Canada/Saskatchewan'>Canada/Saskatchewan GMT-6:00</option>
      <option value='Europe/Amsterdam'>Europe/Amsterdam GMT+2:00</option>
      <option value='Europe/Berlin'>Europe/Berlin GMT+2:00</option>
      <option value='Europe/Copenhagen'>Europe/Copenhagen GMT+2:00</option>
      <option value='Europe/Athens'>Europe/Athens GMT+3:00</option>
      <option value='Europe/Belgrade'>Europe/Belgrade GMT+2:00</option>
      <option value='Europe/Bratislava'>Europe/Bratislava GMT+2:00</option>
      <option value='Europe/Brussels'>Europe/Brussels GMT+2:00</option>
      <option value='Europe/Bucharest'>Europe/Bucharest GMT+3:00</option>
      <option value='Europe/Budapest'>Europe/Budapest GMT+2:00</option>
      <option value='Europe/Dublin'>Europe/Dublin GMT+0:00</option>
      <option value='Europe/Helsinki'>Europe/Helsinki GMT+2:00</option>
      <option value='Europe/Istanbul'>Europe/Istanbul GMT+3:00</option>
      <option value='Europe/Kiev'>Europe/Kiev GMT+3:00</option>
      <option value='Europe/Lisbon'>Europe/Lisbon GMT+0:00</option>
      <option value='Europe/Ljubljana'>Europe/Ljubljana GMT+2:00</option>
      <option value='Europe/London'>Europe/London GMT+0:00</option>
      <option value='Europe/Madrid'>Europe/Madrid GMT+2:00</option>
      <option value='Europe/Minsk'>Europe/Minsk GMT+3:00</option>
      <option value='Europe/Moscow'>Europe/Moscow GMT+3:00</option>
      <option value='Europe/Paris'>Europe/Paris GMT+2:00</option>
      <option value='Riga'>Europe/Riga GMT+3:00</option>
      <option value='Europe/Rome'>Europe/Rome GMT+2:00</option>
      <option value='Europe/Sarajevo'>Europe/Sarajevo GMT+21:00</option>
      <option value='Europe/Skopje'>Europe/Skopje GMT+2:00</option>
      <option value='Europe/Sofia'>Europe/Sofia GMT+3:00</option>
      <option value='Europe/Stockholm'>Europe/Stockholm GMT+2:00</option>
      <option value='Europe/St. Petersburg'>Europe/St. Petersburg GMT+3:00</option>
      <option value='Europe/Tallinn'>Europe/Tallinn GMT+3:00</option>
      <option value='Europe/Vienna'>Europe/Vienna GMT+2:00</option>
      <option value='Europe/Vilnius'>Europe/Vilnius GMT+3:00</option>
      <option value='Europe/Volgograd'>Europe/Volgograd GMT+3:00</option>
      <option value='Europe/Warsaw'>Europe/Warsaw GMT+2:00</option>
      <option value='Europe/Zagreb'>Europe/Zagreb GMT+2:00</option>
      <option value='Mexico/General'>Mexico/General GMT-6:00</option>
      <option value='Pacific/Auckland'>Pacific/Auckland GMT+12:00</option>
      <option value='Pacific/Guam'>Pacific/Guam GMT+10:00</option>
      <option value='Pacific/Fiji'>Pacific/Fiji GMT+12:00</option>
      <option value='Pacific/Midway'>Pacific/Midway GMT-11:00</option>
      <option value='Pacific/Port Moresby'>Pacific/Port_Moresby GMT+10:00</option>
      <option value='Pacific/Samoa'>Pacific/Samoa GMT-11:00</option>
      <option value='US/Alaska'>US/Alaska GMT-9:00</option>
      <option value='US/Arizona'>US/Arizona GMT-7:00</option>
      <option value='US/East-Indiana'>US/East-Indiana GMT-5:00</option>
      <option value='US/Hawaii'>US/Hawaii GMT-10:00</option>
    </>
  )
}

export {TimeZoneOptions}
