import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CanvasControl} from '../../ControlCanvas'
import {IControlData} from '../../models/controls'
import {usersAPI} from '../../../../redux/services/UsersService'
import {convertTime} from '../../../../../_metronic/helpers/components/ConvertTime'
import {formatDateByZone} from '../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  control: IControlData
  type: string | undefined
}

const OneObject: FC<Props> = ({control, type}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const intl = useIntl()

  return (
    <>
      <tr role='row' className='fs-7'>
        <td role='cell' className='w-150px'>
          <div className='row'>
            <span className='fw-bolder text-gray-800'>
              {formatDateByZone(control.created_on!, currentUser?.language, currentUser?.time_zone)}
            </span>
          </div>
        </td>
        <td role='cell' className='w-150px'>
          <div className='d-flex'>
            <Link to={`/settings/users/${control.created_by.id}/`} className='d-flex flex-center'>
              <label className='fw-bold text-dark cursor-pointer'>{control.created_by.name}</label>
            </Link>
          </div>
        </td>
        <td role='cell' className='w-100px'>
          <div className='d-flex'>
            {control.type === 'CALL' && (
              <span className='badge badge-secondary small text-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.TYPE.CALL'})}
              </span>
            )}
            {control.type === 'MEETING' && (
              <span className='badge badge-light-info small text-uppercase'>
                {intl.formatMessage({id: 'CONTROLS.MEETING'})}
              </span>
            )}
          </div>
        </td>
        {type === 'CALL' && control.call && (
          <>
            <td role='cell' className='w-100px'>
              {control.call.type === 'INCOMING' && (
                <span className='badge badge-light-success small text-uppercase'>
                  {intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}
                </span>
              )}
              {control.type === 'CALL' && control.call.type === 'OUTGOING' && (
                <span className='badge badge-light-warning small text-uppercase'>
                  {intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}
                </span>
              )}
            </td>
            <td role='cell' className='w-100px'>
              {control.type === 'CALL' && (
                <span className=''>{convertTime(control.call.duration)}</span>
              )}
            </td>
            <td role='cell' className='w-100px'>
              <span className=''>{control.call.phone_number}</span>
            </td>
          </>
        )}
        <td role='cell' className='w-150px'>
          {control.check_status === 'WAIT' ? (
            <span className='badge badge-light-warning text-uppercase'>
              {intl.formatMessage({id: 'CONTROLS.STATUS.WAIT'})}
            </span>
          ) : control.check_status === 'CHECKED' ? (
            <span className='badge badge-light-success text-uppercase'>
              {intl.formatMessage({id: 'CONTROLS.STATUS.CHECKED'})}
            </span>
          ) : control.check_status === 'WAIT_FOR_APPEAL' ? (
            <span className='badge badge-light-info text-uppercase'>
              {intl.formatMessage({id: 'CONTROLS.STATUS.WAITFORAPPEAL'})}
            </span>
          ) : (
            <span className='badge badge-light-primary text-uppercase'>
              {intl.formatMessage({id: 'CONTROLS.STATUS.APPEALED'})}
            </span>
          )}
        </td>
        <td role='cell' className='w-70px'>
          <div className='flex-end d-flex'>
            <CanvasControl control={control} />
          </div>
        </td>
      </tr>
    </>
  )
}

export {OneObject}
