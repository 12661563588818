import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {RoleAccess} from '../../../../../../models/users/Users'
import {usersAPI} from '../../../../../../redux/services/UsersService'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {selectReduserUpdateAll} from '../../../../../../redux/slices/serviceSlice'
import {getAccesses} from '../../../../../../../_metronic/helpers/components/RoleAccesses'

type Props = {
  page: number
}

const ScoreObjectionsHeaderGroup: FC<Props> = ({page}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  const [checked, setChecked] = useState(false)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  const handleCheck = (isSelected: boolean) => {
    setChecked(isSelected)
    if (isSelected) {
      dispatch(selectReduserUpdateAll({page: page, selectAllMode: 'checked'}))
    } else if (selected.all.get(page) === 'checked') {
      dispatch(selectReduserUpdateAll({page: page, selectAllMode: undefined}))
    }
  }

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else {
      handleCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else if (
      selected.all.get(page) === 'indeterminate' ||
      selected.all.get(page) === 'unChecked'
    ) {
      handleCheck(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.all])

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        {roleAccesses.includes('ORGANIZATIONMODIFY') && (
          <th role='columnheader' className='min-w-50px'>
            <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
              <input
                className='form-check-input'
                type='checkbox'
                checked={checked}
                onChange={(e) => handleCheck(e.target.checked)}
              />
            </div>
          </th>
        )}

        <th role='columnheader' className='min-w-200px'>
          {intl.formatMessage({id: 'TOOLS.OBJECTION.TITLE'})}
        </th>
        <th role='columnheader' className='min-w-200px px-0'>
          {intl.formatMessage({id: 'CRITERIATABLE.AUTHOR'})}
        </th>
        <th role='columnheader' className='min-w-200px px-0'>
          {intl.formatMessage({id: 'CREATION'})}
        </th>
      </tr>
    </thead>
  )
}

export {ScoreObjectionsHeaderGroup}
