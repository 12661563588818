import {RoleAccess} from '../../../app/models/users/Users'

export function getAccesses(role: 'SUPERADMIN' | 'ADMIN' | 'MANAGER' | 'SUPERVISOR' | 'OPERATOR') {
  let accessList: Array<RoleAccess> = [
    'ACCOUNTREAD',
    'ORGANIZATIONREAD',
    'ACCOUNTUSERSREAD',
    'CRITERIAREAD',
    'OBJECTIONREAD',
    'GROUPSREAD',
    'FORMSREAD',
  ]
  if (role === 'SUPERADMIN') {
    accessList.push(
      'ACCOUNTDELETE',
      'ACCOUNTUPDATE',
      'ACCOUNTUSERSINVATE',
      'ACCOUNTUSERSMODIFY',
      'CONTROLSALL',
      'CONTROLSUPDATEOWNER',
      'CRITERIAMODIFY',
      'OBJECTIONMODIFY',
      'FORMSMODIFY',
      'FORMSDELETE',
      'GROUPSMODIFY',
      'ORGANIZATIONMODIFY',
      'INTEGRATIONMODIFY',
      'INTEGRATIONREAD',
      'ANALYTICSALL'
    )
  } else if (role === 'ADMIN') {
    accessList.push(
      'ACCOUNTUPDATE',
      'ACCOUNTUSERSINVATE',
      'ACCOUNTUSERSMODIFY',
      'CONTROLSALL',
      'CONTROLSUPDATEOWNER',
      'CRITERIAMODIFY',
      'OBJECTIONMODIFY',
      'FORMSMODIFY',
      'FORMSDELETE',
      'GROUPSMODIFY',
      'ORGANIZATIONMODIFY',
      'INTEGRATIONMODIFY',
      'INTEGRATIONREAD',
      'ANALYTICSALL'
    )
  } else if (role === 'MANAGER') {
    accessList.push(
      'ACCOUNTUSERSINVATE',
      'CONTROLSDEP',
      'CONTROLSUPDATEOWNER',
      'CRITERIAMODIFY',
      'OBJECTIONMODIFY',
      'FORMSMODIFY',
      'GROUPSMODIFY',
      'INTEGRATIONREAD',
      'ANALYTICSDEP'
    )
  } else if (role === 'SUPERVISOR') {
    accessList.push('CONTROLSDEP', 'ANALYTICSALL', 'CONTROLSUPDATEOWNER')
  } else if (role === 'OPERATOR') {
    accessList.push('CONTROLSOWN', 'ANALYTICSOWN')
  }

  return accessList
}
