export function fixObjEmptyField(obj: any) {
  if (obj) {
    const objCopy = {...obj}
    Object.keys(objCopy).forEach((item) => {
      if (objCopy[item] === undefined || objCopy[item] === null) {
        objCopy[item] = ''
      }
    })
    return objCopy
  }
  return obj
}
