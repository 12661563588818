import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {OneCriterion} from './OneCriterion'
import {CriteriaHeader} from './CriteriaHeader'
import {RoleAccess} from '../../../../../models/users/Users'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'
import {TablePagination} from '../../../../../../_metronic/helpers/pagination/TablePagination'
import {useQueryRequest} from '../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

export const CriteriaTable = () => {
  const intl = useIntl()
  const {state, updateState} = useQueryRequest()
  const {data: criteria, isLoading: isLoadingGetCriterions} = scoreAPI.useCriteriaFetchQuery({
    skip: state.skip,
    limit: state.limit,
    search: state.search!,
  })

  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <CriteriaHeader />
        <tbody>
          {criteria &&
            criteria.criterions.length !== 0 &&
            criteria.criterions.map((c) => (
              <OneCriterion criteria={c} key={c.id} userRole={roleAccesses} />
            ))}
        </tbody>
      </table>
      {isLoadingGetCriterions && (
        <div className='d-flex flex-center'>
          <span
            className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
            style={{display: 'block'}}
          >
            {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
      {criteria && criteria.criterions.length === 0 && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
      <div className='card-footer px-0'>
        <div className='d-flex flex-stack  align-items-center'>
          <div className='justify-content-start'>
            <label>
              <select
                className='form-select form-select-sm form-select-solid'
                value={state.limit}
                onChange={(e) => {
                  updateState({skip: 0, limit: parseInt(e.target.value)})
                }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </label>
            <span className='small text-uppercase ms-4'>
              {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}: {criteria ? criteria?.total : 0}
            </span>
          </div>
          <TablePagination total={criteria ? criteria?.total : 0} />
        </div>
      </div>
    </>
  )
}
