import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  ICRMUser,
  ICRMUserList,
  ICRMUsersMappingsUpdate,
  UCRMUsersUpdate,
} from '../../models/integrations/crm-users/crmUsers'
import {IIntegrationUsersAdd} from '../../models/integrations/Integrations'

export const crmUsersAPI = createApi({
  reducerPath: 'crmUsersAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['crmUsers', 'crmUsersMappings'],
  endpoints: (build) => ({
    usersByIntegrationId: build.query<
      ICRMUserList,
      {integration_id: string; skip?: number; limit?: number; search?: string}
    >({
      query: ({integration_id, skip, limit, search}) => ({
        url: `/integrations/${integration_id}/users`,
        params: {skip: skip, limit: limit, search: search!},
      }),
      providesTags: ['crmUsers'],
    }),
    usersMappingsByIntegrationId: build.query<
      ICRMUserList,
      {integration_id: string; skip?: number; limit?: number; search?: string}
    >({
      query: ({integration_id, skip, limit, search}) => ({
        url: `/integrations/${integration_id}/users`,
        params: {skip: skip, limit: limit, search: search!},
      }),
      providesTags: ['crmUsersMappings'],
    }),
    usersSync: build.query<ICRMUserList, {integration_id: string}>({
      query: ({integration_id}) => ({
        url: `/integrations/${integration_id}/sync`,
      }),
      providesTags: ['crmUsers'],
    }),
    usersUpdate: build.mutation<ICRMUserList, {integration_id: string; data: UCRMUsersUpdate}>({
      query: ({integration_id, data}) => ({
        url: `/integrations/${integration_id}/users`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['crmUsers', 'crmUsersMappings'],
    }),
    usersMappingsUpdate: build.mutation<
      ICRMUsersMappingsUpdate,
      {integration_id: string; data: ICRMUsersMappingsUpdate}
    >({
      query: ({integration_id, data}) => ({
        url: `/integrations/${integration_id}/mappings`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['crmUsersMappings', 'crmUsers'],
    }),
    integrationAddUser: build.mutation<ICRMUser[], {id: string; data?: IIntegrationUsersAdd[]}>({
      query: ({id, data}) => ({
        url: `/integrations/${id}/users`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['crmUsers', 'crmUsersMappings'],
    }),
  }),
})
