import {FC} from 'react'
import {UserName} from '../cells/UserName'
import {ICommunicationAnalytics} from '../../../../models/analytics/Analytics'

type Props = {
  object: ICommunicationAnalytics
}

const OneRowCommunications: FC<Props> = ({object}) => {
  return (
    <>
      <tr role='row' className='fs-7'>
        <th role='cell' className='w-200px table-fixed-th'>
          <UserName user={object.created_by} />
        </th>
        {object.communications_count !== 0 ? (
          <>
            <td role='cell' className='min-w-150px'>
              {object.department.name}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count_incoming}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count_outgoing}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count_score}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count_score_incoming}
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              {object.communications_count_score_outgoing}
            </td>
          </>
        ) : (
          <>
            <td role='cell' className='min-w-150px'></td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
            <td role='cell' className='min-w-100px px-0 text-center'>
              -
            </td>
          </>
        )}
      </tr>
    </>
  )
}

export {OneRowCommunications}
