import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type FormsFilter = {
  user_ids: {label: string; value: string}[]
  period: {start: string; end: string}
  form_ids: {label: string; value: string}[]
  score_types: string
}

type selectedFormsFilter = {
  formsFilter: FormsFilter
  setFormsFilter: (newObjFilter: FormsFilter) => void
}

const initialStateFormsFilter: FormsFilter = {
  user_ids: [],
  period: {start: '', end: ''},
  form_ids: [],
  score_types: '',
}

const initialStatePeriodsGetControlsCallback: selectedFormsFilter = {
  formsFilter: initialStateFormsFilter,
  setFormsFilter: () => {},
}

function getFormsFilter(key: string): FormsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as FormsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateFormsFilter
}

const usersObjectionFiltersContext = createContext<selectedFormsFilter>(
  initialStatePeriodsGetControlsCallback
)

const FormsProvider: FC<WithChildren> = ({children}) => {
  const [formsFilter, setFormsFilter] = useState<FormsFilter>(
    getFormsFilter(`${wl.hostname}_formsFilter`)
  )
  const setFilter = (formsFilter: FormsFilter) => {
    localStorage.setItem(`${wl.hostname}_formsFilter`, JSON.stringify(formsFilter))
    setFormsFilter(formsFilter)
  }

  return (
    <usersObjectionFiltersContext.Provider
      value={{formsFilter: formsFilter, setFormsFilter: setFilter}}
    >
      {children}
    </usersObjectionFiltersContext.Provider>
  )
}

const useFormsFilters = () => useContext(usersObjectionFiltersContext)
export {FormsProvider, useFormsFilters}
