import {useIntl} from 'react-intl'
import {FC, useEffect} from 'react'
import {
  selectReduserClear,
  selectReduserRemoveRow,
  selectReduserUpdateAll,
} from '../../../../../../redux/slices/serviceSlice'
import {OneCriteriaExclude} from './OneCriteriaExclude'
import {ExcludeCriteriaHeader} from './ExcludeCriteriaHeader'
import {ICriteria, IGroup, IScoreForms} from '../../../../../../models/score/Score'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {useQueryRequest} from '../../../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  object: {type: string; object: IGroup | IScoreForms}
  criteriaList: ICriteria[]
}

export const ExcludeCriteriaTable: FC<Props> = ({object, criteriaList}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {state} = useQueryRequest()
  const {selected} = useAppSelector((state) => state.serviceReduser)

  useEffect(() => {
    if (criteriaList) {
      dispatch(selectReduserClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaList])

  useEffect(() => {
    if (criteriaList) {
      if (criteriaList.length === 0) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
        return
      }
      const usersEvery = criteriaList.every((criteria: ICriteria) => selected.rows.has(criteria.id))
      const usersSome = criteriaList.some((criteria: ICriteria) => selected.rows.has(criteria.id))
      if (usersEvery) {
        if (selected.all.get(state.skip as number) !== undefined)
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'checked'}))
        if (selected.all.get(state.skip as number) === undefined) {
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'unChecked'}))
          criteriaList.map((criteria: ICriteria) =>
            dispatch(selectReduserRemoveRow({id: criteria.id}))
          )
        }
      } else if (usersSome) {
        if (selected.all.get(state.skip as number) !== 'indeterminate')
          dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      } else if (!usersSome) {
        dispatch(selectReduserUpdateAll({page: state.skip, selectAllMode: 'indeterminate'}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaList, selected])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ExcludeCriteriaHeader page={state.skip as number} />
        <tbody>
          {criteriaList &&
            criteriaList.length !== 0 &&
            criteriaList.map((c) => (
              <OneCriteriaExclude
                object={object}
                criteria={c}
                key={c.id}
                page={state.skip as number}
              />
            ))}
        </tbody>
      </table>
      {criteriaList.length === 0 && (
        <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
    </>
  )
}
