/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {
  getDepartmentsList,
  localeSettingsDepartmentsSelect,
} from '../../../../../../_metronic/helpers/components/GetDepartments'
import {departmentsAPI} from '../../../../../redux/services/DepartmentsService'
import {IGroup, IGroupCreate, createGroupInitialValues} from '../../../../../models/score/Score'

const groupUpdateShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  group: IGroup
  show: boolean
  handleClose: () => void
}

const UpdateGroupModal: FC<Props> = ({group, show, handleClose}) => {
  const intl = useIntl()
  const [departmentsOptions, setDepOptions] = useState<{label: string; value: string}[]>([])
  const [getDepartments, {data: departments, isLoading: getDepLoading}] =
    departmentsAPI.useLazyDepartmentsFetchQuery()

  let groupDepartments: {label: string; value: string}[] = []
  if (group.departments) {
    for (let i = 0; i < group.departments?.length; i++) {
      groupDepartments.push({label: group?.departments[i].name, value: group?.departments[i].id})
    }
  }

  const [selected, setSelected] = useState(groupDepartments)
  const [updateGroup, {isLoading, isError}] = scoreAPI.useGroupsUpdateMutation()

  const handleCloseModal = () => {
    formik.resetForm()
    setSelected(groupDepartments)
    handleClose()
  }

  const formik = useFormik({
    initialValues: Object.assign(createGroupInitialValues, group),
    validationSchema: groupUpdateShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        department_ids: getDepartmentsList(selected),
      }
      await updateGroup({id: group.id, data: data as IGroupCreate})
        .unwrap()
        .then((response) => {
          handleCloseModal()
        })
        .catch((error) => {
          console.log(error)
          formik.resetForm()
        })
    },
  })

  useEffect(() => {
    if (departments) {
      let depsOptions: {label: string; value: string}[] = []
      departments.departments.all.map((d) => depsOptions.push({label: d.name, value: d.id}))
      setDepOptions(depsOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'GROUPS.EDIT.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body className='px-7'>
            <div className='col mb-5'>
              <label className='col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'GROUPS.ADD.NAME'})}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GROUPS.ADD.NAME'})}
                type='text'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                  {
                    'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col'>
              <label className='col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'SCOREFORMS.DEPARTMENTS'})}
                </span>
              </label>
              <div
                onClick={() => {
                  if (!departments) {
                    getDepartments()
                  }
                }}
              >
                <MultiSelect
                  className='form-control-select form-control-select-lg text-gray-900'
                  options={departmentsOptions}
                  hasSelectAll={true}
                  value={selected}
                  onChange={setSelected}
                  overrideStrings={localeSettingsDepartmentsSelect(
                    intl.formatMessage,
                    getDepLoading
                  )}
                  closeOnChangedValue={false}
                  labelledBy={intl.formatMessage({id: 'MULTISELECT.DEPARTMENTS.SELECT'})}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {UpdateGroupModal}
