/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {RoleAccess} from '../../models/users/Users'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../redux/services/UsersService'
import {CreateControlModal} from './modals/CreateControlModal'
import {controlsAPI} from '../../redux/services/ControlsService'
import {useControlsFilter} from './helpers/ControlsFilterProvider'
import {IIntegration} from '../../models/integrations/Integrations'
import {ObjectsTable} from './components/controls-table/ObjectsTable'
import {departmentsAPI} from '../../redux/services/DepartmentsService'
import {integrationsAPI} from '../../redux/services/IntegrationsService'
import {getSelected} from '../../../_metronic/helpers/components/GetSelected'
import {getAccesses} from '../../../_metronic/helpers/components/RoleAccesses'
import {useQueryRequest} from '../../../_metronic/helpers/pagination/QueryRequestProvider'
import {DateRangePickerInput} from '../../../_metronic/helpers/components/DateRangePicker'

const ObjectsOfControl = () => {
  const intl = useIntl()
  const {controlsFilter, setControlsFilter} = useControlsFilter()
  const {state} = useQueryRequest()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const [getControls, {data: controls, isLoading}] = controlsAPI.useLazyControlsFetchQuery()
  const [sortState, setSortState] = useState<string>('created_on.desc')
  const [callTypeState, setCallTypeState] = useState<string>('')
  const [periodState, setPeriodState] = useState<{start: string; end: string}>({start: '', end: ''})
  const [ratingDateState, setRatingDateState] = useState<{start: string; end: string}>({
    start: '',
    end: '',
  })
  /*const [ratingState, setRatingState] = useState<{start: string; end: string}>({start: '', end: ''})*/
  const [durationState, setDurationState] = useState<{start: string; end: string}>({
    start: '',
    end: '',
  })
  const [statusState, setStatusState] = useState<string>('')
  const [objectTypeState, setObjectTypeState] = useState<string>('')
  const [typeState, setTypeState] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [selectedDepartments, setSelectedDepartments] = useState<{label: string; value: string}[]>(
    []
  )
  const [getDepartments, {data: departments, isLoading: getDepLoading}] =
    departmentsAPI.useLazyDepartmentsFetchQuery()
  const [getUsers, {data: users, isLoading: getUsersLoading}] = usersAPI.useLazyUsersFetchQuery()

  const [usersOptions, setUsersOptions] = useState<
    {label: string; value: string; disabled?: boolean}[]
  >([])
  const [departmentsOptions, setDepOptions] = useState<
    {label: string; value: string; disabled?: boolean}[]
  >([])

  const {data: integrations, isLoading: isLoadingIntegrations} =
    integrationsAPI.useIntegrationsFetchQuery()
  const [integrationsState, setIntegrationsState] = useState<IIntegration[]>([])
  const [showModalCreateControl, setShowModalCreateControl] = useState<boolean>(false)

  const handleCloseCreateControl = () => setShowModalCreateControl(false)
  const handleShowCreateControl = () => setShowModalCreateControl(true)

  const formatTime = (time: string) => {
    let inputTime = time
    let newTime = ''
    const alph = '0123456789'
    if (
      inputTime[inputTime.length - 1] !== '' &&
      alph.indexOf(inputTime[inputTime.length - 1]) === -1
    ) {
      inputTime = inputTime.substring(0, inputTime.length - 1)
    }
    if (inputTime.length < 2) {
      newTime = inputTime
    } else if (inputTime.length === 2 || inputTime.length === 5) {
      newTime = inputTime + ':'
    } else if (
      (inputTime.length > 2 && inputTime.length < 5) ||
      (inputTime.length > 5 && inputTime.length < 9)
    ) {
      newTime = inputTime
    } else if (inputTime.length > 8) {
      newTime = inputTime.substring(0, 8)
    }
    return newTime
  }

  /*const formatRating = (rating: string) => {
    let formatRating = rating
    const alph = '0123456789'
    if (
      (rating !== '' && alph.indexOf(rating[rating.length - 1]) === -1) ||
      rating.length > 3 ||
      parseInt(rating) > 100
    ) {
      formatRating = rating.substring(0, rating.length - 1)
    }
    return formatRating
  }*/

  /*const checkRating = () => {
    if (ratingState.start !== '' && ratingState.end !== '') {
      if (parseInt(ratingState.start) > parseInt(ratingState.end)) {
        setRatingState({
          start: ratingState.start,
          end: (parseInt(ratingState.start) + 1).toString(),
        })
      }
    }
  }*/

  const localeSettingsDepartmentsSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.DEPARTMENTS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.DEPARTMENTS.CLEARSELECTED'}),
      noOptions: getDepLoading
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.DEPARTMENTS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.DEPARTMENTS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.DEPARTMENTS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.DEPARTMENTS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: getUsersLoading
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  const getRating = (startRating: string, endRating: string) => {
    let rating = ''
    if (startRating !== '' && endRating !== '') {
      rating = `ge.${startRating},le.${endRating}`
    } else if (startRating !== '' && endRating === '') {
      rating = 'ge.' + startRating
    } else if (startRating === '' && endRating !== '') {
      rating = 'le.' + endRating
    }
    return rating
  }

  const setSortUsersOptions = () => {
    if (users) {
      let usersOptions: {label: string; value: string; disabled?: boolean}[] = []
      if (selectedDepartments.length === 0) {
        for (let i = 0; i < users.users.length; i++) {
          if (!usersOptions.find((el) => el.value === users.users[i].department.id)) {
            usersOptions.push({
              label: users.users[i].department.name,
              value: users.users[i].department.id,
              disabled: true,
            })
            usersOptions.push({label: users.users[i].name, value: users.users[i].id})
            for (let j = 0; j < users.users.length; j++) {
              if (
                users.users[j].department.id === users.users[i].department.id &&
                users.users[j].id !== users.users[i].id
              ) {
                usersOptions.push({label: users.users[j].name, value: users.users[j].id})
              }
            }
            usersOptions.push({label: '', value: '', disabled: true})
          }
        }
      } else {
        for (let i = 0; i < users.users.length; i++) {
          if (
            selectedDepartments.find((el) => users.users[i].department.id === el.value) &&
            !usersOptions.find((el) => el.value === users.users[i].department.id)
          ) {
            usersOptions.push({
              label: users.users[i].department.name,
              value: users.users[i].department.id,
              disabled: true,
            })
            usersOptions.push({label: users.users[i].name, value: users.users[i].id})
            for (let j = 0; j < users.users.length; j++) {
              if (
                users.users[j].department.id === users.users[i].department.id &&
                users.users[j].id !== users.users[i].id
              ) {
                usersOptions.push({label: users.users[j].name, value: users.users[j].id})
              }
            }
            usersOptions.push({label: '', value: '', disabled: true})
          }
        }
      }

      setUsersOptions(usersOptions)
    }
  }

  const checkUserInOptions = () => {
    if (usersOptions.length !== 0) {
      let new_users: {label: string; value: string}[] = []
      for (let j = 0; j < selectedUsers.length; j++) {
        let u_id = selectedUsers[j].value
        for (let i = 0; i < usersOptions.length; i++) {
          if (u_id === usersOptions[i].value) {
            new_users.push(selectedUsers[j])
          }
        }
      }
      setSelectedUsers(new_users)
    } else {
      setTimeout(checkUserInOptions, 1000)
    }
  }

  useEffect(() => {
    setSortUsersOptions()
    setControlsFilter({
      type: controlsFilter.type,
      sort_by: controlsFilter.sort_by,
      period: controlsFilter.period,
      users: controlsFilter.users,
      departments: selectedDepartments,
      duration: controlsFilter.duration,
      rating: controlsFilter.rating,
      rating_date: controlsFilter.rating_date,
      call_type: controlsFilter.call_type,
      status: controlsFilter.status,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartments])

  useEffect(() => {
    checkUserInOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersOptions])

  useEffect(() => {
    if (departments) {
      let depOptions: {label: string; value: string}[] = []
      departments.departments.all.map((d) => depOptions.push({label: d.name, value: d.id}))
      setDepOptions(depOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments])

  useEffect(() => {
    setSortUsersOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.department])

  useEffect(() => {
    if (roleAccesses.length !== 0 && currentUser) {
      if (controlsFilter) {
        setObjectTypeState(controlsFilter.type)
        setTypeState(controlsFilter.type)
        setSortState(controlsFilter.sort_by)
        setCallTypeState(controlsFilter.call_type)
        setStatusState(controlsFilter.status)
        setPeriodState(controlsFilter.period)
        setRatingDateState(controlsFilter.rating_date)
        /*setRatingState(controlsFilter.rating)*/
        setSelectedUsers(controlsFilter.users)
        setDurationState(controlsFilter.duration)

        if (roleAccesses.includes('CONTROLSDEP')) {
          setSelectedDepartments([
            {
              label: currentUser.department.name,
              value: currentUser.department.id,
            },
          ])
        } else if (roleAccesses.includes('CONTROLSOWN')) {
          setSelectedDepartments([
            {
              label: currentUser.department.name,
              value: currentUser.department.id,
            },
          ])
          setSelectedUsers([{label: currentUser.name, value: currentUser.id}])
        } else {
          setSelectedDepartments(controlsFilter.departments)
        }

        let data = {
          type: controlsFilter.type !== '' ? controlsFilter.type : undefined,
          sort_by: controlsFilter.sort_by,
          period:
            controlsFilter.period.start !== ''
              ? controlsFilter.period.start + ',' + controlsFilter.period.end
              : undefined,
          call_type: controlsFilter.call_type !== '' ? controlsFilter.call_type : undefined,
          rating_date:
            controlsFilter.rating_date.start !== ''
              ? controlsFilter.rating_date.start + ',' + controlsFilter.rating_date.end
              : undefined,
          user_ids: roleAccesses.includes('CONTROLSOWN')
            ? currentUser.id
            : controlsFilter.users.length !== 0
            ? getSelected(controlsFilter.users)
            : undefined,
          department_ids:
            roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN')
              ? currentUser.department.id
              : controlsFilter.departments.length !== 0
              ? getSelected(controlsFilter.departments)
              : undefined,
          rating:
            getRating(controlsFilter.rating.start, controlsFilter.rating.end) !== ''
              ? getRating(controlsFilter.rating.start, controlsFilter.rating.end)
              : undefined,
          duration:
            getRating(controlsFilter.duration.start, controlsFilter.duration.end) !== ''
              ? getRating(controlsFilter.duration.start, controlsFilter.duration.end)
              : undefined,
          status: controlsFilter.status !== '' ? controlsFilter.status : undefined,
          skip: state.skip,
          limit: state.limit,
        }
        getControls(data)
          .unwrap()
          .then((response) => {})
          .catch((error) => {
            console.log(error)
          })
      } else {
        let data = {
          sort_by: 'created_on.desc',
          user_ids: roleAccesses.includes('CONTROLSOWN') ? currentUser.id : undefined,
          department_ids:
            roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN')
              ? currentUser.department.id
              : undefined,

          skip: state.skip,
          limit: state.limit,
        }
        getControls(data)
          .unwrap()
          .then((response) => {})
          .catch((error) => {
            console.log(error)
          })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAccesses])

  useEffect(() => {
    if (roleAccesses.length !== 0) {
      getControls({
        type: controlsFilter.type !== '' ? controlsFilter.type : undefined,
        sort_by: controlsFilter.sort_by,
        period:
          controlsFilter.period.start !== ''
            ? controlsFilter.period.start + ',' + controlsFilter.period.end
            : undefined,
        call_type: controlsFilter.call_type !== '' ? controlsFilter.call_type : undefined,
        rating_date:
          controlsFilter.rating_date.start !== ''
            ? controlsFilter.rating_date.start + ',' + controlsFilter.rating_date.end
            : undefined,
        user_ids: controlsFilter.users.length !== 0 ? getSelected(controlsFilter.users) : undefined,
        department_ids:
          controlsFilter.departments.length !== 0
            ? getSelected(controlsFilter.departments)
            : undefined,
        rating:
          getRating(controlsFilter.rating.start, controlsFilter.rating.end) !== ''
            ? getRating(controlsFilter.rating.start, controlsFilter.rating.end)
            : undefined,
        duration:
          getRating(controlsFilter.duration.start, controlsFilter.duration.end) !== ''
            ? getRating(controlsFilter.duration.start, controlsFilter.duration.end)
            : undefined,
        status: controlsFilter.status !== '' ? controlsFilter.status : undefined,
        skip: state.skip,
        limit: state.limit,
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.skip, state.limit])

  useEffect(() => {
    setControlsFilter({
      type: controlsFilter.type,
      sort_by: controlsFilter.sort_by,
      period: periodState,
      users: controlsFilter.users,
      departments: controlsFilter.departments,
      duration: controlsFilter.duration,
      rating: controlsFilter.rating,
      rating_date: controlsFilter.rating_date,
      call_type: controlsFilter.call_type,
      status: controlsFilter.status,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    setControlsFilter({
      type: controlsFilter.type,
      sort_by: controlsFilter.sort_by,
      period: controlsFilter.period,
      users: controlsFilter.users,
      departments: controlsFilter.departments,
      duration: controlsFilter.duration,
      rating: controlsFilter.rating,
      rating_date: ratingDateState,
      call_type: controlsFilter.call_type,
      status: controlsFilter.status,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingDateState])

  useEffect(() => {
    setControlsFilter({
      type: controlsFilter.type,
      sort_by: controlsFilter.sort_by,
      period: controlsFilter.period,
      users: selectedUsers,
      departments: controlsFilter.departments,
      duration: controlsFilter.duration,
      rating: controlsFilter.rating,
      rating_date: controlsFilter.rating_date,
      call_type: controlsFilter.call_type,
      status: controlsFilter.status,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  useEffect(() => {
    if (integrations?.integrations.length !== 0) {
      let sortedIntegrations: IIntegration[] = []
      integrations?.integrations.forEach((i) => {
        if (i.i_type === 'MANUAL' && i.is_active === true) {
          sortedIntegrations.push(i)
        }
      })
      setIntegrationsState(sortedIntegrations)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations])

  useEffect(() => {
    if (objectTypeState !== 'CALL') {
      if (durationState.start !== '' || durationState.end !== '') {
        setDurationState({start: '', end: ''})
      }
      if (callTypeState !== '') {
        setCallTypeState('')
      }
      if (
        controlsFilter.duration.start !== '' ||
        controlsFilter.duration.end !== '' ||
        controlsFilter.call_type !== ''
      ) {
        setControlsFilter({
          type: controlsFilter.type,
          sort_by: controlsFilter.sort_by,
          period: controlsFilter.period,
          users: controlsFilter.users,
          departments: controlsFilter.departments,
          duration: {start: '', end: ''},
          rating: controlsFilter.rating,
          rating_date: controlsFilter.rating_date,
          call_type: '',
          status: controlsFilter.status,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectTypeState])

  return (
    <>
      <div className='card card-custom card-stretch shadow mb-5'>
        <div className='accordion accordion-icon-toggle' id='AccordionControlsFilters'>
          <div className='card-header ps-3'>
            <div
              className={clsx('accordion-header card-title m-0 d-flex flex-wrap', {
                collapsed: false,
              })}
              data-bs-toggle='collapse'
              data-bs-target='#AccordionFilters'
            >
              <span className='accordion-icon me-1'>
                <KTSVG
                  className='svg-icon svg-icon-4'
                  path='/media/icons/duotune/arrows/arr064.svg'
                />
              </span>

              <h3 className='fw-bolder m-0 me-2'>{intl.formatMessage({id: 'FILTERS'})}</h3>
            </div>
            <div className='card-toolbar'>
              <div className='d-flex'>
                <button
                  className='btn btn-primary btn-sm me-2'
                  type='button'
                  onClick={async () => {
                    /*checkRating()*/
                    setTypeState(objectTypeState)
                    getControls({
                      type: objectTypeState !== '' ? objectTypeState : undefined,
                      sort_by: sortState,
                      period:
                        periodState.start !== ''
                          ? periodState.start + ',' + periodState.end
                          : undefined,
                      call_type: callTypeState !== '' ? callTypeState : undefined,
                      rating_date:
                        ratingDateState.start !== ''
                          ? ratingDateState.start + ',' + ratingDateState.end
                          : undefined,
                      user_ids: roleAccesses.includes('CONTROLSOWN')
                        ? currentUser?.id
                        : selectedUsers.length !== 0
                        ? getSelected(selectedUsers)
                        : undefined,
                      department_ids:
                        roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP')
                          ? currentUser?.department.id
                          : selectedDepartments.length !== 0
                          ? getSelected(selectedDepartments)
                          : undefined,
                      /*rating:
                        getRating(ratingState.start, ratingState.end) !== ''
                          ? getRating(ratingState.start, ratingState.end)
                          : undefined,*/
                      duration:
                        getRating(durationState.start, durationState.end) !== ''
                          ? getRating(durationState.start, durationState.end)
                          : undefined,
                      status: statusState !== '' ? statusState : undefined,
                      skip: state.skip,
                      limit: state.limit,
                    })
                      .unwrap()
                      .then((response) => {})
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                  disabled={isLoading}
                >
                  {!isLoading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'FILTERS.BUTTON.APPLY'})}
                    </span>
                  )}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-light-primary btn-sm'
                  type='button'
                  onClick={async () => {
                    /*checkRating()*/
                    setPeriodState({start: '', end: ''})
                    setCallTypeState('')
                    setRatingDateState({start: '', end: ''})
                    /*setRatingState({start: '', end: ''})*/
                    setDurationState({start: '', end: ''})
                    setStatusState('')
                    setObjectTypeState('')

                    if (roleAccesses.includes('CONTROLSALL')) {
                      setSelectedUsers([])
                      setSelectedDepartments([])
                    } else if (roleAccesses.includes('CONTROLSDEP')) {
                      setSelectedUsers([])
                    }

                    setControlsFilter({
                      type: '',
                      sort_by: controlsFilter.sort_by,
                      period: {start: '', end: ''},
                      users: roleAccesses.includes('CONTROLSOWN')
                        ? [
                            {
                              label: currentUser ? currentUser.name : '',
                              value: currentUser ? currentUser.id : '',
                            },
                          ]
                        : [],
                      departments:
                        roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN')
                          ? [
                              {
                                label: currentUser ? currentUser.department.name : '',
                                value: currentUser ? currentUser.department.id : '',
                              },
                            ]
                          : [],
                      duration: {start: '', end: ''},
                      rating: {start: '', end: ''},
                      rating_date: {start: '', end: ''},
                      status: '',
                      call_type: '',
                    })

                    getControls({
                      sort_by: sortState,
                      user_ids: roleAccesses.includes('CONTROLSOWN') ? currentUser?.id : undefined,
                      department_ids:
                        roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP')
                          ? currentUser?.department.id
                          : undefined,
                      skip: state.skip,
                      limit: state.limit,
                    })
                      .unwrap()
                      .then((response) => {})
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                  disabled={isLoading}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FILTERS.BUTTON.RESET'})}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id='AccordionFilters'
          className={clsx('fs-6 ps-10', {
            collapse: false,
            show: true,
          })}
          data-bs-parent='AccordionControlsFilters'
        >
          <div className='card-body d-flex px-6'>
            <div className='col-lg-4 me-10'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.PERIOD'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <DateRangePickerInput
                    language={currentUser?.language}
                    timeZone={currentUser?.time_zone}
                    rangeId='1'
                    periodState={periodState}
                    updateState={setPeriodState}
                    onlyIcon={false}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.DIRECTION'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900'
                    value={callTypeState}
                    disabled={objectTypeState !== 'CALL'}
                    onChange={(e: any) => {
                      setCallTypeState(e.target.value)
                      setControlsFilter({
                        type: controlsFilter.type,
                        sort_by: controlsFilter.sort_by,
                        period: controlsFilter.period,
                        users: controlsFilter.users,
                        departments: controlsFilter.departments,
                        duration: controlsFilter.duration,
                        rating: controlsFilter.rating,
                        rating_date: controlsFilter.rating_date,
                        call_type: e.target.value,
                        status: controlsFilter.status,
                      })
                    }}
                  >
                    <option value=''>{intl.formatMessage({id: 'FILTERS.DIRECTION.ALL'})}</option>
                    <option value='INCOMING'>
                      {intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}
                    </option>
                    <option value='OUTGOING'>
                      {intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}
                    </option>
                  </select>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.RAITINGDATE'})}
                  </span>
                </label>
                <div
                  className='col-lg-8 fv-row'
                  style={statusState === 'WITHOUT_RAITING' ? {pointerEvents: 'none'} : undefined}
                >
                  <DateRangePickerInput
                    language={currentUser?.language}
                    timeZone={currentUser?.time_zone}
                    rangeId='2'
                    periodState={ratingDateState}
                    updateState={setRatingDateState}
                    onlyIcon={false}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-4 me-10'>
              <div
                className={clsx('row', {
                  'mb-7': roleAccesses.includes('CONTROLSOWN'),
                  'mb-6': !roleAccesses.includes('CONTROLSOWN'),
                })}
              >
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
                  </span>
                </label>
                <div
                  className={clsx('col-lg-8 fv-row', {
                    'd-flex align-items-center': roleAccesses.includes('CONTROLSOWN'),
                  })}
                  style={getUsersLoading ? {pointerEvents: 'none'} : undefined}
                  onClick={() => {
                    if (usersOptions.length === 0) {
                      getUsers({skip: 0, is_active: true})
                    }
                    setSortUsersOptions()
                  }}
                >
                  {roleAccesses.includes('CONTROLSOWN') ? (
                    <label className='text-gray-900'>{currentUser?.name}</label>
                  ) : (
                    <MultiSelect
                      className='form-control-select form-control-select-lg text-gray-900'
                      options={usersOptions}
                      hasSelectAll={false}
                      value={selectedUsers}
                      onChange={setSelectedUsers}
                      overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                      closeOnChangedValue={false}
                      labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
                    />
                  )}
                </div>
              </div>

              <div
                className={clsx('row', {
                  'mb-7':
                    roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP'),
                  'mb-6':
                    !roleAccesses.includes('CONTROLSOWN') && !roleAccesses.includes('CONTROLSDEP'),
                })}
              >
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'USERS.TABLE.HEADER.DEPARTMENT'})}
                  </span>
                </label>
                <div
                  className={clsx('col-lg-8 fv-row', {
                    'd-flex align-items-center':
                      roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP'),
                  })}
                  onClick={() => {
                    if (departmentsOptions.length === 0) {
                      getDepartments()
                    }
                  }}
                  style={getDepLoading ? {pointerEvents: 'none'} : undefined}
                >
                  {roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN') ? (
                    <label className='text-gray-900'>{currentUser?.department.name}</label>
                  ) : (
                    <MultiSelect
                      className='form-control-select form-control-select-lg text-gray-900'
                      options={departmentsOptions}
                      hasSelectAll={false}
                      value={selectedDepartments}
                      onChange={setSelectedDepartments}
                      overrideStrings={localeSettingsDepartmentsSelect(intl.formatMessage)}
                      closeOnChangedValue={false}
                      labelledBy={intl.formatMessage({id: 'MULTISELECT.DEPARTMENTS.SELECT'})}
                    />
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.TYPE'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900'
                    value={objectTypeState}
                    onChange={(e: any) => {
                      setObjectTypeState(e.target.value)
                      setControlsFilter({
                        type: e.target.value,
                        sort_by: controlsFilter.sort_by,
                        period: controlsFilter.period,
                        users: controlsFilter.users,
                        departments: controlsFilter.departments,
                        duration: controlsFilter.duration,
                        rating: controlsFilter.rating,
                        rating_date: controlsFilter.rating_date,
                        call_type: controlsFilter.call_type,
                        status: controlsFilter.status,
                      })
                    }}
                  >
                    <option value=''>{intl.formatMessage({id: 'FILTERS.DIRECTION.ALL'})}</option>
                    <option value='CALL'>{intl.formatMessage({id: 'OBJECTS.TABLE.TITLE'})}</option>
                    <option value='MEETING'>
                      {intl.formatMessage({id: 'FILTERS.TYPE.MEETING'})}
                    </option>
                  </select>
                </div>
              </div>

              {/*<div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.RAITING'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <div className='d-flex'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid  text-gray-900'
                      placeholder='0'
                      disabled={statusState === 'without_raiting'}
                      value={ratingState.start}
                      onInput={(e: any) => {
                        e.target.value = formatRating(e.target.value)
                      }}
                      onChange={(e: any) => {
                        setRatingState({start: e.target.value, end: ratingState.end})
                        setControlsFilter({
                          sort_by: controlsFilter.sort_by,
                          period: controlsFilter.period,
                          users: controlsFilter.users,
                          departments: controlsFilter.departments,
                          duration: controlsFilter.duration,
                          rating: {
                            start: e.target.value,
                            end: controlsFilter.rating.end,
                          },
                          rating_date: controlsFilter.rating_date,
                          call_type: controlsFilter.call_type,
                          status: controlsFilter.status,
                        })
                      }}
                    />
                    <label className='align-self-center mx-2'>—</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid text-gray-900'
                      placeholder='10'
                      disabled={statusState === 'without_raiting'}
                      value={ratingState.end}
                      onInput={(e: any) => {
                        e.target.value = formatRating(e.target.value)
                      }}
                      onChange={(e: any) => {
                        setRatingState({start: ratingState.start, end: e.target.value})
                        setControlsFilter({
                          sort_by: controlsFilter.sort_by,
                          period: controlsFilter.period,
                          users: controlsFilter.users,
                          departments: controlsFilter.departments,
                          duration: controlsFilter.duration,
                          rating: {
                            start: controlsFilter.rating.start,
                            end: e.target.value,
                          },
                          rating_date: controlsFilter.rating_date,
                          call_type: controlsFilter.call_type,
                          status: controlsFilter.status,
                        })
                      }}
                    />
                  </div>
                </div>
                    </div>*/}
            </div>
            <div className='col-lg-4'>
              <div className='col mb-6'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.TIME'})}
                  </span>
                </label>
                <div className='col-10 fv-row'>
                  <div className='d-flex'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid  text-gray-900'
                      placeholder='00:01:20'
                      disabled={objectTypeState !== 'CALL'}
                      value={durationState.start}
                      onChange={(e: any) => {
                        setDurationState({start: e.target.value, end: durationState.end})
                        setControlsFilter({
                          type: controlsFilter.type,
                          sort_by: controlsFilter.sort_by,
                          period: controlsFilter.period,
                          users: controlsFilter.users,
                          departments: controlsFilter.departments,
                          duration: {
                            start: e.target.value,
                            end: controlsFilter.duration.end,
                          },
                          rating: controlsFilter.rating,
                          rating_date: controlsFilter.rating_date,
                          call_type: controlsFilter.call_type,
                          status: controlsFilter.status,
                        })
                      }}
                      onInput={(e: any) => {
                        e.target.value = formatTime(e.target.value)
                      }}
                    />
                    <label className='align-self-center mx-2'>—</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid text-gray-900'
                      placeholder='01:45:25'
                      disabled={objectTypeState !== 'CALL'}
                      value={durationState.end}
                      onChange={(e: any) => {
                        setDurationState({start: durationState.start, end: e.target.value})
                        setControlsFilter({
                          type: controlsFilter.type,
                          sort_by: controlsFilter.sort_by,
                          period: controlsFilter.period,
                          users: controlsFilter.users,
                          departments: controlsFilter.departments,
                          duration: {
                            start: controlsFilter.duration.start,
                            end: e.target.value,
                          },
                          rating: controlsFilter.rating,
                          rating_date: controlsFilter.rating_date,
                          call_type: controlsFilter.call_type,
                          status: controlsFilter.status,
                        })
                      }}
                      onInput={(e: any) => {
                        e.target.value = formatTime(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='col mb-6'>
                <label className='col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'USERS.TABLE.HEADER.STATUS'})}
                  </span>
                </label>
                <div className='d-flex mb-6'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='WithRaiting'
                      checked={statusState === 'WITH_RAITING'}
                      onChange={(e: any) => {
                        if (e.target.checked === true) {
                          setStatusState('WITH_RAITING')
                          setControlsFilter({
                            type: controlsFilter.type,
                            sort_by: controlsFilter.sort_by,
                            period: controlsFilter.period,
                            users: controlsFilter.users,
                            departments: controlsFilter.departments,
                            duration: controlsFilter.duration,
                            rating: controlsFilter.rating,
                            rating_date: controlsFilter.rating_date,
                            call_type: controlsFilter.call_type,
                            status: 'WITH_RAITING',
                          })
                        }
                      }}
                      name='raitingRadio'
                      id='WithRaitingRadio'
                    />
                    <label className='form-check-label' htmlFor='WithRaitingRadio'>
                      {intl.formatMessage({id: 'FILTERS.WITHRAITING'})}
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='WithoutRaiting'
                      checked={statusState === 'WITHOUT_RAITING'}
                      onChange={(e: any) => {
                        if (e.target.checked === true) {
                          setStatusState('WITHOUT_RAITING')
                          setRatingDateState({start: '', end: ''})
                          /*setRatingState({start: '', end: ''})*/
                          setControlsFilter({
                            type: controlsFilter.type,
                            sort_by: controlsFilter.sort_by,
                            period: controlsFilter.period,
                            users: controlsFilter.users,
                            departments: controlsFilter.departments,
                            duration: controlsFilter.duration,
                            rating: controlsFilter.rating,
                            rating_date: controlsFilter.rating_date,
                            call_type: controlsFilter.call_type,
                            status: 'WITHOUT_RAITING',
                          })
                        }
                      }}
                      name='raitingRadio'
                      id='WithoutRaitingRadio'
                    />
                    <label className='form-check-label' htmlFor='WithoutRaitingRadio'>
                      {intl.formatMessage({id: 'FILTERS.WITHOUTRAITING'})}
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='AllRaiting'
                      checked={statusState === ''}
                      onChange={(e: any) => {
                        if (e.target.checked === true) {
                          setStatusState('')
                          setControlsFilter({
                            type: controlsFilter.type,
                            sort_by: controlsFilter.sort_by,
                            period: controlsFilter.period,
                            users: controlsFilter.users,
                            departments: controlsFilter.departments,
                            duration: controlsFilter.duration,
                            rating: controlsFilter.rating,
                            rating_date: controlsFilter.rating_date,
                            call_type: controlsFilter.call_type,
                            status: '',
                          })
                        }
                      }}
                      name='raitingRadio'
                      id='AllRaitingRadio'
                    />
                    <label className='form-check-label' htmlFor='AllRaitingRadio'>
                      {intl.formatMessage({id: 'FILTERS.DIRECTION.ALL'})}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom card-stretch shadow mb-5'>
        <div className='card-header'>
          <div className='d-flex flex-stack col-12 me-10'>
            <div className='card-title m-0 d-flex flex-wrap'>
              <h3 className='fw-bolder m-0'>
                {intl.formatMessage({id: 'COMMUNICATIONS.TABLE.TITLE'})}
              </h3>
            </div>
            <div className='align-self-center d-flex flex-end col-lg-6'>
              {integrationsState.length !== 0 && (
                <div className='me-3'>
                  <button
                    className='btn btn-primary btn-sm me-2'
                    type='button'
                    onClick={handleShowCreateControl}
                    disabled={isLoadingIntegrations}
                  >
                    {!isLoading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'CONTROLS.ADD.BUTTON'})}
                      </span>
                    )}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <CreateControlModal
                    integrations={integrationsState}
                    show={showModalCreateControl}
                    handleClose={handleCloseCreateControl}
                  />
                </div>
              )}

              <div className='align-self-center d-flex text-align-end'>
                <span className=' me-3 text-gray-600 fw-bold fs-7 text-uppercase small align-self-center'>
                  {intl.formatMessage({id: 'OBJECTS.TABLE.SORT'})}:
                </span>
                <select
                  className='form-select form-select-solid form-select-sm text-gray-900'
                  value={sortState}
                  onChange={async (e: any) => {
                    /*checkRating()*/
                    setControlsFilter({
                      type: controlsFilter.type,
                      sort_by: e.target.value,
                      period: controlsFilter.period,
                      users: controlsFilter.users,
                      departments: controlsFilter.departments,
                      duration: controlsFilter.duration,
                      rating: controlsFilter.rating,
                      rating_date: controlsFilter.rating_date,
                      call_type: controlsFilter.call_type,
                      status: controlsFilter.status,
                    })
                    getControls({
                      type: controlsFilter.type !== '' ? controlsFilter.type : undefined,
                      sort_by: e.target.value,
                      period:
                        controlsFilter.period.start !== ''
                          ? controlsFilter.period.start + ',' + controlsFilter.period.end
                          : undefined,
                      call_type:
                        controlsFilter.call_type !== '' ? controlsFilter.call_type : undefined,
                      rating_date:
                        controlsFilter.rating_date.start !== ''
                          ? controlsFilter.rating_date.start + ',' + controlsFilter.rating_date.end
                          : undefined,
                      user_ids:
                        controlsFilter.users.length !== 0
                          ? getSelected(controlsFilter.users)
                          : undefined,
                      department_ids:
                        controlsFilter.departments.length !== 0
                          ? getSelected(controlsFilter.departments)
                          : undefined,
                      rating:
                        getRating(controlsFilter.rating.start, controlsFilter.rating.end) !== ''
                          ? getRating(controlsFilter.rating.start, controlsFilter.rating.end)
                          : undefined,
                      duration:
                        getRating(controlsFilter.duration.start, controlsFilter.duration.end) !== ''
                          ? getRating(controlsFilter.duration.start, controlsFilter.duration.end)
                          : undefined,
                      status: controlsFilter.status !== '' ? controlsFilter.status : undefined,
                      skip: state.skip,
                      limit: state.limit,
                    })
                      .unwrap()
                      .then(() => {
                        setTypeState(objectTypeState)
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                >
                  <option value='created_on.desc'>
                    {intl.formatMessage({id: 'OBJECTS.TABLE.SORT.NEW'})}
                  </option>
                  <option value='created_on.asc'>
                    {intl.formatMessage({id: 'OBJECTS.TABLE.SORT.OLD'})}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body p-1'>
          <div className='table-responsive px-15'>
            <ObjectsTable controls={controls} type={typeState !== '' ? typeState : undefined} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ObjectsOfControl
