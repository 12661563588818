import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {IGroup} from '../../../../models/score/Score'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UpdateGroupModal} from './modals/UpdateGroupModal'
import {DeleteGroupModal} from './modals/DeleteGroupModal'
import {RoleAccess} from '../../../../models/users/Users'
import {usersAPI} from '../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../redux/services/ScoreService'
import {AddCriteriaModal} from '../criteria/modals/AddCriteriaModal'
import {FC, ReactNode, forwardRef, useEffect, useState} from 'react'
import {CriteriaGroupTable} from './criteria-group-table/CriteriaGroupTable'
import {ExcludeCriteriaModal} from '../criteria/modals/ExcludeCriteriaModal'
import {getAccesses} from '../../../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../../../_metronic/helpers/FormatDateZoneHelpers'
import {getDepartments} from '../../../../../_metronic/helpers/components/GetDepartments'
import {QueryRequestProvider} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  group: IGroup
  colapsed: boolean
}

interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}

export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const OneGroup: FC<Props> = ({group, colapsed}) => {
  const intl = useIntl()
  const [getGruopInfo, {data: groupInfo, isLoading: isLoadingGetCriterions}] =
    scoreAPI.useLazyGroupsByIdQuery({})
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const object = {type: 'group', object: groupInfo ? groupInfo : group}
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  const [showModalEditGroup, setShowModalEditGroup] = useState<boolean>(false)
  const [showModalDeleteGroup, setShowModalDeleteGroup] = useState<boolean>(false)
  const [showModalDeleteCriteria, setShowModalDeleteCriteria] = useState<boolean>(false)
  const [showModalAddCriteria, setShowModalAddCriteria] = useState<boolean>(false)

  const handleCloseEditGroup = () => setShowModalEditGroup(false)
  const handleShowEditGroup = () => setShowModalEditGroup(true)

  const handleCloseDeleteGroup = () => setShowModalDeleteGroup(false)
  const handleShowDeleteGroup = () => setShowModalDeleteGroup(true)

  const handleCloseDeleteCriteria = () => setShowModalDeleteCriteria(false)
  const handleShowDeleteCriteria = () => setShowModalDeleteCriteria(true)

  const handleCloseAddCriteria = () => setShowModalAddCriteria(false)
  const handleShowAddCriteria = () => setShowModalAddCriteria(true)

  useEffect(() => {
    if (!colapsed) {
      getGruopInfo({id: group.id})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colapsed])

  useEffect(() => {
    if (!colapsed && group) {
      getGruopInfo({id: group.id})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <div className='card mb-5'>
        <div className='accordion accordion-icon-toggle' id={'AccordionGroup' + group.id}>
          <div className='mb-5'>
            <div className='card-header'>
              <div
                className={clsx('accordion-header py-3 d-flex align-self-center col-3', {
                  collapsed: colapsed,
                })}
                data-bs-toggle='collapse'
                data-bs-target={'#AccordionGroupItem' + group.id}
                onClick={() => {
                  if (!groupInfo) {
                    getGruopInfo({id: group.id})
                  }
                }}
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='/media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>{group.name}</h3>
              </div>
              <div className='d-flex text-gray-800 small fw-bold fs-7 align-self-center col-8 ps-5'>
                <span className='col-5 ps-2'>{group.created_by.name}</span>
                <span className='col-4'>
                  {formatDateByZone(
                    group.created_on!,
                    currentUser?.language,
                    currentUser?.time_zone
                  )}
                </span>
                <span className='col-3' title={getDepartments(group)[1]}>
                  {getDepartments(group)[0]}
                </span>
              </div>

              <div
                className='d-flex flex-end align-self-center col-1'
                onClick={() => {
                  if (!groupInfo) {
                    getGruopInfo({id: group.id})
                  }
                }}
              >
                {roleAccesses.includes('GROUPSMODIFY') && (
                  <>
                    <button
                      className='btn btn-icon btn-sm btn-secondary w-25px h-25px me-2'
                      title={intl.formatMessage({id: 'ADD.CRITERIA'})}
                      onClick={handleShowAddCriteria}
                    >
                      <span className='svg-icon svg-icon-3'>
                        <KTSVG path='/media/icons/duotune/arrows/arr013.svg' />
                      </span>
                    </button>
                    <AddCriteriaModal
                      object={object}
                      show={showModalAddCriteria}
                      handleClose={handleCloseAddCriteria}
                    />

                    <Dropdown drop='down' align='end'>
                      <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3'
                          onClick={handleShowEditGroup}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3'
                          onClick={handleShowDeleteGroup}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'GROUPS.DELETE'})}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3'
                          onClick={handleShowDeleteCriteria}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'GROUPS.DELETE.CRITERIA'})}
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <UpdateGroupModal
                      group={group}
                      show={showModalEditGroup}
                      handleClose={handleCloseEditGroup}
                    />
                    <DeleteGroupModal
                      group={group}
                      show={showModalDeleteGroup}
                      handleClose={handleCloseDeleteGroup}
                    />
                    <QueryRequestProvider>
                      <ExcludeCriteriaModal
                        object={object}
                        criterions={groupInfo && groupInfo?.criterions ? groupInfo.criterions : []}
                        show={showModalDeleteCriteria}
                        handleClose={handleCloseDeleteCriteria}
                      />
                    </QueryRequestProvider>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            id={'AccordionGroupItem' + group.id}
            className={clsx('fs-6 ps-10', {
              collapse: colapsed,
              show: !colapsed,
            })}
            data-bs-parent={'#AccordionGroup' + group.id}
          >
            <div className='card-body'>
              <div className='table-responsive overflow-visible'>
                <CriteriaGroupTable
                  isLoadingGetCriterions={isLoadingGetCriterions}
                  groupId={group.id}
                  criteriaList={groupInfo?.criterions ? groupInfo.criterions : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OneGroup
