import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  IDeleteDepartment,
  IDepartment,
  IDepartmentAddUsers,
  IDepartmentsFetch,
  IDepartmentsList,
  IMutationDepartment,
} from '../../models'

export const departmentsAPI = createApi({
  reducerPath: 'departmentsAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['departments', 'departmentsList'],
  endpoints: (build) => ({
    departmentsFetch: build.query<IDepartmentsFetch, void>({
      query: () => ({
        url: '/departments',
      }),
      transformResponse: (response: IDepartmentsList) => ({
        departments: {
          main: response.departments.filter((d) => {
            return d && d.is_main
          })[0],
          list: response.departments.filter((d) => {
            return d && !d.is_main
          }),
          all: response.departments,
        },
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.departments.all.map(({id}) => ({type: 'departmentsList' as const, id})),
              {type: 'departmentsList', id: 'LIST'},
            ]
          : [{type: 'departmentsList', id: 'LIST'}],
    }),
    departmentById: build.query<
      IDepartment,
      {id: string; _with?: 'users'; skip?: number; limit?: number; search?: any}
    >({
      query: ({id, _with, skip, limit, search}) => ({
        url: `/departments/${id}`,
        params: {_with: _with, skip: skip, limit: limit, search: search},
      }),
      providesTags: ['departments'],
    }),
    departmentCreate: build.mutation<IDepartment, IMutationDepartment>({
      query: (data) => ({
        url: '/departments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['departmentsList'],
    }),
    departmentAddUsers: build.mutation<void, {id: string; data: IDepartmentAddUsers}>({
      query: ({id, data}) => ({
        url: `/departments/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['departments'],
    }),
    departmentUpdate: build.mutation<IDepartment, {id: string; data: IMutationDepartment}>({
      query: ({id, data}) => ({
        url: `/departments/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (response) =>
        response
          ? [{type: 'departmentsList', id: response?.id}]
          : [{type: 'departmentsList', id: 'LIST'}],
    }),
    departmentDelete: build.mutation<void, {id: string; data?: IDeleteDepartment}>({
      query: ({id, data}) => ({
        url: `/departments/${id}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['departmentsList', 'departments'],
    }),
  }),
})
