import {FC} from 'react'
import {UserName} from '../cells/UserName'
import {IUserObjectionsPeriod} from '../../../../models/analytics/Analytics'

type Props = {
  object: IUserObjectionsPeriod
  periodsNumber: number
}

const OneRowObjectionsUser: FC<Props> = ({object, periodsNumber}) => {
  return (
    <>
      <tr role='row' className='fs-7'>
        <th role='cell' className='w-200px table-fixed-th'>
          <UserName user={object.score_objection} />
        </th>
        {Object.keys(object.score).length !== 0
          ? Object.keys(object.score).map((key) => (
              <td role='cell' className='min-w-200px px-0 text-center' key={key}>
                {object.score[key].objections_average ? (
                  <>
                    {object.score[key].objections_average} ({object.score[key].objections_count})
                  </>
                ) : (
                  '-'
                )}
              </td>
            ))
          : Array.from({length: periodsNumber}).map((_, index) => (
              <td role='cell' className='min-w-200px px-0 text-center' key={index}>
                -
              </td>
            ))}
      </tr>
    </>
  )
}

export {OneRowObjectionsUser}
