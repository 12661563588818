import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../../_metronic/helpers'

const wl = window.location

export type ControlsFilter = {
  type: string
  period: {start: string; end: string}
  users: {label: string; value: string}[]
  department_id: string
  call_type: string
}

type selectedControlsFilterCallback = {
  controlsFilter: ControlsFilter
  setControlsFilter: (newFilter: ControlsFilter) => void
}

const initialStateControlsFilter: ControlsFilter = {
  type: '',
  period: {
    start: '',
    end: '',
  },
  users: [],
  department_id: '',
  call_type: '',
}

const initialStateControlsFilterCallback: selectedControlsFilterCallback = {
  controlsFilter: initialStateControlsFilter,
  setControlsFilter: () => {},
}

function getFilter(key: string): ControlsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as ControlsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateControlsFilter
}

const controlsFilterContext = createContext<selectedControlsFilterCallback>(
  initialStateControlsFilterCallback
)

const ControlsCriteriaFilterProvider: FC<WithChildren> = ({children}) => {
  const [controlsFilter, setControlsFilter] = useState<ControlsFilter>(
    getFilter(`${wl.hostname}_controlsCriteriaFilter`)
  )
  const setFilter = (filter: ControlsFilter) => {
    localStorage.setItem(`${wl.hostname}_controlsCriteriaFilter`, JSON.stringify(filter))
    setControlsFilter(filter)
  }

  return (
    <controlsFilterContext.Provider
      value={{controlsFilter: controlsFilter, setControlsFilter: setFilter}}
    >
      {children}
    </controlsFilterContext.Provider>
  )
}

const useControlsCriteriaFilter = () => useContext(controlsFilterContext)
export {ControlsCriteriaFilterProvider, useControlsCriteriaFilter}
