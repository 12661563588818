import {FC, useEffect, useState} from 'react'
import {
  selectReduserAddRow,
  selectReduserRemoveRow,
} from '../../../../../../redux/slices/serviceSlice'
import {ObjectionName} from './cell/ObjectionNameCell'
import {RoleAccess} from '../../../../../../models/users/Users'
import {IScoreObjection} from '../../../../../../models/score/Score'
import {usersAPI} from '../../../../../../redux/services/UsersService'
import {useAppDispatch, useAppSelector} from '../../../../../../redux/hooks/redux'
import {getAccesses} from '../../../../../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  objection: IScoreObjection
  page: number
}

const OneGroupObjection: FC<Props> = ({objection, page}) => {
  const dispatch = useAppDispatch()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {selected} = useAppSelector((state) => state.serviceReduser)
  const [checked, setChecked] = useState<boolean>(false)
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  const handleCheck = (isSelected: boolean) => {
    setChecked(isSelected)
    if (isSelected) {
      if (!selected.rows.get(objection.id)) {
        dispatch(selectReduserAddRow({page: page, id: objection.id}))
      }
    } else {
      if (selected.rows.get(objection.id)) {
        dispatch(selectReduserRemoveRow({page: page, id: objection.id}))
      }
    }
  }

  useEffect(() => {
    if (selected.rows.get(objection.id)) {
      handleCheck(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objection])

  useEffect(() => {
    if (selected.all.get(page) === 'checked') {
      handleCheck(true)
    } else if (selected.all.get(page) === 'unChecked') {
      handleCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.all])

  return (
    <>
      <tr role='row' className='fs-7'>
        {roleAccesses.includes('ORGANIZATIONMODIFY') && (
          <td role='cell' className='min-w-50px px-0'>
            <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
              <input
                className='form-check-input'
                type='checkbox'
                checked={checked}
                onChange={(e) => handleCheck(e.target.checked)}
              />
            </div>
          </td>
        )}

        <td role='cell' className='min-w-200px p-3'>
          <ObjectionName objection={objection} />
        </td>
        <td role='cell' className='min-w-200px py-3 px-0'>
          <label className='fw-bold text-dark'>{objection.created_by.name}</label>
        </td>
        <td role='cell' className='min-w-200px py-3 px-0'>
          <span className='fw-bold text-uppercase small'>
            {formatDateByZone(
              objection?.created_on!,
              currentUser?.language,
              currentUser?.time_zone
            )}
          </span>
        </td>
      </tr>
    </>
  )
}

export {OneGroupObjection}
