import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {FC, ReactNode, forwardRef, useState} from 'react'
import {ICriteria} from '../../../../../models/score/Score'
import {CriterionNameCell} from './cells/CriterionNameCell'
import {RoleAccess} from '../../../../../models/users/Users'
import {CriterionGroupCell} from './cells/CriterionGroupCell'
import {CriterionAuthorCell} from './cells/CriterionAuthorCell'
import {CriterionStatusCell} from './cells/CriterionStatusCell'
import {EditCriterionModal} from '../modals/EditCriterionModal'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {DeleteCriterionModal} from '../modals/DeleteCriterionModal'
import {CriterionCreatedOnCell} from './cells/CriterionCreatedOnCell'

type Props = {
  criteria: ICriteria
  userRole: RoleAccess[]
}
interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const OneCriterion: FC<Props> = ({criteria, userRole}) => {
  const intl = useIntl()
  const [updateCriterion, {isLoading: isLoadingUpdate}] = scoreAPI.useCriteriaUpdateMutation()

  const [showModalEditCriterion, setShowModalEditCriterion] = useState<boolean>(false)
  const [showModalDeleteCriterion, setShowModalDeleteCriterion] = useState<boolean>(false)

  const handleCloseEditCriterion = () => setShowModalEditCriterion(false)
  const handleShowEditCriterion = () => setShowModalEditCriterion(true)

  const handleCloseDeleteCriterion = () => setShowModalDeleteCriterion(false)
  const handleShowDeleteCriterion = () => setShowModalDeleteCriterion(true)

  return (
    <>
      <tr role='row' className='fs-7 text-gray-800 small fw-bold'>
        <td role='cell' className='col-2'>
          <CriterionNameCell criterionName={criteria.name} ratingScale={criteria.rating_scale} />
        </td>
        <td role='cell' className='col-2'>
          <CriterionAuthorCell createdBy={criteria.created_by} />
        </td>
        <td role='cell' className='col-3'>
          <CriterionCreatedOnCell createdOn={criteria.created_on} />
        </td>
        <td role='cell' className='col-2'>
          <CriterionGroupCell criterionGroup={criteria.groups ? criteria.groups.name : '--'} />
        </td>
        <td role='cell' className='col-2'>
          <CriterionStatusCell status={criteria.status} />
        </td>
        {userRole.includes('CRITERIAMODIFY') && (
          <td role='cell' className='col-2 text-end'>
            <Dropdown drop='down' align='end'>
              <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowEditCriterion}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                  </div>
                </Dropdown.Item>
                {criteria.status === 'PUBLISHED' && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={() => {
                      updateCriterion({id: criteria.id, data: {status: 'ARCHIVED'}})
                        .unwrap()
                        .then(() => {})
                        .catch((error) => {
                          console.log(error)
                        })
                    }}
                    disabled={isLoadingUpdate}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.ARCHIVE'})}
                    </div>
                  </Dropdown.Item>
                )}
                {(criteria.status === 'ARCHIVED' || criteria.status === 'DRAFT') && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={() => {
                      updateCriterion({id: criteria.id, data: {status: 'PUBLISHED'}})
                        .unwrap()
                        .then(() => {})
                        .catch((error) => {
                          console.log(error)
                        })
                    }}
                    disabled={isLoadingUpdate}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                    </div>
                  </Dropdown.Item>
                )}
                {criteria.status === 'DRAFT' && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={handleShowDeleteCriterion}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.DELETEDRAFT'})}
                    </div>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <EditCriterionModal
              criterion={criteria}
              show={showModalEditCriterion}
              handleClose={handleCloseEditCriterion}
            />
            <DeleteCriterionModal
              criterion={criteria}
              show={showModalDeleteCriterion}
              handleClose={handleCloseDeleteCriterion}
            />
          </td>
        )}
      </tr>
    </>
  )
}

export {OneCriterion}
