import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICriteria} from '../../../../../models/score/Score'
import {RoleAccess} from '../../../../../models/users/Users'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {FC, ReactNode, forwardRef, useEffect, useState} from 'react'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {EditCriterionModal} from '../../criteria/modals/EditCriterionModal'
import {CriterionNameCell} from '../../criteria/criteria-table/cells/CriterionNameCell'
import {CriterionAuthorCell} from '../../criteria/criteria-table/cells/CriterionAuthorCell'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'
import {CriterionCreatedOnCell} from '../../criteria/criteria-table/cells/CriterionCreatedOnCell'

type Props = {
  groupId: string
  criteria: ICriteria
}
interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}

export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const OneGroupCriterion: FC<Props> = ({groupId, criteria}) => {
  const intl = useIntl()
  const [excludeCriterion, {isLoading}] = scoreAPI.useGroupsActionMutation()
  const [showModalEditCriterion, setShowModalEditCriterion] = useState<boolean>(false)

  const handleCloseEditCriterion = () => setShowModalEditCriterion(false)
  const handleShowEditCriterion = () => setShowModalEditCriterion(true)

  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <tr role='row' className='fs-7 text-gray-800 small fw-bold'>
        <td role='cell' className='col-3 px-0'>
          <CriterionNameCell criterionName={criteria.name} ratingScale={criteria.rating_scale} />
        </td>
        <td role='cell' className='col-3 px-0'>
          <CriterionAuthorCell createdBy={criteria.created_by} />
        </td>
        <td role='cell' className='col-3 ps-8'>
          <CriterionCreatedOnCell createdOn={criteria.created_on} />
        </td>
        <td role='cell' className='col-2 px-0'></td>
        {roleAccesses.includes('CRITERIAMODIFY') && (
          <td role='cell' className='col-1 px-0 text-end'>
            <Dropdown drop='down' align='end'>
              <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowEditCriterion}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  disabled={isLoading}
                  onClick={() => {
                    excludeCriterion({
                      id: groupId,
                      action: 'remove',
                      data: {criterion_ids: [criteria.id]},
                    })
                      .unwrap()
                      .then(() => {})
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'CRITERION.DELETE'})}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <EditCriterionModal
              criterion={criteria}
              show={showModalEditCriterion}
              handleClose={handleCloseEditCriterion}
            />
          </td>
        )}
      </tr>
    </>
  )
}

export {OneGroupCriterion}
