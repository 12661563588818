/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {ObjectionGropsItem} from './ObjectionGropsItem'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {FC, ReactNode, forwardRef, useState} from 'react'
import {ObjectionNoGropItem} from './ObjectionNoGropItem'
import {RoleAccess} from '../../../../../models/users/Users'
import {IObjectionGroup} from '../../../../../models/score/Score'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {CreateGroupObjectionModal} from '../modals/CreateGroupObjectionModal'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'

interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const ObjectionGroupList: FC = () => {
  const intl = useIntl()

  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {data} = scoreAPI.useGroupsObjectionFetchQuery()

  const [showModalCreateGroup, setShowModalCreateGroup] = useState<boolean>(false)
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  const handleCloseCreateGroup = () => setShowModalCreateGroup(false)
  const handleShowCreateGroup = () => setShowModalCreateGroup(true)

  return (
    <>
      <div className='card-header d-flex justify-content-end'>
        {roleAccesses.includes('ORGANIZATIONMODIFY') && (
          <div className='card-toolbar'>
            <button className='btn btn-primary btn-sm' onClick={handleShowCreateGroup}>
              <span className='svg-icon svg-icon-muted svg-icon-1x'>
                <KTSVG path='/media/icons/duotune/arrows/arr013.svg' />
              </span>
              {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.ADD'})}
            </button>
          </div>
        )}
        <CreateGroupObjectionModal
          show={showModalCreateGroup}
          handleClose={handleCloseCreateGroup}
        />
      </div>

      <div className='card-body p-2 px-9'>
        <div className='app-sidebar-menu flex-column-fluid'>
          <div
            className='app-sidebar-wrapper hover-scroll-overlay-y my-5 overflow-visible'
            data-kt-scroll='true'
          >
            <ObjectionNoGropItem />
            {data &&
              data.map((item: IObjectionGroup, i) => (
                <ObjectionGropsItem key={i} objectionGroup={item} />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export {ObjectionGroupList}
