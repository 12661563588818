import {Navigate, Route, Routes} from 'react-router-dom'
import {AuthLayout} from '../../auth/AuthLayout'
import {Profile} from './components/Profile'
import {SelectAccount} from './components/SelectAccount'
import {UpdatePasswordProfile} from './components/UpdatePasswordProfile'

const RootPage = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='*' element={<Navigate to='accounts/' />} />
          <Route path='accounts/' element={<SelectAccount />} />
          <Route path='info/' element={<Profile />} />
          <Route path='update-password/' element={<UpdatePasswordProfile />} />
        </Route>
      </Routes>
    </>
  )
}

export {RootPage}
