/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import Popover from 'react-bootstrap/Popover'
import {OverlayTrigger} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleAccess} from '../../../models/users/Users'
import {getDates, getPercent} from '../DashboardWrapper'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {usePeriodsControls} from '../helpers/PeriodGetControlsProvider'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {convertTime} from '../../../../_metronic/helpers/components/ConvertTime'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'

const CheckedControlsPeriod: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [pulseState, setPulseState] = useState<boolean>(true)
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const {periods, setPeriodsGetControls} = usePeriodsControls()
  const [periodStateCheckedControls, setPeriodStateCheckedControls] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const [getControlsAllPeriod, {data: controlsAllPeriod}] =
    analyticsAPI.useLazyAnalyticsFetchQuery()
  const [getControlsAllPeriodDuration, {data: controlsAllPeriodDuration}] =
    analyticsAPI.useLazyAnalyticsFetchQuery()
  const [
    getControlsCheckedPeriod,
    {data: checkedControlsPeriod, isLoading: isLoadingCheckedControlsPeriod},
  ] = analyticsAPI.useLazyAnalyticsFetchQuery()
  const [
    getControlsCheckedPeriodDuration,
    {data: checkedControlsPeriodDuration, isLoading: isLoadingCheckedControlsPeriodDuration},
  ] = analyticsAPI.useLazyAnalyticsFetchQuery()

  useEffect(() => {
    if (currentUser && periods) {
      setTimeout(() => {
        setPulseState(false)
      }, 10000)
      let weekPeriod = getDates()
      setRoleAccesses(getAccesses(currentUser.role))
      if (weekPeriod !== '') {
        if (
          periods.periodCheckedControls.start === '' &&
          periods.periodCheckedControls.end === ''
        ) {
          setPeriodStateCheckedControls({
            start: weekPeriod.split(',')[0],
            end: weekPeriod.split(',')[1],
          })
        } else {
          setPeriodStateCheckedControls({
            start: periods.periodCheckedControls.start,
            end: periods.periodCheckedControls.end,
          })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    if (periodStateCheckedControls.start !== '' && periodStateCheckedControls.end !== '') {
      getControlsCheckedPeriod({
        period: periodStateCheckedControls.start + ',' + periodStateCheckedControls.end,
        status: 'WITH_RAITING',
      })

      getControlsCheckedPeriodDuration({
        period: periodStateCheckedControls.start + ',' + periodStateCheckedControls.end,
        status: 'WITH_RAITING',
        is_durations: true,
      })

      getControlsAllPeriod({
        period: periodStateCheckedControls.start + ',' + periodStateCheckedControls.end,
      })

      getControlsAllPeriodDuration({
        period: periodStateCheckedControls.start + ',' + periodStateCheckedControls.end,
        is_durations: true,
      })

      setPeriodsGetControls({
        periodAllControls: {
          start: periods.periodAllControls.start,
          end: periods.periodAllControls.end,
        },
        periodCheckedControls: {
          start: periodStateCheckedControls.start,
          end: periodStateCheckedControls.end,
        },
        periodOwnCheckedControls: {
          start: periods.periodOwnCheckedControls.start,
          end: periods.periodOwnCheckedControls.end,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodStateCheckedControls])

  return (
    <>
      <div className='card h-100'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='btn btn-icon pulse pulse-warning pe-3'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr085.svg'
                className='svg-icon-2tx p-0 svg-icon-warning'
              />
              {pulseState && <span className='pulse-ring'></span>}
            </div>
            <div>
              <span className='small fw-bold text-hover-warning text-gray-800 m-0 text-uppercase'>
                {intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.PERIOD'})}
              </span>
              <div className='small text-gray-700 d-flex flex-row3'>
                {
                  periodStateCheckedControls.start
                    .replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1')
                    .split('T')[0]
                }
                -
                {
                  periodStateCheckedControls.end
                    .replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1')
                    .split('T')[0]
                }
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <div className='small text-gray-500'>
                  {roleAccesses.includes('ANALYTICSALL')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.ALLUSERS'})
                    : roleAccesses.includes('ANALYTICSDEP')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.DEPARTMENT'})
                    : roleAccesses.includes('ANALYTICSOWN')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.OWN'})
                    : ''}
                </div>
              </div>
            </div>
          </div>
          <div className='card-toolbar m-0 d-flex flex-column'>
            <div className='d-flex flex-row align-self-end'>
              <div>
                {periodStateCheckedControls.start !== '' &&
                  periodStateCheckedControls.end !== '' && (
                    <DateRangePickerInput
                      language={currentUser?.language}
                      timeZone={currentUser?.time_zone}
                      rangeId='2'
                      periodState={periodStateCheckedControls}
                      updateState={setPeriodStateCheckedControls}
                      onlyIcon={true}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-row px-9 pt-6 pb-8'>
          {isLoadingCheckedControlsPeriod || isLoadingCheckedControlsPeriodDuration ? (
            <div className='d-flex flex-column col-12'>
              <span className='indicator-progress align-self-center' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm text-primary align-middle ms-2 mt-10'></span>
              </span>
            </div>
          ) : (
            <>
              <div className='d-flex flex-column col-6'>
                <div className='d-flex align-items-center fw-bold fs-4 flex-wrap mb-3 text-gray-700'>
                  <div className='fs-3qx fw-bolder text-gray-900'>
                    {checkedControlsPeriod?.total}
                  </div>
                </div>

                <div className='d-flex align-items-center fw-bold flex-wrap fs-4 text-gray-700'>
                  <OverlayTrigger
                    placement='bottom-start'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover>
                        <div className='p-3 small text-uppercase text-gray-700'>
                          {intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.DURATION'})}
                        </div>
                      </Popover>
                    }
                  >
                    <i className='fa-regular fa-clock pe-3 fs-4 btn p-0'></i>
                  </OverlayTrigger>
                  <span className='me-3'>
                    {checkedControlsPeriodDuration &&
                      convertTime(checkedControlsPeriodDuration?.total)}
                  </span>
                </div>
              </div>
              <div className='d-flex col-6'>
                <span className='bullet bullet-line h-auto bg-muted w-1px me-2'></span>
                <div className='d-flex flex-column flex-stack col-12'>
                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT.PERIOD'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {checkedControlsPeriod &&
                          controlsAllPeriod &&
                          getPercent(controlsAllPeriod.total, checkedControlsPeriod.total)}
                        %
                      </span>
                    </OverlayTrigger>
                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{
                          width:
                            checkedControlsPeriod && controlsAllPeriod
                              ? getPercent(controlsAllPeriod.total, checkedControlsPeriod.total) +
                                '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT.PERIOD.DURATION'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {checkedControlsPeriodDuration &&
                          controlsAllPeriodDuration &&
                          getPercent(
                            controlsAllPeriodDuration.total,
                            checkedControlsPeriodDuration.total
                          )}
                        %
                      </span>
                    </OverlayTrigger>

                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{
                          width:
                            checkedControlsPeriodDuration && controlsAllPeriodDuration
                              ? getPercent(
                                  controlsAllPeriodDuration.total,
                                  checkedControlsPeriodDuration.total
                                ) + '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {CheckedControlsPeriod}
