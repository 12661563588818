export interface IProfileModel {
  id?: string
  created_on?: string
  updated_on?: string
  email?: string
  name?: string
  company_name?: string
  phone?: string
  language?: 'ru' | 'en'
  time_zone?: string
  is_active?: boolean
  accounts?: string[]
}

export const ProfileUpdateInitialValues = {
  id: '',
  name: '',
  phone: '',
  email: '',
  company_name: '',
  language: 'en',
  time_zone: '',
}

export interface ProfileUpdateModel {
  name?: string
  phone?: string
  email?: string
  language?: 'ru' | 'en'
  time_zone?: string
  company_name?: string
  password?: string
  confirm_password?: string
}
