import {FC, useEffect, useState} from 'react'
import {OneRow} from './OneRowForm'
import {FormsHeader} from './FormsHeader'
import {IAnalyticsFormsResponse, IFormAverage} from '../../../../models/analytics/Analytics'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  analytics?: IAnalyticsFormsResponse
  selectedUsers?: {label: string; value: string}[]
  selectedForms?: {label: string; value: string}[]
}
export const FormsTable: FC<Props> = ({analytics, selectedUsers, selectedForms}) => {
  const [pageInfo, updatePageInfo] = useState<IFormAverage[]>([])
  const [allForms, updateAllForms] = useState<IFormAverage[]>([])
  const {state} = useQueryRequest()

  useEffect(() => {
    if (analytics && selectedUsers?.length !== 0) {
      let emptyUsers: IFormAverage[] = []
      selectedUsers?.forEach((user) => {
        let mark = false
        for (let d of analytics?.data) {
          if (user.value === d.created_by.id) {
            mark = true
            break
          }
        }
        if (mark === false) {
          emptyUsers.push({
            created_by: {
              id: user.value,
              name: user.label,
            },
            average_by_user: 0,
            average_by_forms: [],
          })
        }
      })
      updateAllForms(analytics.data.concat(emptyUsers))
    } else if (!analytics) {
      updateAllForms([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics])

  useEffect(() => {
    if (allForms.length !== 0) {
      if (allForms.length >= state.skip! + state.limit!) {
        updatePageInfo(allForms.slice(state.skip, state.skip! + state.limit!))
      } else {
        updatePageInfo(allForms.slice(state.skip))
      }
    } else {
      updatePageInfo([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, allForms])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <FormsHeader forms={selectedForms ? selectedForms : []} />
        <tbody>
          {pageInfo.map((u) => (
            <OneRow
              object={u}
              key={u.created_by.id}
              selectedFormsNumber={selectedForms ? selectedForms.length : 0}
            />
          ))}
          <OneRow
            avergForm={analytics?.average_by_form}
            selectedFormsNumber={selectedForms ? selectedForms.length : 0}
          />
        </tbody>
      </table>
    </>
  )
}
