import {FC} from 'react'
import {useIntl} from 'react-intl'
import {TablePagination} from '../../../../../_metronic/helpers/pagination/TablePagination'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  selectedUsers?: {label: string; value: string}[]
}
const ObjectionsFooter: FC<Props> = ({selectedUsers}) => {
  const {state, updateState} = useQueryRequest()
  const intl = useIntl()

  return (
    <>
      <div className='card-footer'>
        <div className='d-flex flex-stack align-items-center'>
          <div className='justify-content-start'>
            <label>
              <select
                className='form-select form-select-sm form-select-solid'
                value={state.limit}
                onChange={(e) => {
                  updateState({skip: 0, limit: parseInt(e.target.value)})
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </label>
            <span className='small text-uppercase ms-4'>
              {intl.formatMessage({id: 'USERS.TABLE.USERSCOUNT'})}:{' '}
              {selectedUsers ? selectedUsers?.length : 0}
            </span>
          </div>
          <TablePagination total={selectedUsers ? selectedUsers?.length : 0} />
        </div>
      </div>
    </>
  )
}

export {ObjectionsFooter}
