import {useIntl} from 'react-intl'

const CriteriaGroupTableHeader = () => {
  const intl = useIntl()
  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 text-uppercase small col-8'>
        <th role='columnheader' className='col-3 px-0'>
          {intl.formatMessage({id: 'CRITERIATABLE.NAME'})}
        </th>
        <th role='columnheader' className='col-3 px-0'>
          {intl.formatMessage({id: 'CRITERIATABLE.AUTHOR'})}
        </th>
        <th role='columnheader' className='col-3 ps-8'>
          {intl.formatMessage({id: 'CRITERIATABLE.CREATEDON'})}
        </th>
        <th role='columnheader' className='col-2 px-0'></th>
        <th role='columnheader' className='col-1 px-0'></th>
      </tr>
    </thead>
  )
}

export {CriteriaGroupTableHeader}
