import {FC, lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ToolsPage from '../modules/tools/ToolsPage'
import {QueryRequestProvider} from '../../_metronic/helpers/pagination/QueryRequestProvider'
import {ControlsFilterProvider} from '../modules/controls/helpers/ControlsFilterProvider'
import ObjectsOfControl from '../modules/controls/Controls'
import {ControlsCriteriaFilterProvider} from '../modules/analytics/components/helpers/ControlsCriteriaFilterProvider'
import AnalyticsCriteriaFilter from '../modules/analytics/components/AnalyticsCriteriaFilter'

const PrivateRoutes = () => {
  const SettingsPage = lazy(() => import('../modules/settings/SettingsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard/' />} />
        <Route path='dashboard/' element={<DashboardWrapper />} />
        <Route
          path='analytics/criteria/'
          element={
            <ControlsCriteriaFilterProvider>
              <QueryRequestProvider>
                <AnalyticsCriteriaFilter />
              </QueryRequestProvider>
            </ControlsCriteriaFilterProvider>
          }
        />
        <Route
          path='objects/'
          element={
            <ControlsFilterProvider>
              <QueryRequestProvider>
                <ObjectsOfControl />
              </QueryRequestProvider>
            </ControlsFilterProvider>
          }
        />
        <Route
          path='tools/*'
          element={
            <SuspensedView>
              <ToolsPage />
            </SuspensedView>
          }
        />
        <Route path='' element={<SuspensedView>{/* <WizardsPage /> */}</SuspensedView>} />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
