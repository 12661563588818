import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import WaveSurfer from 'wavesurfer.js'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Regions from 'wavesurfer.js/dist/plugins/regions'
import {RGBAToString, StringToRGBA} from '../../../helpers/control-helpers'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {IBreakPoints, ICurrentRegion, IStringColorType} from '../../../models/controls'
import {breakpointAPI} from '../../../../../redux/services/BreakpointsService'
import {formatDateByZone} from '../../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  type: 'CALL' | 'MEETING'
  breakPointData: IBreakPoints
  waveSurferRegions: Regions
  waveSurfer: WaveSurfer
  removeBreakPoint: (id: string) => void
}

const ControlBreakpoint: FC<Props> = ({
  type,
  breakPointData,
  waveSurferRegions,
  waveSurfer,
  removeBreakPoint,
}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [isPlayPause, setIsPlayPause] = useState<boolean>(false)
  const [loop, setLoop] = useState<boolean>(false)
  const [editRedion, setEditRedion] = useState<boolean>(false)
  const [breakPoint, setBreakPoint] = useState<IBreakPoints>(breakPointData)
  const [comment, setComment] = useState<string>()
  const [commentColor, setCommentColor] = useState<IStringColorType>()
  const [breakpointUpdate] = breakpointAPI.useBreakpointUpdateMutation()
  const [updateRegionData, setUpdateRegionData] = useState<ICurrentRegion>({
    start: breakPoint!.detail.start,
    end: breakPoint!.detail.end,
  })

  useEffect(() => {
    setBreakPoint(breakPointData)
    setComment(breakPointData.comment)
    setCommentColor(breakPointData.comment_color)
  }, [breakPointData])

  const regions = waveSurferRegions?.getRegions()
  const currentRegion = regions?.filter((region) => region.id === breakPoint.id)[0]
  if (currentRegion !== undefined) {
    currentRegion.setOptions({
      color: StringToRGBA(breakPoint.comment_color ? breakPoint.comment_color : 'DEFAULT'),
    })
  }

  if (waveSurfer !== undefined) {
    waveSurfer.once('timeupdate', (currentTime) => {
      if (breakPoint.detail.start <= currentTime && currentTime <= breakPoint.detail.end!) {
        if (isPlayPause) {
          if (loop && currentTime >= breakPoint.detail.end! - 0.1) {
            currentRegion.play()
          } else if (currentTime >= breakPoint.detail.end! - 0.1) {
            waveSurfer.pause()
          }
        }
      }
    })

    waveSurfer.on('pause', () => {
      setIsPlayPause(false)
      setLoop(false)
    })

    waveSurferRegions.on('region-updated', (region) => {
      if (currentRegion !== undefined) {
        if (currentRegion.id === region.id || editRedion) {
          setUpdateRegionData({
            start: parseFloat(region.start.toFixed(2)),
            end: parseFloat(region.end.toFixed(2)),
          })
        }
      }
    })
  }

  const PlayPause = () => {
    if (isPlayPause) {
      waveSurfer.pause()
    } else {
      waveSurfer.pause()
      setTimeout(() => {
        setIsPlayPause(true)
        currentRegion.play()
      }, 100)
    }
  }

  const updateRegion = () => {
    setEditRedion(true)
    if (type === 'CALL') {
      currentRegion.setOptions({drag: true, resize: true})
    }
  }

  const updateRegionColor = (color: string) => {
    setCommentColor(RGBAToString(color)!)
    if (type === 'CALL') {
      setTimeout(() => {
        currentRegion.setOptions({color: color})
      }, 50)
    }
  }

  const cancelRegion = () => {
    setEditRedion(false)
    setUpdateRegionData({start: breakPoint.detail.start, end: breakPoint.detail.end})
    setComment(breakPoint.comment)
    setCommentColor(breakPoint.comment_color)
    if (type === 'CALL') {
      currentRegion.setOptions({
        drag: false,
        resize: false,
        start: breakPoint.detail.start,
        end: breakPoint.detail.end,
        color: StringToRGBA(breakPoint.comment_color ? breakPoint.comment_color : 'DEFAULT'),
      })
    }
  }

  const saveRegion = () => {
    setEditRedion(false)
    if (type === 'CALL') {
      currentRegion.setOptions({drag: false, resize: false})
    }

    setBreakPoint({
      id: breakPointData.id,
      created_by: breakPointData.created_by,
      created_on: breakPointData.created_on,
      comment: comment!,
      comment_color: commentColor!,
      detail: updateRegionData,
      type: breakPointData.type,
    })
    breakpointUpdate({
      id: breakPoint.id ? breakPoint.id : '',
      data: {
        comment: comment,
        comment_color: commentColor,
        detail: updateRegionData,
      },
    })
  }

  const removeRegion = () => {
    if (type === 'CALL') {
      currentRegion.remove()
      setEditRedion(false)
      removeBreakPoint(currentRegion.id)
    } else {
      setEditRedion(false)
      removeBreakPoint(breakPointData.id ? breakPointData.id : '')
    }
  }

  const handleChangeStart = (start: number) => {
    setUpdateRegionData({start: start, end: updateRegionData.end})
    currentRegion.setOptions({start: start})
  }
  const handleChangeEnd = (end: number) => {
    setUpdateRegionData({start: updateRegionData.start, end: end})
    currentRegion.setOptions({end: end})
  }

  return (
    <>
      {editRedion ? (
        <div className='overflow-hidden position-relative card-rounded mb-3'>
          <div
            className={clsx('card shadow-sm card-bordered', {
              'border-primary': commentColor === 'DEFAULT',
              'border-danger': commentColor === 'RED',
              'border-warning': commentColor === 'YELLOW',
              'border-success': commentColor === 'GREEN',
            })}
          >
            <div className='card-body pt-1 pb-1'>
              <div className='d-flex justify-content-end flex-stack flex-wrap'>
                <button
                  className='btn btn-icon mx-1'
                  onClick={() => updateRegionColor('rgba(255, 0, 0, 0.5)')}
                >
                  <i className='bi bi-plus-circle-fill text-danger fs-1' />
                </button>
                <button
                  className='btn btn-icon mx-1'
                  onClick={() => updateRegionColor('rgba(255, 255, 0, 0.6)')}
                >
                  <i className='bi bi-plus-circle-fill text-warning fs-1' />
                </button>
                <button
                  className='btn btn-icon mx-1'
                  onClick={() => updateRegionColor('rgba(0, 255, 0, 0.5)')}
                >
                  <i className='bi bi-plus-circle-fill text-success fs-1' />
                </button>
              </div>
              <div className='input-group input-group-sm'>
                <textarea
                  className='form-control'
                  placeholder='Comment'
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                ></textarea>
              </div>
              <div
                className={clsx('d-flex mt-3 mb-2', {
                  'justify-content-between flex-stack flex-wrap0': type === 'CALL',
                  'flex-end': type === 'MEETING',
                })}
              >
                {type === 'CALL' && (
                  <span className='d-flex align-items-center'>
                    <button className='btn btn-icon play-pause me-2' onClick={PlayPause}>
                      <i
                        className={clsx('fs-3x bi', {
                          'bi-play-fill text-gray-700 text-hover-primary': !isPlayPause,
                          'bi-stop-fill text-primary': isPlayPause,
                        })}
                      />
                    </button>
                    <label className='me-1 fw-bold'>START</label>
                    <input
                      type='number'
                      min={0}
                      step={0.1}
                      className='form-control form-control-sm w-80px me-2'
                      onChange={(e) => handleChangeStart(parseFloat(e.target.value))}
                      value={updateRegionData.start}
                    />
                    {breakPoint.type === 'RANGE' && (
                      <>
                        <label className='me-1 fw-bold'>END</label>
                        <input
                          type='number'
                          min={0}
                          step={0.1}
                          className='form-control form-control-sm w-80px'
                          onChange={(e) => handleChangeEnd(parseFloat(e.target.value))}
                          value={updateRegionData.end}
                        />
                      </>
                    )}
                  </span>
                )}

                <div className='d-flex justify-content-end mt-3'>
                  <button className='btn btn-sm btn-primary me-2' onClick={saveRegion}>
                    Save
                  </button>
                  <button className='btn btn-sm btn-light-primary' onClick={cancelRegion}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          {breakPoint!.created_by!.id === currentUser!.id && (
            <div className='ribbon ribbon-triangle ribbon-top-start border-gray-400'>
              <div className='ribbon-icon ms-n6 mt-n6'>
                <i
                  className='bi bi-trash3 fs-3 text-white text-hover-danger cursor-pointer'
                  onClick={() => removeRegion()}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='overflow-hidden position-relative card-rounded mb-3'>
          <div
            className={clsx(
              'card card-bordered d-flex flex-column flex-sm-row p-5 align-items-center card card-flush shadow-sm',
              {
                'border-primary': breakPoint.comment_color === 'DEFAULT',
                'border-danger': breakPoint.comment_color === 'RED',
                'border-warning': breakPoint.comment_color === 'YELLOW',
                'border-success': breakPoint.comment_color === 'GREEN',
              }
            )}
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={clsx('svg-icon svg-icon-3x me-4', {
                'text-primary': breakPoint.comment_color === 'DEFAULT',
                'text-danger': breakPoint.comment_color === 'RED',
                'text-warning': breakPoint.comment_color === 'YELLOW',
                'text-success': breakPoint.comment_color === 'GREEN',
              })}
            />
            <div className='card-body p-0 w-100'>
              <h5 className='mb-1 text-dark'>{breakPoint.created_by?.name}</h5>
              <span className='small'>
                {formatDateByZone(
                  breakPoint.created_on!,
                  currentUser?.language,
                  currentUser?.time_zone
                )}
              </span>
              <div className='separator mt-2 mb-1' />
              <span className='text-dark fs-5'>{breakPoint.comment}</span>
              {type === 'CALL' && (
                <div className='fw-bold mt-3 text-gray-800'>
                  START: {breakPoint.detail.start}
                  {' sec '}
                  {breakPoint.type === 'RANGE' && <>- END: {breakPoint.detail.end} sec</>}
                </div>
              )}
            </div>
            {breakPoint.type === 'RANGE' && (
              <button className='btn btn-icon' onClick={() => setLoop(!loop)}>
                <OverlayTrigger
                  delay={{show: 50, hide: 50}}
                  overlay={
                    <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                      Repeat
                    </Tooltip>
                  }
                >
                  <i className={clsx('fa-solid fa-repeat fs-1x ms-2', {'text-primary': loop})} />
                </OverlayTrigger>
              </button>
            )}
            {type === 'CALL' && (
              <button className='btn btn-icon play-pause' onClick={PlayPause}>
                <i
                  className={clsx('fs-4x bi', {
                    'bi-play-fill text-gray-700 text-hover-primary': !isPlayPause,
                    'bi-stop-fill text-primary': isPlayPause,
                  })}
                />
              </button>
            )}
          </div>
          {breakPoint!.created_by!.id === currentUser!.id && (
            <div className='ribbon ribbon-triangle ribbon-bottom-end border-gray-400'>
              <div className='ribbon-icon mt-0 me-n6'>
                <i
                  className='bi bi-pencil fs-3 text-white text-hover-primary cursor-pointer'
                  onClick={updateRegion}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export {ControlBreakpoint}
