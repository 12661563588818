/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {authAPI} from '../../../redux/services/AuthService'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {setLoginBackground} from '../../../redux/slices/serviceSlice'
import {useAppDispatch} from '../../../redux/hooks/redux'

const initialLang = Intl.DateTimeFormat().resolvedOptions().locale
const initialTZ = Intl.DateTimeFormat().resolvedOptions().timeZone

const REACT_APP_URL = process.env.REACT_APP_URL

const initialValues = {
  name: '',
  email: '',
  phone: '',
  password: '',
  organizationName: '',
  changepassword: '',
  acceptTerms: false,
}

function checkLanguage(language: string) {
  if (language.indexOf('en') !== -1) {
    return 'en'
  } else if (language.indexOf('ru') !== -1) {
    return 'ru'
  } else {
    return 'en'
  }
}

const registrationSchema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    email: Yup.string()
      .email(f({id: 'FORMIK.ERROR.WRONGEMAILFORMAT'}))
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    phone: Yup.number(),
    organizationName: Yup.string(),
    password: Yup.string()
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    changepassword: Yup.string()
      .required('FORMIK.ERROR.REQUIREDFIELD')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], f({id: 'FORMIK.ERROR.PASSWORDSMISMATCH'})),
      }),
    acceptTerms: Yup.bool().required(f({id: 'FORMIK.ERROR.LICENSEAGREEMENT'})),
  })

export function Registration() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [register, {isLoading: loading, isError}] = authAPI.useRegisterMutation()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema(intl.formatMessage),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await register({
        name: values.name,
        email: values.email,
        phone: values.phone,
        organizationName: values.organizationName,
        password: values.password,
        confirm_password: values.changepassword,
        language: checkLanguage(initialLang),
        time_zone: initialTZ,
      })
        .unwrap()
        .then((response) => {
          setStatus(response?.detail)
          setTimeout(() => {
            navigate('/login', {replace: true})
          }, 7000)
        })
        .catch((error) => {
          if (error.status === 400) {
            setStatus(intl.formatMessage({id: 'DETAIL.REGISTRERROR'}))
          } else {
            setStatus(intl.formatMessage({id: error.data.detail}))
          }
          setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-lg-400px'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <a href={`${REACT_APP_URL}`}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-white.svg')}
            className='h-50px app-sidebar-logo-default mb-5'
          />
        </a>
        <h1 className='text-light fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}
        </h1>
        <div className='text-muted fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE'})}
        </div>
      </div>
      <div className='separator separator-content my-14'>
        <span className='text-muted fw-semibold fs-7 min-w-125px'>
          {intl.formatMessage({id: 'AUTH.SIGNIN.WITHEMAIL'})}
        </span>
      </div>
      {formik.status && (
        <>
          {isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {!isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-info'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      )}

      <div className='fv-row mb-8'>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.FULLNAME'})}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx('form-control bg-white text-black', {
            'is-invalid': (formik.touched.name && formik.errors.name) || isError,
          })}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-white text-black', {
            'is-invalid': (formik.touched.email && formik.errors.email) || isError,
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.PHONE'})}
          type='phone'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx('form-control bg-white text-black')}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-center fv-row mb-8'>
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid me-10'>
          <label className='form-check-label text-muted me-5' htmlFor='OrganizationSwitch'>
            {intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATION'})}
          </label>
          <input
            className={clsx('form-control form-check-input bg-active-info')}
            type='checkbox'
            id='OrganizationSwitch'
            onClick={(e: any) => {
              document.getElementById('OrganizationName')?.classList.toggle('visible-hidden')
            }}
            {...formik.getFieldProps('organization')}
          />
        </div>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.REGISTR.ORGANIZATIONNAME'})}
          type='text'
          id='OrganizationName'
          autoComplete='off'
          {...formik.getFieldProps('organizationName')}
          className={clsx('form-control bg-white text-black visible-hidden')}
        />
      </div>
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx('form-control bg-white text-black', {
                'is-invalid': (formik.touched.password && formik.errors.password) || isError,
              })}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-info rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-info rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-info rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-info rounded h-5px'></div>
          </div>
        </div>
      </div>
      <div className='fv-row mb-5'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx('form-control bg-white text-black', {
            'is-invalid':
              (formik.touched.changepassword && formik.errors.changepassword) || isError,
          })}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span className='text-light'>
            {intl.formatMessage({id: 'AUTH.REGISTER.LICENSE'})}
            <a href='https://sidbis.com' target='_blank' className='ms-1 link-info'>
              {intl.formatMessage({id: 'AUTH.REGISTER.LICENSELINK'})}
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm btn-info w-200px mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SIGNUP_BUTTON'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='text-muted text-center fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.REGISTER.ACCOUNTEXISTS.PRETITLE'})}{' '}
          <Link
            to='/auth/login/'
            onClick={() => {
              dispatch(setLoginBackground())
            }}
            className='link-info'
          >
            {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
          </Link>
        </div>
      </div>
    </form>
  )
}
