/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {QueryRequestProvider} from '../../../../_metronic/helpers/pagination/QueryRequestProvider'
import {ObjectionTable} from './objection/objection-table/ObjectionTable'

const Objection: FC = () => {
  return (
    <div className='card card-custom card-stretch shadow mb-5'>
      <div className='card-body px-1'>
        <div className='table-responsive px-15 overflow-visible'>
          <QueryRequestProvider>
            <ObjectionTable />
          </QueryRequestProvider>
        </div>
      </div>
    </div>
  )
}

export default Objection
