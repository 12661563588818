import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {useAppSelector} from '../redux/hooks/redux'
import {RootPage} from '../modules/profile/root/RootPage'
import {profileAPI} from '../redux/services/ProfileService'

const {PUBLIC_URL, REACT_APP_PROFILE_NAME} = process.env

const AppRoutes: FC = () => {
  const {accessToken} = useAppSelector((state) => state.authReduser)
  const {data: profile, isSuccess} = profileAPI.useProfileMeFetchQuery()
  const wl = window.location

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout/' element={<Logout />} />
          {isSuccess && accessToken && profile ? (
            <>
              {wl.hostname.split('.').length === 2 ||
              wl.hostname.split('.')[0] === REACT_APP_PROFILE_NAME ? (
                <>
                  <Route path='*' element={<RootPage />} />
                  <Route path='*' element={<Navigate to='/accounts/' />} />
                </>
              ) : (
                <>
                  {profile?.accounts !== undefined &&
                  profile?.accounts.includes(wl.hostname.split('.')[0]) ? (
                    <>
                      <Route path='/*' element={<PrivateRoutes />} />
                      <Route index element={<Navigate to='/dashboard/' />} />
                    </>
                  ) : (
                    <>
                      <Route path='auth/*' element={<AuthPage />} />
                      <Route path='*' element={<Navigate to='/auth/' />} />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth/' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
