import {IScoreType} from '../../../models/score/Score'

type ICheckStatusType = 'WAIT' | 'CHECKED' | 'WAIT_FOR_APPEAL' | 'APPEALED'
export type IBreakPointType = 'RANGE' | 'SINGLE'
export type IStringColorType = 'DEFAULT' | 'RED' | 'YELLOW' | 'GREEN' | 'BLUE'

export interface IControlScore {
  score_form_id?: string
  values: IScoreValue[]
  detail: Map<string, number | null>
}

export interface IControlScoredDetail {
  id?: string
  criterion_id: string
  criterion_value: number
}

interface IControlScoredComments {
  id?: string
  is_show_operator: boolean
  comment: string
}

export interface IScoreValue {
  score_type: IScoreType
  value: number | null | undefined
}

export interface IControlScored {
  control_id?: string
  id?: string
  created_on?: string
  score_form_id?: string
  values: IScoreValue[]
  detail: IControlScoredDetail[]
  comments?: IControlScoredComments
}

export interface IControlData {
  id: string
  created_by: IAccountUser
  created_on: string
  type: 'CALL' | 'MEETING'
  call: {
    id: string
    type: string
    duration: number
    phone_number: string
    media: string
  }
  meeting: {
    id: string
    name: string
    link: string
  }
  check_status: ICheckStatusType
  score?: IControlScored
  breakpoints?: IBreakPoints[]
  objections?: IObjection[]
}

export interface IControls {
  total: number
  controls: IControlData[]
}

interface IAccountUser {
  id: string
  name: string
}

export interface IControlRecord {
  record_src: string
}

export interface ICurrentRegion {
  id?: string
  start: number
  end?: number
}

export interface ICreator {
  id: string
  name?: string
}

interface IBreakPointDetail {
  start: number
  end?: number
}

interface IPointDetail {
  start: number
  end?: number
}

export interface IBreakPoints {
  id?: string
  comment: string
  comment_color?: IStringColorType
  created_by?: ICreator
  created_on?: string
  type: IBreakPointType
  detail: IBreakPointDetail
}

export interface IBreakPointsCreate {
  id?: string
  control_id: string
  comment: string
  comment_color?: string
  user_id: string
  type: IBreakPointType
  detail?: IBreakPointDetail
}

export interface IBreakPointsUpdate {
  comment?: string
  comment_color?: IStringColorType
  detail?: IBreakPointDetail
}

interface IObjectionScoreData {
  id: string
  name?: string
}

export interface IObjection {
  id: string
  created_by?: ICreator
  created_on?: string
  score_objection: IObjectionScoreData
  objection_value: number
  comment: string
  detail: IPointDetail
}

export interface IObjectionSelect {
  value: string
  label: string
}

export interface IObjectionCreate {
  id?: string
  score_objection_id: string
  objection_value: number
  comment: string
  detail?: IPointDetail
  control_id: string
  user_id: string
}

export interface IObjectionUpdate {
  score_objection_id: string
  objection_value: number
  comment: string
  detail: IPointDetail
}

export interface IFilterQuery {
  type?: string
  sort_by?: string
  period?: string
  call_type?: string
  rating_date?: string
  user_ids?: string
  department_ids?: string
  rating?: string
  duration?: string
  status?: string
  skip?: number
  limit?: number
}

export const IFilterInitialValues = {
  sort_by: 'created_on.desc',
  period: '',
  call_type: '',
  rating_date: '',
  user_ids: '',
  department_ids: '',
  rating: '',
  duration: '',
  status: '',
  skip: 0,
  limit: 0,
}

export interface IControlListResponse {
  total: number
  controls: IControlData[]
}

export interface IControlCreate {
  created_by: string
  created_on: string
  link: string
}
export interface IControlUpdateOwner {
  owner: string
}

export const createControlInitValues = {
  created_on: '',
  created_by: '',
  link: '',
}
