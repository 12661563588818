import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  status: string
}

const CriterionStatusCell: FC<Props> = ({status}) => {
  const intl = useIntl()
  return (
    <>
      <span className='small text-uppercase fw-bold'>
        {status === 'DRAFT'
          ? intl.formatMessage({id: 'DRAFT'})
          : status === 'PUBLISHED'
          ? intl.formatMessage({id: 'PUBLISHED'})
          : intl.formatMessage({id: 'ARCHIVED'})}
      </span>
    </>
  )
}

export {CriterionStatusCell}
