import {FC} from 'react'

type Props = {
  email?: string
}

const ProfileEmail: FC<Props> = ({email}) => {
  return (
    <div className='fv-row mb-8'>
      <label className='ms-2 mb-2 label text-muted text-uppercase'>Email</label>
      <span className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white text-black'>
        {email}
      </span>
    </div>
  )
}

export {ProfileEmail}
