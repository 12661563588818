import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  IBreakPoints,
  IBreakPointsCreate,
  IBreakPointsUpdate,
} from '../../modules/controls/models/controls'

export const breakpointAPI = createApi({
  reducerPath: 'breakpointAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  endpoints: (build) => ({
    breakpointCreate: build.mutation<IBreakPoints, IBreakPointsCreate>({
      query: (data) => ({
        url: '/breakpoints',
        method: 'POST',
        body: data,
      }),
    }),
    breakpointUpdate: build.mutation<IBreakPoints, {id: string; data: IBreakPointsUpdate}>({
      query: ({id, data}) => ({
        url: `/breakpoints/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    breakpointDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/breakpoints/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})
