import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  IQueryParams,
  IUserCreate,
  IUserIsActive,
  IUserModel,
  IUserUpdate,
  ListResponse,
} from '../../models/users/Users'

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['users', 'currentUser'],
  endpoints: (build) => ({
    fetchGetMe: build.query<IUserModel, void>({
      query: () => ({
        url: '/users/me',
      }),
      providesTags: ['currentUser'],
      keepUnusedDataFor: 60 * 30,
    }),
    userFetchByID: build.query<IUserModel, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result) =>
        result ? [{type: 'users', id: result?.id}] : [{type: 'users', id: 'LIST'}],
    }),
    usersFetch: build.query<ListResponse<IUserModel>, IQueryParams>({
      query: ({skip, limit, search, is_active}) => ({
        url: '/users',
        params: {skip: skip, limit: limit, search: search!, is_active: is_active},
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({id}) => ({type: 'users' as const, id})),
              {type: 'users', id: 'LIST'},
            ]
          : [{type: 'users', id: 'LIST'}],
    }),
    userCreate: build.mutation<IUserModel, IUserCreate>({
      query: (data) => ({
        url: '/users',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{type: 'users', id: 'LIST'}],
    }),
    userIsActiveUpdate: build.mutation<IUserModel, {id: string; data: IUserIsActive}>({
      query: ({id, data}) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result) =>
        result ? [{type: 'users', id: result?.id}] : [{type: 'users', id: 'LIST'}],
    }),
    userUpdate: build.mutation<IUserModel, IUserUpdate>({
      query: (data) => ({
        url: `/users/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result) =>
        result ? [{type: 'users', id: result?.id}] : [{type: 'users', id: 'LIST'}],
      async onQueryStarted(userUpdate, {dispatch, queryFulfilled}) {
        const patchResultUserMe = dispatch(
          usersAPI.util.updateQueryData('fetchGetMe', undefined, (user) => {
            if (user.id === userUpdate.id) {
              Object.assign(user, userUpdate)
            }
          })
        )
        const patchResultUser = dispatch(
          usersAPI.util.updateQueryData('userFetchByID', userUpdate.id, (user) => {
            Object.assign(user, userUpdate)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResultUserMe.undo()
          patchResultUser.undo()
        }
      },
    }),
    userSendInvite: build.mutation<string, string>({
      query: (id) => ({
        url: `/users/invite/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [{type: 'users', id: 'LIST'}],
    }),
  }),
})
