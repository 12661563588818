import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleAccess} from '../../../models/users/Users'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {departmentsAPI} from '../../../redux/services/DepartmentsService'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {useControlsCriteriaFilter} from './helpers/ControlsCriteriaFilterProvider'
import {ControlsCriteriaTable} from './controls_criteria_table/ControlsCriteriaTable'
import {ControlCriteriaFooter} from './controls_criteria_table/ControlCriteriaFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {useQueryRequest} from '../../../../_metronic/helpers/pagination/QueryRequestProvider'

const AnalyticsCriteriaFilter = () => {
  const intl = useIntl()
  const {controlsFilter, setControlsFilter} = useControlsCriteriaFilter()
  const {state} = useQueryRequest()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const [callTypeState, setCallTypeState] = useState<string>('')
  const [periodState, setPeriodState] = useState<{start: string; end: string}>({start: '', end: ''})
  const [objectTypeState, setObjectTypeState] = useState<string>('')
  const [typeState, setTypeState] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [selectedDepartment, setSelectedDepartment] = useState<string>('')
  const [getDepartments, {data: departments, isLoading: getDepLoading}] =
    departmentsAPI.useLazyDepartmentsFetchQuery()
  const [getUsers, {data: users, isLoading: getUsersLoading}] = usersAPI.useLazyUsersFetchQuery()
  const [getControls, {data: controls, isLoading: isLoadingControls}] =
    analyticsAPI.useLazyAnalyticsControlscriteriaFetchQuery()

  const [usersOptions, setUsersOptions] = useState<
    {label: string; value: string; disabled?: boolean}[]
  >([])

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: getUsersLoading
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  const setSortUsersOptions = () => {
    if (users) {
      let usersOptions: {label: string; value: string; disabled?: boolean}[] = []
      if (selectedDepartment !== '') {
        for (let i = 0; i < users.users.length; i++) {
          if (
            selectedDepartment === users.users[i].department.id &&
            !usersOptions.find((el) => el.value === users.users[i].department.id)
          ) {
            usersOptions.push({
              label: users.users[i].department.name,
              value: users.users[i].department.id,
              disabled: true,
            })
            usersOptions.push({label: users.users[i].name, value: users.users[i].id})
            for (let j = 0; j < users.users.length; j++) {
              if (
                users.users[j].department.id === users.users[i].department.id &&
                users.users[j].id !== users.users[i].id
              ) {
                usersOptions.push({label: users.users[j].name, value: users.users[j].id})
              }
            }
            usersOptions.push({label: '', value: '', disabled: true})
          }
        }
      }

      setUsersOptions(usersOptions)
    }
  }

  const checkUserInOptions = () => {
    if (usersOptions.length !== 0) {
      let new_users: {label: string; value: string}[] = []
      for (let j = 0; j < selectedUsers.length; j++) {
        let u_id = selectedUsers[j].value
        for (let i = 0; i < usersOptions.length; i++) {
          if (u_id === usersOptions[i].value) {
            new_users.push(selectedUsers[j])
          }
        }
      }
      setSelectedUsers(new_users)
    } else {
      setTimeout(checkUserInOptions, 1000)
    }
  }

  useEffect(() => {
    setSelectedUsers([])
    setSortUsersOptions()

    setControlsFilter({
      type: controlsFilter.type,
      period: controlsFilter.period,
      users: [],
      department_id: selectedDepartment,
      call_type: controlsFilter.call_type,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment])

  useEffect(() => {
    checkUserInOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersOptions])

  useEffect(() => {
    setSortUsersOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.department])

  useEffect(() => {
    if (roleAccesses.length !== 0 && currentUser) {
      if (controlsFilter) {
        setObjectTypeState(controlsFilter.type)
        setTypeState(controlsFilter.type)
        setCallTypeState(controlsFilter.call_type)
        setPeriodState(controlsFilter.period)
        setSelectedUsers(controlsFilter.users)

        if (roleAccesses.includes('CONTROLSDEP')) {
          setSelectedDepartment(currentUser.department.id)
        } else if (roleAccesses.includes('CONTROLSOWN')) {
          setSelectedDepartment(currentUser.department.id)
          setSelectedUsers([{label: currentUser.name, value: currentUser.id}])
        } else {
          setSelectedDepartment(controlsFilter.department_id)
        }

        if (controlsFilter.department_id !== '') {
          getDepartments()
        }

        let data = {
          type: controlsFilter.type !== '' ? controlsFilter.type : undefined,
          period:
            controlsFilter.period.start !== ''
              ? controlsFilter.period.start + ',' + controlsFilter.period.end
              : undefined,
          call_type: controlsFilter.call_type !== '' ? controlsFilter.call_type : undefined,
          user_ids: roleAccesses.includes('CONTROLSOWN')
            ? currentUser.id
            : controlsFilter.users.length !== 0
            ? getSelected(controlsFilter.users)
            : undefined,
          department_id:
            roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN')
              ? currentUser.department.id
              : controlsFilter.department_id !== ''
              ? controlsFilter.department_id
              : '',
          skip: state.skip,
          limit: state.limit,
        }
        if (controlsFilter.department_id !== '') {
          getControls(data)
            .unwrap()
            .then((response) => {})
            .catch((error) => {
              console.log(error)
            })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAccesses])

  useEffect(() => {
    if (roleAccesses.length !== 0 && controlsFilter.department_id !== '') {
      getControls({
        type: controlsFilter.type !== '' ? controlsFilter.type : undefined,
        period:
          controlsFilter.period.start !== ''
            ? controlsFilter.period.start + ',' + controlsFilter.period.end
            : undefined,
        call_type: controlsFilter.call_type !== '' ? controlsFilter.call_type : undefined,
        user_ids: controlsFilter.users.length !== 0 ? getSelected(controlsFilter.users) : undefined,
        department_id: controlsFilter.department_id,
        skip: state.skip,
        limit: state.limit,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          console.log(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.skip, state.limit])

  useEffect(() => {
    setControlsFilter({
      type: controlsFilter.type,
      period: periodState,
      users: controlsFilter.users,
      department_id: controlsFilter.department_id,
      call_type: controlsFilter.call_type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    setControlsFilter({
      type: controlsFilter.type,
      period: controlsFilter.period,
      users: selectedUsers,
      department_id: controlsFilter.department_id,
      call_type: controlsFilter.call_type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  useEffect(() => {
    if (objectTypeState !== 'CALL') {
      if (callTypeState !== '') {
        setCallTypeState('')
      }

      if (controlsFilter.call_type !== '') {
        setControlsFilter({
          type: controlsFilter.type,
          period: controlsFilter.period,
          users: controlsFilter.users,
          department_id: controlsFilter.department_id,
          call_type: '',
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectTypeState])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='card card-custom card-stretch shadow mb-5'>
        <div className='accordion accordion-icon-toggle' id='AccordionCriteriaFilters'>
          <div className='card-header ps-3'>
            <div
              className={clsx('accordion-header card-title m-0 d-flex flex-wrap', {
                collapsed: false,
              })}
              data-bs-toggle='collapse'
              data-bs-target='#AccordionFilters'
            >
              <span className='accordion-icon me-1'>
                <KTSVG
                  className='svg-icon svg-icon-4'
                  path='/media/icons/duotune/arrows/arr064.svg'
                />
              </span>

              <h3 className='fw-bolder m-0 me-2'>{intl.formatMessage({id: 'FILTERS'})}</h3>
            </div>
            <div className='card-toolbar'>
              <div className='d-flex'>
                <button
                  className='btn btn-primary btn-sm me-2'
                  type='button'
                  onClick={async () => {
                    setTypeState(objectTypeState)
                    if (selectedDepartment !== '') {
                      getControls({
                        type: objectTypeState !== '' ? objectTypeState : undefined,
                        period:
                          periodState.start !== ''
                            ? periodState.start + ',' + periodState.end
                            : undefined,
                        call_type: callTypeState !== '' ? callTypeState : undefined,

                        user_ids: roleAccesses.includes('CONTROLSOWN')
                          ? currentUser?.id
                          : selectedUsers.length !== 0
                          ? getSelected(selectedUsers)
                          : undefined,
                        department_id: selectedDepartment,
                        skip: state.skip,
                        limit: state.limit,
                      })
                        .unwrap()
                        .then((response) => {})
                        .catch((error) => {
                          console.log(error)
                        })
                    }
                  }}
                  disabled={isLoadingControls || selectedDepartment === ''}
                >
                  {!isLoadingControls && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'FILTERS.BUTTON.APPLY'})}
                    </span>
                  )}
                  {isLoadingControls && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-light-primary btn-sm'
                  type='button'
                  onClick={async () => {
                    setPeriodState({start: '', end: ''})
                    setCallTypeState('')
                    setObjectTypeState('')

                    if (roleAccesses.includes('CONTROLSALL')) {
                      setSelectedUsers([])
                    } else if (roleAccesses.includes('CONTROLSDEP')) {
                      setSelectedUsers([])
                    }

                    setControlsFilter({
                      type: '',
                      period: {start: '', end: ''},
                      users: roleAccesses.includes('CONTROLSOWN')
                        ? [
                            {
                              label: currentUser ? currentUser.name : '',
                              value: currentUser ? currentUser.id : '',
                            },
                          ]
                        : [],
                      department_id:
                        (roleAccesses.includes('CONTROLSDEP') ||
                          roleAccesses.includes('CONTROLSOWN')) &&
                        currentUser?.department.id
                          ? currentUser?.department.id
                          : controlsFilter.department_id
                          ? controlsFilter.department_id
                          : '',
                      call_type: '',
                    })

                    getControls({
                      department_id: controlsFilter.department_id
                        ? controlsFilter.department_id
                        : '',
                      skip: state.skip,
                      limit: state.limit,
                    })
                      .unwrap()
                      .then((response) => {})
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                  disabled={isLoadingControls}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FILTERS.BUTTON.RESET'})}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id='AccordionFilters'
          className={clsx('fs-6 ps-10', {
            collapse: false,
            show: true,
          })}
          data-bs-parent='AccordionCriteriaFilters'
        >
          <div className='card-body d-flex px-6'>
            <div className='col-lg-6 me-10'>
              <div
                className={clsx('row', {
                  'mb-7':
                    roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP'),
                  'mb-6':
                    !roleAccesses.includes('CONTROLSOWN') && !roleAccesses.includes('CONTROLSDEP'),
                })}
              >
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600 required'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'USERS.TABLE.HEADER.DEPARTMENT'})}
                  </span>
                </label>
                <div
                  className={clsx('col-lg-8 fv-row', {
                    'd-flex align-items-center':
                      roleAccesses.includes('CONTROLSOWN') || roleAccesses.includes('CONTROLSDEP'),
                  })}
                  onClick={() => {
                    if (!departments || departments?.departments.all.length === 0) {
                      getDepartments()
                    }
                  }}
                  style={getDepLoading ? {pointerEvents: 'none'} : undefined}
                >
                  {roleAccesses.includes('CONTROLSDEP') || roleAccesses.includes('CONTROLSOWN') ? (
                    <label className='text-gray-900'>{currentUser?.department.name}</label>
                  ) : (
                    <select
                      className='form-select form-select-solid form-select-lg text-gray-900'
                      value={selectedDepartment}
                      onChange={(e: any) => {
                        setSelectedDepartment(e.target.value)
                      }}
                    >
                      <option value=''>
                        {intl.formatMessage({id: 'ANALYTICS.CRITERIA.SELECTDEP'})}
                      </option>
                      {departments?.departments.all.map((dep) => (
                        <option value={dep.id} key={dep.id}>
                          {dep.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div
                className={clsx('row', {
                  'mb-7': roleAccesses.includes('CONTROLSOWN'),
                  'mb-6': !roleAccesses.includes('CONTROLSOWN'),
                })}
              >
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
                  </span>
                </label>
                <div
                  className={clsx('col-lg-8 fv-row', {
                    'd-flex align-items-center': roleAccesses.includes('CONTROLSOWN'),
                  })}
                  style={getUsersLoading ? {pointerEvents: 'none'} : undefined}
                  onClick={() => {
                    if (usersOptions.length === 0) {
                      getUsers({skip: 0, is_active: true})
                    }
                    setSortUsersOptions()
                  }}
                >
                  {roleAccesses.includes('CONTROLSOWN') ? (
                    <label className='text-gray-900'>{currentUser?.name}</label>
                  ) : (
                    <MultiSelect
                      disabled={selectedDepartment === ''}
                      className='form-control-select form-control-select-lg text-gray-900'
                      options={usersOptions}
                      hasSelectAll={false}
                      value={selectedUsers}
                      onChange={setSelectedUsers}
                      overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                      closeOnChangedValue={false}
                      labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
                    />
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.PERIOD'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <DateRangePickerInput
                    language={currentUser?.language}
                    timeZone={currentUser?.time_zone}
                    rangeId='1'
                    periodState={periodState}
                    updateState={setPeriodState}
                    onlyIcon={false}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.TYPE'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900'
                    value={objectTypeState}
                    onChange={(e: any) => {
                      setObjectTypeState(e.target.value)

                      setControlsFilter({
                        type: e.target.value,

                        period: controlsFilter.period,
                        users: controlsFilter.users,
                        department_id: controlsFilter.department_id,
                        call_type: controlsFilter.call_type,
                      })
                    }}
                  >
                    <option value=''>{intl.formatMessage({id: 'FILTERS.DIRECTION.ALL'})}</option>
                    <option value='CALL'>{intl.formatMessage({id: 'OBJECTS.TABLE.TITLE'})}</option>
                    <option value='MEETING'>
                      {intl.formatMessage({id: 'FILTERS.TYPE.MEETING'})}
                    </option>
                  </select>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                  <span className='small text-uppercase'>
                    {intl.formatMessage({id: 'FILTERS.DIRECTION'})}
                  </span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg text-gray-900'
                    value={callTypeState}
                    disabled={objectTypeState !== 'CALL'}
                    onChange={(e: any) => {
                      setCallTypeState(e.target.value)
                      setControlsFilter({
                        type: controlsFilter.type,
                        period: controlsFilter.period,
                        users: controlsFilter.users,
                        department_id: controlsFilter.department_id,
                        call_type: e.target.value,
                      })
                    }}
                  >
                    <option value=''>{intl.formatMessage({id: 'FILTERS.DIRECTION.ALL'})}</option>
                    <option value='INCOMING'>
                      {intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}
                    </option>
                    <option value='OUTGOING'>
                      {intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom card-stretch shadow mb-5'>
        <div className='card-header'>
          <div className='d-flex flex-stack col-12 me-10'>
            <div className='card-title m-0 d-flex flex-wrap'>
              <h3 className='fw-bolder m-0'>
                {intl.formatMessage({id: 'ANALYTICS.CRITERIA.TABLE.TITLE'})}
              </h3>
            </div>
            <div className='align-self-center d-flex flex-end col-lg-6'></div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <ControlsCriteriaTable
              controls={controls}
              type={typeState !== '' ? typeState : undefined}
            />
          </div>
        </div>
        <ControlCriteriaFooter len={controls?.controls.total} />
      </div>
    </div>
  )
}

export default AnalyticsCriteriaFilter
