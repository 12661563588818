import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {IControlData} from './models/controls'
import {OverlayTrigger} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {Offcanvas, Popover} from 'react-bootstrap'
import {RoleAccess} from '../../models/users/Users'
import {ControlDetail} from './detail/ControlDetail'
import {usersAPI} from '../../redux/services/UsersService'
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query'
import {controlsAPI} from '../../redux/services/ControlsService'
import {getAccesses} from '../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  control: IControlData
}

const CanvasControl: FC<Props> = ({control}) => {
  const intl = useIntl()
  const [show, setShow] = useState<boolean>(false)
  const [updateOwner, setUpdateOwner] = useState<boolean>(false)
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [getControlData, {data: controlData}] = controlsAPI.useLazyControlDataByIdQuery()
  const [updateOwnerMutation] = controlsAPI.useControlUpdateOwnerMutation()
  const [roleAccesses] = useState<Array<RoleAccess>>(getAccesses(currentUser!.role))
  const [getUsers, {data: users, isFetching: usersIsFetching}] = usersAPI.useLazyUsersFetchQuery()
  const [updateToUser, setUpdateToUser] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [
    getRecord,
    {
      data: controlRecord,
      isLoading: loadingRecord,
      isError: errorRecord,
      error: responseErrorRecord,
    },
  ] = controlsAPI.useLazyControlRecordByIdQuery()

  useEffect(() => {
    if (responseErrorRecord) {
      const err = responseErrorRecord as FetchBaseQueryError
      if (err.status === 400) {
        const err_detail = JSON.stringify(err.data)
        setErrorMessage(err_detail.replace('{"detail":', '').replace('}', ''))
      }
    }
  }, [responseErrorRecord, errorRecord])

  useEffect(() => {
    if (control.type === 'CALL' && show === true) {
      getRecord({id: control.id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, show])

  const handleClose = () => {
    setShow(false)
    setUpdateOwner(false)
    setUpdateToUser(undefined)
  }

  const handleShow = () => {
    getControlData({id: control.id}).then(() => setShow(true))
  }

  const handleUpdateOwner = () => {
    setUpdateOwner(true)
    getUsers({skip: 0, is_active: true})
  }

  const handleUpdateOwnerCancel = () => {
    setUpdateOwner(false)
    setUpdateToUser(undefined)
  }

  const handleUpdateOwnerSave = () => {
    if (updateToUser && updateToUser !== control.created_by.id) {
      updateOwnerMutation({id: control.id, data: {owner: updateToUser}})
    }
    setUpdateOwner(false)
    setUpdateToUser(undefined)
  }

  const handleChangeUsers = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUpdateToUser(e.target.value)
  }

  return (
    <>
      <button
        onClick={handleShow}
        className={clsx(
          'btn btn-icon btn-sm',
          control.check_status === 'WAIT' ? 'btn-light-primary' : 'btn-light-success'
        )}
      >
        <span className='svg-icon svg-icon-1'>
          <KTSVG path='/media/icons/duotune/arrows/arr024.svg' className='svg-icon svg-icon-2' />
        </span>
      </button>

      {controlData && (
        <Offcanvas show={show} onHide={handleClose} placement='end' className='control-offcanvas'>
          <Offcanvas.Header className='pb-0 pt-3'>
            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-light h-30px w-30px btn-color-primary btn-active-color-muted position-absolute top-3x start-0 translate-middle'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
            <Offcanvas.Title>
              {roleAccesses.includes('CONTROLSUPDATEOWNER') &&
              control.check_status !== 'CHECKED' ? (
                <div className='d-flex align-items-center'>
                  {updateOwner && !usersIsFetching ? (
                    <div className='d-flex align-items-center'>
                      <select
                        value={updateToUser === undefined ? control.created_by.id : updateToUser}
                        onChange={(e) => {
                          handleChangeUsers(e)
                        }}
                        className='form-select form-select-solid bg-white text-gray-900'
                      >
                        <option value=''>
                          {intl.formatMessage({id: 'CONTROL.SELECT.EMPLOYEE'})}
                        </option>

                        {users!.users.map((usr) => (
                          <option value={usr.id} key={usr.id}>
                            {usr.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className='btn btn-sm btn-primary ms-3'
                        onClick={handleUpdateOwnerSave}
                        disabled={updateToUser === undefined}
                      >
                        {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                      </button>
                      <button
                        className='btn btn-sm btn-primary ms-2'
                        onClick={handleUpdateOwnerCancel}
                      >
                        {intl.formatMessage({id: 'COMMON.BUTTON.CANCEL'})}
                      </button>
                    </div>
                  ) : (
                    <div className='mt-2 d-flex align-items-center'>
                      <h4 className='mb-0'>{controlData.created_by.name}</h4>
                      <OverlayTrigger
                        placement='right-end'
                        trigger={['hover', 'focus']}
                        overlay={
                          <Popover>
                            <div className='p-3 small text-uppercase text-gray-900'>
                              {intl.formatMessage({id: 'CONTROLS.UPDATE_CREATED_BY'})}
                            </div>
                          </Popover>
                        }
                      >
                        <div className='p-0'>
                          <i
                            className='bi bi-pencil fs-3 text-gray-700 text-hover-primary cursor-pointer ms-5'
                            onClick={handleUpdateOwner}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              ) : (
                <h4 className='mb-0 mt-2'>{controlData.created_by.name}</h4>
              )}
              {controlData.type !== 'MEETING' ? (
                <span className='fw-bold fs-8 text-uppercase small'>
                  {formatDateByZone(
                    controlData.created_on,
                    currentUser?.language,
                    currentUser?.time_zone
                  )}
                  <div>
                    {intl.formatMessage({id: 'CONTROLS.TYPE'})}:{' '}
                    {control?.call.type === 'INCOMING'
                      ? intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})
                      : intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}
                  </div>
                  <div>
                    {intl.formatMessage({id: 'CONTROLS.PHONENUMBER'})}:{' '}
                    {control?.call ? control.call.phone_number : undefined}
                  </div>
                  <div>
                    {control.type === 'CALL' && (
                      <>
                        {intl.formatMessage({id: 'FILTERS.TIME'})}:{' '}
                        {control?.call ? control.call.duration : undefined}
                        <span className='text-lowercase'>
                          {intl.formatMessage({id: 'CONTROLS.SECONDS'})}
                        </span>
                      </>
                    )}
                  </div>
                </span>
              ) : (
                <span className='fw-bold fs-8 text-uppercase small'>
                  {formatDateByZone(
                    controlData.created_on,
                    currentUser?.language,
                    currentUser?.time_zone
                  )}
                  <div>
                    {intl.formatMessage({id: 'CONTROLS.TYPE'})}:
                    {intl.formatMessage({id: 'CONTROLS.MEETING'})}
                  </div>
                </span>
              )}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='pt-0'>
            <ControlDetail
              control={controlData}
              controlRecord={controlRecord!}
              loadingRecord={loadingRecord}
              isErrorGetRecord={errorRecord!}
              errorMessage={errorMessage}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  )
}

export {CanvasControl}
