/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {FC, useEffect, useState} from 'react'
import Popover from 'react-bootstrap/Popover'
import {OverlayTrigger} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleAccess} from '../../../models/users/Users'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {convertTime} from '../../../../_metronic/helpers/components/ConvertTime'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'

const AllControls: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [pulseState, setPulseState] = useState<boolean>(true)
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const {data: controlsAll, isLoading: isLoadingControlsAll} = analyticsAPI.useAnalyticsFetchQuery(
    {}
  )
  const {data: controlsAllDuration, isLoading: isLoadingControlsAllDuration} =
    analyticsAPI.useAnalyticsFetchQuery({
      is_durations: true,
    })

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        setPulseState(false)
      }, 10000)
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <div className='card h-100'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='btn btn-icon pulse pulse-primary pe-3 rotate active'>
              <i className='fa-solid fa-phone-volume fs-2 text-primary rotate-n45'></i>
              {pulseState && <span className='pulse-ring'></span>}
            </div>
            <div>
              <Link
                to='/objects/'
                className='small fw-bold text-hover-primary text-gray-800 m-0 text-uppercase'
              >
                {intl.formatMessage({id: 'ANALYTICS.CONTROLS.ALLTIME'})}
              </Link>
              <div className='d-flex flex-wrap fs-6'>
                <div className='small text-gray-500'>
                  {roleAccesses.includes('ANALYTICSALL')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.ALLUSERS'})
                    : roleAccesses.includes('ANALYTICSDEP')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.DEPARTMENT'})
                    : roleAccesses.includes('ANALYTICSOWN')
                    ? intl.formatMessage({id: 'ANALYTICS.CONTROLS.OWN'})
                    : ''}
                </div>
              </div>
            </div>
          </div>
          <div className='card-toolbar m-0'>
            <OverlayTrigger
              placement='top-end'
              trigger={['hover', 'focus']}
              overlay={
                <Popover>
                  <div className='p-3 small text-uppercase text-gray-700'>
                    {intl.formatMessage({id: 'ANALYTICS.CONTROLS.ALLTIME.INFO'})}
                  </div>
                </Popover>
              }
            >
              <div className='p-0'>
                <KTSVG
                  path='/media/icons/duotune/general/gen046.svg'
                  className='svg-icon-2tx btn p-0'
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
          {isLoadingControlsAll || isLoadingControlsAllDuration ? (
            <span className='indicator-progress align-self-center' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm text-primary align-middle ms-2 mt-10'></span>
            </span>
          ) : (
            <>
              <Link to='/objects/' className='fs-3qx fw-bolder mb-3 text-gray-900'>
                {controlsAll?.total}
              </Link>

              <div className='d-flex align-items-center fw-bold flex-wrap fs-4 text-gray-700'>
                <OverlayTrigger
                  placement='bottom-start'
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover>
                      <div className='p-3 small text-uppercase text-gray-700'>
                        {intl.formatMessage({id: 'ANALYTICS.CONTROLS.DURATION'})}
                      </div>
                    </Popover>
                  }
                >
                  <i className='fa-regular fa-clock pe-3 fs-4 btn p-0'></i>
                </OverlayTrigger>

                <span>{controlsAllDuration && convertTime(controlsAllDuration?.total)}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {AllControls}
