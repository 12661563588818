import {useIntl} from 'react-intl'
import {useState} from 'react'
import {RoleAccess} from '../../../../../models/users/Users'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'

const ObjectionHeader = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 text-uppercase small'>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'CRITERIATABLE.NAME'})}
        </th>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'CRITERIATABLE.AUTHOR'})}
        </th>
        <th role='columnheader' className='col-3'>
          {intl.formatMessage({id: 'CRITERIATABLE.CREATEDON'})}
        </th>
        <th role='columnheader' className='col-2'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.STATUS'})}
        </th>
        {roleAccesses.includes('CRITERIAMODIFY')}
        <th role='columnheader' className='col-1'></th>
      </tr>
    </thead>
  )
}

export {ObjectionHeader}
