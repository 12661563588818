import {FC, useState, useEffect, useRef} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {WithChildren} from '../../../../_metronic/helpers'
import {profileAPI} from '../../../redux/services/ProfileService'

const AuthInit: FC<WithChildren> = ({children}) => {
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [profile] = profileAPI.useLazyProfileMeFetchQuery()

  useEffect(() => {
    const requestUser = async () => {
      if (!didRequest.current) {
        await profile()
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    requestUser()
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit}
