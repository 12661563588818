import {FC} from 'react'
import {UserName} from '../cells/UserName'
import {IAverageInfo, IFormAverage} from '../../../../models/analytics/Analytics'
import {useIntl} from 'react-intl'

type Props = {
  object?: IFormAverage
  avergForm?: IAverageInfo[]
  selectedFormsNumber: number
}

const OneRow: FC<Props> = ({object, avergForm, selectedFormsNumber}) => {
  const intl = useIntl()
  return (
    <>
      <tr role='row' className='fs-7'>
        {object && (
          <th role='cell' className='w-200px table-fixed-th'>
            <UserName user={object.created_by} />
          </th>
        )}
        {avergForm && (
          <th
            role='cell'
            className='w-200px table-fixed-th text-gray-600 fw-bold fs-7 gs-0 text-uppercase small '
          >
            {intl.formatMessage({id: 'FORMS.AVERAGE.FORM'})}
          </th>
        )}

        {object && object.average_by_forms.length !== 0
          ? object.average_by_forms.map((i) => (
              <td role='cell' className='min-w-70px px-0 text-center' key={i.id}>
                {i.average}
              </td>
            ))
          : object &&
            Array.from({length: selectedFormsNumber}).map((_, index) => (
              <td role='cell' className='min-w-70px px-0 text-center' key={index}>
                -
              </td>
            ))}
        {object && (
          <td role='cell' className='w-200px px-0 text-center fw-bolder'>
            {object.average_by_user}
          </td>
        )}
        {avergForm &&
          avergForm.map((o) => (
            <td role='cell' className='min-w-70px px-0 text-center fw-bolder' key={o.id}>
              {o.average}
            </td>
          ))}
      </tr>
    </>
  )
}

export {OneRow}
