import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {IProfileModel, ProfileUpdateModel} from '../../models/profile/Profile'
import {baseQueryWithReauthStaticUrl} from './BaseQueryReauth'

export const profileAPI = createApi({
  reducerPath: 'prifileAPI',
  baseQuery: baseQueryWithReauthStaticUrl,
  tagTypes: ['profileMe', 'ProfileInfo'],
  endpoints: (build) => ({
    profileMeFetch: build.query<IProfileModel, void>({
      query: () => ({
        url: '/profile/me',
      }),
      providesTags: ['profileMe'],
      keepUnusedDataFor: 60 * 30,
    }),
    profileInfoFetch: build.query<IProfileModel, void>({
      query: () => ({
        url: '/profile/info',
      }),
      providesTags: ['ProfileInfo'],
    }),
    profileUpdate: build.mutation<IProfileModel, ProfileUpdateModel>({
      query: (data) => ({
        url: `/profile`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['ProfileInfo'],
    }),
  }),
})
