import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {FC, ReactNode, forwardRef, useState} from 'react'
import {ObjectionNameCell} from './cells/ObjectionNameCell'
import {RoleAccess} from '../../../../../models/users/Users'
import {IScoreObjection} from '../../../../../models/score/Score'
import {EditObjectionModal} from '../modals/EditObjectionModal'
import {ObjectionAuthorCell} from './cells/ObjectionAuthorCell'
import {ObjectionStatusCell} from './cells/ObjectionStatusCell'
import {DeleteObjectionModal} from '../modals/DeleteObjectionModal'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {ObjectionCreatedOnCell} from './cells/ObjectionCreatedOnCell'
import {getAccesses} from '../../../../../../_metronic/helpers/components/RoleAccesses'

type Props = {
  objection: IScoreObjection
}
interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

const ObjectionOne: FC<Props> = ({objection}) => {
  const intl = useIntl()
  const [updateObjection, {isLoading: isLoadingUpdate}] = scoreAPI.useObjectionUpdateMutation()

  const [showModalEditObjection, setShowModalEditObjection] = useState<boolean>(false)
  const [showModalDeleteObjection, setShowModalDeleteObjection] = useState<boolean>(false)

  const handleCloseEditObjection = () => setShowModalEditObjection(false)
  const handleShowEditObjection = () => setShowModalEditObjection(true)

  const handleCloseDeleteObjection = () => setShowModalDeleteObjection(false)
  const handleShowDeleteObjection = () => setShowModalDeleteObjection(true)

  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses] = useState<Array<RoleAccess>>(
    currentUser ? getAccesses(currentUser.role) : []
  )

  return (
    <>
      <tr role='row' className='fs-7 text-gray-800 small fw-bold'>
        <td role='cell' className='col-2'>
          <ObjectionNameCell name={objection.name} />
        </td>
        <td role='cell' className='col-2'>
          <ObjectionAuthorCell createdBy={objection.created_by} />
        </td>
        <td role='cell' className='col-3'>
          <ObjectionCreatedOnCell createdOn={objection.created_on} />
        </td>
        <td role='cell' className='col-2'>
          <ObjectionStatusCell status={objection.status} />
        </td>
        {roleAccesses.includes('OBJECTIONMODIFY') && (
          <td role='cell' className='col-2 text-end'>
            <Dropdown drop='down' align='end'>
              <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                <span className='svg-icon svg-icon-3'>
                  <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                <Dropdown.Item
                  bsPrefix='menu-item'
                  as='div'
                  className='px-3'
                  onClick={handleShowEditObjection}
                >
                  <div className='menu-link p-3 text-uppercase small'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                  </div>
                </Dropdown.Item>
                {objection.status === 'PUBLISHED' && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={() => {
                      updateObjection({id: objection.id, data: {status: 'ARCHIVED'}})
                    }}
                    disabled={isLoadingUpdate}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.ARCHIVE'})}
                    </div>
                  </Dropdown.Item>
                )}
                {(objection.status === 'ARCHIVED' || objection.status === 'DRAFT') && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={() => {
                      updateObjection({id: objection.id, data: {status: 'PUBLISHED'}})
                    }}
                    disabled={isLoadingUpdate}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                    </div>
                  </Dropdown.Item>
                )}
                {objection.status === 'DRAFT' && (
                  <Dropdown.Item
                    bsPrefix='menu-item'
                    as='div'
                    className='px-3'
                    onClick={handleShowDeleteObjection}
                  >
                    <div className='menu-link p-3 text-uppercase small'>
                      {intl.formatMessage({id: 'CRITERION.DELETEDRAFT'})}
                    </div>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <EditObjectionModal
              objection={objection}
              show={showModalEditObjection}
              handleClose={handleCloseEditObjection}
            />
            <DeleteObjectionModal
              objection={objection}
              show={showModalDeleteObjection}
              handleClose={handleCloseDeleteObjection}
            />
          </td>
        )}
      </tr>
    </>
  )
}

export {ObjectionOne}
