import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  language?: string
  time_zone?: string
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
]

const Language: FC<Props> = ({language, time_zone}) => {
  const currentLanguage = languages.find((x) => x.lang === language)
  const intl = useIntl()

  return (
    <div className='fv-row mb-8 d-flex flex-center'>
      <div className='d-flex flex-col me-10'>
        <label className='ms-2 me-2 mb-2 label text-muted text-uppercase'>
          {intl.formatMessage({id: 'MENU.LANGUAGE'})}:
        </label>
        <label className='fw-bold'>{currentLanguage?.name}</label>
        <img
          className='w-15px h-15px ms-2 rounded-1 align-self-center'
          src={currentLanguage?.flag}
          alt='metronic'
        />
      </div>

      <div className='d-flex flex-col w-60'>
        <label className='me-2 mb-2 label text-muted text-uppercase'>
          {intl.formatMessage({id: 'AUTH.PROFILEINFO.TIMEZONE'})}:
        </label>
        <label className='fw-bold'>{time_zone}</label>
      </div>
    </div>
  )
}

export {Language}
