import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  IObjection,
  IObjectionCreate,
  IObjectionUpdate,
} from '../../modules/controls/models/controls'

export const objectionAPI = createApi({
  reducerPath: 'objectiontAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  endpoints: (build) => ({
    objectionCreate: build.mutation<IObjection, IObjectionCreate>({
      query: (data) => ({
        url: '/objection',
        method: 'POST',
        body: data,
      }),
    }),
    objectionUpdate: build.mutation<IObjection, {id: string; data: IObjectionUpdate}>({
      query: ({id, data}) => ({
        url: `/objection/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    objectionDelete: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `/objection/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})
