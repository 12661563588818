/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import * as Yup from 'yup'
import 'moment/locale/ru'
import {Moment} from 'moment'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import Datetime from 'react-datetime'
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {usersAPI} from '../../../redux/services/UsersService'
import {controlsAPI} from '../../../redux/services/ControlsService'
import {IIntegration} from '../../../models/integrations/Integrations'
import {IControlCreate, createControlInitValues} from '../models/controls'

const controlsAddShema = (f: any) =>
  Yup.object().shape({
    created_by: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    link: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  integrations: IIntegration[]
  show: boolean
  handleClose: () => void
}

const CreateControlModal: FC<Props> = ({integrations, show, handleClose}) => {
  const [controlCreate, {isLoading, isError}] = controlsAPI.useControlCreateMutation()
  const [intgrId, updateIntgrId] = useState<string>(integrations[0].id)
  const [getUsers, {data: users, isLoading: isloadingUsers}] = usersAPI.useLazyUsersFetchQuery()
  const intl = useIntl()
  const [dateTimeState, setDateTime] = useState<Date>(new Date())

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const formik = useFormik({
    initialValues: createControlInitValues,
    validationSchema: controlsAddShema(intl.formatMessage),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const data = {
        created_by: values.created_by,
        created_on: dateTimeState.toISOString(),
        link: values.link,
      }
      await controlCreate({id: intgrId, data: data as IControlCreate})
        .unwrap()
        .then(() => {
          handleCloseModal()
          setDateTime(new Date())
        })
        .catch((error) => {
          setStatus(error.data.detail)
          setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    if (integrations.length !== 0) {
      updateIntgrId(integrations[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations])

  useEffect(() => {
    if (show === true) {
      setDateTime(new Date())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'CONTROLS.CREATE.TITLE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>

        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            {formik.status && (
              <>
                {isError && (
                  <div className='alert alert-danger mb-5'>
                    <div className='alert-text font-weight-bold text-uppercase'>
                      {formik.status}
                    </div>
                  </div>
                )}
                {!isError && (
                  <div className='alert alert-info mb-5'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}
              </>
            )}
            <div className='row mb-6  flex-center'>
              <label className='col-lg-4 col-form-label fw-bolder text-gray-600'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'INTEGRATION'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                {integrations.length > 1 ? (
                  <select
                    disabled={isloadingUsers}
                    onChange={(e: any) => {
                      updateIntgrId(e.target.value)
                    }}
                    className='form-select form-select-solid form-select-lg text-gray-900 text-uppercase'
                  >
                    {integrations.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                ) : integrations.length !== 0 ? (
                  <span className='small text-uppercase text-gray-900 fw-bold'>
                    {integrations[0].name}
                  </span>
                ) : (
                  <span className='small text-uppercase text-danger'>No integrations</span>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  onClick={() => {
                    if (!users) {
                      getUsers({is_active: true})
                    }
                  }}
                  className='form-select form-select-solid form-select-lg text-gray-900'
                  {...formik.getFieldProps('created_by')}
                >
                  <option value='' disabled>
                    {intl.formatMessage({id: 'CONTROL.SELECT.EMPLOYEE'})}
                  </option>
                  {users &&
                    users.users.map((u) => (
                      <option value={u.id} key={u.id}>
                        {u.name}
                      </option>
                    ))}
                </select>
                {formik.touched.created_by && formik.errors.created_by && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.created_by}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CONTROL.CREATE.DATE'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Datetime
                  className='input-group input-group-solid'
                  initialValue={dateTimeState}
                  dateFormat='DD/MM/YYYY'
                  locale='ru'
                  onChange={(value) => {
                    setDateTime((value as Moment).toDate())
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <label className='col-lg-4 col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'CONTROLS.MEDIA'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  placeholder={intl.formatMessage({id: 'CONTROLS.MEDIA'})}
                  {...formik.getFieldProps('link')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                    {
                      'is-invalid': (formik.touched.link && formik.errors.link) || isError,
                    }
                  )}
                />
                {formik.touched.link && formik.errors.link && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.link}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'CONTROLS.ADD.BUTTON'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {CreateControlModal}
