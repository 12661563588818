import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

const wl = window.location

export type ControlsFilter = {
  type: string
  sort_by: string
  period: {start: string; end: string}
  duration: {start: string; end: string}
  rating: {start: string; end: string}
  users: {label: string; value: string}[]
  departments: {label: string; value: string}[]
  status: string
  call_type: string
  rating_date: {start: string; end: string}
}

type selectedControlsFilterCallback = {
  controlsFilter: ControlsFilter
  setControlsFilter: (newFilter: ControlsFilter) => void
}

const initialStateControlsFilter: ControlsFilter = {
  type: '',
  sort_by: 'created_on.desc',
  period: {
    start: '',
    end: '',
  },
  users: [],
  departments: [],
  duration: {
    start: '',
    end: '',
  },
  rating: {
    start: '',
    end: '',
  },
  rating_date: {
    start: '',
    end: '',
  },
  call_type: '',
  status: '',
}

const initialStateControlsFilterCallback: selectedControlsFilterCallback = {
  controlsFilter: initialStateControlsFilter,
  setControlsFilter: () => {},
}

function getFilter(key: string): ControlsFilter {
  const ls = localStorage.getItem(key)
  if (ls) {
    try {
      return JSON.parse(ls) as ControlsFilter
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateControlsFilter
}

const controlsFilterContext = createContext<selectedControlsFilterCallback>(
  initialStateControlsFilterCallback
)

const ControlsFilterProvider: FC<WithChildren> = ({children}) => {
  const [controlsFilter, setControlsFilter] = useState<ControlsFilter>(
    getFilter(`${wl.hostname}_controlsFilter`)
  )
  const setFilter = (filter: ControlsFilter) => {
    localStorage.setItem(`${wl.hostname}_controlsFilter`, JSON.stringify(filter))
    setControlsFilter(filter)
  }

  return (
    <controlsFilterContext.Provider
      value={{controlsFilter: controlsFilter, setControlsFilter: setFilter}}
    >
      {children}
    </controlsFilterContext.Provider>
  )
}

const useControlsFilter = () => useContext(controlsFilterContext)
export {ControlsFilterProvider, useControlsFilter}
