import {IRatingScale} from '../../../../../../models/score/Score'

export const checkMaxValueMinRangeMax = (ratingScale: IRatingScale) => {
  if (ratingScale === 'QUATERNARY_SCALE') {
    return 2
  } else if (ratingScale === 'QUINKARNARY_SCALE') {
    return 3
  } else if (ratingScale === 'DECINARY_SCALE') {
    return 8
  }
}
export const checkMaxValueMaxRangeMin = (ratingScale: IRatingScale) => {
  if (ratingScale === 'QUATERNARY_SCALE') {
    return 4
  } else if (ratingScale === 'QUINKARNARY_SCALE') {
    return 5
  } else if (ratingScale === 'DECINARY_SCALE') {
    return 10
  }
}
export const checkMaxValueAverageMax = (ratingScale: IRatingScale) => {
  if (ratingScale === 'QUATERNARY_SCALE') {
    return 3
  } else if (ratingScale === 'QUINKARNARY_SCALE') {
    return 4
  } else if (ratingScale === 'DECINARY_SCALE') {
    return 9
  }
}
export const checkMaxValueAverageMin = (ratingScale: IRatingScale) => {
  if (ratingScale === 'QUATERNARY_SCALE') {
    return 3
  } else if (ratingScale === 'QUINKARNARY_SCALE') {
    return 4
  } else if (ratingScale === 'DECINARY_SCALE') {
    return 9
  }
}
