import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAppDispatch} from '../../../redux/hooks/redux'
import {authAPI} from '../../../redux/services/AuthService'
import {loginAction} from '../../../redux/slices/authSlice'
import {profileAPI} from '../../../redux/services/ProfileService'
import {useIntl} from 'react-intl'
import {setFrogotPasswordBackground, setRegistrBackground} from '../../../redux/slices/serviceSlice'

const loginSchema = (f: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(f({id: 'FORMIK.ERROR.WRONGEMAILFORMAT'}))
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
    password: Yup.string()
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

const {REACT_APP_INITIAL_USERNAME, REACT_APP_INITIAL_PASSWORD, REACT_APP_URL} = process.env

const initialValues = {
  email: !REACT_APP_INITIAL_USERNAME ? '' : REACT_APP_INITIAL_USERNAME,
  password: !REACT_APP_INITIAL_PASSWORD ? '' : REACT_APP_INITIAL_PASSWORD,
}

export function Login() {
  const dispatch = useAppDispatch()
  const [userLogin, {isLoading: loading, isError}] = authAPI.useLoginMutation()
  const [getProfile] = profileAPI.useLazyProfileMeFetchQuery()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema(intl.formatMessage),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await userLogin({username: values.email, password: values.password})
        .unwrap()
        .then(async (result) => {
          dispatch(loginAction(result))
          await getProfile()
        })
        .catch((error) => {
          if (error.status === 400) {
            setStatus(intl.formatMessage({id: 'DETAIL.LOGINERROR'}))
          } else {
            setStatus(intl.formatMessage({id: error.data.detail}))
          }
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form
        className='form min-w-150px w-lg-400px'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <a href={`${REACT_APP_URL}`}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-white.svg')}
              className='h-50px app-sidebar-logo-default mb-5'
            />
          </a>
          <h1 className='text-light fw-bolder mb-3'>
            {intl.formatMessage({id: 'AUTH.SIGNIN.TITLE'})}
          </h1>
          <div className='text-muted fw-semibold fs-6'>
            {intl.formatMessage({id: 'AUTH.SIGNIN.SUBTITLE'})}
          </div>
        </div>

        <div className='separator separator-content my-14'>
          <span className='text-muted fw-semibold fs-7 min-w-125px'>
            {intl.formatMessage({id: 'AUTH.SIGNIN.WITHEMAIL'})}
          </span>
        </div>

        {formik.status && (
          <>
            {isError && (
              <div className='w-lg-400px mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {!isError && (
              <div className='w-lg-400px mb-lg-15 alert alert-info'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
          </>
        )}

        <div className='fv-row mb-8'>
          <input
            placeholder='Email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx('form-control bg-white text-black', {
              'is-invalid': (formik.touched.email && formik.errors.email) || isError,
            })}
            type='email'
            name='email'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-3'>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-white text-black', {
              'is-invalid': (formik.touched.password && formik.errors.password) || isError,
            })}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
          <Link
            to='/auth/forgot-password/'
            className='link-info'
            onClick={() => {
              dispatch(setFrogotPasswordBackground())
            }}
          >
            {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
          </Link>
        </div>
        <div className='d-flex flex-center mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-info btn-sm w-200px'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {window.location.hostname.includes('my.') === true && (
          <div className='text-muted text-center fw-semibold fs-6'>
            {intl.formatMessage({id: 'AUTH.REGISTER.PRETITLE'})}{' '}
            <Link
              to='/auth/registration/'
              className='link-info'
              onClick={() => {
                dispatch(setRegistrBackground())
              }}
            >
              {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}
            </Link>
          </div>
        )}
      </form>
    </>
  )
}
