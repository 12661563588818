import {FC} from 'react'

interface Props {
  showBreakpoints: () => void
  showObjections: () => void
}

const Filter: FC<Props> = ({showBreakpoints, showObjections}) => {
  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className='form-check form-check-custom form-check-solid form-check-sm mt-3'>
          <input
            id='commentCheck'
            className='form-check-input cursor-pointer'
            type='checkbox'
            value=''
            defaultChecked={true}
            onChange={showBreakpoints}
          />
          <label className='form-check-label cursor-pointer' htmlFor='commentCheck'>
            show comments
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid form-check-sm ms-3 mt-3'>
          <input
            id='objectionCheck'
            className='form-check-input cursor-pointer'
            type='checkbox'
            value=''
            defaultChecked={true}
            onChange={showObjections}
          />
          <label className='form-check-label cursor-pointer' htmlFor='objectionCheck'>
            show objection
          </label>
        </div>
      </div>
      <div className='separator mt-2 mb-5' />
    </>
  )
}

export default Filter
