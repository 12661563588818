import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  ICreateIntegration,
  IIntegartionsList,
  IIntegration,
  IIntegrationDelete,
  IIntegrationUpdate,
} from '../../models/integrations/Integrations'

export const integrationsAPI = createApi({
  reducerPath: 'integrationsAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['integrations'],
  endpoints: (build) => ({
    integrationsFetch: build.query<IIntegartionsList, void>({
      query: () => ({
        url: '/integrations',
      }),
      providesTags: ['integrations'],
    }),
    integrationById: build.query<IIntegration, {id: string}>({
      query: ({id}) => ({
        url: `/integrations/${id}`,
      }),

      providesTags: ['integrations'],
    }),
    integrationCreate: build.mutation<IIntegration, ICreateIntegration>({
      query: (data) => ({
        url: '/integrations',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['integrations'],
    }),
    integrationUpdate: build.mutation<IIntegration, {id: string; data: IIntegrationUpdate}>({
      query: ({id, data}) => ({
        url: `/integrations/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['integrations'],
    }),
    integrationDelete: build.mutation<void, {id: string; data?: IIntegrationDelete}>({
      query: ({id, data}) => ({
        url: `/integrations/${id}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['integrations'],
    }),
  }),
})
