import {OneRow} from './OneRow'
import {FC, useEffect, useState} from 'react'
import {ObjectionsHeader} from './ObjectionsHeader'
import {IAnalyticsObjectionsResponse, IObjectionsUser} from '../../../../models/analytics/Analytics'
import {useQueryRequest} from '../../../../../_metronic/helpers/pagination/QueryRequestProvider'

type Props = {
  objectionsAnalytics?: IAnalyticsObjectionsResponse | undefined
  selectedUsers?: {label: string; value: string}[]
}

export const ObjectionsTable: FC<Props> = ({objectionsAnalytics, selectedUsers}) => {
  const [pageObjections, updatePageObjections] = useState<IObjectionsUser[]>([])
  const [allObjections, updateAllObjections] = useState<IObjectionsUser[]>([])
  const {state} = useQueryRequest()

  useEffect(() => {
    if (objectionsAnalytics && selectedUsers?.length !== 0) {
      let emptyUsers: IObjectionsUser[] = []
      selectedUsers?.forEach((user) => {
        let mark = false
        for (let d of objectionsAnalytics?.data) {
          if (user.value === d.created_by.id) {
            mark = true
            break
          }
        }
        if (mark === false) {
          emptyUsers.push({
            created_by: {
              id: user.value,
              name: user.label,
            },
            objections_average: [],
            average_all: '-',
          })
        }
      })
      updateAllObjections(objectionsAnalytics.data.concat(emptyUsers))
    } else if (!objectionsAnalytics) {
      updateAllObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectionsAnalytics])

  useEffect(() => {
    if (allObjections.length !== 0) {
      if (allObjections.length >= state.skip! + state.limit!) {
        updatePageObjections(allObjections.slice(state.skip, state.skip! + state.limit!))
      } else {
        updatePageObjections(allObjections.slice(state.skip))
      }
    } else {
      updatePageObjections([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, allObjections])

  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <ObjectionsHeader
          objections={
            objectionsAnalytics && Object.keys(objectionsAnalytics.score_objections).length !== 0
              ? objectionsAnalytics?.score_objections
              : {}
          }
        />
        <tbody>
          {pageObjections.map((u) => (
            <OneRow
              object={u}
              key={u.created_by.id}
              objectionsNumber={
                objectionsAnalytics ? Object.keys(objectionsAnalytics.score_objections).length : 0
              }
            />
          ))}
        </tbody>
      </table>
    </>
  )
}
