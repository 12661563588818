import clsx from 'clsx'
import {HTMLProps} from 'react'

export type PropsPageLink = HTMLProps<HTMLAnchorElement> & {active?: boolean}

export default function PageLink({className, active, disabled, children, ...otherProps}: PropsPageLink) {
  const customClassName = clsx('page-link', className, {
    active,
    disabled,
  })

  if (disabled) {
    return <span className={customClassName}>{children}</span>
  }

  return (
    <a className={className} style={{cursor: 'pointer'}} {...otherProps}>
      {children}
    </a>
  )
}
