export function formatDateByZone(date: string, language?: 'en' | 'ru', timeZone?: string) {
  const dateStr = new Date(date + 'Z')
  const defaultZone = 'UTC'

  try {
    const formatedDate = dateStr.toLocaleString(language ? language : 'en', {
      timeZone: timeZone ? timeZone : defaultZone,
      dateStyle: 'long',
      timeStyle: 'medium',
      hour12: false,
    })
    return formatedDate
  } catch (error) {
    const formatedDate = dateStr.toLocaleString(language ? language : 'en', {
      timeZone: defaultZone,
      dateStyle: 'long',
      timeStyle: 'medium',
      hour12: false,
    })
    return formatedDate
  }
}
