import clsx from 'clsx'
import WaveSurfer from 'wavesurfer.js'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Regions from 'wavesurfer.js/dist/plugins/regions'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {StringToRGBA} from '../../../helpers/control-helpers'
import {IScoreObjection} from '../../../../../models/score/Score'
import {usersAPI} from '../../../../../redux/services/UsersService'
import {ICurrentRegion, IObjection} from '../../../models/controls'
import {scoreAPI} from '../../../../../redux/services/ScoreService'
import {SelectScoreObjection} from './components/SelectScoreObjection'
import {SelectedScoreObjection} from './components/SelectedScoreObjection'
import {objectionAPI} from '../../../../../redux/services/ObjectionService'
import {formatDateByZone} from '../../../../../../_metronic/helpers/FormatDateZoneHelpers'

type Props = {
  c_type: 'CALL' | 'MEETING'
  waveSurfer: WaveSurfer
  objectionData: IObjection
  waveSurferRegions: Regions | undefined
  removeObjection: (id: string) => void
}

const ControlObjection: FC<Props> = ({
  waveSurfer,
  objectionData,
  removeObjection,
  waveSurferRegions,
  c_type,
}) => {
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [isPlayPause, setIsPlayPause] = useState<boolean>(false)
  const [loop, setLoop] = useState<boolean>(false)
  const [editRedion, setEditRedion] = useState<boolean>(false)
  const [objection, setObjection] = useState<IObjection>(objectionData)
  const [comment, setComment] = useState<string>(objection.comment)
  const [objectionUpdate] = objectionAPI.useObjectionUpdateMutation()
  const [isSaveDisable, setIsSaveDisable] = useState<boolean>(false)
  const [objectionValue, setObjectionValue] = useState<number>(objection.objection_value)
  const {data: scoreObjections} = scoreAPI.useObjectionFetchQuery({}, {skip: !editRedion})
  const [selectedScoreObjection, setSelectedScoreObjection] = useState<IScoreObjection>()
  const [updateRegionData, setUpdateRegionData] = useState<ICurrentRegion>(
    c_type === 'CALL'
      ? {
          start: objection.detail.start,
          end: objection.detail.end,
        }
      : {start: 0, end: 0}
  )

  useEffect(() => {
    if (scoreObjections && scoreObjections.objections.length > 0) {
      setSelectedScoreObjection(
        scoreObjections.objections.find((obj) => obj.id === objection.score_objection.id)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreObjections])

  const regions = waveSurferRegions?.getRegions()
  const currentRegion = regions?.filter((region) => region.id === objection.id)[0]
  if (currentRegion !== undefined) {
    currentRegion.setOptions({color: StringToRGBA('BLUE')})
  }

  if (
    waveSurfer !== undefined &&
    currentRegion !== undefined &&
    c_type === 'CALL' &&
    waveSurferRegions !== undefined
  ) {
    waveSurfer.once('timeupdate', (currentTime) => {
      if (objection.detail.start <= currentTime && currentTime <= objection.detail.end!) {
        if (isPlayPause) {
          if (loop && currentTime >= objection.detail.end! - 0.1) {
            currentRegion.play()
          } else if (currentTime >= objection.detail.end! - 0.1) {
            waveSurfer.pause()
          }
        }
      }
    })

    waveSurfer.on('pause', () => {
      setIsPlayPause(false)
      setLoop(false)
    })

    waveSurferRegions.on('region-updated', (region) => {
      if (currentRegion !== undefined) {
        if (currentRegion.id === region.id || editRedion) {
          setUpdateRegionData({
            start: parseFloat(region.start.toFixed(2)),
            end: parseFloat(region.end.toFixed(2)),
          })
        }
      }
    })
  }

  const PlayPause = () => {
    if (currentRegion !== undefined) {
      if (isPlayPause) {
        waveSurfer.pause()
      } else {
        waveSurfer.pause()
        setTimeout(() => {
          setIsPlayPause(true)

          currentRegion.play()
        }, 100)
      }
    }
  }

  const updateRegion = () => {
    setEditRedion(true)

    if (currentRegion !== undefined) {
      currentRegion.setOptions({drag: true, resize: true})
    }
  }

  const cancelRegion = () => {
    setEditRedion(false)
    setUpdateRegionData(
      c_type === 'CALL'
        ? {start: objection.detail.start, end: objection.detail.end}
        : {start: 0, end: 0}
    )
    setComment(objection.comment)

    if (currentRegion !== undefined) {
      currentRegion.setOptions({
        drag: false,
        resize: false,
        start: c_type === 'CALL' ? objection.detail.start : 0,
        end: c_type === 'CALL' ? objection.detail.end : 0,
        color: StringToRGBA('BLUE'),
      })
    }

    setIsSaveDisable(false)
    setObjectionValue(objection.objection_value)
    if (scoreObjections && scoreObjections.objections.length > 0) {
      setSelectedScoreObjection(
        scoreObjections.objections.find((obj) => obj.id === objection.score_objection.id)
      )
    }
  }

  const saveRegion = () => {
    setEditRedion(false)

    if (currentRegion !== undefined) {
      currentRegion.setOptions({drag: false, resize: false})
    }

    objectionUpdate({
      id: objection.id,
      data: {
        comment: comment,
        detail: updateRegionData,
        score_objection_id: selectedScoreObjection
          ? selectedScoreObjection.id
          : objection.score_objection.id!,
        objection_value: objectionValue,
      },
    })
      .unwrap()
      .then((response) => {
        setObjection(response)
      })
  }

  const removeRegion = () => {
    if (currentRegion !== undefined) {
      currentRegion.remove()
    }
    setEditRedion(false)
    removeObjection(
      c_type === 'CALL' && currentRegion !== undefined ? currentRegion.id : objection.id
    )
  }

  const handleChangeStart = (start: number) => {
    setUpdateRegionData({start: start, end: updateRegionData.end})

    if (currentRegion !== undefined) {
      currentRegion.setOptions({start: start})
    }
  }
  const handleChangeEnd = (end: number) => {
    setUpdateRegionData({start: updateRegionData.start, end: end})

    if (currentRegion !== undefined) {
      currentRegion!.setOptions({end: end})
    }
  }

  const handleSetObjection = (objection?: IScoreObjection) => {
    setSelectedScoreObjection(objection)
  }

  return (
    <>
      {editRedion ? (
        <div className='position-relative card-rounded mb-3'>
          <div className='card shadow-sm card-bordered border-info'>
            <div className='card-body pt-1 pb-1'>
              <div className='py-3 mt-5'>
                <SelectScoreObjection
                  setSelectObjection={handleSetObjection}
                  initialDefaultValue={objection.score_objection.id}
                />
              </div>
              {selectedScoreObjection && (
                <SelectedScoreObjection
                  selectedScoreObjection={selectedScoreObjection}
                  setIsSaveDisable={setIsSaveDisable}
                  comment={comment || ''}
                  setComment={setComment}
                  objectionValue={objectionValue}
                  setObjectionValue={setObjectionValue}
                />
              )}

              <div className='d-flex justify-content-between align-items-center flex-stack flex-wrap mt-3 mb-2'>
                {c_type === 'CALL' && (
                  <div className='d-flex align-items-center'>
                    <button className='btn btn-icon play-pause me-2' onClick={PlayPause}>
                      <i
                        className={clsx('fs-3x bi', {
                          'bi-play-fill text-gray-700 text-hover-primary': !isPlayPause,
                          'bi-stop-fill text-primary': isPlayPause,
                        })}
                      />
                    </button>
                    <label className='me-1 fw-bold'>START</label>
                    <input
                      type='number'
                      min={0}
                      step={0.1}
                      className='form-control form-control-sm w-80px me-2'
                      onChange={(e) => handleChangeStart(parseFloat(e.target.value))}
                      value={updateRegionData.start}
                    />
                    <label className='me-1 fw-bold'>END</label>
                    <input
                      type='number'
                      min={0}
                      step={0.1}
                      className='form-control form-control-sm w-80px'
                      onChange={(e) => handleChangeEnd(parseFloat(e.target.value))}
                      value={updateRegionData.end}
                    />
                  </div>
                )}
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-sm btn-primary me-2'
                    onClick={saveRegion}
                    disabled={isSaveDisable}
                  >
                    Save
                  </button>
                  <button className='btn btn-sm btn-light-primary' onClick={cancelRegion}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          {objection!.created_by!.id === currentUser!.id && (
            <div className='ribbon ribbon-triangle ribbon-top-start border-gray-400'>
              <div className='ribbon-icon ms-n6 mt-n6'>
                <i
                  className='bi bi-trash3 fs-3 text-white text-hover-danger cursor-pointer'
                  onClick={() => removeRegion()}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='overflow-hidden position-relative card-rounded mb-3'>
          <div className='card card-bordered d-flex flex-column flex-sm-row p-5 align-items-center card card-flush shadow-sm border-info'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon svg-icon-3x me-4 text-info'
            />
            <div className='card-body p-0 w-100'>
              <h5 className='mb-1 text-dark'>{objection.created_by?.name}</h5>
              <span className='small'>
                {formatDateByZone(
                  objection.created_on!,
                  currentUser?.language,
                  currentUser?.time_zone
                )}
              </span>
              <div className='separator mt-2 mb-1' />
              <div className='fw-bolder mt-2'>{objection.score_objection.name}</div>
              <div className='d-flex justify-content-start my-3'>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-danger',
                      {
                        active: objection.objection_value === 1,
                      }
                    )}
                    disabled
                  >
                    <span className='small'>1</span>
                  </button>
                  <div className='bg-danger rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-warning',
                      {
                        active: objection.objection_value === 2,
                      }
                    )}
                    disabled
                  >
                    <span className='small'>2</span>
                  </button>
                  <div className='bg-warning rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-warning',
                      {
                        active: objection.objection_value === 3,
                      }
                    )}
                    disabled
                  >
                    <span className='small'>3</span>
                  </button>
                  <div className='bg-warning rounded me-2 mt-1 h-5px'></div>
                </div>
                <div>
                  <button
                    className={clsx(
                      'btn btn-sm btn-outline btn-outline-gray me-2 btn-active-success',
                      {
                        active: objection.objection_value === 4,
                      }
                    )}
                    disabled
                  >
                    <span className='small'>4</span>
                  </button>
                  <div className='bg-success rounded me-2 mt-1 h-5px'></div>
                </div>
              </div>
              <span className='text-dark fs-5'>{objection.comment}</span>
              {c_type === 'CALL' && (
                <>
                  <div className='separator mt-3'></div>
                  <div className='fw-bold mt-3 text-gray-800'>
                    <span className='me-3'>START:</span>
                    {objection.detail.start}
                    {' sec '} - <span className='me-3'>END:</span>
                    {objection.detail.end} sec
                  </div>
                </>
              )}
            </div>
            {c_type === 'CALL' && (
              <>
                <button className='btn btn-icon' onClick={() => setLoop(!loop)}>
                  <OverlayTrigger
                    delay={{show: 50, hide: 50}}
                    overlay={
                      <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                        Repeat
                      </Tooltip>
                    }
                  >
                    <i className={clsx('fa-solid fa-repeat fs-1x ms-2', {'text-primary': loop})} />
                  </OverlayTrigger>
                </button>
                <button className='btn btn-icon play-pause' onClick={PlayPause}>
                  <i
                    className={clsx('fs-4x bi', {
                      'bi-play-fill text-gray-700 text-hover-primary': !isPlayPause,
                      'bi-stop-fill text-primary': isPlayPause,
                    })}
                  />
                </button>
              </>
            )}
          </div>
          {objection!.created_by!.id === currentUser!.id && (
            <div className='ribbon ribbon-triangle ribbon-bottom-end border-gray-400'>
              <div className='ribbon-icon mt-0 me-n6'>
                <i
                  className='bi bi-pencil fs-3 text-white text-hover-primary cursor-pointer'
                  onClick={updateRegion}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export {ControlObjection}
