import {FC} from 'react'

type Props = {
  criterionName: string
  ratingScale: string
}

const CriterionNameCell: FC<Props> = ({criterionName, ratingScale}) => {
  return (
    <>
      <span className='small text-uppercase fw-bolder'>{criterionName}</span>
    </>
  )
}

export {CriterionNameCell}
