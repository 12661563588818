/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IMutationDepartment} from '../../../../../models'
import {IObjectionGroup} from '../../../../../models/score/Score'
import {scoreAPI} from '../../../../../redux/services/ScoreService'

const groupAddShema = (f: any) =>
  Yup.object().shape({
    name: Yup.string().required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

type Props = {
  group: IObjectionGroup
  show: boolean
  handleClose: () => void
}

const RenameGroup: FC<Props> = ({group, show, handleClose}) => {
  const intl = useIntl()
  const [updateGroup, {isLoading, isError}] = scoreAPI.useGroupObjectionUpdateMutation()

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const formik = useFormik({
    initialValues: Object.assign({name: ''}, group),
    validationSchema: groupAddShema(intl.formatMessage),
    onSubmit: async (values) => {
      const data: IMutationDepartment = {
        name: values.name,
      }
      await updateGroup({id: group.id, data: data})
        .unwrap()
        .then(() => handleClose())
        .catch(() => formik.resetForm())
    },
  })

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='mw-500px'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.CHANGE'})}</h4>
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <form className='form' noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row'>
              <label className='col-lg-4 col-form-label fw-bolder text-gray-600 required'>
                <span className='small text-uppercase'>
                  {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.NAME'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  placeholder={intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.NAME'})}
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid mb-3 mb-lg-0 text-gray-900',
                    {
                      'is-invalid': (formik.touched.name && formik.errors.name) || isError,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className='card-footer d-flex justify-content-end py-2 px-1'
              style={{border: 'none'}}
            >
              <button
                type='submit'
                className='btn btn-sm btn-primary text-uppercase'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'COMMON.BUTTON.SAVE'})}
                  </span>
                )}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export {RenameGroup}
