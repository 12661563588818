/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import Popover from 'react-bootstrap/Popover'
import {OverlayTrigger} from 'react-bootstrap'
import {getPercent} from '../DashboardWrapper'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleAccess} from '../../../models/users/Users'
import {usersAPI} from '../../../redux/services/UsersService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {convertTime} from '../../../../_metronic/helpers/components/ConvertTime'

const AllCheckedControls: FC = () => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const [pulseState, setPulseState] = useState<boolean>(true)
  const {data: controlsAll} = analyticsAPI.useAnalyticsFetchQuery({})
  const {data: controlsAllDuration} = analyticsAPI.useAnalyticsFetchQuery({is_durations: true})
  const {data: checkedControlsAll, isLoading: isLoadingCheckedControlsAll} =
    analyticsAPI.useAnalyticsFetchQuery({
      status: 'WITH_RAITING',
    })
  const {data: checkedControlsAllDuration, isLoading: isLoadingCheckedControlsAllDuration} =
    analyticsAPI.useAnalyticsFetchQuery({
      status: 'WITH_RAITING',
      is_durations: true,
    })

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        setPulseState(false)
      }, 10000)
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <div className='card h-100'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='btn btn-icon pulse pulse-success pe-3'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr084.svg'
                className='svg-icon-2tx p-0 svg-icon-success'
              />
              {pulseState && <span className='pulse-ring'></span>}
            </div>
            <div>
              <span className='small fw-bold text-hover-success text-gray-800 m-0 text-uppercase'>
                {intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.ALLTIME'})}
              </span>
              <div className='d-flex flex-wrap fs-6'>
                <div className='small text-gray-500'>
                  {roleAccesses.includes('ANALYTICSALL')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.ALLUSERS'})
                    : roleAccesses.includes('ANALYTICSDEP')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.DEPARTMENT'})
                    : roleAccesses.includes('ANALYTICSOWN')
                    ? intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.OWN'})
                    : ''}
                </div>
              </div>
            </div>
          </div>
          <div className='card-toolbar m-0'>
            <OverlayTrigger
              placement='top-end'
              trigger={['hover', 'focus']}
              overlay={
                <Popover>
                  <div className='p-3 small text-uppercase text-gray-700'>
                    {intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.ALLTIME.INFO'})}
                  </div>
                </Popover>
              }
            >
              <div className='p-0'>
                <KTSVG
                  path='/media/icons/duotune/general/gen046.svg'
                  className='svg-icon-2tx btn p-0'
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className='card-body d-flex flex-row px-9 pt-6 pb-8'>
          {isLoadingCheckedControlsAll || isLoadingCheckedControlsAllDuration ? (
            <div className='d-flex flex-column col-12'>
              <span className='indicator-progress align-self-center' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm text-primary align-middle ms-2 mt-10'></span>
              </span>
            </div>
          ) : (
            <>
              <div className='d-flex flex-column col-6'>
                <div className='d-flex align-items-center fw-bold fs-4 flex-wrap mb-3 text-gray-700'>
                  <div className='fs-3qx fw-bolder text-gray-900'>{checkedControlsAll?.total}</div>
                </div>

                <div className='d-flex align-items-center fw-bold flex-wrap fs-4 text-gray-700'>
                  <OverlayTrigger
                    placement='bottom-start'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover>
                        <div className='p-3 small text-uppercase text-gray-700'>
                          {intl.formatMessage({id: 'ANALYTICS.CHECKEDCONTROLS.DURATION'})}
                        </div>
                      </Popover>
                    }
                  >
                    <i className='fa-regular fa-clock pe-3 fs-4 btn p-0'></i>
                  </OverlayTrigger>
                  <span className='me-3'>
                    {checkedControlsAllDuration && convertTime(checkedControlsAllDuration?.total)}
                  </span>
                </div>
              </div>
              <div className='d-flex col-6'>
                <span className='bullet bullet-line h-auto bg-muted w-1px me-2'></span>
                <div className='d-flex flex-column flex-stack col-12'>
                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {controlsAll &&
                          checkedControlsAll &&
                          getPercent(controlsAll.total, checkedControlsAll.total)}
                        %
                      </span>
                    </OverlayTrigger>
                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{
                          width:
                            controlsAll && checkedControlsAll
                              ? getPercent(controlsAll.total, checkedControlsAll.total) + '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className='d-flex flex-column col-9'>
                    <OverlayTrigger
                      placement='top-start'
                      trigger={['hover', 'focus']}
                      overlay={
                        <Popover>
                          <div className='p-3 small text-uppercase text-gray-700'>
                            {intl.formatMessage({id: 'ANALYTICS.PERCENT.DURATION'})}
                          </div>
                        </Popover>
                      }
                    >
                      <span
                        className='text-gray-700 mb-2 fs-5 fw-semibold'
                        style={{width: 'max-content'}}
                      >
                        {controlsAllDuration &&
                          checkedControlsAllDuration &&
                          getPercent(controlsAllDuration.total, checkedControlsAllDuration.total)}
                        %
                      </span>
                    </OverlayTrigger>

                    <div className='progress h-6px'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{
                          width:
                            controlsAllDuration && checkedControlsAllDuration
                              ? getPercent(
                                  controlsAllDuration.total,
                                  checkedControlsAllDuration.total
                                ) + '%'
                              : 0,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {AllCheckedControls}
