import moment from 'moment'
import OneGroup from './groups/OneGroup'
import {useEffect, useState} from 'react'
import {IGroup} from '../../../models/score/Score'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {useIntl} from 'react-intl'

const CriteriaGroups = () => {
  const intl = useIntl()
  const {data: groups} = scoreAPI.useGroupsFetchQuery()
  const [sortedGroups, setSortedGroups] = useState<IGroup[]>([])

  useEffect(() => {
    let sortedList: IGroup[] = []
    if (groups && groups.length !== 0) {
      sortedList = [...groups].sort((x: IGroup, y: IGroup) => {
        if (moment(x.created_on) > moment(y.created_on)) return -1
        if (moment(x.created_on) < moment(y.created_on)) return 1
        else return 0
      })
    }
    if (sortedList.length !== 0) {
      setSortedGroups(sortedList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  return (
    <>
      {groups?.length !== 0 && sortedGroups.length !== 0 ? (
        <>
          <OneGroup group={sortedGroups[0]} colapsed={false} />
          {sortedGroups.slice(1).map((group: IGroup) => (
            <OneGroup group={group} key={group.id} colapsed={true} />
          ))}
        </>
      ) : (
        <span className='d-flex flex-start px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
    </>
  )
}

export default CriteriaGroups
