import {FC} from 'react'
import {OneGroupCriterion} from './OneGroupCriterion'
import {ICriteria} from '../../../../../models/score/Score'
import {CriteriaGroupTableHeader} from './CriteriaGroupTableHeader'
import {useIntl} from 'react-intl'

type Props = {
  isLoadingGetCriterions: boolean
  groupId: string
  criteriaList: ICriteria[]
}

export const CriteriaGroupTable: FC<Props> = ({isLoadingGetCriterions, groupId, criteriaList}) => {
  const intl = useIntl()
  return (
    <>
      <table className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'>
        <CriteriaGroupTableHeader />
        <tbody>
          {criteriaList &&
            criteriaList.length !== 0 &&
            criteriaList.map((c) => (
              <OneGroupCriterion groupId={groupId} criteria={c} key={c.id} />
            ))}
        </tbody>
      </table>
      {isLoadingGetCriterions && (
        <div className='d-flex flex-center'>
          <span
            className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
            style={{display: 'block'}}
          >
            {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
      {criteriaList.length === 0 && !isLoadingGetCriterions && (
        <span className='d-flex flex-start px-0 small text-uppercase text-muted mt-5 mb-5'>
          {intl.formatMessage({id: 'TABLE.NODATA'})}
        </span>
      )}
    </>
  )
}
