import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  isChecked: boolean
  selectAllEnable: () => void
  selectAllDisable: () => void
}

const ObjectionsAddHeader: FC<Props> = ({isChecked, selectAllEnable, selectAllDisable}) => {
  const intl = useIntl()

  const handleSelectAll = (isSelect: boolean) => {
    if (isSelect) {
      selectAllEnable()
    } else {
      selectAllDisable()
    }
  }

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='min-w-50px'>
          <div className='form-check form-check-custom form-check-solid d-flex flex-center'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={isChecked}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
          </div>
        </th>
        <th role='columnheader' className='min-w-200px'>
          {intl.formatMessage({id: 'TOOLS.OBJECTION.TITLE'})}
        </th>
        <th role='columnheader' className='min-w-150px px-0'>
          {intl.formatMessage({id: 'CRITERIATABLE.AUTHOR'})}
        </th>
        <th role='columnheader' className='min-w-100px px-0'>
          {intl.formatMessage({id: 'CREATION'})}
        </th>
      </tr>
    </thead>
  )
}

export {ObjectionsAddHeader}
