import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import {
  IAuthLogin,
  IAuthModel,
  IForgotPassword,
  IRegister,
  IResponse,
  IToken,
  IUpdatePassword,
} from '../../models/auth/Auth'
import {RootState} from '../Store'

const qs = require('qs')
const API_URL = process.env.REACT_APP_API_URL

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, {getState}) => {
      const accessToken = (getState() as RootState).authReduser.accessToken
      if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
    credentials: 'include',
  }),
  tagTypes: ['auth'],
  endpoints: (build) => ({
    login: build.mutation<IAuthModel, IAuthLogin>({
      query: ({username, password}) => ({
        url: '/auth/login',
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: qs.stringify({
          username: username,
          password: password,
        }),
      }),
    }),
    register: build.mutation<IResponse, IRegister>({
      query: ({
        name,
        email,
        phone,
        organizationName,
        password,
        confirm_password,
        language,
        time_zone,
      }) => ({
        url: '/auth/signup',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          name: name,
          email: email,
          phone: phone,
          company_name: organizationName,
          password: password,
          confirm_password: confirm_password,
          language: language,
          time_zone: time_zone,
        },
      }),
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: '/auth/logout',
        headers: {'Content-Type': 'application/json'},
      }),
    }),
    activate: build.query<void, IToken>({
      query: ({token}) => ({
        url: `/auth/activate/${token}`,
      }),
    }),
    forgotPassword: build.mutation<IResponse, IForgotPassword>({
      query: ({email}) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          email: email,
        },
      }),
    }),
    updatePassword: build.mutation<IResponse, IUpdatePassword>({
      query: ({token, password, confirm_password}) => ({
        url: `/auth/update-password/${token}`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
          password: password,
          confirm_password: confirm_password,
        },
      }),
    }),
  }),
})
