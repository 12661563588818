import {Navigate, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {Activate} from './components/Activate'
import {UpdatePassword} from './components/UpdatePassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login/' element={<Login />} />
      <Route path='registration/' element={<Registration />} />
      <Route path='forgot-password/' element={<ForgotPassword />} />
      <Route path='update-password/:token' element={<UpdatePassword />} />
      <Route path='activate/:token' element={<Activate />} />
      <Route path='accounts/' element={<Navigate to='/auth/' />} />
      <Route path='*' element={<Navigate to='/auth/' />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
