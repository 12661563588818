import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  name?: string
}

const ProfileFullname: FC<Props> = ({name}) => {
  const intl = useIntl()

  return (
    <div className='fv-row mb-8'>
      <label className='ms-2 mb-2 label text-muted text-uppercase'>
        {intl.formatMessage({id: 'AUTH.INPUT.FULLNAME'})}
      </label>
      <span className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-white text-black'>
        {name === '' ? intl.formatMessage({id: 'AUTH.INPUT.FULLNAME'}) : name}
      </span>
    </div>
  )
}

export {ProfileFullname}
