import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAppDispatch} from '../../../redux/hooks/redux'
import {setLoginBackground} from '../../../redux/slices/serviceSlice'
import {authAPI} from '../../../redux/services/AuthService'
import {useState} from 'react'

const initialValues = {
  email: '',
}

const REACT_APP_URL = process.env.REACT_APP_URL

const forgotPasswordSchema = (f: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(f({id: 'FORMIK.ERROR.WRONGEMAILFORMAT'}))
      .min(3, f({id: 'FORMIK.ERROR.MIN'}))
      .max(50, f({id: 'FORMIK.ERROR.MAX'}))
      .required(f({id: 'FORMIK.ERROR.REQUIREDFIELD'})),
  })

export function ForgotPassword() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [sendDisabledState, updateSendDisabledState] = useState(false)
  const [forgotPassword, {isLoading: loading, isError}] = authAPI.useForgotPasswordMutation()
  const intl = useIntl()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema(intl.formatMessage),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await forgotPassword({
        email: values.email,
      })
        .unwrap()
        .then((response) => {
          setStatus(intl.formatMessage({id: 'AUTH.GENERAL.FORGOTPASSWRD.INFO'}))
          updateSendDisabledState(true)
          setTimeout(() => {
            navigate('/login', {replace: true})
          }, 7000)
        })
        .catch((error) => {
          if (error.status === 400) {
            setStatus(intl.formatMessage({id: 'AUTH.PROFILEINFO.FORGOTPASSWORD.ERROR'}))
          } else {
            setStatus(error.detail)
          }
          setSubmitting(false)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <a href={`${REACT_APP_URL}`}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-white.svg')}
            className='h-50px app-sidebar-logo-default mb-5'
          />
        </a>
        <h1 className='text-light fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.GENERAL.FORGOTPASSWRD.TITLE'})}
        </h1>
        <div className='text-muted fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.GENERAL.FORGOTPASSWRD.SUBTITLE'})}
        </div>
      </div>
      {formik.status && (
        <>
          {isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {!isError && (
            <div className='w-lg-400px mb-lg-15 alert alert-info'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      )}
      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-white text-black', {
            'is-invalid': (formik.touched.email && formik.errors.email) || isError,
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-info btn-sm me-4'
          disabled={sendDisabledState}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link
          to='/auth/login/'
          onClick={() => {
            dispatch(setLoginBackground())
          }}
        >
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light-info btn-sm'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({id: 'COMMON.BUTTON.BACK'})}
          </button>
        </Link>
      </div>
    </form>
  )
}
