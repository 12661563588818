import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const CommunicationsHeader = () => {
  const intl = useIntl()

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='w-200px table-fixed-th'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
        </th>
        <th role='columnheader' className='min-w-150px'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.DEPARTMENT'})}
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.ALL'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'COMMUNICATIONS.TABLE.TITLE'})}</span>
          </OverlayTrigger>
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.INCOMING'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}</span>
          </OverlayTrigger>
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.OUTGOING'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}</span>
          </OverlayTrigger>
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.RATINGS.INFO'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'COMMUNICATIONS.HEADER.RATINGS'})}</span>
          </OverlayTrigger>
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.INCOMING.RATINGS'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'FILTERS.DIRECTION.INCOMING'})}</span>
          </OverlayTrigger>
        </th>
        <th role='columnheader' className='min-w-70px text-center'>
          <OverlayTrigger
            placement='top-end'
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <div className='p-3 small text-uppercase text-gray-700'>
                  {intl.formatMessage({id: 'COMMUNICATIONS.HEADER.OUTGOING.RATINGS'})}
                </div>
              </Popover>
            }
          >
            <span>{intl.formatMessage({id: 'FILTERS.DIRECTION.OUTGOING'})}</span>
          </OverlayTrigger>
        </th>
      </tr>
    </thead>
  )
}

export {CommunicationsHeader}
