import {IDepartment} from '../../../app/models'

export const getDepartments = (object: any) => {
  const departmentsValue =
    object.departments && object.departments.length !== 0
      ? object.departments.length > 1
        ? object.departments[0].name + '...'
        : object.departments[0].name
      : '--'
  let departmentsPlaceholder = ''
  if (object.departments) {
    if (departmentsValue.indexOf('...') !== -1) {
      let departmentsList: string[] = []
      object.departments.map((dep: IDepartment) => departmentsList.push(dep.name))
      departmentsPlaceholder = departmentsList.join(', ')
    }
  }

  return [departmentsValue, departmentsPlaceholder]
}

export const getDepartmentsList = (depObjList: {label: string; value: string}[]) => {
  let departmentsList: string[] = []
  depObjList.map((depObj) => departmentsList.push(depObj.value))
  return departmentsList
}

export const localeSettingsDepartmentsSelect = (f: any, loading: any) => {
  return {
    allItemsAreSelected: f({id: 'MULTISELECT.DEPARTMENTS.ALLITEMSSELECTED'}),
    clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
    clearSelected: f({id: 'MULTISELECT.DEPARTMENTS.CLEARSELECTED'}),
    noOptions: loading
      ? f({id: 'COMMON.BUTTON.WAIT'})
      : f({id: 'MULTISELECT.DEPARTMENTS.NOOPTIONS'}),
    search: f({id: 'MULTISELECT.SEARCH'}),
    selectAll: f({id: 'MULTISELECT.DEPARTMENTS.SELECTALL'}),
    selectAllFiltered: f({id: 'MULTISELECT.DEPARTMENTS.SELECTALLFILTERED'}),
    selectSomeItems: f({id: 'MULTISELECT.DEPARTMENTS.SELECT'}),
    create: f({id: 'MULTISELECT.DEPARTMENTS.CREATE'}),
  }
}
