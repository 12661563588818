/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {FC, useEffect, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {usersAPI} from '../../../redux/services/UsersService'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {analyticsAPI} from '../../../redux/services/AnalyticsService'
import {ObjectionsFooter} from '../tables_widgets/objection_table/ObjectionsFooter'
import {DateRangePickerInput} from '../../../../_metronic/helpers/components/DateRangePicker'
import {getSelected} from '../../../../_metronic/helpers/components/GetSelected'
import {useFormsFilters} from '../helpers/FormsProvider'
import {FormsTable} from '../tables_widgets/forms_table/FormsTable'

const FormsAnalytics: FC = () => {
  const intl = useIntl()
  const [periodState, setPeriodState] = useState<{
    start: string
    end: string
  }>({start: '', end: ''})
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const {formsFilter, setFormsFilter} = useFormsFilters()
  const [selectedForms, setSelectedForms] = useState<{label: string; value: string}[]>([])
  const [selectedUsers, setSelectedUsers] = useState<{label: string; value: string}[]>([])
  const [selectedScoreType, setSelectedScoreType] = useState<string>('')
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const [getUsers, {data: users, isLoading: isLoadingGetUsers}] = usersAPI.useLazyUsersFetchQuery()
  const [getAnalytics, {data: analytics, isLoading: isLoadingAnalytic}] =
    analyticsAPI.useLazyAnalyticsFormsFetchQuery()

  const [getForms, {data: forms, isLoading: isLoadingGetForms}] = scoreAPI.useLazyFormsFetchQuery()
  const [formsOptions, setFormsOptions] = useState<{label: string; value: string}[]>([])

  const localeSettingsFormsSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.FORMS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.FORMS.CLEARSELECTED'}),
      noOptions: isLoadingGetForms
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.FORMS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.FORMS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.FORMS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.FORMS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  const localeSettingsUsersSelect = (f: any) => {
    return {
      allItemsAreSelected: f({id: 'MULTISELECT.USERS.ALLITEMSSELECTED'}),
      clearSearch: f({id: 'MULTISELECT.CLEARSEARCH'}),
      clearSelected: f({id: 'MULTISELECT.USERS.CLEARSELECTED'}),
      noOptions: isLoadingGetUsers
        ? f({id: 'COMMON.BUTTON.WAIT'})
        : f({id: 'MULTISELECT.USERS.NOOPTIONS'}),
      search: f({id: 'MULTISELECT.SEARCH'}),
      selectAll: f({id: 'MULTISELECT.USERS.SELECTALL'}),
      selectAllFiltered: f({id: 'MULTISELECT.USERS.SELECTALLFILTERED'}),
      selectSomeItems: f({id: 'MULTISELECT.USERS.SELECT'}),
      create: f({id: 'MULTISELECT.CREATE'}),
    }
  }

  useEffect(() => {
    if (periodState) {
      setFormsFilter({
        period: periodState,
        user_ids: formsFilter.user_ids,
        form_ids: formsFilter.form_ids,
        score_types: formsFilter.score_types,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodState])

  useEffect(() => {
    if (users && users?.users.length !== 0) {
      let usersList: {label: string; value: string}[] = []
      users.users.map((user) => usersList.push({label: user.name, value: user.id}))
      setUsersOptions(usersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (forms && forms.length !== 0) {
      let formsList: {label: string; value: string}[] = []
      forms.forEach((form) => {
        if (form.status === 'PUBLISHED') {
          formsList.push({label: form.name, value: form.id})
        }
      })
      setFormsOptions(formsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms])

  useEffect(() => {
    if (formsFilter.user_ids.length !== 0) {
      setSelectedUsers(formsFilter.user_ids)
    }
    if (formsFilter.form_ids.length !== 0) {
      setSelectedForms(formsFilter.form_ids)
    }
    if (formsFilter.period.start !== '' && formsFilter.period.end !== '') {
      setPeriodState(formsFilter.period)
    }
    if (formsFilter.score_types !== '') {
      setSelectedScoreType(formsFilter.score_types)
    }

    if (
      formsFilter.form_ids.length !== 0 &&
      formsFilter.user_ids.length !== 0 &&
      formsFilter.period.start !== '' &&
      formsFilter.period.end !== ''
    ) {
      getAnalytics({
        period: formsFilter.period.start + ',' + formsFilter.period.end,
        user_ids: getSelected(formsFilter.user_ids),
        form_ids: getSelected(formsFilter.form_ids),
        score_types: formsFilter.score_types,
      })
        .unwrap()
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsFilter])

  return (
    <>
      <div className='card card-custom card-stretch shadow mt-20'>
        <div className='card-header'>
          <div className='card-title d-flex mt-5 mb-3 col-lg-12'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ANAYTICS.FORMS.TITLE'})}</h3>
          </div>
          <div className='col-lg-12 d-flex mb-4'>
            <div className='pe-3 col-lg-3'>
              <MultiSelect
                onMenuToggle={() => {
                  if (!users) {
                    getUsers({is_active: true})
                  }
                  if (selectedUsers.length !== 0) {
                    setFormsFilter({
                      period: formsFilter.period,
                      user_ids: selectedUsers,
                      form_ids: formsFilter.form_ids,
                      score_types: formsFilter.score_types,
                    })
                  }
                }}
                className='text-gray-900'
                options={usersOptions}
                hasSelectAll={true}
                value={selectedUsers}
                onChange={(e: any) => {
                  setSelectedUsers(e)
                  if (e.length === 0) {
                    setFormsFilter({
                      period: formsFilter.period,
                      user_ids: e,
                      form_ids: formsFilter.form_ids,
                      score_types: formsFilter.score_types,
                    })
                  }
                }}
                overrideStrings={localeSettingsUsersSelect(intl.formatMessage)}
                closeOnChangedValue={false}
                labelledBy={intl.formatMessage({id: 'MULTISELECT.USERS.SELECT'})}
              />
            </div>
            <div className='pe-3 col-lg-3'>
              <MultiSelect
                onMenuToggle={() => {
                  if (!forms) {
                    getForms()
                  }
                  if (selectedForms.length !== 0) {
                    setFormsFilter({
                      period: formsFilter.period,
                      user_ids: formsFilter.user_ids,
                      form_ids: selectedForms,
                      score_types: formsFilter.score_types,
                    })
                  }
                }}
                className='text-gray-900'
                options={formsOptions}
                hasSelectAll={true}
                value={selectedForms}
                onChange={(e: any) => {
                  setSelectedForms(e)
                  if (e.length === 0) {
                    setFormsFilter({
                      period: formsFilter.period,
                      user_ids: formsFilter.user_ids,
                      form_ids: e,
                      score_types: formsFilter.score_types,
                    })
                  }
                }}
                overrideStrings={localeSettingsFormsSelect(intl.formatMessage)}
                closeOnChangedValue={false}
                labelledBy={intl.formatMessage({id: 'MULTISELECT.FORMS.SELECT'})}
              />
            </div>
            <div className='pe-3 col-lg-3'>
              <DateRangePickerInput
                language={currentUser?.language}
                timeZone={currentUser?.time_zone}
                rangeId='2'
                periodState={periodState}
                updateState={setPeriodState}
                onlyIcon={false}
                cancel={true}
              />
            </div>
            <div className='pe-3 col-lg-3'>
              <select
                disabled={isLoadingAnalytic}
                value={selectedScoreType}
                className='form-select form-select-solid form-select-lg text-gray-900'
                placeholder={intl.formatMessage({id: 'ANALYTICS.SELECTSCORETYPE'})}
                onChange={(e: any) => {
                  setSelectedScoreType(e.target.value)
                  setFormsFilter({
                    period: formsFilter.period,
                    user_ids: formsFilter.user_ids,
                    form_ids: formsFilter.form_ids,
                    score_types: e.target.value,
                  })
                }}
              >
                <option value='' disabled style={{display: 'none'}}>
                  {intl.formatMessage({id: 'ANALYTICS.SELECTSCORETYPE'})}
                </option>

                <option value='PERCENT_SIMPLE'>
                  {intl.formatMessage({id: 'SCOREFORMS.METHOD.SIMPLE'})}
                </option>
                <option value='NUMBER_AVERAGE_SIMPLE'>
                  {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.AVERAGE'})}
                </option>
                <option value='NUMBER_SUM_SIMPLE'>
                  {intl.formatMessage({id: 'SCOREFORMS.METHOD.NUMBER.SUM'})}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-body px-15'>
          <div className='table-responsive ms-200px'>
            <FormsTable
              selectedForms={formsFilter.form_ids}
              selectedUsers={formsFilter.user_ids}
              analytics={
                formsFilter.user_ids.length !== 0 &&
                formsFilter.form_ids.length !== 0 &&
                formsFilter.period.start !== '' &&
                formsFilter.period.end !== ''
                  ? analytics
                  : undefined
              }
            />
          </div>
          {isLoadingAnalytic ? (
            <div className='d-flex flex-center'>
              <span
                className='indicator-progress d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'
                style={{display: 'block'}}
              >
                {intl.formatMessage({id: 'COMMON.BUTTON.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            (formsFilter.user_ids.length === 0 ||
              formsFilter.form_ids.length === 0 ||
              formsFilter.period.start === '' ||
              formsFilter.period.end === '') && (
              <span className='d-flex flex-center px-0 small text-uppercase text-muted mt-5 mb-5'>
                {intl.formatMessage({id: 'FORMS.TABLE.INFO'})}
              </span>
            )
          )}
        </div>
        <ObjectionsFooter selectedUsers={selectedUsers} />
      </div>
    </>
  )
}

export {FormsAnalytics}
