import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {baseQueryWithReauthDynamicUrl} from './BaseQueryReauth'
import {
  IControlCreate,
  IControlData,
  IControlListResponse,
  IControlRecord,
  IControlScored,
  IControlUpdateOwner,
  IControls,
  IFilterQuery,
} from '../../modules/controls/models/controls'

export const controlsAPI = createApi({
  reducerPath: 'controlsAPI',
  baseQuery: baseQueryWithReauthDynamicUrl,
  tagTypes: ['controls', 'control', 'controlRecord'],
  endpoints: (build) => ({
    controlsFetch: build.query<IControlListResponse, IFilterQuery>({
      query: ({
        type,
        sort_by,
        period,
        call_type,
        rating_date,
        user_ids,
        department_ids,
        rating,
        duration,
        status,
        skip,
        limit,
      }) => ({
        url: '/controls',
        params: {
          type: type,
          sort_by: sort_by,
          period: period!,
          call_type: call_type,
          rating_date: rating_date!,
          user_ids: user_ids!,
          department_ids: department_ids!,
          rating: rating!,
          duration: duration,
          status: status!,
          skip: skip,
          limit: limit,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.controls.map(({id}) => ({type: 'controls' as const, id})),
              {type: 'controls', id: 'LIST'},
            ]
          : [{type: 'controls', id: 'LIST'}],
    }),
    controlsAll: build.query<IControls, void>({
      query: () => ({url: `/controls`}),
    }),
    controlDataById: build.query<IControlData, {id: string}>({
      query: ({id}) => ({
        url: `/controls/${id}`,
      }),
      providesTags: (response) => [{type: 'control', id: response!.id}],
    }),
    controlRecordById: build.query<IControlRecord, {id: string}>({
      query: ({id}) => ({
        url: `/controls/${id}/record`,
      }),
      providesTags: ['controlRecord'],
      keepUnusedDataFor: 3600,
    }),
    controlScoreSave: build.mutation<IControlData, {id: string; data: IControlScored}>({
      query: ({id, data}) => ({
        url: `/controls/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (response) =>
        response ? [{type: 'controls', id: response?.id}] : [{type: 'controls', id: 'LIST'}],
    }),
    controlCreate: build.mutation<IControlData, {id: string; data: IControlCreate}>({
      query: ({id, data}) => ({
        url: `/controls/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{type: 'controls', id: 'LIST'}],
    }),
    controlUpdateOwner: build.mutation<IControlData, {id: string; data: IControlUpdateOwner}>({
      query: ({id, data}) => ({
        url: `/controls/${id}/owner`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (response) => [
        {type: 'controls', id: 'LIST'},
        {type: 'control', id: response!.id},
      ],
    }),
  }),
})
