import {FC} from 'react'

type Props = {
  criterionGroup: string
}

const CriterionGroupCell: FC<Props> = ({criterionGroup}) => {
  return (
    <>
      <span className=''>{criterionGroup}</span>
    </>
  )
}

export {CriterionGroupCell}
