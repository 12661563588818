/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {checkIsActive} from '../../../../../../_metronic/helpers'

const ObjectionNoGropItem: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const to = `/tools/objection_groups/`
  const isActive = checkIsActive(pathname, to)

  return (
    <>
      <div
        className={clsx('d-flex bd-highlight fw-bold small py-2 px-4 rounded', {
          'bg-light-primary': isActive,
        })}
      >
        <div className='bd-highlight w-100'>
          <Link to={to}>
            <div className='menu-item text-gray-800'>
              <div className={clsx('', {'text-primary': isActive})}>
                <div className='d-flex align-items-center'>
                  <div className='flex-grow-1'>
                    <div className='menu-title text-gray-900 text-uppercase'>
                      {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.ALL'})}
                    </div>
                    <span className='fw-bold label text-gray-600 small d-block'>
                      {intl.formatMessage({id: 'TOOLS.OBJECTION.GROUPS.ALL'})}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export {ObjectionNoGropItem}
