/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {useQueryRequest} from './QueryRequestProvider'
import {getPaginationItems} from './pagination'
import PageLink from './PageLink'

interface Props {
  total: number
}

const TablePagination: FC<Props> = ({total}) => {
  const PN = 7
  const {state, updateState} = useQueryRequest()
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(Math.ceil(total / state.limit!))
  const [pageNums, setPageNums] = useState(getPaginationItems(currentPage, lastPage, PN))

  function pn(currentPage: number) {
    setPageNums(getPaginationItems(currentPage, lastPage, PN))
  }

  useEffect(() => {
    const newLastPage = Math.ceil(total / state.limit!)
    if (newLastPage !== lastPage || state.search !== undefined) {
      setCurrentPage(1)
      setLastPage(newLastPage)
      setPageNums(getPaginationItems(currentPage, newLastPage, PN))
      updateState({skip: 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.limit, state.search, total])

  return (
    <div className='col-sm-7 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
      <div id='kt_table_users_paginate'>
        <ul className='pagination'>
          <li className={clsx('page-item previous', {disabled: currentPage === 1})}>
            <PageLink
              className='page-link'
              style={{cursor: 'pointer'}}
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1)
                updateState({skip: state.skip! - state.limit!})
                pn(currentPage - 1)
              }}
            >
              <i className='previous' />
            </PageLink>
          </li>
          {pageNums.map((pageNum, idx) => (
            <li
              className={clsx('page-item', {
                active: currentPage === pageNum || pageNums.length === 1,
              })}
              key={idx}
            >
              <PageLink
                key={idx}
                className='page-link'
                active={currentPage === pageNum || pageNums.length === 1}
                disabled={isNaN(pageNum)}
                onClick={() => {
                  setCurrentPage(pageNum)
                  updateState({skip: state.limit! * (pageNum - 1)})
                  pn(pageNum)
                }}
              >
                {!isNaN(pageNum) ? pageNum : '...'}
              </PageLink>
            </li>
          ))}
          <li
            className={clsx('page-item next', {
              disabled: currentPage === lastPage,
            })}
          >
            <PageLink
              className='page-link'
              disabled={currentPage === lastPage}
              onClick={() => {
                setCurrentPage(currentPage + 1)
                updateState({skip: state.skip! + state.limit!})
                pn(currentPage + 1)
              }}
            >
              <i className='next' />
            </PageLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export {TablePagination}
