import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  forms?: {label: string; value: string}[]
}

const FormsHeader: FC<Props> = ({forms}) => {
  const intl = useIntl()

  return (
    <thead>
      <tr className='text-gray-600 fw-bold fs-7 gs-0 px-10 text-uppercase small'>
        <th role='columnheader' className='table-fixed-th'>
          {intl.formatMessage({id: 'USERS.TABLE.HEADER.EMPLOYEE'})}
        </th>
        {forms && forms.length !== 0 ? (
          forms.map((form) => (
            <td role='columnheader' className='min-w-70px px-0 text-center' key={form.value}>
              {form.label}
            </td>
          ))
        ) : (
          <td role='columnheader' className='text-center'>
            {intl.formatMessage({id: 'TOOLS.SCOREFORMS.TITLE'})}
          </td>
        )}
        <td role='columnheader' className='min-w-200px px-0 text-center'>
          {intl.formatMessage({id: 'FORMS.AVERAGE.EMPLOYEE'})}
        </td>
      </tr>
    </thead>
  )
}

export {FormsHeader}
